<template>
	<main id="verifyphone" class="verifyphone page">
		<div class="content">
			<span class="verifyphone__logo">
				<svg viewBox="0 0 189.3 62">
					<use xlink:href="#logo--main" width="100%" height="100%"></use>
				</svg>
			</span>
			<div class="verifyphone__content">
				<VerifyPhoneSystem />
			</div>
		</div>
	</main>
</template>
<script>
import { EventBus } from '@/events_bus.js';

import VerifyPhoneSystem from '@/views/components/common/VerifyPhoneSystem';

export default {
	name: 'VerifyPhone',
	components: {
		VerifyPhoneSystem
	},
	data() {
		return {
			wWidth: 0,
		}
	},
	methods: {
		onResize() {
			this.wWidth = window.innerWidth;
			if(this.wWidth < 1200) {
				this.editMode = 'mobile'
			} else {
				this.editMode = 'desktop'
			}
		},
		init() {
			
		}
	},
	mounted() {
		this.onResize();
		this.$nextTick(() => {
			window.addEventListener('resize', () => {
				this.onResize();
			});
		});
		this.init();
	}
}
</script>
<style lang="scss">
.verifyphone {
	background: $color-seasalt;
	height: 100svh;
	position: relative;
	&:before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		display: block;
		background: url('~@/assets/images/texture-green.png') repeat-x;
		@include size(100%,100%);
		z-index: 1;
		pointer-events: none;
	}
	.content {
		@extend %flexCenterCenter;
		flex-direction: column;
		height: 100%;
	}
	&__logo {
		display: block;
		@include size(190px,62px);
		svg {
			display: block;
			@include size(100%,100%);
			fill: $color-green;
		}
	}
	&__content {
		display: block;
		width: 100%;
		margin: var(--supergutter) 0;
	}
	&__text {
		@extend %text-19;
		display: block;
		width: 100%;
	}
	&__code {
		@extend %flexCenterCenter;
		width: 100%;
		margin: var(--gutter) 0 0;
		input {
			@extend %text-40;
			@extend %flexCenterCenter;
			@include size(var(--supergutter),var(--smallpanelh));
			border-radius: calc(var(--bigradius) / 2);
			border: 1px solid $color-grey;
			color: $color-black;
			margin: 0 6px;
			text-align: center;
		}
	}
	&__instruction {
		@extend %text-15;
		display: block;
		width: 100%;
		text-align: center;
		margin: var(--gutter) 0;
	}
	&__resend {
		display: block;
		width: max-content;
		margin: var(--gutter) auto 0;
		font-variation-settings: 'wght' 700;
		cursor: pointer;
	}
	&__loading {
		display: block;
		width: 100%;
		&__label {
			display: block;
			@extend %text-20;
			font-family: $font-manrope;
			font-variation-settings: 'wght' 600;
			text-align: center;
		}
		&__spinner {
			display: block;
			@include size(65px,65px);
			position: relative;
			margin: var(--gutter) auto;
		}
	}
	&__tooshort {
		display: block;
		width: 100%;
		text-align: center;
		color: $color-error;
		text-transform: uppercase;
		@extend %text-12;
		padding-top: var(--minigutter);
	}
	&__result {
		display: block;
		width: 100%;
		text-align: center;
		&__icon {
			@extend %flexCenterCenter;
			@include size(var(--icon),var(--icon));
			border: 4px solid $color-black;
			border-radius: 100%;
			margin: 0 auto;
			svg {
				display: block;
				@include size(60%,60%);
			}
		}
		&__label {
			display: block;
			width: 100%;
			text-align: center;
			@extend %text-20;
			font-family: $font-manrope;
			font-variation-settings: 'wght' 600;
			margin-top: var(--gutter);
		}
		&__btn {
			display: block;
			width: max-content;
			padding: 0 var(--gutter);
			box-shadow: 0;
			margin: var(--supergutter) auto;
			&.account {
				background: $color-blue;
				color: $color-white;
			}
			&.retry {
				background: $color-green;
				color: $color-white;
			}
		}
		&.confirm {
			.verifyphone__result {
				&__icon {
					border-color: $color-success;
					svg {
						fill: $color-success;
					}
				}
				&__label {
					color: $color-success;
				}
			}
		}
		&.error {
			.verifyphone__result {
				&__icon {
					border-color: $color-error;
					svg {
						fill: $color-error;
					}
				}
				&__label {
					color: $color-error;
				}
			}
		}
	}
	&__text {
		display: block;
		width: 100%;
		text-align: center;
	}
}
</style>