<template>
	<div class="stats">
		<div class="stats__head">
			<span v-if="statType" class="stats__head__label">
				{{ $t(`common.titles.stats.${statType}`) }}
			</span>
			<span class="stats__head__actions" v-if="garagesList && garagesList.length">
				<Select name="periods" :options="periods" :base="periods[0]" @input="filterChanges($event)" />
				<Select name="garage_ids" :options="garagesList" :base="garagesList[0]" @input="filterChanges($event)" />
			</span>
		</div>
		<div class="stats__content">
			<template v-if="data && data.length">
				<BarChart :data="data" class="barchart" />
			</template>
			<template v-else>
				<span class="stats__content__nodata" v-html="$t('dashboard.stats.nodata')"></span>
			</template>
		</div>
		<div class="stats__totals">
			
		</div>
	</div>
</template>
<script>
import Select from '@/views/components/stats/Select';
import BarChart from '@/views/components/common/BarChart';

export default {
	name: 'Stats',
	components: {
		Select,
		BarChart
	},
	props: {
		statType: {
			type: String,
			required: true,
		},
		garages: {
			type: Array,
			required: true
		}
	},
	data() {
		return {
			data: null,
			filters: {
				periods: 'LAST_7_DAYS',
				garage_ids: ''
			},
			garagesList: null,
			periods: [
				{
					id: 'LAST_7_DAYS',
					value: 'LAST_7_DAYS',
					label: this.$t(`dashboard.stats.periods.last_7_days`)
				},
				{
					id: 'LAST_30_DAYS',
					value: 'LAST_30_DAYS',
					label: this.$t(`dashboard.stats.periods.last_30_days`)
				},
				{
					id: 'LAST_90_DAYS',
					value: 'LAST_90_DAYS',
					label: this.$t(`dashboard.stats.periods.last_90_days`)
				},
			]
		}
	},
	methods: {
		filterChanges(e) {
			if(e && e.selected) {
				this.filters[e.filter] = e.selected.value;
				this.getData();
			}
		},
		getData() {
			const params = `filters[period]=${this.filters.periods}${(this.filters.garage_ids != '') ? `&filters[garage_ids][]=${this.filters.garage_ids}` : ''}`;
			if(this.statType == 'earning') {
				this.$service.stats.earnings(params).then(res => {
					if(res && res.data) {
						if(res.data.length) {
							this.data = res.data;
						}
					} else {
						this.data = [];
					}
				})
			} else {
				this.$service.stats.bookings(params).then(res => {
					if(res && res.data) {
						if(res.data.length) {
							this.data = res.data;
						} else {
							this.data = [];
						}
					}
				})
			}
		},
		init() {
			this.garagesList = [];
			
			setTimeout(() => {
				this.garagesList.push({
					id: '',
					value: '',
					label: this.$t('dashboard.stats.garages.all')
				})
				this.garages.forEach(g => {
					this.garagesList.push({
						id: g.id,
						value: g.id,
						label: g.name
					})
				})
				this.getData();
			}, 50)
		}
	},
	mounted() {
		if(this.garages && this.garages.length) {
			this.init();
		}
	}
}
</script>
<style lang="scss">
.stats {
	@extend %panel;
	@extend .bigradius;
	width: 100%;
	font-family: $font-manrope;
	padding: var(--minigutter);
	margin-bottom: var(--gutter);
	&__head {
		@extend %flexCenterBetween;
		width: 100%;
		margin-bottom: var(--minigutter);
		&__label {
			@extend %text-20;
			width: 40%;
			font-variation-settings: 'wght' 700;
		}
		&__actions {
			@extend %flexCenterEnd;
			width: 60%;
		}
		&__btn {
			@extend %flexCenterCenter;
			@extend %simplebtn;
		}
	}
	&__content {
		@extend %flexCenterCenter;
		width: 100%;
		.barchart {
			width: 100%;
			height: 350px;
		}
		&__nodata {
			@extend %flexCenterCenter;
			@include size(100%,150px);
			@extend %text-13;
			text-transform: uppercase;
		}
	}
	@media (min-width: $from-huge) {
		width: 49%;
		margin-bottom: 0;
		padding: var(--gutter);
	}
}
</style>