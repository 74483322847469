import { EventBus } from '@/events_bus.js';
import Vue from 'vue';

const VUE_APP_FIREBASE_APIKEY = (process.env.VUE_APP_FIREBASE_APIKEY !== undefined && process.env.VUE_APP_FIREBASE_APIKEY !== "") ? process.env.VUE_APP_FIREBASE_APIKEY : '{{ VUE_APP_FIREBASE_APIKEY }}'
const VUE_APP_FIREBASE_AUTHDOMAIN = (process.env.VUE_APP_FIREBASE_AUTHDOMAIN !== undefined && process.env.VUE_APP_FIREBASE_AUTHDOMAIN !== "") ? process.env.VUE_APP_FIREBASE_AUTHDOMAIN : '{{ VUE_APP_FIREBASE_AUTHDOMAIN }}'
const VUE_APP_FIREBASE_PORJECTID = (process.env.VUE_APP_FIREBASE_PORJECTID !== undefined && process.env.VUE_APP_FIREBASE_PORJECTID !== "") ? process.env.VUE_APP_FIREBASE_PORJECTID : '{{ VUE_APP_FIREBASE_PORJECTID }}'
const VUE_APP_FIREBASE_STORAGEBUCKET = (process.env.VUE_APP_FIREBASE_STORAGEBUCKET !== undefined && process.env.VUE_APP_FIREBASE_STORAGEBUCKET !== "") ? process.env.VUE_APP_FIREBASE_STORAGEBUCKET : '{{ VUE_APP_FIREBASE_STORAGEBUCKET }}'
const VUE_APP_FIREBASE_MESSAGINGSENDERID = (process.env.VUE_APP_FIREBASE_MESSAGINGSENDERID !== undefined && process.env.VUE_APP_FIREBASE_MESSAGINGSENDERID !== "") ? process.env.VUE_APP_FIREBASE_MESSAGINGSENDERID : '{{ VUE_APP_FIREBASE_MESSAGINGSENDERID }}'
const VUE_APP_FIREBASE_APPID = (process.env.VUE_APP_FIREBASE_APPID !== undefined && process.env.VUE_APP_FIREBASE_APPID !== "") ? process.env.VUE_APP_FIREBASE_APPID : '{{ VUE_APP_FIREBASE_APPID }}'
const VUE_APP_FIREBASE_MEASUREMENTID = (process.env.VUE_APP_FIREBASE_MEASUREMENTID !== undefined && process.env.VUE_APP_FIREBASE_MEASUREMENTID !== "") ? process.env.VUE_APP_FIREBASE_MEASUREMENTID : '{{ VUE_APP_FIREBASE_MEASUREMENTID }}'

import { initializeApp } from "firebase/app";
import { getMessaging, getToken, deleteToken } from "firebase/messaging";


export default {
	install: function (Vue) {
		var config = {
			apiKey: VUE_APP_FIREBASE_APIKEY,
			authDomain: VUE_APP_FIREBASE_AUTHDOMAIN,
			projectId: VUE_APP_FIREBASE_PORJECTID,
			storageBucket: VUE_APP_FIREBASE_STORAGEBUCKET,
			messagingSenderId: VUE_APP_FIREBASE_MESSAGINGSENDERID,
			appId: VUE_APP_FIREBASE_APPID,
			measurementId: VUE_APP_FIREBASE_MEASUREMENTID
		};
		const app = initializeApp(config);
		const messaging = getMessaging(app);

		function initNotifications() {
			let me = null;
			Vue.prototype.$messaging = messaging;
			Notification.requestPermission().then(permission => {
				localStorage.removeItem('no-notifications');
				if(permission === 'granted') {
					Vue.prototype.$service.user.me.get().then(res => {
						res.push_notification = true;
						Vue.prototype.$service.user.me.update(res).then(r => {
							console.log('Notification granted.');
						})
					})
					console.log('GRANTED');
					getToken(messaging, { vapidKey: 'BCsYMyN-XZqBkIbV7RVO3uWhR1DWQvrviP6bij0WuFAjnsz6DJxfnkEdZW3qgly4QJ16SGmjOZGGxZVtL4D5FFQ' }).then((currentToken) => {
						if (currentToken) {
							localStorage.setItem('firebase_token', currentToken)
						} else {
							console.log('No registration token available. Request permission to generate one.');
						}
					}).catch((err) => {
						console.log('An error occurred while retrieving token. ', err);
					});
				} else {
					console.log('Notification not granted');
					Vue.prototype.$service.user.me.get().then(res => {
						res.push_notification = false;
						Vue.prototype.$service.user.me.update(res).then(r => {
							console.log('Notification granted.');
						})
					})
					// if(!localStorage('no-notifications')) {
					// 	EventBus.$emit('no-notifications')
					// }
				}
			}).catch(err => {
				console.error('ERR', err);
			})
		}

		function getPushPermission() {
			Vue.prototype.$service.user.me.get().then(res => {
				if(res.push_notification) {
					initNotifications();
				}
			})
		}

		let tokenTimer = setInterval(() => {
			if(localStorage.getItem('token') && localStorage.getItem('token') != '') {
				// initNotifications();
				getPushPermission();
				clearInterval(tokenTimer);
			}
		}, 1000)

		EventBus.$on('ask-notification', () => {
			initNotifications();
		})

		EventBus.$on('revoke-notification', () => {
			console.log('REVOKE', messaging)
			deleteToken(messaging);
		});

		// Notification
		// 	.requestPermission()
		// 	.then(function () {
		// 		console.log("Notification permission granted.");
		// 		getToken({ vapidKey: 'BCsYMyN-XZqBkIbV7RVO3uWhR1DWQvrviP6bij0WuFAjnsz6DJxfnkEdZW3qgly4QJ16SGmjOZGGxZVtL4D5FFQ' })
		// 			.then(function (currentToken) {
		// 				localStorage.setItem('firebase_token', currentToken);
		// 			})
		// 			.catch(function (err) {
		// 				console.log(
		// 					"An error occurred while retrieving token. ",
		// 					err
		// 				);
		// 			});
		// 	})
		// 	.catch(function (err) {
		// 		console.log("Unable to get permission to notify.", err);
		// 	});

	},
};
