<template>
	<div class="smartbox__device device" :id="`device__${index}`">
		<span class="device__row">
			<span class="device__label type">Tipologia</span>
			<span class="device__box type">
				<label class="device__type" :for="`switchbot__${index}`">
					<input type="radio" :name="`device_type__${index}`" :id="`switchbot__${index}`" v-model="device.type" :value="`SWITCHBOT`" />
					<span class="device__type__fake"></span>
					<span class="device__type__value">Switchbot</span>
				</label>
				<label class="device__type" :for="`solo__${index}`">
					<input type="radio" :name="`device_type__${index}`" :id="`solo__${index}`" v-model="device.type" :value="`SOLO`" />
					<span class="device__type__fake"></span>
					<span class="device__type__value">Solo</span>
				</label>
			</span>
		</span>
		<template v-if="device.type">
			<template v-if="device.type == 'SOLO'">
				<span v-if="limit > 1" class="device__row">
					<label for="solomulti" class="solo__toggle">
						<span class="form__characts__solo__title" v-html="`Il tuo SOLO gestisce più di un varco?`"></span>
						<input type="checkbox" name="solomulti" id="solomulti" class="solo__toggle__input" @change="updateMultisolo" />
						<span class="solo__toggle__btn" :class="{ 'on': multisolo }"></span>
					</label>
				</span>
				<small v-if="multisolo" class="device__info" v-html="$t('garages.devices.multisolo')">
				</small>
				<template v-if="multisolo">
					<span class="device__row">
						<span class="device__label">Seriale</span>
						<span class="device__box">
							<input type="text" name="serial_number" id="serial_number" placeholder="Numero Seriale" v-model="device.configuration.mac_address" />
						</span>
					</span>
					<span class="device__row">
						<span class="device__label">Nome primo varco</span>
						<span class="device__box">
							<input type="text" name="device_name" id="device_name" placeholder="Nome device" v-model="device.name" />
						</span>
					</span>
					<span class="device__row">
						<span class="device__label">Nome secondo varco</span>
						<span class="device__box">
							<input type="text" name="device_name" id="device_name" placeholder="Nome device" v-model="device.tmpname" />
						</span>
					</span>
				</template>
				<template v-else>
					<span class="device__row">
						<span class="device__label">Seriale</span>
						<span class="device__box">
							<input type="text" name="serial_number" id="serial_number" placeholder="Numero Seriale" v-model="device.configuration.mac_address" />
						</span>
					</span>
					<span class="device__row">
						<span class="device__label">Nome</span>
						<span class="device__box">
							<input type="text" name="device_name" id="device_name" placeholder="Nome device" v-model="device.name" />
						</span>
					</span>
				</template>
				<template v-if="device.type == 'SOLO'">
					<small class="device__info" v-html="$t('garages.devices.solo')"></small>
				</template>
			</template>
			<template v-else>
				<span class="device__row">
					<span class="device__label">Nome</span>
					<span class="device__box">
						<input type="text" name="device_name" id="device_name" placeholder="Nome device" v-model="device.name" />
					</span>
				</span>
					<span class="device__row">
					<span class="device__label">Configurazione</span>
					<span class="device__box device__config">
						<input type="text" name="device_mac" id="device_mac" placeholder="mac address" v-model="device.configuration.mac_address" />
						<input type="text" name="device_psw" id="device_psw" placeholder="password" v-model="device.configuration.password" />
					</span>
				</span>
				<template v-if="device.type == 'SWITCHBOT'">
					<small class="device__info" v-html="$t('garages.devices.switchbot')"></small>
				</template>
			</template>
		</template>
		<small class="device__remove" @click="removeDevice">Elimina device</small>
	</div>
</template>
<script>
export default {
	name: 'NewDevice',
	props: {
		index: {
			type: Number,
			required: true,
		},
		device: {
			type: Object,
			required: true,
		},
		limit: {
			type: Number,
			required: true,
		}
	},
	data() {
		return {
			multisolo: false,
		}
	},
	methods: {
		updateMultisolo() {
			this.multisolo = !this.multisolo;
			if(this.multisolo) {
				EventBus.$emit('multisolo');
			} else {
				EventBus.$emit('nomultisolo');
			}
		},
		removeDevice() {
			EventBus.$emit('remove-device', this.index)
		},
		init() {
		}
	},
	
	mounted() {
		this.init();
		EventBus.$on('is-multisolo', () => {
			this.multisolo = true;
		})
	}
}

import { EventBus } from '@/events_bus.js';
</script>
<style lang="scss">
.device {
	display: block;
	width: 100%;
	margin: var(--thirdgutter) 0;
	padding-bottom: var(--gutter);
	margin-top: var(--minigutter);
	margin-bottom: var(--guttergutter);
	border-bottom: 1px solid rgba($color-black, 0.4);
	position: relative;
	input[type="text"] {
		display: block;
		@extend %text-15;
		@include size(100%,var(--input));
		border: 1px solid $color-black;
		border-radius: var(--inputradius);
		margin-bottom: var(--thirdgutter);
		padding-left: var(--thirdgutter);
	}
	&__title {
		@extend %text-12;
		font-variation-settings: 'wght' 650;
		text-transform: uppercase;
		display: block;
		width: 100%;
		margin-bottom: var(--minigutter);
	}
	&__row {
		display: block;
		width: 100%;
		margin-bottom: calc(var(--thirdgutter) * 2);
	}
	&__label,
	&__box {
		display: block;
		width: 100%;
		margin-bottom: var(--thirdgutter);
	}
	&__label {
		@extend %text-12;
		text-transform: uppercase;
		text-align: left;
	}
	&__box {
		&.type {
			@extend %flexCenterStart;
			width: 100%;
		}
	}
	&__type {
		@extend %flexCenterStart;
		width: 50%;
		padding-right: var(--minigutter);
		cursor: pointer;
		input {
			display: none;
		}
		&__fake {
			display: block;
			@include size(20px,20px);
			border: 1px solid $color-black;
			@extend %arrowClose;
			border-radius: 100%;
			position: relative;
			&:before, &:after {
				background: $color-blue;
				@include size(55%,2px);
			}
			&:after {
				width: 28%;
				margin-left: -3px;
			}
			&:before {
				margin-left: 2px;
			}
		}
		input:checked ~ .device__type__fake {
			&:before, &:after {
				display: block;
			}
		}
		input:not(:checked) ~ .device__type__fake {
			&:before, &:after {
				display: none;
			}
		}
		&__value {
			display: block;
			padding-left: var(--thirdgutter);
			line-height: 1.5rem;
		}
	}
	&__config {
		@extend %flexCenterStart;
		display: flex;
		flex-wrap: wrap;
		input {
			width: 100%;
		}
	}
	&__remove {
		@extend %text-12;
		position: absolute;
		bottom: var(--thirdgutter);
		right: 0;
		width: max-content;
		color: $color-error;
		text-transform: uppercase;
		margin-top: var(--minigutter);
		cursor: pointer;
	}
	&__info {
		display: block;
		width: 100%;
		@extend %text-13;
		text-align: left;
		margin-bottom: var(--minigutter);
		a {
			color: $color-blue
		}
	}
	.type {
		@extend %flexCenterStart;
		flex-wrap: wrap;
		display: flex !important;
	}
	.solo {
		&__toggle {
			@extend %flexCenterBetween;
			width: 100%;
			margin: var(--gutter) 0 var(--minigutter);
			cursor: pointer;
			&__input {
				display: none;
			}
			&__label {
				@extend %text-15;
				display: block;
				font-family: $font-manrope;
				padding-right: var(--thirdgutter);
				text-align: left;
			}
			&__btn {
				display: block;
				@include size(48px,22px);
				border-radius: var(--minigutter);
				border: 1px solid $color-davy;
				position: relative;
				&:before {
					content: '';
					position: absolute;
					top: 50%;
					left: 2px;
					display: block;
					background: $color-blue;
					@include size(16px,16px);
					border-radius: 100%;
					transform: translateY(-50%);
					transition: 180ms linear;
					filter: grayscale(1);
				}
				&.on {
					&:before {
						left: calc(100% - 18px);
						filter: grayscale(0);
					}
				}
			}
		}
	}
	@media (min-width: $from-large) {
		&__row {
			display: flex;
			flex-wrap: wrap;
			margin-bottom: var(--minigutter);
		}
		&__label, &__box {
			margin-bottom: 0;
		}
		&__label {
			display: flex;
			align-items: center;
			@include size(120px, var(--input));
			&.type {
				height: 24px;
			}
		}
		&__box {
			width: calc(100% - 120px);
			&.type {
				flex-wrap: wrap;
				width: calc(100% - 120px);
			}
		}
		&__type {
			width: max-content;
			margin-right: var(--minigutter);
		}
		&__config {
			input {
				width: 49% !important;
				margin-right: 1%;
			}
		}
		&__info {
			width: calc(100% - 120px);
			margin-left: 120px;
		}
	}
}
</style>
