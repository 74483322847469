<template>
	<main id="resetpsw" class="resetpsw page">
		<div class="content">
			<span class="resetpsw__logo">
				<svg viewBox="0 0 189.3 62">
					<use xlink:href="#logo--main" width="100%" height="100%"></use>
				</svg>
			</span>
			<div class="resetpsw__content">
				<form id="changepassword" class="changepswform">
					<div class="form__field">
						<label for="psw" class="cchangepswform__label">
							{{ $t('create.psw.label') }}
						</label>
						<span class="input-box">
							<input type="password" name="psw" id="psw" class="form__input" :placeholder="$t('create.psw.placeholder')" v-model="form.password" />
							<span class="show-psw" @click="togglePswVisibility">
								<span class="show-psw__show">
									<svg viewBox="0 0 50 50">
										<use xlink:href="#icon--hidepsw" width="100%" height="100%"></use>
									</svg>
								</span>
								<span class="show-psw__hide">
									<svg viewBox="0 0 50 50">
										<use xlink:href="#icon--showpsw" width="100%" height="100%"></use>
									</svg>
								</span>
							</span>
						</span>
						<small class="form__error"></small>
					</div>
					<div class="form__field">
						<label for="confirmpsw" class="changepswform__label">
							{{ $t('create.confirmpsw.label') }}
						</label>
						<span class="input-box">
							<input type="password" name="confirmpsw" id="confirmpsw" class="form__input" :placeholder="$t('create.confirmpsw.placeholder')" v-model="form.password_confirmation" />
							<span class="show-psw" @click="togglePswVisibility">
								<span class="show-psw__show">
									<svg viewBox="0 0 50 50">
										<use xlink:href="#icon--hidepsw" width="100%" height="100%"></use>
									</svg>
								</span>
								<span class="show-psw__hide">
									<svg viewBox="0 0 50 50">
										<use xlink:href="#icon--showpsw" width="100%" height="100%"></use>
									</svg>
								</span>
							</span>
						</span>
						<small class="form__error"></small>
					</div>
					<span v-if="message" class="form__field errors">
						<small v-html="message"></small>
					</span>
					<div class="form__field actions">
						<span id="save" class="changepswform__button btn filled" @click="setNewPassword">
							{{ $t('common.btns.save') }}
							<template v-if="isSaving">
								<span class="changepswform__saving">
									<template v-if="!editSaved">
										<span class="changepswform__saving__spinner">
											<Spinner />
										</span>
									</template>
									<template v-else>
										<span class="changepswform__saving__done">
											<svg viewBox="0 0 50 50">
												<use xlink:href="#icon--check" width="100%" height="100%"></use>
											</svg>
										</span>
									</template>
								</span>
							</template>
						</span>
					</div>
				</form>
			</div>
		</div>
	</main>
</template>
<script>
import Spinner from '@/views/components/common/Spinner';

export default {
	name: 'PasswordReset',
	components: {
		Spinner
	},
	data() {
		return {
			isLoading: false,
			isSaving: false,
			editSaved: false,
			params: null,
			form: {
				password: null,
				password_confirmation: null
			},
			message: null,
			error: null,
			psw: null,
			pswVerify: null,
			nomatch: false,
		}
	},
	watch: {
		'form.password_confirm': {
			handler(newval, oldval) {
				if(this.form.password === this.form.password_confirmation) {
					this.message = null;
				}
			}
		}
	},
	methods: {
		onResize() {
			this.wWidth = window.innerWidth;
			if(this.wWidth < 1200) {
				this.editMode = 'mobile'
			} else {
				this.editMode = 'desktop'
			}
		},
		togglePswVisibility(item) {
			let input = item.target.parentNode.querySelector('input');

			item.target.classList.contains('show') ? item.target.classList.remove('show') : item.target.classList.add('show')

			if(input.type == 'password') {
				input.type = 'text';
			} else {
				input.type = 'password';
			}

		},
		setNewPassword() {
			this.isSaving = true;
			if(this.form.password === this.form.password_confirmation) {
				this.message = null;
				let data = {
					token: this.params.hash,
					email: this.params.email,
					password: this.form.password,
					password_confirmation: this.form.password_confirmation
				}
				this.$service.login.resetPassword(data).then(res => {
					this.editSaved = true;
					setTimeout(() => {
						this.isSaving = false;
						this.$router.push({ name: 'login.page' });
					}, 1500)
				})
			} else {
				this.isLoading = false;
				this.message = this.$t('account.password.errors.nomatch');
			}
		},
		init() {
			this.params = {
				hash: null,
				email: null
			}

			if(this.$route) {
				if(this.$route.params && this.$route.params.hash) {
					this.params.hash = this.$route.params.hash;
				}
				if(this.$route.query && this.$route.query.email) {
					this.params.email = this.$route.query.email;
				}
			}
		}
	},
	mounted() {
		this.onResize();
		this.$nextTick(() => {
			window.addEventListener('resize', () => {
				this.onResize();
			});
		});
		this.init();
	}
}
</script>
<style lang="scss">
.resetpsw {
	background: $color-seasalt;
	height: 100svh;
	position: relative;
	&:before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		display: block;
		background: url('~@/assets/images/texture-green.png') repeat-x;
		@include size(100%,100%);
		z-index: 1;
		pointer-events: none;
	}
	.content {
		@extend %flexCenterCenter;
		flex-direction: column;
		height: 100%;
	}
	&__logo {
		display: block;
		@include size(190px,62px);
		svg {
			display: block;
			@include size(100%,100%);
			fill: $color-green;
		}
	}
	&__content {
		display: block;
		width: 100%;
		margin: var(--supergutter) 0;
	}
	.input-box {
		display: block;
		width: 100%;
		position: relative;
		.show-psw {
			position: absolute;
			top: 50%;
			right: var(--thirdgutter);
			display: block;
			@include size(var(--smallicon),var(--smallicon));
			transform: translateY(-50%);
			cursor: pointer;
			&__show,
			&__hide {
				position: absolute;
				top: 0;
				left: 0;
				display: flex;
				@extend %flexCenterCenter;
				@include size(100%,100%);
				pointer-events: none;
				svg {
					display: block;
					@include size(100%,100%);
					fill: $color-black;
				}
			}
			&__hide {
				display: none;
			}
			&.show {
				.show-psw__show {
					display: none;
				}
				.show-psw__hide {
					display: block;
				}
			}
		}
	}
	.changepswform {
		display: block;
		width: 100%;
		margin: var(--supergutter) 0;
		&__button {
			padding: 0 var(--supergutter);
			background: $color-blue;
			color: $color-white;
			position: relative;
			overflow: hidden;
		}
		&__saving {
			position: absolute;
			top: 0;
			left: 0;
			@extend %flexCenterCenter;
			@include size(100%,100%);
			background: $color-blue;
			&__spinner {
				@include size(calc(var(--gutter) * 0.75),calc(var(--gutter) * 0.75));
				position: relative;
				.spinner {
					@include size(calc(var(--gutter) * 0.75),calc(var(--gutter) * 0.75));
					margin: 0 auto;
					.path {
						stroke: $color-white !important;
					}
				}

			}
			&__done {
				@include size(calc(var(--gutter) * 0.75),calc(var(--gutter) * 0.75));
				svg {
					fill: $color-white;
				}
			}
		}
	}
	&__text {
		display: block;
		width: 100%;
		text-align: center;
	}
	@media (min-width: $from-medium) {
		&__content {
			width: 350px;
		}
	}
}
</style>