<template>
	<main id="page" class="reviews">
		<div class="mobile__head">
			<span class="mobile__head__back" @click="closeDrawer">
				<span class="mobile__head__back__icon">
					<svg viewBox="0 0 50 50">
						<use xlink:href="#icon--back" width="100%" height="100%"></use>
					</svg>
				</span>
				<span class="mobile__head__back__label">
					{{ $t('common.back') }}
				</span>
			</span>
		</div>
		<template v-if="pageLoading">
			<Loading :isLoading="pageLoading" />
		</template>
		<template v-else>
			<template v-if="noreviews">
				<div class="reviews__empty">
					<figure class="reviews__empty__media">
						<img src="@/assets/images/boy.png" alt="" />
					</figure>
					<span class="reviews__empty__title" v-html="$t('reviews.empty.title')"></span>
					<span class="reviews__empty__text" v-html="$t('reviews.empty.text')"></span>
				</div>
			</template>
			<template v-else>
				<div class="reviews__head">
					<div class="content">
						<div class="reviews__head__title">
							{{ $t('common.titles.reviews') }}
						</div>
					</div>
				</div>
				<div class="reviews__content">
					<div class="smallcontent">
						<div class="reviews__box">
							<div class="reviews__box__head">
								<span class="reviews__box__head__label">
									{{ reviews.length }} {{ (reviews.length > 1) ? $t('reviews.labels') : $t('reviews.label') }}
								</span>
								<span class="reviews__box__actions">
									<Filters :mode="editMode" :garages="garages" />
									<span class="reviews__box__filter btn filled" @click="openFilters">
										<span class="reviews__box__filter__label">
											{{ $t('reviews.btns.filter') }} {{ howmany }}
										</span>
									</span>
								</span>
							</div>
							<div class="reviews__box__list">
								<ReviewPreview v-for="r in reviews" :key="r.id" :review="r" />
							</div>
						</div>
					</div>
				</div>
			</template>
		</template>
		<ReviewModal />
	</main>
</template>
<script>
import { EventBus } from '@/events_bus.js';
import { gsap } from 'gsap';
import { CustomEase } from 'gsap/all';
gsap.registerPlugin(CustomEase);

import dayjs from 'dayjs';

import Loading from '@/views/components/common/Loading';
import ReviewPreview from '@/views/components/reviews/ReviewPreview';
import Filters from '@/views/components/reviews/Filters';
import ReviewModal from '@/views/components/reviews/ReviewModal';

export default {
	name: 'Reviews',
	components: {
		Loading,
		ReviewPreview,
		Filters,
		ReviewModal
	},
	data() {
		return {
			editMode: 'mobile',
			pageLoading: false,
			noreviews: true,
			reviews: null,
			garages: null,
			howmany: null,
		}
	},
	methods: {
		onResize() {
			this.wWidth = window.innerWidth;
			if(this.wWidth < 1200) {
				this.editMode = 'mobile'
			} else {
				this.editMode = 'desktop'
			}
		},
		openFilters() {
			EventBus.$emit('open-reviewsfilters');
		},
		closeDrawer() {
			this.$router.go(-1);
		},
		init() {
			this.pageLoading = true;

			this.$service.garages.reviews().then(res => {
				this.pageLoading = false;
				if(res.data && res.data.length) {
					this.noreviews = false;
					this.reviews = res.data;
					this.list = [...this.reviews];
				}
			}).then(() => {
				if(this.$route && this.$route.hash && this.$route.hash != '') {
					let hash = this.$route.hash.replace('#','');
					if(this.reviews && this.reviews.length) {
						this.reviews.forEach(r => {
							if(r.id == hash) {
								EventBus.$emit('open-review', r);
							}
						})
					}
				}
			});


			this.garages = [];
			this.$service.garages.main.get().then(res => {
				if(res && res.data && res.data.length) {
					this.garages = [...res.data];
				}
			})
		}
	},
	mounted() {
		this.onResize();
		this.$nextTick(() => {
			window.addEventListener('resize', () => {
				this.onResize();
			});
		});

		this.init();

		EventBus.$on('filter-results', (filters) => {
			let filtri = [];
			if(filters.from_date != '') {
				filtri.push(`filters[start_at]=${dayjs(filters.from_date).format('YYYY-MM-DDTHH:mm:ss')}`)
			}
			if(filters.to_date != '') {
				filtri.push(`filters[end_at]=${dayjs(filters.to_date).format('YYYY-MM-DDTHH:mm:ss')}`)
			}
			if(filters.garages.length) {
				filters.garages.forEach(g => {
					filtri.push(`filters[garage_ids][]=${+g}`)
				})
			}
			
			this.reviews = [];
			this.$service.garages.reviews(filtri.join('&')).then(res => {
				this.noreviews = false;
				if(res.data && res.data.length) {
					this.reviews = res.data;
					this.list = [...this.reviews];
					this.pageLoading = false;
				}
			})
		})
	}
}
</script>
<style lang="scss">
.reviews {
	padding-top: var(--head);
	overflow: auto;
	.mobile__head {
		@extend %flexCenterBetween;
		@include size(100%, var(--head));
		padding: 0 var(--gutter);
		position: relative;
		&__back {
			@extend %flexCenterStart;
			width: calc(100% - var(--btn));
			cursor: pointer;
			&__icon {
				@extend %flexCenterCenter;
				@include size(var(--smallicon),var(--smallicon));
				svg {
					display: block;
					@include size(75%,75%);
					fill: $color-black;
				}
			}
			&__label {
				@extend %text-15;
				display: block;
				width: max-content;
				font-variation-settings: 'wght' 600;
				padding-left: var(--minigutter);
			}
		}
	}
	&__box {
		@extend %panel;
		display: block;
		width: 100%;
		padding: var(--gutter);
		&__head {
			@extend %flexCenterBetween;
			width: 100%;
			margin-bottom: var(--gutter);
			&__label, &__actions {
				width: 50%;
			}
			&__label {
				@extend %text-15;
				@extend %flexBaselineStart;
				width: 50%;
			}
		}
		&__actions {
			@extend %flexCenterEnd;
			position: relative;
		}
		&__filter {
			@extend %flexCenterEnd;
			background: $color-blue;
			width: 140px;
			color: $color-white;
			padding: 0 var(--gutter);
			box-shadow: none !important;
			&__label {
				display: block;
			}
		}
		&__list {
			display: flex;
			flex-wrap: wrap;
			width: 100%;
		}
	}
	&__empty {
		@extend %flexCenterCenter;
		flex-direction: column;
		@include size(330px,100%);
		margin: 0 auto;
		&__media {
			display: block;
			@include size(200px,220px);
			margin-bottom: var(--supergutter);
			&__image {
				display: block;
				@include size(100%,100%);
				object-fit: contain;
			}
		}
		&__title {
			@extend %text-25;
			font-family: $font-manrope;
			font-variation-settings: 'wght' 700;
			margin-bottom: var(--minigutter);
			text-align: center;
		}
		&__text {
			@extend %text-15;
			margin-bottom: var(--gutter);
			text-align: center;
		}
		&__add {
			@extend %btn;
			display: block;
			@include size(var(--addbtn),var(--addbtn));
			background: $color-white;
			border: 1px solid $color-blue;
			position: relative;
			padding: 0;
			box-shadow: $shadow;
			@extend %arrowClose;
			&:before,
			&:after {
				background: $color-blue;
				@include size(40%,3px);
			}
			&:before {
				transform: translate(-50%,-50%);
			}
			&:after {
				transform: translate(-50%,-50%) rotate(90deg);
			}
		}
	}
	&__content {
		display: block;
		width: 100%;
		margin: var(--supergutter) 0;
		@media (min-width: $from-large) {
			.small-panel {
				height: var(--smallpanelh);
			}
		}
	}
	@media (min-width: $from-large) {
		.mobile__head {
			display: none;
		}
		&__head {
			display: none;
		}
		&__box {
			background: transparent;
			box-shadow: none;
			padding: var(--supergutter) var(--supergutter) var(--gutter);
		}
	}
	@media (max-width: $to-large) {
		position: fixed;
		top: 0;
		left: 0;
		display: block;
		background: $color-white !important;
		padding-top: 0;
		z-index: 100000;
		&__head {
			display: none;
		}
		&__box {
			border-radius: 0;
			box-shadow: none;
			padding: 0;
		}
		&__content {
			margin-top: 0;
		}
	}
}
</style>