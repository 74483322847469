<template>
	<main id="page" class="test" ref="page">
		<div class="testbox">
			<div class="testbox__status">
				<span class="testbox__step" data-id="dates">
					<span class="testbox__step__icon">
						<svg viewBox="0 0 50 50">
							<use xlink:href="#icon--calendar" width="100%" height="100%"></use>
						</svg>
					</span>
					<span class="testbox__step__label">
						Date
					</span>
					<span class="testbox__step__check">
						<svg viewBox="0 0 50 50">
							<use xlink:href="#icon--check" width="100%" height="100%"></use>
						</svg>
					</span>
				</span>
				<span class="testbox__step" data-id="repeats">
					<span class="testbox__step__icon">
						<svg viewBox="0 0 50 50">
							<use xlink:href="#icon--repeats" width="100%" height="100%"></use>
						</svg>
					</span>
					<span class="testbox__step__label">
						Ripetizioni
					</span>
					<span class="testbox__step__check">
						<svg viewBox="0 0 50 50">
							<use xlink:href="#icon--check" width="100%" height="100%"></use>
						</svg>
					</span>
				</span>
				<span class="testbox__step" data-id="prices">
					<span class="testbox__step__icon">
						<svg viewBox="0 0 50 50">
							<use xlink:href="#icon--prices" width="100%" height="100%"></use>
						</svg>
					</span>
					<span class="testbox__step__label">
						Prezzi
					</span>
					<span class="testbox__step__check">
						<svg viewBox="0 0 50 50">
							<use xlink:href="#icon--check" width="100%" height="100%"></use>
						</svg>
					</span>
				</span>
			</div>
			<div class="testbox__panels">
				<div id="dates" class="testbox__panel box current">
					<span class="box__title">
						Seleziona date e orari
					</span>
					<span class="box__dates">
						<span class="box__dates__item">
							<date-picker v-model="fromdate" valueType="timestamp" :lang="lang" format="dddd, D MMMM YYYY" :disabled-date="(date) => date < new Date()" placeholder="Data"></date-picker>
						</span>
						<span class="testbox__panel__dates">
							<date-picker v-model="todate" valueType="timestamp" :lang="lang" format="dddd, D MMMM YYYY" :disabled-date="(date) => date < new Date()" placeholder="Data"></date-picker>
						</span>
					</span>
					<span class="box__dates__addtime btn" @click="addTime = !addTime">
						Aggiungi orario
					</span>
					<span v-if="addTime" class="box__times">
						<date-picker v-model="fromtime" type="time" format="hh:mm" :minute-step="10" placeholder="Orario"></date-picker>
						<date-picker v-model="fromtime" type="time" format="hh:mm" :minute-step="10" placeholder="Orario"></date-picker>
					</span>
					<div class="box__controls">
						<span class="box__control continue btn filled" data-step="repeats" @click="handleSteps($event)">
							Continua
						</span>
					</div>
				</div>
				<div id="repeats" class="testbox__panel box">
					<span class="box__title">
						Ripetizioni
					</span>
					<div class="box__controls">
						<span class="box__control back btn outline" data-step="dates" @click="handleSteps($event)">
							Indietro
						</span>
						<span class="box__control continue btn filled" data-step="prices" @click="handleSteps($event)">
							Continua
						</span>
					</div>
				</div>
				<div id="prices" class="testbox__panel box">
					<span class="box__title">
						Prezzi
					</span>
					<div class="box__controls">
						<span class="box__control back btn outline" data-step="repeats" @click="handleSteps($event)">
							Indietro
						</span>
						<!-- <span class="box__control continue btn filled" data-step="prices" @click="handleSteps($event)">
							Continua
						</span> -->
					</div>
				</div>
			</div>
		</div>
	</main>
</template>
<script>
import { EventBus } from '@/events_bus.js';
import dayjs from 'dayjs';
import { gsap } from 'gsap';
import { CustomEase } from 'gsap/all';
gsap.registerPlugin(CustomEase);
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";

const { CalendarPanel } = DatePicker;

import Spinner from '@/views/components/common/Spinner';

export default {
	name: 'Test',
	components: {
		DatePicker,
		CalendarPanel,
		Spinner,
	},
	data() {
		return {
			fromdate: new Date().valueOf(),
			todate: new Date().valueOf(),
			fromtime: null,
			totime: null,
			addTime: false,
			isEdit: false,
			currentStep: 'dates',
			form: {
				start_at: null,
				end_at: null,
				price: 0,
				price_hour: 0,
				single_booking: false,
				garage_id: null,
				repeat: 'DAY',
				repeat_number: 1,
			},
			lang: {
				months: ['Gen', 'Feb', 'Mar', 'Apr', 'Mag', 'Giu', 'Lug', 'Ago', 'Set', 'Ott', 'Nov', 'Dic'],
				days: ['Dom', 'Lun', 'Mar', 'Mer', 'Gio', 'Ven', 'Sab'],
			}
		}
	},
	methods: {
		handleSteps(evt) {
			this.currentStep = evt.target.getAttribute('data-step');
			document.querySelector('.box.current').classList.remove('current');
			setTimeout(() => {
				document.querySelector(`#${this.currentStep}`).classList.add('current');
			}, 100)
		},
	},
	mounted() {

	}
}
</script>
<style lang="scss">
.testbox {
	@extend %flexStartBetween;
	display: block;
	width: 100%;
	padding: 0;
	margin-top: var(--gutter);
	padding-top: var(--thirdgutter);
	&__status {
		@extend %flexCenterCenter;
		@include size(100%,var(--input));
	}
	&__step {
		@extend %flexCenterStart;
		@include size(31%, 100%);
		padding: 0 var(--minigutter);
		border: 1px solid $color-silver;
		border-radius: calc(var(--radius) / 2);
		position: relative;
		margin-right: 2%;
		&__icon {
			@extend %flexCenterCenter;
			@include size(var(--smallicon),var(--smallicon));
		}
		&__label {
			display: block;
			width: calc(100% - var(--smallicon));
			padding-left: var(--minigutter);
			font-variation-settings: 'wght' 700;
		}
		&__check {
			position: absolute;
			top: 50%;
			right: var(--minigutter);
			@extend %flexCenterCenter;
			@include size(calc(var(--gutter) - 4px),calc(var(--gutter) - 4px));
			border-radius: 100%;
			border: 2px solid $color-success;
			transform: translateY(-50%);
			transition: 180ms linear;
			filter: grayscale(1);
			opacity: 0.7;
			svg {
				display: block;
				@include size(60%,60%);
				fill: $color-blue;
				transform: scale(0);
				transition: 180ms linear;
			}
		}
		&.completed {
			.testbox__step__check {
				background: $color-blue;
				filter: grayscale(0);
				opacity: 1;
				svg {
					transform: scale(1);
				}
			}
		}
	}
	&__panels {
		display: block;
		width: 100%;
		height: auto;
		min-height: 600px;
		position: relative;
	}
	&__panel {
		position: absolute;
		top: 0;
		left: 0;
	}
	.box {
		display: block;
		width: 100%;
		opacity: 0;
		pointer-events: none;
		padding: var(--supergutter) 0;
		&__title {
			@extend %text-20;
			display: block;
			width: 100%;
			font-family: $font-manrope;
			margin-bottom: var(--gutter);
		}
		&__controls {
			@extend %flexCenterEnd;
			width: 100%;
			margin-top: var(--supergutter);
		}
		&__control {
			padding: 0 var(--supergutter);
			&.back {
				color: $color-blue;
			}
			&.continue {
				background: $color-blue;
				margin-left: var(--gutter);
				color: $color-white;
			}
		}
		transition: 180ms linear;
		&.current {
			opacity: 1;
			pointer-events: all;
		}
	}
	@media (min-width: $from-medium) {
		max-height: 650px;
		overflow: auto;
	}
}
.mx-time {
	width: var(--hourinput) !important;
	&-list {
		list-style: none;
		&:after { display: none !important; }
	}
	&-item {
		padding: 0;
		&:before { display: none; }
	}
}
</style>