<template>
    <div id="dashboard-layout" class="dashboard-layout">
		<Head :editMode="editMode" />
		<router-view />
    </div>
</template>

<script>
import { EventBus } from '@/events_bus.js';
import Head from '@/views/components/layout/Head.vue';

export default {
    name: 'DashboardLayout',
    components: {
        Head,
    },
	data() {
		return {
			wWidth: 0,
			editMode: 'mobile',
			errors: [],
			isloaded: false,
			currentUser: null,
			notices: null
		}
	},
	watch: {
		errors: {
			deep: true,
			immediate: true,
			handler(newval, oldval) {
				return newval;
			}
		}
	},
	methods: {
		onResize() {
			this.wWidth = window.innerWidth;
			if(this.wWidth < 1200) {
				this.editMode = 'mobile'
			} else {
				this.editMode = 'desktop'
			}
		},
		getErrors(error) {
			console.error('ERROR', error);
			if(error && error.messages) {
				error.messages.forEach(err => {
					if(err.includes('not_authorized')) {
						this.$router.push({ name: 'login.page' })
					}
				})
			}
		},
		init() {
			this.isloaded = true;
		}
	},
	created() {
		this.onResize();
	},
	mounted() {
		this.$nextTick(() => {
			window.addEventListener('resize', () => {
				this.onResize();
			});
		});

		this.init();
	},
}
</script>

<style lang="scss">
.dashboard-layout {
	display: block;
	width: 100%;
	min-height: 100svh;
	background: $color-antiflash;
}
</style>
