<template>
	<div class="createaccount">
		<div class="createaccount__head">
			<span class="createaccount__head__back" @click="closeDrawer">
				<span class="createaccount__head__back__icon">
					<svg viewBox="0 0 50 50">
						<use xlink:href="#icon--back" width="100%" height="100%"></use>
					</svg>
				</span>
				<span class="createaccount__head__back__label">
					{{ $t('common.back') }}
				</span>
			</span>
		</div>
		<div class="createaccount__box">
			<div class="createaccount__box__head">
				<div class="createaccount__box__head__title">
					{{ $t('create.title') }}
				</div>
				<span class="createaccount__box__head__close" @click="closeModal">
					<svg viewBox="0 0 50 50">
						<use xlink:href="#icon--close" width="100%" height="100%"></use>
					</svg>
				</span>
			</div>
			<form id="createaccount" class="createform" @submit.prevent="onSubmit">
				<div class="form__field">
					<label for="email" class="createaccount__label">
						{{ $t('create.email.label') }}
					</label>
					<input type="email" name="email" id="email" class="form__input" :placeholder="$t('create.email.placeholder')" v-model="form.email" />
					<small v-if="errors && errors.email" class="form__error" v-html="errors.email"></small>
					<small v-if="validMailError" class="form__error">{{ $t('create.errors.emailNotValid') }}</small>
				</div>
				<div class="form__field">
					<label for="firstname" class="createaccount__label">
						{{ $t('create.firstname.label') }}
					</label>
					<input type="firstname" name="firstname" id="firstname" class="form__input" :placeholder="$t('create.firstname.placeholder')" v-model="form.name" />
					<small v-if="errors && errors.name" class="form__error" v-html="errors.name"></small>
				</div>
				<div class="form__field">
					<label for="lastname" class="createaccount__label">
						{{ $t('create.lastname.label') }}
					</label>
					<input type="lastname" name="lastname" id="lastname" class="form__input" :placeholder="$t('create.lastname.placeholder')" v-model="form.surname" />
					<small v-if="errors && errors.surname" class="form__error" v-html="errors.surname"></small>
				</div>
				<div class="form__field phone">
					<label for="phone" class="createaccount__label">
						{{ $t('create.phone.label') }}
					</label>
					<input type="tel" inputmode="tel" pattern="[0-9\s]{10,19}" name="phone" id="phone" class="form__input" :placeholder="$t('create.phone.placeholder')" v-model="form.phone" />
					<small v-if="errors && errors.phone" class="form__error" v-html="errors.phone"></small>
				</div>
				<div class="form__field dob">
					<label for="dob" class="createaccount__label">
						{{ $t('create.dob.label') }}
					</label>
					<date-picker v-model="form.birthday" valueType="timestamp" format="DD/MM/YYYY" :disabled-date="(date) => date >= getMinDate()">
						<template v-slot:icon-calendar>
							<svg viewBox="0 0 50 50" width="300" height="300">
								<use xlink:href="#icon--form-calendar" width="100%" height="100%"></use>
							</svg>
						</template>
					</date-picker>
					<small v-if="errors && errors.birthday" class="form__error" v-html="errors.birthday"></small>
				</div>
				<div class="form__field">
					<label for="fiscal_code" class="createaccount__label">
						{{ $t('create.fiscal_code.label') }}
					</label>
					<input type="fiscal_code" name="fiscal_code" id="fiscal_code" class="form__input" :placeholder="$t('create.fiscal_code.placeholder')" v-model="form.fiscal_code" maxlength="16" />
					<small v-if="errors && errors.fiscal_code" class="form__error" v-html="errors.fiscal_code"></small>
				</div>
				<div class="form__field">
					<label for="psw" class="createaccount__label">
						{{ $t('create.psw.label') }}
					</label>
					<span class="input-box">
						<input type="password" name="psw" id="psw" class="form__input" :placeholder="$t('create.psw.placeholder')" v-model="form.password" />
						<span class="show-psw" @click="togglePswVisibility">
							<span class="show-psw__show">
								<svg viewBox="0 0 50 50">
									<use xlink:href="#icon--hidepsw" width="100%" height="100%"></use>
								</svg>
							</span>
							<span class="show-psw__hide">
								<svg viewBox="0 0 50 50">
									<use xlink:href="#icon--showpsw" width="100%" height="100%"></use>
								</svg>
							</span>
						</span>
					</span>
					<small v-if="errors && errors.password" class="form__error" v-html="errors.password"></small>
				</div>
				<div class="form__field">
					<label for="confirmpsw" class="createaccount__label">
						{{ $t('create.confirmpsw.label') }}
					</label>
					<span class="input-box">
						<input type="password" name="confirmpsw" id="confirmpsw" class="form__input" :placeholder="$t('create.confirmpsw.placeholder')" v-model="form.password_confirmation" />
						<span class="show-psw" @click="togglePswVisibility">
							<span class="show-psw__show">
								<svg viewBox="0 0 50 50">
									<use xlink:href="#icon--hidepsw" width="100%" height="100%"></use>
								</svg>
							</span>
							<span class="show-psw__hide">
								<svg viewBox="0 0 50 50">
									<use xlink:href="#icon--showpsw" width="100%" height="100%"></use>
								</svg>
							</span>
						</span>
					</span>
					<small v-if="errors && errors.password_confirmation" class="form__error" v-html="errors.password_confirmation"></small>
				</div>
				<div class="form__field">
					<label for="newsletter" class="form__label">
						<input type="checkbox" v-model="form.newsletter" name="newsletter" id="newsletter" />
						<span class="form__checkbox" :class="{ 'checked': form.newsletter }">
							<svg viewBox="0 0 50 50">
								<use xlink:href="#icon--check" width="100%" height="100%"></use>
							</svg>
						</span>
						<span class="form__label__text" v-html="$t('create.newsletter')"></span>
					</label>
					<label for="privacy" class="form__label">
						<input type="checkbox" v-model="form.privacy" name="privacy" id="privacy" />
						<span class="form__checkbox" :class="{ 'checked': form.privacy }">
							<svg viewBox="0 0 50 50">
								<use xlink:href="#icon--check" width="100%" height="100%"></use>
							</svg>
						</span>
						<span class="form__label__text" v-html="$t('create.privacy')"></span>
					</label>
				</div>
				<div class="form__field">
					<button id="createuser" class="createaccount__button btn filled" type="submit" :class="{ 'disabled': !form.privacy }">
						{{ $t('create.btns.create') }}
					</button>
					<div v-if="errors && errors.length" class="form__field errors">
						<span v-for="(er, erI) in errors" :key="erI" class="errors__item" v-html="er"></span>
					</div>
				</div>
			</form>
		</div>
		<div v-if="isLoading" class="createaccount__loading">
			<template v-if="!editSaved">
				<span class="createaccount__loading__spinner">
					<span class="createaccount__loading__spinner__box">
						<Spinner />
					</span>
					<span class="createaccount__loading__spinner__label">
						{{ $t('common.is_saving') }}
					</span>
				</span>
			</template>
			<template v-else>
				<span class="createaccount__success" :class="{ 'success': !errors }">
					<span class="createaccount__success__icon">
						<template v-if="!errors">
							<svg viewBox="0 0 50 50">
								<use xlink:href="#icon--check" width="100%" height="100%"></use>
							</svg>
						</template>
						<template v-else>
							<svg viewBox="0 0 50 50">
								<use xlink:href="#icon--close" width="100%" height="100%"></use>
							</svg>
						</template>
					</span>
					<span class="createaccount__success__label">
						<template v-if="!errors">
							<span class="createaccount__success__label__txt">
								{{ $t('common.account_created') }}
							</span>
						</template>
						<template v-else>
							<span class="createaccount__success__label__txt">
								<small v-for="(e, eI) in errors" :key="eI" v-html="e"></small>
							</span>
							<span class="createaccount__success__label__btn btn filled" @click="tryAgain">
								{{ $t('availabilities.btns.edit') }}
							</span>
						</template>
					</span>
				</span>
			</template>
		</div>
	</div>
</template>
<script>
import { EventBus } from '@/events_bus.js';
import { gsap } from 'gsap';
import { CustomEase } from 'gsap/all';

import dayjs from 'dayjs';

import DatePicker from 'vue2-datepicker';

import CustomSelect from '@/views/components/common/CustomSelect';

gsap.registerPlugin(CustomEase);

import Spinner from "@/views/components/common/Spinner";

export default {
	name: 'CreateAccount',
	props: {
		mode: {
			type: String,
			required: true,
			default: 'mobile'
		}
	},
	components: {
		DatePicker,
		CustomSelect,
		Spinner
	},
	data() {
		return {
			isLoading: false,
			editSaved: false,
			form: {
				email: null,
				name: null,
				surname: null,
				phone: null,
				birthday: null,
				password: null,
				password_confirmation: null,
				newsletter: false,
				privacy: false,
				fiscal_code: null
			},
			errors: null,
			msg: '',
			psw: null,
			pswVerify: null,
			nomatch: false,
			validMailError: false,
		}
	},
	methods: {
		getMinDate() {
			let now = dayjs()
			let adult = now.subtract(18, 'year');
			return new Date(adult);
		},
		openModal() {
			gsap.timeline({
				onComplete: () => {
					gsap.set('.createaccount', {
						pointerEvents: 'all'
					})
				}
			})
				.to('.createaccount', {
					duration: 0.3,
					opacity: 1
				})
				.to('.createaccount__box', {
					duration: 0.3,
					y: '0%',
					opacity: 1
				})
		},
		closeModal() {
			gsap.timeline({
				onComplete: () => {
					gsap.set('.createaccount', {
						pointerEvents: 'none'
					})
					this.$router.go(-1)
				}
			})
			.to('.createaccount__box', {
				duration: 0.3,
				y: 150,
				opacity: 0
			})
			.to('.createaccount', {
				duration: 0.3,
				opacity: 0
			})
		},
		openDrawer() {
			gsap.to('.createaccount', {
				duration: 0.9,
				x: '0%',
				ease: CustomEase.create("custom", "M0,0 C0.726,0 0,1 1,1 "),
				onComplete: () => {
					gsap.set('.createaccount', {
						pointerEvents: 'all'
					})
				}
			})
		},
		closeDrawer() {
			gsap.to('.createaccount', {
				duration: 0.9,
				x: '100%',
				ease: CustomEase.create("custom", "M0,0 C0.726,0 0,1 1,1 "),
				onComplete: () => {
					this.$router.go(-1)
				}
			})
		},
		init() {
		},
		validEmail (email) {
			var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
			return re.test(email);
		},
		togglePswVisibility(item) {
			let input = item.target.parentNode.querySelector('input');

			item.target.classList.contains('show') ? item.target.classList.remove('show') : item.target.classList.add('show')

			if(input.type == 'password') {
				input.type = 'text';
			} else {
				input.type = 'password';
			}

		},
		async onSubmit() {
			this.submit();
		},
		submit () {
			// empty errors
			this.errors = null;
			this.msg = '';
			this.formResult = '';

			this.isLoading = true;
			let validMail = this.validEmail(this.form.email);
			
			console.log('--->>>', this.validMailError);
			
			if(validMail) {
				this.validMailError = false;
				// check
				["name", "surname", "email", "phone", "birthday", "fiscal_code", "password", "password_confirmation"].map(checkField => {
					if (this.form && !(this.form[checkField] && this.form[checkField].toString().trim().length)) {
						this.errors = (this.errors == null) ? {} : this.errors;
						// this.errors[checkField] = true;
						if(!this.errors[checkField] || this.errors[checkField] == '') {
							this.errors[checkField] = this.$t(`account.edit.errors.${checkField}`);
						}
						if(this.form['password'] != this.form['password_confirmation']) {
							this.errors['password_confirmation'] = this.$t('account.edit.errors.nomatch');
						}
					}
				})
				
				if (this.errors === null) {
					let data = {};
					Object.keys(this.form).map(k => {
						if (k != "privacy") {
							data[k] = this.form[k];
						}
					})
					
					this.form.birthday = dayjs(this.form.birthday).format('YYYY-MM-DDTHH:mm:ss');
					
					this.$service.login.register(this.form).then(res => {
						this.errors = null;
						this.editSaved = true;
						setTimeout(() => {
							this.isLoading = false;
							this.$router.push({ name: 'login.page' })
							setTimeout(() => {
								window.location.reload();
							}, 100)
						}, 1500)
					}).catch((err) => {
						console.log(`Errore`, err);
						this.isLoading = false;
						this.errors = [];
						// this.errors.push(err.messages.message.toLowerCase().split(' ').join('_'))
						let temp = err.messages.errors;
						for(const key in temp) {
							this.errors.push(this.$t(`login.errors.${temp[key][0].toLowerCase().split(' ').join('_').replace(/\./g,'')}`))
						}
						
						console.log('Errors', this.errors);
						this.msg = this.$t('form.senderror');
						// TODO: Errore
					});
					
				} else {
					// TODO: deve tornare su all'errore
					
					console.log('E', this.errors);
					this.formResult = 'error';
					this.msg = this.$t('form.formerror');
					this.isLoading = false;
				}
			} else {
				this.validMailError = true;
				this.isLoading = false;
			}

		},
	},
	mounted() {
		EventBus.$on('show-create', () => {
			this.init();
			if(this.mode == 'mobile') {
				this.openDrawer()
			} else {
				this.openModal();
			}
		})
	}
}
</script>
<style lang="scss">
@import '~vue2-datepicker/scss/index.scss';
#fiscal_code {
	text-transform: uppercase;
}
.createaccount {
	--select: 100px;
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	display: block;
	background: $color-seasalt;
	@include size(100%, 100svh);
	padding: var(--gutter) 0;
	display: block;
	overflow: auto;
	z-index: 10000;
	transform: translateX(100%);
	pointer-events: none;
	overflow-x: hidden;
	overflow-y: auto;
	&__head {
		@extend %flexCenterStart;
		@include size(100%, var(--headDrawer));
		padding: 0 var(--gutter);
		&__back {
			@extend %flexCenterStart;
			width: calc(100% - var(--btn));
			cursor: pointer;
			&__icon {
				@extend %flexCenterCenter;
				@include size(var(--smallicon),var(--smallicon));
				svg {
					display: block;
					@include size(75%,75%);
					fill: $color-black;
				}
			}
			&__label {
				@extend %text-15;
				display: block;
				width: max-content;
				font-variation-settings: 'wght' 600;
				padding-left: var(--minigutter);
			}
		}
	}
	&__box {
		@extend  %panel;
		background: transparent;
		padding: 0 var(--gutter);
		height: auto;
		overflow: auto;
		padding-bottom: var(--supergutter);
		&__head {
			@extend %flexCenterBetween;
			width: 100%;
			margin-bottom: var(--gutter);
			&__title {
				@extend %text-33;
				display: block;
				width: 100%;
				font-family: $font-manrope;
				font-variation-settings: 'wght' 700;
			}
			&__close {
				@extend %icon;
				display: none;
				cursor: pointer;
				svg {
					@include size(70%,70%);
					fill: $color-black;
				}
			}
		}
		&__text {
			@extend %text-19;
			display: block;
			width: 100%;
			margin-bottom: var(--supergutter);
		}
		@media (max-width: $to-large) {
			box-shadow: none;
		}
	}
	&__back {
		@extend %flexCenterStart;
		width: 100%;
		margin-bottom: var(--gutter);
		cursor: pointer;
		&__icon {
			@extend %flexCenterCenter;
			@include size(var(--smallicon),var(--smallicon));
			svg {
				display: block;
				@include size(75%,75%);
				fill: $color-black;
			}
		}
		&__label {
			@extend %text-15;
			display: block;
			width: max-content;
			font-variation-settings: 'wght' 600;
			padding-left: var(--minigutter);
		}
	}
	.form__error {
		@extend %text-10;
		display: block;
		width: 100%;
		text-transform: uppercase;
		color: $color-error;
		padding: 5px;
	}
	.input-box {
		display: block;
		width: 100%;
		position: relative;
		.show-psw {
			position: absolute;
			top: 50%;
			right: var(--thirdgutter);
			display: block;
			@include size(var(--smallicon),var(--smallicon));
			transform: translateY(-50%);
			cursor: pointer;
			&__show,
			&__hide {
				position: absolute;
				top: 0;
				left: 0;
				display: flex;
				@extend %flexCenterCenter;
				@include size(100%,100%);
				pointer-events: none;
				svg {
					display: block;
					@include size(100%,100%);
					fill: $color-black;
				}
			}
			&__hide {
				display: none;
			}
			&.show {
				.show-psw__show {
					display: none;
				}
				.show-psw__hide {
					display: block;
				}
			}
		}
	}
	&__label {
		display: block;
		width: 100%;
		&__text {
			display: block;
			width: 100%;
		}
	}
	&__button {
		width: 100%;
		text-align: center;
		margin-bottom: var(--gutter);
		&.filled {
			background: $color-blue;
			color: $color-white;
		}
		&.outline {
			border-color: $color-blue;
			color: $color-blue
		}
		&.disabled {
			opacity: 0.6;
			pointer-events: none;
		}
	}
	.form__checkbox {
		border: 2px solid $color-blue !important;
		border-radius: calc(var(--radius) / 3);
	}
	input[type="checkbox"]:not(checked) ~ .form__checkbox {
		border: 2px solid $color-blue !important;
	}
	.form__field {
		.errors {
			&__item {
				display: block;
				width: 100%;
				@extend %text-14;
				color: $color-error;
				margin-bottom: 6px;
				text-align: center;
			}
		}
	}
	.mx-datepicker {
		width: 100%;
		.mx-input {
			background: $color-white;
			height: var(--input);
			border: 1px solid $color-white;
			border-radius: var(--inputradius);
			padding: 0 var(--minigutter);
			border: 1px solid $color-grey;
		}
		.mx-icon-calendar {
			svg {
				fill: $color-black;
			}
		}
	}
	.phone {
		@extend %flexCenterBetween;
		flex-wrap: wrap;
		width: 100%;
		.form {
			&__label {
				justify-content: space-between !important;
			}
			&__input {
				width: 100%;
			}
		}
	}
	&__loading {
		position: fixed;
		top: 0;
		left: 0;
		@extend %flexCenterCenter;
		background: rgba($color-white, 0.86);
		@include size(100%,100svh);
		pointer-events: all;
		z-index: 100;
		&__spinner {
			display: block;
			margin-bottom: var(--gutter);
			&__box {
				display: block;
				@include size(65px,65px);
				margin: 0 auto var(--gutter);
			}
			&__label {
				@extend %text-15;
				display: block;
				width: 100%;
				font-family: $font-manrope;
				font-variation-settings: 'wght' 600;
				text-align: center;
			}
		}
	}
	&__success {
		@extend %flexCenterCenter;
		flex-direction: column;
		text-align: center;
		&__icon {
			@extend %flexCenterCenter;
			background: $color-success;
			@include size(var(--supergutter),var(--supergutter));
			border-radius: 100%;
			margin-bottom: var(--gutter);
			overflow: hidden;
			svg {
				display: block;
				@include size(var(--smallicon),var(--smallicon));
				fill: $color-white;
			}
		}
		&__label {
			display: block;
			padding: var(--gutter) var(--supergutter);
			&__txt {
				display: block;
				width: 100%;
				@extend %text-33;
				font-variation-settings: 'wght' 700;
				color: $color-success;
				margin-bottom: var(--gutter);
			}
			&__btn {
				width: max-content;
				padding: 0 var(--supergutter);
				margin: 0 auto;
				background: $color-blue;
				color: $color-white;
			}
		}
		&.success {
			.availability__success {
				&__icon {
					background: $color-success;
				}
				&__label {
					&__txt {
						color: $color-success;
					}
				}
			}
		}
	}
	@media (min-width: $from-medium) {
		display: flex;
		align-items: center;
		justify-content: center;
		background: rgba($color-black, 0.1);
		transform: translateX(0%);
		opacity: 0;
		&__head {
			display: none;
		}
		&__box {
			background: $color-white;
			max-height: 90vh;
			width: 780px;
			max-height: 75vh;
			padding: var(--gutter);
			transform: translateY(150px);
			opacity: 0;
			position: relative;
			&__head {
				display: flex;
				margin-bottom: var(--thirdgutter);
				&__close {
					display: block;
				}
			}
			&__text {
				font-size: 0.938rem;
			}
			&__content {
				position: absolute;
				top: 50%;
				left: 0;
				display: block;
				width: 100%;
				text-align: center;
				transform: translateY(-50%);
			}
		}
		.createform {
			display: flex;
			align-items: flex-start;
			justify-content: space-between;
			flex-wrap: wrap;
			.form__field {
				width: 48%;
				&:last-of-type {
					width: 100%;
				}
			}
		}
	}
}

.mx-calendar-content .cell.active div {
	position: absolute;
}
</style>
