<template>
	<div class="statsselect" :tabindex="tabindex" @blur="open = false">
		<div class="statsselect__selected" :class="{ 'open': open }" @click="open = !open">
			<span class="statsselect__selected__label">
				<template v-if="selected">
					{{ selected.label }}
				</template>
			</span>
			<span class="statsselect__selected__icon">
				<svg viewBox="0 0 50 50">
					<use xlink:href="#icon--back" width="100%" height="100%"></use>
				</svg>
			</span>
		</div>
		<div class="statsselect__items" :class="{ 'selectHide': !open}">
			<span v-for="(option, i) of options" :key="i" @click="selected = option; open = false; $emit('input', { selected: option, filter: name })" v-html="option.label"></span>
		</div>
	</div>
</template>
<script>
export default {
	name: 'Select',
	props: {
		name: {
			type: String,
			required: true,
		},
		options: {
			type: Array,
			required: true
		},
		base: {
			type: Object,
			required: false,
			default: null,
		},
		tabindex: {
			type: Number,
			required: false,
			defualt: 0
		}
	},
	data() {
		return {
			selected: null,
			open: false
		}
	},
	methods: {
		init() {
			this.selected = this.base ? this.base : this.options.length > 0 ? this.options[0] : null;
		}
	},
	mounted() {
		this.init();
	}
}
</script>
<style lang="scss">
.statsselect {
	display: block;
	@include size(max-content, var(--gutter));
	background: $color-white;
	min-width: 120px;
	border-radius: var(--inputradius);
	border: 1px solid $color-grey;
	position: relative;
	margin-left: var(--thirdgutter);
	&__selected {
		@extend %flexCenterBetween;
		background: $color-white;
		@include size(100%, 100%);
		padding: 0 6px;
		position: relative;
		z-index: 2;
		user-select: none;
		border-radius: var(--inputradius);
		overflow: hidden;
		cursor: pointer;
		&__icon {
			@extend %flexCenterCenter;
			@include size(var(--smallicon),var(--smallicon));
			svg {
				display: block;
				@include size(50%,50%);
				fill: $color-black;
				transform: rotate(-90deg);
				transition: 250ms linear;
			}
		}
		&__label {
			@extend %text-15;
			display: block;
			white-space: nowrap;
		}
		&:after {
			content: '';
			position: absolute;
			top: 22px;
			right: 1em;
			width: 0;
			height: 0;
			border: 5px solid transparent;
			border-color: #fff transparent transparent transparent;
		}
	}
	&__items {
		position: absolute;
		top: 0;
		left: -1px;
		right: 0;
		background: $color-white;
		width: calc(100% + 2px);
		max-height: 250px;
		padding: calc(var(--gutter) - 2px) 0 6px;
		border-radius: var(--inputradius);
		border: 1px solid $color-grey;
		transition: 250ms linear;
		overflow: auto;
		z-index: 1;
		span {
			@extend %text-13;
			@extend %flexCenterStart;
			@include size(100%,var(--input));
			padding: 0 6px;
			cursor: pointer;
			user-select: none;
			white-space: nowrap;
			&:hover {
				background: $color-blue;
				color: $color-white;
			}
		}
	}
}
.selectHide {
	display: none;
}
</style>