<template>
	<div>
		<div class="google" @click="loginWithGoogle">
			Accedi con Google
		</div>
	</div>
</template>
<script>
export default {
	name: "GoogleLogin",
	data() {
		return {
			href: null,
		}
	},
	methods: {
		loginWithGoogle() {
			window.location.href = this.$service.login.google('/');
		},
	},
};
</script>
<style lang="scss">
.google {
	@extend %btn;
	width: 100%;
	color: $color-white;
	margin-bottom: calc(var(--gutter) * 0.66);
	background: $color-red;
}
</style>
