<template>
	<div class="nonots">
		<div class="nonots__box">
			<div class="nonots__box__head">
				<div class="nonots__box__head__title">
					{{ $t("notifications.nonots.title") }}
				</div>
			</div>
			<div class="nonots__box__content">
				<span class="nonots__text" v-html="$t('notifications.nonots.text')"></span>
				<div class="nonots__ctas">
					<span class="nonots__button btn filled" @click="resetPermissions" v-html="$t('common.btns.reset')"></span>
					<span class="nonots__button btn outline" @click="closeModal" v-html="$t('common.btns.continue')"></span>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import { EventBus } from '@/events_bus.js';
import { gsap } from "gsap";
import { CustomEase } from "gsap/all";
gsap.registerPlugin(CustomEase);

export default {
	name: 'NoNotificationsModal',
	data() {
		return {

		}
	},
	methods: {
		openModal() {
			gsap.timeline({
				onComplete: () => {
					gsap.set(".nonots", {
						pointerEvents: "all",
					});
				},
			})
				.to(".nonots", {
					duration: 0.3,
					opacity: 1,
				})
				.to(".nonots__box", {
					duration: 0.3,
					y: "0%",
					opacity: 1,
				});
		},
		closeModal() {
			localStorage.setItem('no-notifications', true);
			gsap.timeline({
				onComplete: () => {
					gsap.set(".nonots", {
						pointerEvents: "none",
					});
				},
			})
				.to(".nonots__box", {
					duration: 0.3,
					y: 150,
					opacity: 0,
				})
				.to(".nonots", {
					duration: 0.3,
					opacity: 0,
				});
		},
		resetPermissions() {
			EventBus.$emit('request-permission-again');
		}
	},
	mounted() {
		EventBus.$on('no-notifications', () => {
			this.openModal();
		})
	}
}
</script>
<style lang="scss">
.nonots {
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	display: block;
	background: $color-seasalt;
	padding: var(--gutter) 0;
	display: block;
	overflow: auto;
	z-index: 10000;
	transform: translateX(100%);
	pointer-events: none;
	overflow-x: hidden;
	overflow-y: auto;
	&__box {
		@extend %panel;
		background: transparent;
		padding: 0 var(--supergutter);
		height: auto;
		overflow: auto;
		padding-bottom: var(--supergutter);
		&__head {
			@extend %flexCenterBetween;
			width: 100%;
			margin-bottom: var(--gutter);
			&__title {
				@extend %text-33;
				display: block;
				width: 100%;
				font-family: $font-manrope;
				font-variation-settings: "wght" 700;
				text-align: center;
			}
		}
		&__content {
			@extend %text-19;
			display: block;
			width: 100%;
			margin-top: var(--supergutter);
			text-align: center;
		}
		@media (max-width: $to-large) {
			box-shadow: none;
		}
	}
	&__text {
		@extend %text-15;
		display: block;
		width: 100%;
		margin-bottom: var(--gutter);
	}
	&__button {
		width: 100%;
		text-align: center;
		margin-bottom: var(--thirdgutter);
		&.filled {
			background: $color-blue;
			color: $color-white;
		}
		&.outline {
			border-color: $color-blue;
			color: $color-blue;
		}
	}
	&__ctas {
		@extend %flexCenterCenter;
		flex-wrap: wrap;
		width: 100%;
	}
	@media (min-width: $from-medium) {
		display: flex;
		align-items: center;
		justify-content: center;
		background: rgba($color-black, 0.1);
		transform: translateX(0%);
		opacity: 0;
		&__box {
			background: $color-white;
			max-height: 90vh;
			min-width: 400px;
			height: auto;
			padding: var(--gutter) var(--supergutter);
			transform: translateY(150px);
			opacity: 0;
			position: relative;
			&__head {
				display: flex;
				margin-bottom: var(--thirdgutter);
				&__close {
					display: block;
				}
			}
			&__text {
				font-size: 0.938rem;
			}
		}
		&__button {
			width: max-content;
			padding: 0 var(--gutter);
			margin: 0 var(--thirdgutter);
		}
	}
}
</style>