import Vue from 'vue'
import App from './App.vue'
import i18n from './i18n'
import router from './router'
import store from './store'
import api from './plugins/api';
import maps from './plugins/maps';
import service from './plugins/service';
import filters from './plugins/filters';
import applelogin from './plugins/applelogin';
import notifications from './plugins/notifications';

import './registerServiceWorker'

import firebase from './plugins/firebase';
import VueEventBus from "vue-plugin-event-bus";
import VueAppleLogin from 'vue-apple-login';
const VUE_APP_REDIRECT_URI = (process.env.VUE_APP_REDIRECT_URI !== undefined && process.env.VUE_APP_REDIRECT_URI !== "") ? process.env.VUE_APP_REDIRECT_URI : '{{ VUE_APP_REDIRECT_URI }}'

console.log('VUE_APP_REDIRECT_URI', VUE_APP_REDIRECT_URI);

Vue.config.productionTip = false
Vue.use(api);
Vue.use(maps);
Vue.use(service);
Vue.use(notifications);
Vue.use(VueEventBus);

Vue.use(VueAppleLogin, {
	clientId: 'digital.mwd.web.garaging',
	scope: 'name email',
	redirectURI: VUE_APP_REDIRECT_URI,
	usePopup: true,
	state: `${new Date().getTime()}`
});

Vue.use(applelogin);
Vue.use(firebase);


filters.forEach(f => {
	Vue.filter(f.name, f.execute);
})

new Vue({
	i18n,
	router,
	store,
	render: h => h(App)
}).$mount('#app')
