<template>
	<div class="accountdelete">
		<div class="accountdelete__head">
			<span class="accountdelete__head__back" @click="closeDrawer">
				<span class="accountdelete__head__back__icon">
					<svg viewBox="0 0 50 50">
						<use xlink:href="#icon--back" width="100%" height="100%"></use>
					</svg>
				</span>
				<span class="accountdelete__head__back__label">
					{{ $t('common.back') }}
				</span>
			</span>
		</div>
		<div class="accountdelete__box">
			<div class="accountdelete__box__head">
				<div class="accountdelete__box__head__title">
					{{ $t('account.delete.title') }}
				</div>
				<span class="accountdelete__box__head__close" @click="closeModal">
					<svg viewBox="0 0 50 50">
						<use xlink:href="#icon--close" width="100%" height="100%"></use>
					</svg>
				</span>
			</div>
			<div class="accountdelete__box__text" v-html="$t('account.delete.text')"></div>
			<div class="accountdelete__box__actions">
				<span class="accountdelete__box__btn btn outline" @click="closeModal">
					{{ $t('common.btns.cancel') }}
				</span>
				<span class="accountdelete__box__btn btn filled" @click="deleteAccount">
					{{ $t('common.btns.delete') }}
				</span>
			</div>
		</div>
	</div>
</template>
<script>
import { EventBus } from '@/events_bus.js';
import { gsap } from 'gsap';
import { CustomEase } from 'gsap/all';
gsap.registerPlugin(CustomEase);

export default {
	name: 'Email',
	props: {
		mode: {
			type: String,
			required: true,
			default: 'mobile'
		}
	},
	data() {
		return {
		}
	},
	methods: {
		closeWindow() {
			if(this.mode == 'mobile') {
				this.closeDrawer()
			} else {
				this.closeModal();
			}
		},
		deleteAccount() {
			this.$service.user.delete().then(res => {
				this.$api.clearToken();
				window.location.reload();
			}).catch(err => {
				console.error('ERR', err);
			})
		},
		openModal() {
			gsap.timeline({
				onComplete: () => {
					gsap.set('.accountdelete', {
						pointerEvents: 'all'
					})
				}
			})
				.to('.accountdelete', {
					duration: 0.3,
					opacity: 1
				})
				.to('.accountdelete__box', {
					duration: 0.3,
					y: '0%',
					opacity: 1
				})
		},
		closeModal() {
			gsap.timeline({
				onComplete: () => {
					gsap.set('.accountdelete', {
						pointerEvents: 'none'
					})
				}
			})
			.to('.accountdelete__box', {
				duration: 0.3,
				y: 150,
				opacity: 0
			})
			.to('.accountdelete', {
				duration: 0.3,
				opacity: 0
			})
		},
		openDrawer() {
			gsap.to('.accountdelete', {
				duration: 0.9,
				x: '0%',
				ease: CustomEase.create("custom", "M0,0 C0.726,0 0,1 1,1 "),
				onComplete: () => {
					gsap.set('.accountdelete', {
						pointerEvents: 'all'
					})
				}
			})
		},
		closeDrawer() {
			gsap.to('.accountdelete', {
				duration: 0.9,
				x: '100%',
				ease: CustomEase.create("custom", "M0,0 C0.726,0 0,1 1,1 ")
			})
		},
		init() {
		},
	},
	mounted() {
		EventBus.$on('delete-account', () => {
			this.init();
			if(this.mode == 'mobile') {
				this.openDrawer()
			} else {
				this.openModal();
			}
		});

		this.init();
	}
}
</script>
<style lang="scss">
.accountdelete {
	@extend %flexCenterCenter;
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	background: $color-seasalt;
	z-index: 10000;
	display: block;
	overflow: auto;
	transform: translateX(100%);
	pointer-events: none;
	&__head {
		@extend %flexCenterStart;
		@include size(100%, var(--headDrawer));
		padding: 0 var(--gutter);
		&__back {
			@extend %flexCenterStart;
			width: calc(100% - var(--btn));
			cursor: pointer;
			&__icon {
				@extend %flexCenterCenter;
				@include size(var(--smallicon),var(--smallicon));
				svg {
					display: block;
					@include size(75%,75%);
					fill: $color-black;
				}
			}
			&__label {
				@extend %text-15;
				display: block;
				width: max-content;
				font-variation-settings: 'wght' 600;
				padding-left: var(--minigutter);
			}
		}
	}
	&__box {
		@extend  %panel;
		background: transparent;
		padding: 0 var(--gutter);
		height: auto;
		overflow: auto;
		&__head {
			@extend %flexCenterBetween;
			width: 100%;
			display: none;
			margin-bottom: var(--gutter);
			&__title {
				@extend %text-33;
				display: block;
				width: 100%;
				font-family: $font-manrope;
				font-variation-settings: 'wght' 700;
			}
			&__close {
				@extend %icon;
				cursor: pointer;
				svg {
					@include size(70%,70%);
					fill: $color-black;
				}
			}
		}
		&__text {
			@extend %text-19;
			display: block;
			width: 100%;
		}
		&__actions {
			@extend %flexCenterBetween;
			width: 100%;
		}
		&__btn {
			width: 100%;
			&.filled {
				background: $color-red;
				color: $color-white;
			}
		}
		@media (max-width: $to-large) {
			display: flex;
			box-shadow: none;
			flex-direction: column;
			justify-content: space-between;
			height: calc(100vh - var(--headDrawer));
			padding-bottom: var(--gutter);
			&__btn {
				&.outline {
					display: none;
				}
			}
		}
	}
	@media (min-width: $from-large) {
		display: flex;
		background: rgba($color-black, 0.1);
		transform: translateX(0%);
		opacity: 0;
		&__head {
			display: none;
		}
		&__box {
			background: $color-white;
			max-height: 90vh;
			width: 780px;
			max-height: 75vh;
			padding: var(--gutter) var(--gutter) calc(var(--supergutter) * 2);
			transform: translateY(150px);
			opacity: 0;
			position: relative;
			&__head {
				display: flex;
				margin-bottom: var(--thirdgutter);
			}
			&__text {
				font-size: 0.938rem;
			}
			&__content {
				position: absolute;
				top: 50%;
				left: 0;
				display: block;
				width: 100%;
				text-align: center;
				transform: translateY(-50%);
			}
			&__actions {
				position: absolute;
				bottom: var(--gutter);
				left: 0;
				width: 100%;
				padding: 0 var(--gutter);
			}
			&__btn {
				width: 47%;
			}
		}
	}
}
</style>