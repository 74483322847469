<template>
	<div class="garage">
		<div class="garage__head">
			<span class="garage__head__label">
				{{ $t('common.titles.mygarages') }}
			</span>
			<span class="garage__head__actions">
				<template v-if="list && list.length">
					<router-link :to="{ name: 'garages.page' }" class="garage__head__btn simple-btn">
						{{ $t('common.viewall') }}
					</router-link>
				</template>
			</span>
		</div>
		<div class="garage__content">
			<template v-if="isLoading">
				<span class="garage__loading">
					<Spinner />
				</span>
			</template>
			<template v-else>
				<template v-if="list && list.length">
					<GaragePreview v-for="(l, lI) in list" :key="lI" :garage="l" type="list" />
				</template>
				<template v-else>
					<small class="garage__content__nodata">
						<router-link :to="{ name: 'create-garage.page'}" class="garage__content__nodata__btn btn outline" v-html="$t('garages.empty.title')"></router-link>
					</small>
				</template>
			</template>
		</div>
	</div>
</template>
<script>
import GaragePreview from '@/views/components/garages/GaragePreview';
import Spinner from '@/views/components/common/Spinner';

export default {
	name: 'Garage',
	props: {
		list: {
			required: true,
			default: []
		},
		isLoading: {
			type: Boolean,
			required: true,
			default: false,
		}
	},
	components: {
		Spinner,
		GaragePreview
	}
}
</script>
<style lang="scss">
.garage {
	@extend %panel;
	@extend .bigradius;
	width: 100%;
	font-family: $font-manrope;
	padding: var(--minigutter);
	&__head {
		@extend %flexCenterBetween;
		width: 100%;
		margin-bottom: var(--minigutter);
		&__label {
			@extend %text-20;
			font-variation-settings: 'wght' 700;
		}
		&__actions {
			@extend %flexCenterEnd;
		}
		&__btn {
			@extend %flexCenterCenter;
			@extend %simplebtn;
		}
	}
	&__loading {
		@extend %flexCenterCenter;
		@include size(100%,100%);
		position: relative;
		.spinner {
			@include size(30px,30px);
		}
	}
	&__content {
		display: block;
		width: 100%;
		&__nodata {
			@extend %flexCenterCenter;
			width: 100%;
			padding: var(--supergutter);
			text-transform: uppercase;
			&__btn {
				padding: var(--thirdgutter) var(--gutter);
				text-align: center;
			}
		}
	}
	@media (min-width: $from-large) {
		width: 49%;
		padding: var(--gutter);
	}
}
</style>