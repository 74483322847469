<template>
	<div id="loading">
		<span class="loading__content">
			<span class="loading__content__spinner">
				<Spinner />
			</span>
			<span class="loading__content__text">
				{{ $t('common.is_loading') }}
			</span>
		</span>
	</div>
</template>
<script>
import Spinner from '@/views/components/common/Spinner';

export default {
	name: 'Loading',
	props: {
		isLoading: {
			type: Boolean,
			required: false,
			default: true
		}
	},
	components: {
		Spinner,
	},
	mounted() {
		if(!this.loading) {
			setTimeout(() => {
				this.$el.classList.add('show');
			}, 100)
		} else {
			this.$el.classList.remove('show');
		}
	}
}
</script>
<style lang="scss">
#loading {
	position: absolute;
	top: 0;
	left: 0;
	@extend %flexCenterCenter;
	@include size(100%,100%);
	opacity: 0;
	pointer-events: none;
	overflow: hidden;
	z-index: 10000000;
	transition: 350ms var(--ease);
	.loading__content {
		@extend %flexCenterCenter;
		flex-direction: column;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%,-50%);
		z-index: 100;
		&__spinner {
			display: block;
			@include size(65px,65px);
			margin: 0 auto var(--gutter);
		}
		&__text {
			@extend %text-15;
			display: block;
			width: 100%;
			font-family: $font-manrope;
			font-variation-settings: 'wght' 600;
			text-align: center;
		}
	}
	&.show {
		opacity: 1;
		&:after {
			transform: translateY(0%);
		}
	}
}
</style>