<template>
	<div class="deleteavailabilities">
		<div class="deleteavailabilities__head">
			<div class="deleteavailabilities__head__title">
				{{ $t('availabilities.delete.title') }}
			</div>
		</div>
		<div class="deleteavailabilities__text" v-html="$t('availabilities.delete.text')"></div>
		<template v-if="isLoading">
			<span class="deleteavailabilities__spinner">
				<Spinner />
			</span>
		</template>
		<template v-else>
			<template v-if="isDeleted">
			</template>
			<template v-else>
				<template v-if="hasErrors">
					<span class="deleteavailabilities__errors">
						{{ errMessage }}
					</span>
					<span class="deleteavailabilities__btn btn outline deleteavailabilities__errors__close" @click="closeModal">
						{{ $t('common.btns.cancel') }}
					</span>
				</template>
				<template v-else>
					<div v-if="list" class="deleteavailabilities__content">
					<span v-for="l in list" :key="l.id" class="avaitem">
						<span class="avaitem__link__info">
							<span class="avaitem__link__info__time start">
								{{ l.start }}
							</span>
							<span v-if="l.end" class="avaitem__link__info__time end">
								{{ l.end }}
							</span>
						</span>
					</span>
						</span>
					</div>
					<div class="deleteavailabilities__actions">
					<span class="deleteavailabilities__btn btn outline" @click="closeModal">
						{{ $t('common.btns.cancel') }}
					</span>
						<span class="deleteavailabilities__btn btn filled" @click="deleteAvailabilties">
						{{ $t('common.btns.delete') }}
					</span>
					</div>
				</template>
			</template>
		</template>
	</div>
</template>
<script>
import { EventBus } from '@/events_bus.js';
import { gsap } from 'gsap';
import { CustomEase } from 'gsap/all';
gsap.registerPlugin(CustomEase);

import dayjs from 'dayjs';

import Spinner from '@/views/components/common/Spinner';

export default {
	name: 'DeleteAvailabilityList',
	props: {
		selected: {
			type: Array,
			required: true,
		}
	},
	components: {
		Spinner
	},
	data() {
		return {
			isLoading: false,
			isDeleted: false,
			list: null,
			tbdeleted: null,
			errMessage: null,
			hasErrors: false
		}
	},
	methods: {
		closeModal() {
			EventBus.$emit('close-delete-list');
		},
		deleteAvailabilties() {
			this.isLoading = true;
			this.$service.availability.multiDelete(this.tbdeleted).then(res => {
				this.isDeleted = true;
				setTimeout(() => {
					EventBus.$emit('close-delete-list');
					this.isLoading = false;
					this.isDeleted = false;
					EventBus.$emit('ava-deleted');
				}, 1500)
			}).catch(err => {
				console.error('ERR', err);
				this.hasErrors = true;
				this.isLoading = false;
				this.isDeleted = false;
				this.errMessage = err.messages.message;
			})
		},
		formatItem(item) {
			let start = dayjs(item.start_at).valueOf();
			let end = dayjs(item.end_at).valueOf();
			if(dayjs(start).date() == dayjs(end).date()) {
				item.start = `${dayjs(start).format('DD MMM')} - ${dayjs(start).format('HH:mm')} - ${dayjs(end).format('HH:mm')}`;
			} else {
				item.start = `${dayjs(start).format('DD MMM - HH:mm')}`;
				item.end = `${dayjs(end).format('DD MMM - HH:mm')}`;
			}
			return item;
		}
	},
	mounted() {
		this.list = [];
		this.tbdeleted = [];
		if(this.selected && this.selected.length) {
			this.selected.forEach(l => {
				this.list.push(this.formatItem(l));
				this.tbdeleted.push(l.id);
			})
		}
	}
}
</script>
<style lang="scss">
.deleteavailabilities {
	display: block;
	width: 100%;
	height: 100%;
	overflow: auto;
	padding: var(--gutter);
	
	&__errors {
		@extend %flexCenterCenter;
		padding: var(--supergutter);
		text-align: center;
		@extend %text-13;
		text-transform: uppercase;
		color: $color-error;
		
		&__close {
			margin: 0 auto;
		}
	}
	
	&__spinner {
		@extend %flexCenterCenter;
		margin: calc(var(--supergutter) * 2) auto;
		position: relative;
		@include size(var(--supergutter), var(--supergutter));
	}
	&__head {
		@extend %flexCenterBetween;
		width: 100%;
		display: none;
		margin-bottom: var(--gutter);
		&__title {
			@extend %text-33;
			display: block;
			width: 100%;
			font-family: $font-manrope;
			font-variation-settings: 'wght' 700;
		}
		&__close {
			@extend %icon;
			cursor: pointer;
			svg {
				@include size(70%,70%);
				fill: $color-black;
			}
		}
	}
	&__text {
		@extend %text-19;
		display: block;
		width: 100%;
	}
	&__content {
		display: block;
		width: 100%;
		padding: 0 var(--gutter);
		margin: var(--supergutter) 0;
		overflow: auto;
	}
	&__actions {
		position: absolute;
		bottom: 0;
		left: 0;
		@extend %flexCenterBetween;
		width: 100%;
	}
	&__btn {
		width: 100%;
		&.filled {
			background: $color-red;
			color: $color-white;
		}
	}
	@media (max-width: $to-large) {
		display: flex;
		box-shadow: none;
		flex-direction: column;
		justify-content: space-between;
		// height: calc(100svh - var(--headDrawer));
		padding-bottom: var(--gutter);
		&__btn {
			&.outline {
				display: none;
			}
		}
	}
	.avaitem {
		display: block;
		width: 100%;
		padding: var(--thirdgutter);
		margin-bottom: var(--minigutter);
		position: relative;
		padding: 0 var(--gutter);
		pointer-events: none !important;
		&__link {
			@extend %panel;
			position: relative;
			@extend %flexCenterBetween;
			width: 100%;
			font-family: $font-manrope;
			padding: var(--thirdgutter);
			z-index: 2;
			transition: 250ms linear;
			&__info {
				display: block;
				width: 100%;
				font-variation-settings: 'wght' 600;
				&__time {
					@extend %flexCenterStart;
					width: 100%;
					line-height: 1;
					&:before {
						content: '';
						display: block;
						@include size(12px,12px);
						border: 2px solid $color-blue;
						margin-right: var(--minigutter);
						border-radius: 100%;
					}
					&.start {
						padding-bottom: 1px;
					}
					&.end {
						position: relative;
						padding-top: calc(var(--minigutter) + 1px);
						&:after {
							content: '';
							position: absolute;
							top: 0;
							left: 5px;
							display: block;
							background: $color-blue;
							@include size(2px,var(--minigutter));
						}
						
					}
				}
			}
		}
		&.disabled {
			pointer-events: none;
			& * {
				pointer-events: none;
			}
		}
	}
	@media (min-width: $from-large) {
		&__head {
			display: flex;
			margin-bottom: var(--thirdgutter);
		}
		&__text {
			font-size: 0.938rem;
		}
		&__content {
			height: calc(100% - 180px);
			padding: var(--gutter) 0 0;
			margin: 0;
		}
		&__actions {
			position: absolute;
			bottom: var(--gutter);
			left: 0;
			width: 100%;
			padding: 0 var(--gutter);
		}
		&__btn {
			width: 47%;
		}
		.avaitem {
			padding: var(--minigutter);
			border-bottom: 1px solid $color-antiflash;
			border-radius: var(--inputradius);
			&__check {
				left: calc(var(--minigutter) - var(--supergutter));
				transition: 250ms linear;
			}
			&__link {
				background: transparent;
				border-radius: 0;
				box-shadow: none;
			}
			&:hover {
				background: $color-antiflash;
			}
		}
	}
}
</style>
