<template>
	<main id="page" class="garages">
		<div class="mobile__head">
			<span class="mobile__head__back" @click="closeDrawer">
				<span class="mobile__head__back__icon">
					<svg viewBox="0 0 50 50">
						<use xlink:href="#icon--back" width="100%" height="100%"></use>
					</svg>
				</span>
				<span class="mobile__head__back__label">
					{{ $t('common.back') }}
				</span>
			</span>
		</div>
		<template v-if="pageLoading">
			<Loading :isLoading="pageLoading" />
		</template>
		<template v-else>
			<template v-if="nogarages">
				<div class="garages__empty">
					<figure class="garages__empty__media">
						<img src="@/assets/images/boy.png" alt="" />
					</figure>
					<span class="garages__empty__title" v-html="$t('garages.empty.title')"></span>
					<span class="garages__empty__text" v-html="$t('garages.empty.text')"></span>
					<router-link :to="{ name: 'create-garage.page' }" class="garages__empty__add"></router-link>
				</div>
			</template>
			<template v-else>
				<div class="garages__head">
					<div class="content">
						<div class="garages__head__title">
							Garages
						</div>
					</div>
				</div>
				<div v-if="garages && garages.length" class="garages__content">
					<div class="smallcontent">
						<div class="garages__box">
							<div class="garages__box__head">
								<span class="garages__box__head__label">
									{{ garages.length }} {{ garages.length > 1 ? $t('garages.labels') : $t('garages.label') }}
								</span>
								<span class="garages__box__actions">
									<router-link :to="{ name: 'create-garage.page'}" class="garages__box__add btn filled">
										<span class="garages__box__add__icon"></span>
										<span class="garages__box__add__label" v-html="$t('garages.btns.add')"></span>
									</router-link>
								</span>
							</div>
							<div class="garages__box__list">
								<GaragePreview v-for="g in garages" :key="g.id" :garage="g" type="preview" />
							</div>
						</div>
					</div>
				</div>
			</template>
		</template>
		
	</main>
</template>
<script>
import { EventBus } from '@/events_bus.js';
import { gsap } from 'gsap';
import { CustomEase } from 'gsap/all';
gsap.registerPlugin(CustomEase);

import Loading from '@/views/components/common/Loading';
import GaragePreview from '@/views/components/garages/GaragePreview';

import AvailabilityList from '@/views/components/garages/AvailabilityList';

export default {
	name: 'Garages',
	components: {
		Loading,
		GaragePreview,
		AvailabilityList
	},
	data() {
		return {
			wWidth: 0,
			nogarages: true,
			garages: null,
			pageLoading: false,
		}
	},
	methods: {
		closeDrawer() {
			this.$router.go(-1);
		},
		onResize() {
			this.wWidth = window.innerWidth;
			if(this.wWidth < 1200) {
				this.editMode = 'mobile'
			} else {
				this.editMode = 'desktop'
			}
		},
		init() {
			this.pageLoading = true;
			this.garages = [];
			this.$service.garages.main.get().then(res => {
				this.pageLoading = false;
				if(res && res.data && res.data.length) {
					this.nogarages = false;
					res.data.forEach(g => {
						this.garages.push(g);
					})

				}
			})
		}
	},
	mounted() {
		this.onResize();
		this.$nextTick(() => {
			window.addEventListener('resize', () => {
				this.onResize();
			});
		});

		this.init();
	}
}
</script>
<style lang="scss">
.garages {
	padding-top: var(--head);
	overflow: auto;
	.mobile__head {
		@extend %flexCenterBetween;
		@include size(100%, var(--head));
		padding: 0 var(--gutter);
		position: relative;
		&__back {
			@extend %flexCenterStart;
			width: calc(100% - var(--btn));
			cursor: pointer;
			&__icon {
				@extend %flexCenterCenter;
				@include size(var(--smallicon),var(--smallicon));
				svg {
					display: block;
					@include size(75%,75%);
					fill: $color-black;
				}
			}
			&__label {
				@extend %text-15;
				display: block;
				width: max-content;
				font-variation-settings: 'wght' 600;
				padding-left: var(--minigutter);
			}
		}
	}
	&__empty {
		@extend %flexCenterCenter;
		flex-direction: column;
		@include size(330px,100%);
		margin: 0 auto;
		&__media {
			display: block;
			@include size(200px,220px);
			margin-bottom: var(--supergutter);
			&__image {
				display: block;
				@include size(100%,100%);
				object-fit: contain;
			}
		}
		&__title {
			@extend %text-25;
			font-family: $font-manrope;
			font-variation-settings: 'wght' 700;
			margin-bottom: var(--minigutter);
			text-align: center;
		}
		&__text {
			@extend %text-15;
			margin-bottom: var(--gutter);
			text-align: center;
		}
		&__add {
			@extend %btn;
			display: block;
			@include size(var(--addbtn),var(--addbtn));
			background: $color-white;
			border: 1px solid $color-blue;
			position: relative;
			padding: 0;
			box-shadow: $shadow;
			@extend %arrowClose;
			&:before,
			&:after {
				background: $color-blue;
				@include size(40%,3px);
			}
			&:before {
				transform: translate(-50%,-50%);
			}
			&:after {
				transform: translate(-50%,-50%) rotate(90deg);
			}
		}
	}
	&__content {
		display: block;
		width: 100%;
		margin: var(--supergutter) 0;
		@media (min-width: $from-large) {
			.small-panel {
				height: var(--smallpanelh);
			}
		}
	}
	&__box {
		@extend %panel;
		@extend %flexCenterBetween;
		flex-wrap: wrap;
		width: 100%;
		padding: var(--gutter);
		background: transparent;
		box-shadow: none;
		&__head {
			@extend %flexCenterBetween;
			width: 100%;
			margin-bottom: var(--gutter);
			&__label, &__actions {
				width: 50%;
			}
			&__label {
				@extend %text-15;
				@extend %flexBaselineStart;
				width: 50%;
			}
			&__actions {
				@extend %flexCenterEnd;
			}
		}
		&__add {
			@extend %flexCenterEnd;
			background: $color-white;
			border: 1px solid $color-silver;
			padding: 0 var(--minigutter);
			&__icon {
				display: block;
				@extend %arrowClose;
				position: relative;
				@include size(var(--minigutter),var(--minigutter));
				border-radius: calc(var(--bigRadius) / 2);
				&:before,
				&:after {
					background: $color-black;
					@include size(100%, 2px);
				}
				&:before {
					transform: translate(-50%,-50%);
				}
				&:after {
					transform: translate(-50%,-50%) rotate(90deg);
				}
			}
			&__label {
				display: block;
				padding-left: var(--thirdgutter);
			}
		}
		&__list {
			display: block;
			width: 100%;
		}
	}
	@media (min-width: $from-large) {
		.mobile__head {
			display: none;
		}
		&__head {
			display: none;
		}
		&__box {
			background: $color-white;
			box-shadow: $shadow;
		}
	}
	@media (max-width: $to-large) {
		position: fixed;
		top: 0;
		left: 0;
		display: block;
		background: $color-antiflash !important;
		padding-top: 0;
		z-index: 100000;
		&__head {
			display: none;
		}
		&__box {
			border-radius: 0;
			box-shadow: none;
			padding: 0;
		}
		&__content {
			margin-top: 0;
		}
	}
}
</style>