import { EventBus } from '@/events_bus.js';
import dayjs from 'dayjs';

function sortPush(arr) {
	const sortedArr = arr.sort((a, b) => {
		if (new Date(a.created_at).getTime() > new Date(b.created_at).getTime()) {
			return -1;
		}
		if (new Date(a.created_at).getTime() < new Date(b.created_at).getTime()) {
			return 1;
		}
		return 0;
	});

	return sortedArr;
}

export default {
	install: function (Vue) {
		let notifications = {};
		Vue.mixin({
			mounted() {
				/**
				 * Crea i parametri per la lista
				 * @param page
				 * @param sort
				 * @param search
				 * @param search_fields
				 * @returns {string}
				 */
				notifications.init = () => {
					let list = this.$api.get('/notifications').then(res => {
						let nots = res.data;

						let now = dayjs();
						let today = [];
						let lastWeek = [];
						let lastMonth = [];
						if(nots && nots.length) {
							nots.forEach(n => {
								let date = dayjs(n.created_at);
								let diff = now.diff(date, 'day');
								switch(true) {
									case diff == 0:
										today.push(n);
										break;
									case diff > 0 && diff <= 7:
										lastWeek.push(n);
										break;
									case diff > 7:
										lastMonth.push(n);
										break;
								}
							})
			
							let n = {};
			
							if(today && today.length) {
								n.today = sortPush(today);
							}
							if(lastWeek && lastWeek.length) {
								n.lastWeek = sortPush(lastWeek);
							}
							if(lastMonth && lastMonth.length) {
								n.lastMonth = sortPush(lastMonth);
							}
							return n;
						}

					});
					return list;
				}
		
				notifications.getDetail = (id) => {
					return this.$api.get(`/notifications/${id}`);
				}
		
				notifications.howmany = () => {
					let hm = 0;
					let num = this.$api.get('/notifications').then(res => {
						res.data.forEach(r => {
							if(!r.read_at) {
								hm += 1;
							}
						})
						return hm;
					});
					return num;
				}

				notifications.isRead = (id) => {
					let letta = this.$api.post(`/notifications/${id}/read`).then(res => {
						EventBus.$emit('notification-read');
						return res;
					})
					return letta;
				}
			}
		});


		Vue.prototype.$nots = notifications;
	},
};
