<template>
	<span class="applebox">
		<span class="apple">
			Accedi con Apple
		</span>
		<vue-apple-login
			:radius="20"
			:border="true"
			type="sign in"
			className="vue-apple-login btn"
			:onSuccess="appleSuccess"
			:onFailure="appleFail"
		></vue-apple-login>
	</span>
</template>
<script>
export default {
	name: "AppleLogin",
	methods: {
		appleSuccess(e) {
			this.$service.login.apple(e.authorization.id_token).then(res => {
				this.$api.setToken(res.access_token, res.expires_in);
				window.location.href = '/#/app/';
			}).catch(err => {
				console.error('ERR APPLE', err);
			})
		},
		appleFail(e) {
			console.log("APPLE FAIL", e);
		},
	},
	mounted() {},
};
</script>
<style lang="scss">
.vue-apple-login {
	opacity: 0;
}
.applebox {
	display: block;
	position: relative;
	margin-bottom: calc(var(--gutter) * 0.66);
	.apple {
		position: absolute;
		top: 0;
		left: 0;
		@extend %btn;
		width: 100%;
		color: $color-white;
		margin-bottom: calc(var(--gutter) * 0.66);
		background: $color-black;
	}
}
</style>
