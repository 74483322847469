<template>
	<router-link v-if="booking" :to="{ name: 'bookingdetail.page', params: { id: booking.id }}" class="bookingpreview" :class="type">
		<template v-if="type == 'preview'">
			<div class="bookingpreview__symbol">
				<template v-if="booking.single_booking">
					<svg viewBox="0 0 50 50">
						<use xlink:href="#icon--clock" width="100%" height="100%"></use>
					</svg>
				</template>
				<template v-else>
					<svg viewBox="0 0 50 50">
						<use xlink:href="#icon--calendar" width="100%" height="100%"></use>
					</svg>
				</template>
			</div>
		</template>
		<span class="bookingpreview__data">
			<span class="bookingpreview__data__datetime" v-html="dates"></span>
			<small v-if="booking.garage && booking.garage.name" class="bookingpreview__data__name" v-html="booking.garage.name"></small>
			<small v-if="booking.id" class="bookingpreview__data__id">
				{{ $t('common.booking') }} <span>{{ booking.id }}</span>
			</small>
		</span>
		<span class="bookingpreview__info">
			<template v-if="type == 'list'">
				<!-- <span class="bookingpreview__info__id">{{ booking.id }}</span> -->
				<span class="bookingpreview__info__icon" :class="{ 'occupied': occupied }">
					<span class="emptybooking">
						<svg viewBox="0 0 50 50">
							<use xlink:href="#icon--garage-empty" width="100%" height="100%"></use>
						</svg>
					</span>
					<span class="fullbooking">
						<svg viewBox="0 0 50 50">
							<use xlink:href="#icon--garage-full" width="100%" height="100%"></use>
						</svg>
					</span>
				</span>
			</template>
			<template v-if="type == 'preview'">
				<span class="bookingpreview__price">
					{{ booking.price }} &euro; <small>{{ booking.price_hour }} &euro; / ora</small>
				</span>
				<span class="bookingpreview__idnum" :class="booking.status.toLowerCase()">
					<small>{{ $t('bookings.status.title') }}</small>
					{{ $t(`bookings.status.${booking.status.toLowerCase()}`) }}
				</span>
			</template>
		</span>
	</router-link>
</template>
<script>
import dayjs from 'dayjs';

export default {
	name: 'BookingPreview',
	props: {
		booking: {
			type: Object,
			required: true,
			default: {}
		},
		type: {
			type: String,
			required: true,
			default: 'list'
		}
	},
	data() {
		return {
			bookiginInfo: null,
			now: null,
			dates: '',
			occupied: false,
			total: {
				days: null,
				hours: null,
				price: null
			},
		}
	},
	methods: {
		getMonth(start,end) {
			
		},
		init() {
			this.now = new Date().getTime();
			let start = new Date(this.booking.start_at).getTime();
			let end = new Date(this.booking.end_at).getTime();

			this.total.days = parseInt(dayjs(this.booking.end_at).diff(dayjs(this.booking.start_at), 'day', true));
			this.total.hours = dayjs(this.booking.end_at).hour() - dayjs(this.booking.start_at).hour();
			this.total.price = (this.total.days * this.total.hours) * this.booking.price_hour;

			if(this.booking.single_booking) {
				if(dayjs(start).month() == dayjs(this.booking.end_at).month()) {
					this.dates = `${dayjs(this.booking.start_at).format('DD')} - ${dayjs(this.booking.end_at).format('DD MMM')} - ${dayjs(this.booking.start_at).format('HH:mm')} - ${dayjs(this.booking.end_at).format('HH:mm')}`;
				} else {
					this.dates = `${dayjs(this.booking.start_at).format('DD MMM')} - ${dayjs(this.booking.end_at).format('DD MMM')} - ${dayjs(this.booking.start_at).format('HH:mm')} - ${dayjs(this.booking.end_at).format('HH:mm')}`;
				}
			} else {
				this.dates = `${dayjs(this.booking.start_at).format('DD MMM')} - ${dayjs(this.booking.end_at).format('DD MMM')} - ${dayjs(this.booking.start_at).format('HH:mm')} - ${dayjs(this.booking.end_at).format('HH:mm')}`;
			}

			if(this.now > start && this.now < end) {
				this.occupied = true;
			}
		}
	},
	mounted() {
		if(this.booking) {
			this.init()
		}
	}
}
</script>
<style lang="scss">
.bookingpreview {
	@extend %flexCenterBetween;
	width: 100%;
	padding: var(--thirdgutter);
	transition: 250ms linear;
	&:hover {
		background: $color-seasalt;
	}
	&__symbol {
		@extend %icon;
		svg {
			fill: $color-blue;
		}
	}
	&__data {
		display: block;
		&__datetime,
		&__name,
		&__id {
			display: block;
			width: 100%;
		}
		&__id {
			text-transform: uppercase;
			color: rgba($color-black, 0.4);
			@extend %text-10;
			margin-top: 6px;
			span {
				@extend %text-12;
				font-variation-settings: 'wght' 700;
				color: $color-black;
			}
		}
	}
	&__info {
		&__id {
			@extend %text-20;
			font-family: $font-manrope;
			font-variation-settings: 'wght' 700;
			color: $color-davy;
		}
		&__icon {
			@extend %icon;
			margin-left: var(--thirdgutter);
			svg {
				display: block;
				@include size(100%,100%);
				fill: $color-black;
			}
			.emptybooking {
				display: block;
			}
			.fullbooking {
				display: none;
			}
			&.occupied {
				.emptybooking {
					display: none;
				}
				.fullbooking {
					display: block;
				}
			}
		}
	}
	&.list {
		.bookingpreview {
			&__symbol {
				display: none;
			}
			&__data {
				width: 60%;
				&__datetime {
					@extend %text-15;
					font-variation-settings: 'wght' 600;
					margin-bottom: 3px;
					@media (max-width: $to-large) {
						font-size: 1rem;
					}
				}
				&__name {
					@extend %text-10;
					color: rgba($color-davy, 0.66);
					font-variation-settings: 'wght' 700;
					text-transform: uppercase;
				}
			}
			&__info {
				@extend %flexCenterEnd;
				width: 40%;
			}
		}
	}
	&.preview {
		flex-wrap: wrap;
		background: $color-white;
		border-radius: var(--radius);
		box-shadow: $shadow;
		margin: var(--minigutter) 0;
		padding: calc(var(--thirdgutter) * 2);
		position: relative;
		&:first-of-type {
			margin-top: 0;
		}
		&:hover {
			background: $color-seasalt;
		}
		&:after {
			content: '';
			position: absolute;
			top: 100%;
			left: 0;
			display: block;
			background: $color-antiflash;
			@include size(100%, 1px);
		}
		&:last-of-type {
			&:after {
				display: none;
			}
		}
		&:before {
			content: '';
			position: absolute;
			top: 0;
			left: calc(var(--supergutter) * 0.9);
			display: block;
			background: $color-silver;
			@include size(1px, 100%);
		}
		.bookingpreview {
			&__symbol {
				position: absolute;
				top: calc(var(--thirdgutter) * 2);
				left: 0;
				@extend %icon;
				display: block;
				width: calc(var(--supergutter) * 0.9);
				svg {
					@include size(var(--smaiicon),var(--smallicon));
					fill: $color-blue;
					margin: 0 auto;
				}
			}
			&__data,
			&__info {
				width: calc(100% - (var(--supergutter) * 0.9) + (var(--thirdgutter) * 2));
				margin-left: calc((var(--supergutter) * 0.9) - (var(--thirdgutter) * 2));
				padding-left: var(--thirdgutter);
			}
			&__data {
				&__datetime {
					@extend %text-20;
					font-variation-settings: 'wght' 600;
					margin-bottom: 3px;
					@media (max-width: $to-large) {
						font-size: 1rem;
					}
				}
				&__name {
					@extend %text-14;
					color: rgba($color-davy, 0.66);
					font-variation-settings: 'wght' 700;
					text-transform: uppercase;
				}
			}
			&__info {
				@extend %flexCenterStart;
				padding-top: var(--minigutter);
			}
			&__price {
				@extend %text-20;
				@extend %flexBaselineStart;
				width: 50%;
				font-variation-settings: 'wght' 700;
				text-align: left;
				small {
					@extend %text-13;
					display: block;
					font-variation-settings: 'wght' 400;
					padding-left: calc(var(--thirdgutter) * 0.75);
				}
			}
			&__idnum {
				@extend %text-16;
				@extend %flexEndCenter;
				flex-direction: column;
				width: 50%;
				font-variation-settings: 'wght' 700;
				text-align: right;
				text-transform: uppercase;
				small {
					@extend %text-10;
					text-transform: uppercase;
					color: $color-black;
					font-variation-settings: 'wght' 400;
				}
				&.confirmed {
					color: $color-success;
				}
				&.pending {
					color: $color-warn;
				}
				&.canceled {
					color: $color-error;
				}
			}
		}
	}
	@media (min-width: $from-large) {
		&.list {
			.bookingpreview {
				&__data {
					width: 75%;
				}
				&__info {
					width: 25%;
				}
			}
		}
		&.preview {
			box-shadow: none;
			margin: var(--thirdgutter) 0;
			&:before {
				display: none;
			}
			.bookingpreview {
				&__data {
					width: 300px;
				}
				&__info {
					display: flex;
					width: 50%;
					margin: 0;
					padding: 0;
				}
				&__price {
					justify-content: flex-end;
					text-align: right;
				}
			}
		}
	}
}
</style>