<template>
	<div class="veryphone">
		<template v-if="!isLoading && tested && confirmed">
			<div class="veryphone__result confirm">
				<span class="veryphone__result__icon">
					<svg viewBox="0 0 50 50">
						<use xlink:href="#icon--check" width="100%" height="100%"></use>
					</svg>
				</span>
				<span class="veryphone__result__label">
					Verifica avvenuta con successo<br />
				</span>
				<router-link :to="{ name: 'account.page' }" class="veryphone__result__btn btn filled account">
					Torna al tuo account
				</router-link>
			</div>
		</template>
		<template v-if="!isLoading && tested && !confirmed">
			<div class="veryphone__result error">
				<span class="veryphone__result__icon">
					<svg viewBox="0 0 50 50">
						<use xlink:href="#icon--close" width="100%" height="100%"></use>
					</svg>
				</span>
				<span class="veryphone__result__label">
					Si è verificato un errore
				</span>
				<span class="veryphone__result__btn btn filled retry" @click="tryAgain">
					Riprova
				</span>
			</div>
		</template>
		<template v-if="isLoading && !tested">
			<div class="veryphone__loading">
				<span class="veryphone__loading__label">
					Stiamo verificando il codice
				</span>
				<span class="veryphone__loading__spinner">
					<Spinner />
				</span>
			</div>
		</template>
		<template v-if="!isLoading && !tested && !confirmed">
			<div class="veryphone__code" ref="otpCode">
				<input type="text" v-for="(el, ind) in digits" :key="el+ind" v-model="digits[ind]" :autofocus="ind === 0" maxlength="1" @keydown="handleKeyDown($event, ind)" />
			</div>
			<small v-if="tooshort" class="veryphone__tooshort">
				Codice troppo corto
			</small>
			<div class="veryphone__instruction" v-html="$t('account.phone.instruction')"></div>
			<span class="veryphone__resend" @click="resend">
				{{ $t('account.phone.resend') }}
			</span>
		</template>
	</div>
</template>
<script>
import { EventBus } from '@/events_bus.js';
import Spinner from '@/views/components/common/Spinner';

export default {
	name: 'VerifyPhoneSystem',
	components: {
		Spinner
	},
	data() {
		return {
			isLoading: false,
			code: '',
			digitCount: 5,
			digits: null,
			phoneSent: false,
			confirmed: false,
			tested: false,
			tooshort: null,
		}
	},
	methods: {
		resend() {
			this.$service.user.phone.verification().then(res => {
				this.phoneSent = true;
			})
		},
		handleKeyDown(event, index) {
			if(event.key !== 'Tab' && event.key !== 'ArrowRight' && event.key !== 'ArrowLeft') {
				event.preventDefault();
				event.stopPropagation();
			}

			if(event.key === 'Backspace') {
				this.digits[index] = '';

				if(index != 0) {
					this.$refs.otpCode.children[index - 1].focus();
				}
				this.$forceUpdate();

				return;
			}

			if((new RegExp('^([0-9])$')).test(event.key)) {
				this.digits[index] = event.key;

				if(index != this.digitCount - 1) {
					this.$refs.otpCode.children[index + 1].focus();
				}
			}

			if(this.digits.length == this.digitCount && !this.digits.includes('')) {
				this.tooshort = null;
				this.verifyCode();
			} else {
				this.tooshort = true;
			}
			this.$forceUpdate();
		},
		verifyCode() {
			this.isLoading = true;
			this.$service.user.phone.opt(this.digits.join('')).then(res => {
				this.isLoading = false;
				this.confirmed = true;
				this.tested = true;
				EventBus.$emit('phone-verification-successful');
			}).catch(err => {
				console.error('ERR', err);
				this.isLoading = false;
				this.confirmed = false;
				this.tested = true;
			})
		},
		init() {
			this.digits = [];
			for(let i = 0; i < this.digitCount; i++) {
				this.digits[i] = this.code.charAt(i);
			}	
		},
		tryAgain() {
			this.isLoading = false;
			this.confirmed = false;
			this.tested = false;
			this.digits = ['','','','',''];
		}
	},
	mounted() {
		this.init();
	}
}
</script>
<style lang="scss">
.veryphone {
	display: block;
	width: 100%;
	margin: var(--supergutter) 0;
	&__text {
		@extend %text-19;
		display: block;
		width: 100%;
	}
	&__code {
		@extend %flexCenterCenter;
		width: 100%;
		margin: var(--gutter) 0 0;
		input {
			@extend %text-40;
			@extend %flexCenterCenter;
			@include size(var(--supergutter),var(--smallpanelh));
			border-radius: calc(var(--bigradius) / 2);
			border: 1px solid $color-grey;
			color: $color-black;
			margin: 0 6px;
			text-align: center;
		}
	}
	&__instruction {
		@extend %text-15;
		display: block;
		width: 100%;
		text-align: center;
		margin: var(--gutter) 0;
	}
	&__resend {
		display: block;
		width: max-content;
		margin: var(--gutter) auto 0;
		font-variation-settings: 'wght' 700;
		cursor: pointer;
	}
	&__loading {
		display: block;
		width: 100%;
		&__label {
			display: block;
			@extend %text-20;
			font-family: $font-manrope;
			font-variation-settings: 'wght' 600;
			text-align: center;
		}
		&__spinner {
			display: block;
			@include size(65px,65px);
			position: relative;
			margin: var(--gutter) auto;
		}
	}
	&__tooshort {
		display: block;
		width: 100%;
		text-align: center;
		color: $color-error;
		text-transform: uppercase;
		@extend %text-12;
		padding-top: var(--minigutter);
	}
	&__result {
		display: block;
		width: 100%;
		text-align: center;
		&__icon {
			@extend %flexCenterCenter;
			@include size(var(--icon),var(--icon));
			border: 4px solid $color-black;
			border-radius: 100%;
			margin: 0 auto;
			svg {
				display: block;
				@include size(60%,60%);
			}
		}
		&__label {
			display: block;
			width: 100%;
			text-align: center;
			@extend %text-20;
			font-family: $font-manrope;
			font-variation-settings: 'wght' 600;
			margin-top: var(--gutter);
		}
		&__btn {
			display: block;
			width: max-content;
			padding: 0 var(--gutter) !important;
			box-shadow: 0;
			margin: var(--supergutter) auto;
			&.account {
				background: $color-blue;
				color: $color-white;
			}
			&.retry {
				background: $color-green;
				color: $color-white;
			}
		}
		&.confirm {
			.veryphone__result {
				&__icon {
					border-color: $color-success;
					svg {
						fill: $color-success;
					}
				}
				&__label {
					color: $color-success;
				}
			}
		}
		&.error {
			.veryphone__result {
				&__icon {
					border-color: $color-error;
					svg {
						fill: $color-error;
					}
				}
				&__label {
					color: $color-black;
				}
			}
		}
	}
	&__text {
		display: block;
		width: 100%;
		text-align: center;
	}
}
</style>