<template>
	<div class="uniqueform">
		<div class="uniqueform__block dates">
			<span class="uniqueform__title">
				Data di inizio *
			</span>
			<date-picker v-model="fromdate" valueType="timestamp" lang="it" format="ddd, D MMMM YYYY" :disabled-date="(date) => date < new Date()" placeholder="Data" class="dateinput" v-bind:clearable="false" @change="checkRepeats"></date-picker>
			<span class="uniqueform__title">
				Data di fine *
			</span>
			<date-picker v-model="todate" valueType="timestamp" lang="it" format="ddd, D MMMM YYYY" :disabled-date="(date) => date < getEndDate()" placeholder="Data" class="dateinput" v-bind:clearable="false" @change="checkRepeats"></date-picker>
			<span class="uniqueform__title">
				Orari *
			</span>
			<span class="times__box">
				<span class="times__box__label">
					Inizio
				</span>
				<date-picker v-model="fromtime" type="time" format="H:mm" :minute-step="10" :use12h="false" placeholder="Orario" class="timeinput" v-bind:clearable="false"></date-picker>
			</span>
			<span class="times__box">
				<span class="times__box__label">
					Fine
				</span>
				<date-picker v-model="totime" type="time" format="H:mm" :minute-step="10" :use12h="false" placeholder="Orario" class="timeinput" v-bind:clearable="false"></date-picker>
			</span>
		</div>
		<div class="uniqueform__block prices">
			<span class="uniqueform__title">
				Costi *
			</span>
			<div class="prices__content">
				<label for="unihourprice" class="prices__type">
					<input type="radio" name="price" id="unihourprice" class="prices__type__input" checked @change="checkIfSingle($event)" />
					<span class="prices__type__fake"></span>
					<span class="prices__type__label" v-html="$t('availabilities.cost.hourly.label')"></span>
				</label>
				<span v-if="!form.single_booking" class="prices__info" v-html="$t('availabilities.cost.hourly.text')"></span>
				<small v-if="!form.single_booking">&euro;</small>
				<input v-if="!form.single_booking" type="number" name="number" id="number" min="1" v-model="form.price_hour" class="prices__number__input" />
			</div>
			<div class="prices__content">
				<label for="unifullprice" class="prices__type" :class="{ 'disabled': !okmonth }">
					<input type="radio" name="price" id="unifullprice" class="prices__type__input" @change="checkIfSingle($event)" />
					<span class="prices__type__fake"></span>
					<span class="prices__type__label" v-html="$t('availabilities.cost.total.label')"></span>
				</label>
				<span v-if="form.single_booking" class="prices__info" v-html="$t('availabilities.cost.total.text')"></span>
				<small v-if="form.single_booking">&euro;</small>
				<input v-if="form.single_booking" type="number" name="number" id="number" min="1" v-model="form.price" class="prices__number__input" />
			</div>
		</div>
		<div class="uniqueform__block repeats" :class="{ 'disabled': isedit }">
			<span class="uniqueform__repeatrigger">
				<label for="unirepeat" class="uniqueform__repeatrigger__toggle">
					<span class="uniqueform__repeatrigger__toggle__title" v-html="$t('availabilities.repeats.label')"></span>
					<input type="checkbox" name="unirepeat" id="unirepeat" class="uniqueform__repeatrigger__toggle__input" @change="repeats($event)"/>
					<span class="uniqueform__repeatrigger__toggle__btn" :class="{ on: showunirepeat }"></span>
				</label>
			</span>
			<div v-if="showunirepeat" class="uniqueform__repeats rpts">
				<div class="rpts__block">
					<label for="uniweek" class="rpts__type" :class="{ 'disabled': !okweek }">
						<input type="radio" name="repeat" id="uniweek" class="rpts__type__input" value="WEEK" v-model="form.repeat" />
						<span class="rpts__type__fake"></span>
						<span class="rpts__type__label" v-html="$t('availabilities.repeats.week.label')"></span>
					</label>
					<small v-if="!okweek" class="norepeats" v-html="$t('availabilities.repeats.week.noreps')"></small>
					<div v-if="(form.repeat == 'WEEK') && okweek" class="rpts__block__content inline">
						<small class="rpts__block__title" v-html="$t('availabilities.repeats.week.info')"></small>
						<input type="number" name="number" id="number" min="0" value="0" v-model="form.repeat_number" class="rpts__number__input" />
					</div>
				</div>
				<div class="rpts__block">
					<label for="unimonth" class="rpts__type" :class="{ 'disabled': !okmonth }">
						<input type="radio" name="repeat" id="unimonth" class="rpts__type__input" value="MONTH" v-model="form.repeat" />
						<span class="rpts__type__fake"></span>
						<span class="rpts__type__label" v-html="$t('availabilities.repeats.month.label')"></span>
					</label>
					<small v-if="!okmonth" class="norepeats" v-html="$t('availabilities.repeats.month.noreps')"></small>
					<div v-if="form.repeat == 'MONTH' && okmonth" class="rpts__block__content inline">
						<small class="rpts__block__title" v-html="$t('availabilities.repeats.month.info')"></small>
						<input type="number" name="number" id="number" min="0" value="0" v-model="form.repeat_number" class="rpts__number__input" />
					</div>
				</div>
			</div>
		</div>
		<div class="uniqueform__block ctas">
			<span class="ctas__btn btn outline" @click="cancelNewSlot">
				{{ $t('common.btns.cancel') }}
			</span>
			<template v-if="!isedit">
				<span class="ctas__btn btn filled" @click="createNewSlots">
					{{ $t('common.btns.save') }}
				</span>
			</template>
			<template v-else>
				<span class="ctas__btn btn filled" @click="editSlot">
					{{ $t('common.btns.save') }}
				</span>
			</template>
		</div>
		<div v-if="isLoading" class="singleform__loading">
			<template v-if="!editSaved">
				<span class="singleform__loading__spinner">
					<span class="singleform__loading__spinner__box">
						<Spinner />
					</span>
					<span class="singleform__loading__spinner__label">
						{{ $t('common.is_saving') }}
					</span>
				</span>
			</template>
			<template v-else>
				<span class="singleform__success" :class="{ 'success': !errors }">
					<span class="singleform__success__icon">
						<template v-if="!errors">
							<svg viewBox="0 0 50 50">
								<use xlink:href="#icon--check" width="100%" height="100%"></use>
							</svg>
						</template>
						<template v-else>
							<svg viewBox="0 0 50 50">
								<use xlink:href="#icon--close" width="100%" height="100%"></use>
							</svg>
						</template>
					</span>
					<span class="singleform__success__label">
						<template v-if="!errors">
							<span class="singleform__success__label__txt">
								{{ $t('common.edit_saved') }}
							</span>
						</template>
						<template v-else>
							<span class="singleform__success__label__txt">
								{{ errors }}
							</span>
							<span class="singleform__success__label__btn btn filled" @click="tryAgain">
								{{ $t('availabilities.btns.edit') }}
							</span>
						</template>
					</span>
				</span>
			</template>
		</div>
		<div v-if="hasConflicts" class="singleform__conflict">
			<span class="singleform__conflict__title">
				C'è stato un errore
			</span>
			<span v-if="conflicts && conflicts.length" class="singleform__conflict__list">
				<span class="singleform__conflict__text">
					I seguenti intervalli sono già stati inseriti
				</span>
				<span v-for="(c, cI) in conflicts" :key="cI" class="singleform__conflict__item">
					{{ c.start }} <svg viewBox="0 0 50 50"><use xlink:href="#icon--between" width="100%" height="100%"></use></svg> {{ c.end }}
				</span>
				<span class="singleform__conflict__btn btn outline" @click="hideConflicts">
					Modifica
				</span>
			</span>
		</div>
	</div>
</template>
<script>
import { EventBus } from "@/events_bus.js";
import dayjs from "dayjs";
var weekOfYear = require('dayjs/plugin/weekOfYear')
dayjs.extend(weekOfYear);

import { gsap } from "gsap";
import { CustomEase } from "gsap/all";
gsap.registerPlugin(CustomEase);

import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";

import it from 'vue2-datepicker/locale/it';
var utc = require('dayjs/plugin/utc')
var timezone = require('dayjs/plugin/timezone')

dayjs.extend(utc);
dayjs.extend(timezone);

const tz = "Europe/Rome";

dayjs.tz.setDefault(tz);

const { CalendarPanel } = DatePicker;

import Spinner from "@/views/components/common/Spinner";

export default {
	name: "UniqueForm",
	props: {
		gid: {
			required: false
		},
		isedit: {
			type: Boolean,
			required: false,
		}
	},
	components: {
		DatePicker,
		CalendarPanel,
		Spinner,
	},
	data() {
		return {
			hasConflicts: false,
			conflicts: null,
			errors: null,
			isLoading: false,
			editSaved: false,
			info: null,
			fromdate: new Date().valueOf(),
			todate: new Date().valueOf(),
			fromtime: null,
			totime: null,
			addTime: false,
			isEdit: false,
			showunirepeat: false,
			form: {
				id: null,
				start_at: null,
				end_at: null,
				price: 1,
				price_hour: 1,
				single_booking: false,
				garage_id: null,
				range: false,
				repeat: "WEEK",
				repeat_number: 1,
			},
			lang: {
				days: ['Dom', 'Lun', 'Mar', 'Mer', 'Gio', 'Ven', 'Sab'],
			},
			okweek: true,
			okmonth: true,
			slots: null,
		};
	},
	watch: {
		info: {
			handler(newval, oldval) {
				return newval;
			}
		},
		fromdate: {
			deep: true,
			handler(newval, oldval) {
				this.checkRepeats();
			}
		},
		todate: {
			deep: true,
			handler(newval, oldval) {
				this.checkRepeats();
			}
		}
	},
	methods: {
		getEndDate() {
			return new Date(this.fromdate)
		},
		hideConflicts() {
			this.conflicts = [];
			this.hasConflicts = false;
		},
		resetSlot() {
			let coeff = 1000 * 60 * 10;
			let date = new Date;
			this.slots = [];
			this.fromdate = new Date().valueOf();
			this.todate = new Date().valueOf();
			this.fromtime = new Date(Math.round(date.getTime() / coeff) * coeff);
			this.totime = new Date(Math.round(date.getTime() / coeff) * coeff);
			this.form.start_at = new Date();
			this.form.end_at = new Date();
			this.form.price = 1;
			this.form.price_hour = 1;
			this.form.single_booking = false;
			this.form.garage_id = null;
			this.form.range = false;
			this.form.repeat = "WEEK";
			this.form.repeat_number = 1;
		},
		checkRepeats() {
			if(new Date(this.todate).getTime() < new Date(this.fromdate).getTime()) {
				this.todate = new Date(this.fromdate).valueOf();
			}


			let daydiff = dayjs(this.todate).diff(dayjs(this.fromdate), 'day');

			if(daydiff > 7) {
				this.okweek = false;
				this.form.repeat = null;
			} else {
				this.okweek = true;
			}
			if(daydiff > 28) {
				this.okmonth = false;
				this.form.repeat = null;
			} else {
				this.okmonth = true;
			}
		},
		repeats() {
			this.showunirepeat = !this.showunirepeat;
			this.checkRepeats();
		},
		checkIfSingle(e) {
			let type = e.target.getAttribute('id');
			if(type == 'unihourprice') {
				this.form.single_booking = false;
			} else {
				this.form.single_booking = true;
			}
		},
		initEdit() {
			this.fromdate = new Date(this.info.start_at).valueOf();
			this.todate = new Date(this.info.end_at).valueOf();
			this.fromtime = new Date(this.info.start_at);
			this.totime = new Date(this.info.end_at);
			this.form.price = this.info.price;
			this.form.price_hour = this.info.price_hour;
			this.form.single_booking = this.info.single_booking;
			this.form.range = this.info.range;
			this.form.repeat = this.info.repeat;
			this.form.repeat_number = this.info.repeat_number;
			this.form.garage_id = this.info.garage_id;
			this.form.id = this.info.id;

			setTimeout(() => {
				this.$forceUpdate();
			}, 100)
		},
		init() {
			if(this.gid) {
				this.form.garage_id = this.gid;
			}
			setTimeout(() => {
				this.resetSlot();
			}, 100)
		},
		cancelNewSlot() {
			this.resetSlot();
			EventBus.$emit('close-avaform', this.info);
		},
		repeatWeeks() {
			for(let i = 0; i < this.form.repeat_number; i++) {
				this.slots.push({
					start_at: dayjs(this.fromdate).hour(this.startHours.hour).minute(this.startHours.min).add(i, 'week').toISOString(),
					end_at: dayjs(this.todate).hour(this.endHours.hour).minute(this.endHours.min).add(i, 'week').toISOString(),
					price: this.form.price,
					price_hour: this.form.price_hour,
					single_booking: this.form.single_booking,
					garage_id: this.gid,
					repeat: "WEEK",
					repeat_number: this.form.repeat_number,
				})
			}

			this.saveAvailability();
		},
		repeatMonths() {
			for(let i = 0; i < this.form.repeat_number; i++) {
				this.slots.push({
					start_at: dayjs(this.fromdate).hour(this.startHours.hour).minute(this.startHours.min).add(i, 'month').toISOString(),
					end_at: dayjs(this.todate).hour(this.endHours.hour).minute(this.endHours.min).add(i, 'month').toISOString(),
					price: this.form.price,
					price_hour: this.form.price_hour,
					single_booking: this.form.single_booking,
					garage_id: this.gid,
					repeat: "MONTH",
					repeat_number: this.form.repeat_number,
				})
			}

			this.saveAvailability();
		},
		createNewSlots() {
			this.isLoading = true;
			this.slots = [];
			this.startHours = {
				hour: dayjs(this.fromtime).hour(),
				min: dayjs(this.fromtime).minute()
			};
			this.endHours = {
				hour: dayjs(this.totime).hour(),
				min: dayjs(this.totime).minute()
			};

			this.form.start_at = dayjs(this.fromdate).hour(this.startHours.hour).minute(this.startHours.min).toISOString();

			this.form.end_at = dayjs(this.todate).hour(this.endHours.hour).minute(this.endHours.min).toISOString();

			if(!this.showunirepeat) {
				this.form.garage_id = this.gid;
				this.slots.push(this.form);
				this.saveAvailability();
			} else {
				switch(this.form.repeat) {
					case 'WEEK':
						this.repeatWeeks();
						break;
					case 'MONTH':
						this.repeatMonths();
						break;
				}
			}
		},
		editSlot() {
			this.isLoading = true;
			this.slots = [];
			this.startHours = {
				hour: dayjs(this.fromtime).hour(),
				min: dayjs(this.fromtime).minute()
			};
			this.endHours = {
				hour: dayjs(this.totime).hour(),
				min: dayjs(this.totime).minute()
			};

			this.form.start_at = dayjs(this.fromdate).hour(this.startHours.hour).minute(this.startHours.min).toISOString();

			this.form.end_at = dayjs(this.todate).hour(this.endHours.hour).minute(this.endHours.min).toISOString();

			setTimeout(() => {
				this.$service.availability.detail.put(this.info.id, this.form).then(res => {
					this.errors = null;
					this.editSaved = true;
					setTimeout(() => {
						this.isLoading = false;
						EventBus.$emit('return-to-list', this.info);
						this.resetSlot();
					}, 1500)
				}).catch(err => {
					if(err && err.messages && err.messages.data && err.messages.data.length) {
						this.conflicts = [];
						this.hasConflicts = true;
						err.messages.data.forEach(d => {
							this.conflicts.push({
								start: dayjs(d.start_at).format('DD/MM/YYYY - HH:mm'),
								end: dayjs(d.end_at).format('DD/MM/YYYY - HH:mm')
							});
						})
						this.isLoading = false;
					}
					console.error('ERR', err);
				})
			}, 100)
		},
		saveAvailability() {
			this.$service.availability.multiple(this.slots).then(res => {
				this.errors = null;
				this.editSaved = true;
				setTimeout(() => {
					this.isLoading = false;
					EventBus.$emit('return-to-list', this.info);
					this.resetSlot();
				}, 1500)
			}).catch(err => {
				if(err && err.messages && err.messages.data && err.messages.data.length) {
					this.conflicts = [];
					this.hasConflicts = true;
					err.messages.data.forEach(d => {
						this.conflicts.push({
							start: dayjs(d.start_at).format('DD/MM/YYYY - HH:mm'),
							end: dayjs(d.end_at).format('DD/MM/YYYY - HH:mm')
						});
					})
					this.isLoading = false;
				}
				console.error('ERR', err);
			})
		},
	},
	mounted() {
		EventBus.$on("open-unique", info => {
			this.info = info;
			if(info.isedit) {
				this.initEdit();
				this.$forceUpdate();
			} else {
				this.init();
			}
		});
	},
};
</script>
<style lang="scss">
.uniqueform {
	&__block {
		@extend %panel;
		display: block;
		width: 100%;
		margin-bottom: var(--minigutter);
		padding: var(--gutter);
		&.ctas {
			display: flex;
			flex-direction: column;
			background: none;
			box-shadow: 0;
			padding: 0;
			margin: var(--gutter) 0;
			.ctas {
				&__btn {
					margin-bottom: var(--minigutter);
					&.filled {
						background: $color-blue;
						color: $color-white;
						order: 1;
					}
					&.outline {
						order: 2;
					}
				}
			}
		}
	}
	&__title {
		display: block;
		width: 100%;
		font-variation-settings: 'wght' 700;
		margin-bottom: var(--minigutter);
		&:not(:first-of-type) {
			margin-top: var(--gutter);
		}
	}
	.dates {
		@extend %flexCenterStart;
		flex-wrap: wrap;
		width: 100%;
		.times {
			&__box {
				@extend %flexCenterStart;
				width: 50%;
				&:last-child {
					justify-content: flex-end
				}
				&__label {
					@extend %text-14;
					display: block;
					padding-right: var(--thirdgutter);
				}
			}
		}
	}
	.prices {
		&__content {
			@extend %flexStartBetween;
			flex-wrap: wrap;
			width: 100%;
		}
		&__info {
			display: block;
			width: calc(100% - var(--hourinputsmall) - 20px);
			@extend %text-13;
			color: $color-davy;
			padding-left: var(--gutter);
		}
		small {
			@extend %flexCenterCenter;
			@include size(20px, var(--input));
			@extend %text-18;
			font-variation-settings: 'wght' 300;
		}
		&__type {
			@extend %text-15;
			@extend %flexCenterStart;
			width: 100%;
			font-variation-settings: "wght" 600;
			margin: var(--minigutter) 0;
			cursor: pointer;
			&.disabled {
				opacity: 0.5;
				pointer-events: none;
			}
			&__input {
				display: none;
			}
			&__fake {
				display: block;
				@include size(
					calc(var(--thirdgutter) * 2),
					calc(var(--thirdgutter) * 2)
				);
				border: 2px solid $color-blue;
				border-radius: 100%;
				position: relative;
				margin-right: var(--thirdgutter);
				&:after {
					content: "";
					position: absolute;
					top: 50%;
					left: 50%;
					display: block;
					background: $color-blue;
					@include size(65%, 65%);
					border-radius: 100%;
					transform: translate(-50%, -50%) scale(0);
					transition: 180ms linear;
				}
			}
			&__input:checked ~ .prices__type__fake {
				&:after {
					transform: translate(-50%, -50%) scale(1);
				}
			}
			&__input:not(:checked) ~ .prices__type__fake {
				&:after {
					transform: translate(-50%, -50%) scale(0);
				}
			}
		}
		&__number {
			@extend %flexCenterBetween;
			width: var(--hourinputsmall);
			margin-top: var(--gutter);
			&__label {
				display: block;
				width: calc(100% - var(--hourinputsmall));
				padding-right: var(--thirdgutter);
				&__txt,
				&__info {
					display: block;
					width: 100%;
				}
				&__txt {
					@extend %text-15;
					font-variation-settings: 'wght' 600;
				}
			}
			&__input {
				@include size(var(--hourinputsmall), var(--input));
				border: 1px solid $color-silver;
				padding: var(--thirdgutter);
				text-align: center;
				border-radius: var(--inputradius);
			}
		}
	}
	&__repeatrigger  {
		display: block;
		width: 100%;
		margin: var(--gutter) 0 0;
		&__toggle {
			@extend %flexCenterBetween;
			width: 100%;
			cursor: pointer;
			& * {
				pointer-events: none;
			}
			&__input {
				display: none;
			}
			&__label {
				@extend %text-15;
				display: block;
				font-family: $font-manrope;
				padding-right: var(--thirdgutter);
			}
			&__btn {
				display: block;
				@include size(48px, 22px);
				border-radius: var(--minigutter);
				border: 1px solid $color-davy;
				position: relative;
				&:before {
					content: "";
					position: absolute;
					top: 50%;
					left: 2px;
					display: block;
					background: $color-blue;
					@include size(16px, 16px);
					border-radius: 100%;
					transform: translateY(-50%);
					transition: 180ms linear;
					filter: grayscale(1);
				}
				&.on {
					&:before {
						left: calc(100% - 18px);
						filter: grayscale(0);
					}
				}
			}
		}
	}
	.repeats {
		&.disabled {
			opacity: 0.4;
			filter: grayscale(1);
			pointer-events: none;
		}
	}
	&__repeats {
		display: block;
		width: 100%;
		margin-top: var(--minigutter);
		.rpts {
			&__block {
				display: block;
				width: 100%;
				.norepeats {
					display: block;
					width: 100%;
					padding-left: var(--gutter);
				}
				&__title {
					display: block;
					width: 100%;
					margin-bottom: var(--thirdgutter);
					font-variation-settings: "wght" 600;
					color: $color-davy;
					&:not(:first-of-type) {
						margin-top: calc(var(--minigutter) * 1.5);
					}
				}
				&__content {
					@extend %flexCenterBetween;
					width: 100%;
					padding: 0 0 0 var(--gutter);
					.dateinput {
						margin-bottom: var(--minigutter);
					}
					.weekdays {
						display: block;
						@include size(var(--smallicon), var(--smallicon));
						margin: 0 6px;
						position: relative;
						&__input {
							position: absolute;
							top: 0;
							left: 0;
							opacity: 0;
							z-index: 0;
						}
						&__fake {
							position: absolute;
							top: 50%;
							left: 50%;
							@extend %text-14;
							@extend %flexCenterCenter;
							@include size(100%, 100%);
							color: $color-black;
							border-radius: 100%;
							transform: translate(-50%, -50%);
							transition: 180ms linear;
							z-index: 1;
						}
						&__input:checked ~ .weekdays__fake {
							background: $color-blue;
							color: $color-white;
						}
						&__input:not(:checked) ~ .weekdays__fake {
							background: $color-white;
							color: $color-blue;
						}
					}
				}
			}
			&__type {
				@extend %text-15;
				@extend %flexCenterStart;
				width: 100%;
				font-variation-settings: "wght" 600;
				margin: var(--minigutter) 0;
				cursor: pointer;
				&__input {
					display: none;
				}
				&__fake {
					display: block;
					@include size(
						calc(var(--thirdgutter) * 2),
						calc(var(--thirdgutter) * 2)
					);
					border: 2px solid $color-blue;
					border-radius: 100%;
					position: relative;
					margin-right: var(--thirdgutter);
					&:after {
						content: "";
						position: absolute;
						top: 50%;
						left: 50%;
						display: block;
						background: $color-blue;
						@include size(65%, 65%);
						border-radius: 100%;
						transform: translate(-50%, -50%) scale(0);
						transition: linear;
					}
				}
				&__input:checked ~ .rpts__type__fake {
					&:after {
						transform: translate(-50%, -50%) scale(1);
					}
				}
				&__input:not(:checked) ~ .rpts__type__fake {
					&:after {
						transform: translate(-50%, -50%) scale(0);
					}
				}
				&.disabled {
					opacity: 0.4;
					pointer-events: none;
					filter: grayscale(1);
				}
			}
			&__number {
				@extend %flexCenterBetween;
				width: 100%;
				margin-top: var(--gutter);
				&__label {
					display: block;
					width: calc(100% - var(--hourinputsmall));
					padding-right: var(--thirdgutter);
					&__txt,
					&__info {
						display: block;
						width: 100%;
					}
					&__txt {
						@extend %text-15;
						font-variation-settings: 'wght' 600;
					}
				}
				&__input {
					@include size(var(--hourinputsmall), var(--input));
					border: 1px solid $color-silver;
					padding: var(--thirdgutter);
					text-align: center;
					border-radius: var(--inputradius);
				}
			}
		}
	}
	&__price {
		display: block;
		width: 100%;
		margin-top: var(--gutter);
		.price {
			&__title {
				display: block;
				width: max-content;
			}
			&__content {
				@extend %flexCenterBetween;
				width: 100%;
			}
			&__type {
				@extend %text-15;
				@extend %flexCenterStart;
				width: 100%;
				font-variation-settings: "wght" 600;
				margin: var(--minigutter) 0;
				&__input {
					display: none;
				}
				&__fake {
					display: block;
					@include size(
						calc(var(--thirdgutter) * 2),
						calc(var(--thirdgutter) * 2)
					);
					border: 2px solid $color-blue;
					border-radius: 100%;
					position: relative;
					margin-right: var(--thirdgutter);
					&:after {
						content: "";
						position: absolute;
						top: 50%;
						left: 50%;
						display: block;
						background: $color-blue;
						@include size(65%, 65%);
						border-radius: 100%;
						transform: translate(-50%, -50%) scale(0);
						transition: 180ms linear;
					}
				}
				&__input:checked ~ .prices__type__fake {
					&:after {
						transform: translate(-50%, -50%) scale(1);
					}
				}
				&__input:not(:checked) ~ .prices__type__fake {
					&:after {
						transform: translate(-50%, -50%) scale(0);
					}
				}
			}
			&__number {
				@extend %flexCenterBetween;
				width: var(--hourinputsmall);
				margin-top: var(--gutter);
				&__label {
					display: block;
					width: calc(100% - var(--hourinputsmall));
					padding-right: var(--thirdgutter);
					&__txt,
					&__info {
						display: block;
						width: 100%;
					}
					&__txt {
						@extend %text-15;
						font-variation-settings: 'wght' 600;
					}
				}
				&__input {
					@include size(var(--hourinputsmall), var(--input));
					border: 1px solid $color-silver;
					padding: var(--thirdgutter);
					text-align: center;
					border-radius: var(--inputradius);
				}
			}
		}
	}
	.dateinput {
		&.mx-datepicker {
			width: 100%;
			.mx-input {
				background: $color-white;
				height: var(--input);
				border: 1px solid $color-white;
				border-radius: var(--inputradius);
				padding: 0 var(--minigutter);
				border: 1px solid $color-grey;
			}
			.mx-icon-calendar {
				svg {
					fill: $color-black;
				}
			}
		}
	}
	.timeinput {
		&.mx-datepicker {
			width: var(--hourinputsmall) !important;
			margin-right: 1%;
			&-list {
				list-style: none;
				&:after {
					display: none !important;
				}
			}
			&-item {
				padding: 0;
				&:before {
					display: none;
				}
			}
			.mx-input {
				background: $color-white;
				height: var(--input);
				border: 1px solid $color-white;
				border-radius: var(--inputradius);
				padding: 0 var(--thirdgutter);
				border: 1px solid $color-grey;
			}
			.mx-icon-calendar {
				svg {
					fill: $color-black;
				}
			}
		}
	}
	&__loading {
		position: absolute;
		top: 0;
		left: 0;
		@extend %flexCenterCenter;
		background: rgba($color-white, 0.86);
		@include size(100%,100%);
		pointer-events: all;
		z-index: 100;
		&__spinner {
			display: block;
			margin-bottom: var(--gutter);
			&__box {
				display: block;
				@include size(65px,65px);
				margin: 0 auto var(--gutter);
			}
			&__label {
				@extend %text-15;
				display: block;
				width: 100%;
				font-family: $font-manrope;
				font-variation-settings: 'wght' 600;
				text-align: center;
			}
		}
	}
	&__success {
		@extend %flexCenterCenter;
		flex-direction: column;
		text-align: center;
		&__icon {
			@extend %flexCenterCenter;
			background: $color-success;
			@include size(var(--supergutter),var(--supergutter));
			border-radius: 100%;
			margin-bottom: var(--gutter);
			overflow: hidden;
			svg {
				display: block;
				@include size(var(--smallicon),var(--smallicon));
				fill: $color-white;
			}
		}
		&__label {
			display: block;
			padding: var(--gutter) var(--supergutter);
			&__txt {
				display: block;
				width: 100%;
				@extend %text-33;
				font-variation-settings: 'wght' 700;
				color: $color-success;
				margin-bottom: var(--gutter);
			}
			&__btn {
				width: max-content;
				padding: 0 var(--supergutter);
				margin: 0 auto;
				background: $color-blue;
				color: $color-white;
			}
		}
		&.success {
			.availability__success {
				&__icon {
					background: $color-success;
				}
				&__label {
					&__txt {
						color: $color-success;
					}
				}
			}
		}
	}
	&__conflict {
		position: absolute;
		top: 0;
		left: 0;
		@extend %flexCenterCenter;
		flex-direction: column;
		flex-wrap: wrap;
		background: rgba($color-white, 0.92);
		@include size(100%,100%);
		padding: var(--gutter);
		pointer-events: all;
		z-index: 100;
		&__title {
			@extend %text-20;
			display: block;
			width: 100%;
			font-family: $font-manrope;
			font-variation-settings: 'wght' 700;
			color: $color-error;
			margin-bottom: var(--minigutter);
			text-align: center;
		}
		&__list {
			display: block;
			width: 100%;
			max-height: 400px;
			overflow: auto;
		}
		&__text {
			display: block;
			width: 100%;
			text-align: center;
			margin-bottom: var(--minigutter);
		}
		&__item {
			@extend %flexCenterCenter;
			width: 100%;
			margin-bottom: var(--thirdgutter);
			svg {
				display: block;
				@include size(20px, 20px);
				fill: $color-davy;
				margin: 0 var(--minigutter);
			}
		}
		&__btn {
			display: block;
			width: max-content;
			padding: 0 var(--gutter);
			margin: var(--gutter) auto 0;
		}
	}
	@media (min-width: $from-large) {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		.dates,
		.prices {
			width: 48%;
		}
		&__block {
			&.ctas {
				flex-direction: row;
				justify-content: flex-end;
				box-shadow: unset;
				.ctas {
					&__btn {
						width: max-content;
						padding: 0 var(--supergutter);
						&.outline { order: 1; }
						&.filled {
							order: 2;
							margin-left: var(--minigutter);
						}
					}
				}
			}
		}
	}
}
.mx-calendar-content {
	.cell {
		position: relative;
		&.active {
			background-color: $color-blue !important;
			div {
				@extend %flexCenterCenter;
				top: 0;
				left: 0;
				@include size(100%,100%);
			}
		}
	}
}
.mx-time {
	width: var(--hourinputsmall) !important;
	&-list {
		list-style: none;
		&:after {
			display: none !important;
		}
	}
	&-item {
		padding: 0;
		&:before {
			display: none;
		}
	}
}
</style>
