<template>
	<div class="noticeslist" @click="closeFromExt($event)">
		<div class="noticeslist__box">
			<div class="noticeslist__box__head">
				<span class="noticeslist__box__title">
					Notifiche
				</span>
				<span class="noticeslist__close">
					<span class="noticeslist__close__icon" @click="closeList">
						<svg viewBox="0 0 50 50">
							<use xlink:href="#icon--close" width="100%" height="100%"></use>
						</svg>
					</span>
				</span>
			</div>
			<div class="noticeslist__list">
				<template v-if="notices">
					<div v-if="notices.today && notices.today.length" class="noticeslist__period">
						<small class="noticeslist__period__title">{{ $t('common.today') }}</small>
						<NotificationPreview v-for="t in notices.today" :key="t.id" :notice="t" />
					</div>
					<div v-if="notices.lastWeek && notices.lastWeek.length" class="noticeslist__period">
						<small class="noticeslist__period__title">{{ $t('common.lastweek') }}</small>
						<NotificationPreview v-for="lw in notices.lastWeek" :key="lw.id" :notice="lw" />
					</div>
					<div v-if="notices.lastMonth && notices.lastMonth.length" class="noticeslist__period">
						<small class="noticeslist__period__title">{{ $t('common.lastmonth') }}</small>
						<NotificationPreview v-for="lm in notices.lastMonth" :key="lm.id" :notice="lm" />
					</div>
				</template>
				<template v-else>
					<small class="empty-notifications" v-html="$t('notifications.empty')"></small>
				</template>
			</div>
		</div>
	</div>
</template>
<script>
import dayjs from 'dayjs';
import { EventBus } from '@/events_bus.js';
import { gsap } from 'gsap';
import { CustomEase } from 'gsap/all';
gsap.registerPlugin(CustomEase);

import NotificationPreview from '@/views/components/notifications/NotificationPreview';

export default {
	name: 'NoticesList',
	components: {
		NotificationPreview,
		// Notice,
	},
	data() {
		return {
			notices: null,
			// today: null,
			// lastWeek: null,
			// lastMonth: null,
		}
	},
	methods: {
		closeFromExt(e) {
			if(e.target.classList.contains('noticeslist')){
				this.closeList();	
			}
		},
		closeList() {
			gsap.timeline()
				.to('.noticeslist__box', {
					duration: 0.8,
					height: 0,
					ease: CustomEase.create("custom", "M0,0 C0.726,0 0,1 1,1 ")
				})
				.to('.noticeslist', {
					duration: 0.4,
					opacity: 0,
					pointerEvents: 'none'
				})
		},
		init() {
			this.notices = [];
			this.$nots.init().then(res => {
				this.notices = res;
				gsap.timeline()
					.to('.noticeslist', {
						duration: 0.4,
						opacity: 1,
						pointerEvents: 'all'
					})
					.to('.noticeslist__box', {
						duration: 0.8,
						height: `${window.innerHeight - 60}px`,
						ease: CustomEase.create("custom", "M0,0 C0.726,0 0,1 1,1 "),
						onComplete: () => {
							gsap.set('.noticeslist__box', {
								overflow: 'auto'
							})
						}
					})
			});


		},
	},
	mounted() {
		EventBus.$on('notices-list', () => {
			this.init();
			// this.splitNotifications();
		})

		EventBus.$on('close-notilist', () => {
			this.closeList();
		});
	}
}
</script>
<style lang="scss">
.noticeslist {
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	display: block;
	background: rgba($color-black, 0.1);
	opacity: 0;
	pointer-events: none;
	&__box {
		position: absolute;
		top: var(--gutter);
		right: var(--gutter);
		background: $color-white;
		@include size(28vw, 0);
		border-radius: var(--radius);
		overflow: hidden;
		&__head {
			@extend %flexCenterBetween;
			width: 100%;
		}
		&__title {
			display: block;
			width: calc(100% - var(--gutter));
			padding: var(--minigutter);
			@extend %text-20;
			font-variation-settings: 'wght' 700;
		}
	}
	&__readall {
		display: block;
		width: max-content;
		padding-left: var(--minigutter);
		@extend %text-13;
		font-variation-settings: 'wght' 700;
		cursor: pointer;
	}
	&__close {
		@extend %flexCenterEnd;
		width: max-content;
		padding: var(--minigutter);
		cursor: pointer;
		&__icon {
			@extend %icon;
			cursor: pointer;
			svg {
				@include size(70%,70%);
				fill: $color-black;
			}
		}
	}
	&__list {
		display: block;
		width: 100%;
		padding: 0 var(--thirdgutter);
		margin-top: var(--gutter);
		.notice {
			margin-right: 0;
		}
		.empty-notifications {
			display: block;
			width: 100%;
			padding: var(--gutter) 0;
			text-align: center;
			text-transform: uppercase;
		}
	}
	&__period {
		display: block;
		width: 100%;
		margin-bottom: calc(var(--gutter) * 1.5);
		&__title {
			display: block;
			@extend %text-13;
			font-family: $font-manrope;
			color: $color-davy;
			font-variation-settings: 'wght' 600;
			text-transform: uppercase;
			padding-left: var(--thirdgutter);
			margin-bottom: var(--thirdgutter);
		}
	}
}
</style>