<template>
	<div class="reviewmodal">
		<template v-if="review">
			<div class="reviewmodal__box">
				<div class="reviewmodal__box__head">
					<span class="reviewmodal__box__head__close" @click="closeModal">
						<svg viewBox="0 0 50 50">
							<use xlink:href="#icon--close" width="100%" height="100%"></use>
						</svg>
					</span>
				</div>
				<div class="reviewmodal__box__content">
					<span class="reviewmodal__stars">
						<svg v-for="index in 5" :key="`${review.id}_${index}`" class="star" viewBox="0 0 50 50">
							<use xlink:href="#icon--star" width="100%" height="100%"></use>
						</svg>
					</span>
					<span v-if="review.user && review.user.name && review.user.surname" class="reviewmodal__name">
						{{ review.user.name }} {{ review.user.surname }}
					</span>
					<small class="reviewmodal__date">
						{{ reviewdate }}
					</small>
					<span class="reviewmodal__text" v-html="review.description"></span>
				</div>
			</div>
		</template>
	</div>
</template>
<script>
import { EventBus } from '@/events_bus.js';
import { gsap } from 'gsap';
import { CustomEase } from 'gsap/all';
gsap.registerPlugin(CustomEase);

import dayjs from 'dayjs';

export default {
	name: 'ReviewModal',
	data() {
		return {
			review: null,
			reviewdate: null,
		}
	},
	methods: {
		openModal() {
			gsap.timeline({
				onComplete: () => {
					gsap.set('.reviewmodal', {
						pointerEvents: 'all'
					})
				}
			})
				.to('.reviewmodal', {
					duration: 0.3,
					opacity: 1
				})
				.to('.reviewmodal__box', {
					duration: 0.3,
					y: '0%',
					opacity: 1
				})
		},
		closeModal() {
			gsap.timeline({
				onComplete: () => {
					gsap.set('.reviewmodal', {
						pointerEvents: 'none'
					})
				}
			})
			.to('.reviewmodal__box', {
				duration: 0.3,
				y: 150,
				opacity: 0
			})
			.to('.reviewmodal', {
				duration: 0.3,
				opacity: 0
			})
		},
		handleRating() {
			let stars = this.$el.querySelectorAll('.star');

			stars.forEach((s, i) => {
				if(i < this.review.rating) {
					s.classList.add('active');
				}
			})
		},
		handleDate() {
			this.reviewdate = dayjs(this.review.booked_at).format('DD MMM');
		},
		init() {
			if(this.review) {
				if(this.review.rating) {
					this.handleRating();
				}
				if(this.review.booked_at) {
					this.handleDate();
				}
			}
		}
	},
	mounted() {
		EventBus.$on('open-review', review => {
			this.review = review;
			if(this.review) {
				setTimeout(() => {
					this.init();
					this.openModal();	
				}, 100)
			}
		});
	}
}
</script>
<style lang="scss">
.reviewmodal {
	@extend %flexCenterCenter;
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	background: rgba($color-black, 0.8);
	z-index: 10000;
	overflow: hidden;
	opacity: 0;
	pointer-events: none;
	&__box {
		@extend  %panel;
		display: block;
		background: $color-seasalt;
		width: max-content;
		max-width: 90vw;
		max-height: 90vh;
		min-width: 300px;
		min-height: 300px;
		padding: var(--gutter);
		overflow: hidden;
		&__head {
			@extend %flexCenterEnd;
			width: 100%;
			display: none;
			margin-bottom: var(--gutter);
			&__title {
				@extend %text-33;
				display: block;
				width: 100%;
				font-family: $font-manrope;
				font-variation-settings: 'wght' 700;
			}
			&__close {
				@extend %icon;
				cursor: pointer;
				svg {
					@include size(70%,70%);
					fill: $color-black;
				}
			}
		}
		&__content {
			display: block;
			width: 100%;
			padding-bottom: var(--gutter);
			.reviewmodal {
				&__stars {
					@extend %flexCenterCenter;
					width: 100%;
					text-align: center;
					margin-bottom: var(--minigutter);
					.star {
						@extend %icon;
						margin: 0 var(--thirdgutter);
						fill: rgba($color-silver, 0.5);
						&.active {
							fill: $color-blue;
						}
					}
				}
				&__name, &__date,
				&__text {
					display: block;
					width: 100%;
					text-align: center;
				}
				&__name, &__date {
					margin-bottom: var(--minigutter);
				}
				&__name {
					@extend %text-20;
					font-family: $font-manrope;
					font-variation-settings: 'wght' 700;
					color: $color-black;
				}
				&__date {
					@extend %text-13;
					color: $color-silver;
				}
				&__text {
					@extend %text-13;
					max-height: 360px;
					overflow: auto;
				}
			}
		}
	}
	&__label {
		display: block;
		width: 100%;
		&__text {
			display: block;
			width: 100%;
		}
	}
	.errors {
		display: block;
		width: 100%;
		color: $color-error;
		text-transform: uppercase;
	}
	.actions {
		@extend %flexCenterBetween;
		flex-wrap: wrap;
	}
	&__button {
		width: 100%;
		text-align: center;
		margin-bottom: var(--gutter);
		&.filled {
			background: $color-blue;
			color: $color-white;
			order: 2;
		}
		&.outline {
			border-color: $color-blue;
			color: $color-blue;
			order: 1;
		}
	}
	&__loading {
		position: absolute;
		top: 0;
		left: 0;
		@extend %flexCenterCenter;
		background: rgba($color-white, 0.86);
		@include size(100%,100%);
		pointer-events: all;
		z-index: 100;
		&__spinner {
			display: block;
			margin-bottom: var(--gutter);
			&__box {
				display: block;
				@include size(65px,65px);
				margin: 0 auto var(--gutter);
			}
			&__label {
				@extend %text-15;
				display: block;
				width: 100%;
				font-family: $font-manrope;
				font-variation-settings: 'wght' 600;
				text-align: center;
			}
		}
		&__success {
			display: block;
			@extend %text-33;
			color: $color-green;
			font-variation-settings: 'wght' 700;
			text-align: center;
		}
	}
	.input-box {
		display: block;
		width: 100%;
		position: relative;
		.show-psw {
			position: absolute;
			top: 50%;
			right: var(--thirdgutter);
			display: block;
			@include size(var(--smallicon),var(--smallicon));
			transform: translateY(-50%);
			cursor: pointer;
			&__show,
			&__hide {
				position: absolute;
				top: 0;
				left: 0;
				display: flex;
				@extend %flexCenterCenter;
				@include size(100%,100%);
				pointer-events: none;
				svg {
					display: block;
					@include size(100%,100%);
					fill: $color-black;
				}
			}
			&__hide {
				display: none;
			}
			&.show {
				.show-psw__show {
					display: none;
				}
				.show-psw__hide {
					display: block;
				}
			}
		}
	}
	.changepswform {
		display: block;
		width: 100%;
		margin: var(--supergutter) 0;
	}
	@media (min-width: $from-large) {
		display: flex;
		background: rgba($color-black, 0.1);
		transform: translateX(0%);
		opacity: 0;
		&__head {
			display: none;
		}
		&__box {
			background: $color-white;
			max-height: 90vh;
			max-width: 780px;
			padding: var(--gutter);
			transform: translateY(150px);
			opacity: 0;
			&__head {
				display: flex;
				margin-bottom: var(--thirdgutter);
			}
			&__text {
				font-size: 0.938rem;
			}
		}
		&__button {
			margin-bottom: 0;
			&.filled {
				order: 2;
			}
			&.outline {
				order: 1;
			}
		}
	}
}
</style>