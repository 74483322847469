<template>
	<div class="bookingfilters">
		<div class="bookingfilters__head">
			<div class="bookingfilters__head__title">
				{{ $t('bookings.btns.filter') }}
			</div>
			<span class="bookingfilters__head__close" @click="closeModal">
				<svg viewBox="0 0 50 50">
					<use xlink:href="#icon--close" width="100%" height="100%"></use>
				</svg>
			</span>
		</div>
		<div class="bookingfilters__box">
			<div class="bookingfilters__box__content">
				<div class="bookingfilters__block">
					<span class="bookingfilters__block__title" v-html="$t('bookings.filters.date')"></span>
					<span class="bookingfilters__block__subtitle" v-html="$t('bookings.filters.from')"></span>
					<div class="bookginfilters__block__date">
						<date-picker v-model="filters.from_date" valueType="timestamp" format="DD/MM/YYYY">
							<template v-slot:icon-calendar>
								<svg viewBox="0 0 50 50" width="300" height="300">
									<use xlink:href="#icon--form-calendar" width="100%" height="100%"></use>
								</svg>
							</template>
						</date-picker>
					</div>
					<span class="bookingfilters__block__subtitle" v-html="$t('bookings.filters.to')"></span>
					<div class="bookginfilters__block__date">
						<date-picker v-model="filters.to_date" valueType="timestamp" format="DD/MM/YYYY">
							<template v-slot:icon-calendar>
								<svg viewBox="0 0 50 50" width="300" height="300">
									<use xlink:href="#icon--form-calendar" width="100%" height="100%"></use>
								</svg>
							</template>
						</date-picker>
					</div>
				</div>
				<div v-if="garages && garages.length" class="bookingfilters__block">
					<span class="bookingfilters__block__title" v-html="$t('bookings.filters.garage')"></span>
					<div class="bookingfilters__multi">
						<div class="form__field">
							<label v-for="g in garages" :key="g.id" :for="g.id" class="form__label">
								<input type="checkbox" :value="g.id" :name="g.id" :id="g.id" v-model="filters.garages" />
								<span class="form__checkbox" :class="{ 'checked': filters.garages }">
									<svg viewBox="0 0 50 50">
										<use xlink:href="#icon--check" width="100%" height="100%"></use>
									</svg>
								</span>
								<span class="form__label__text" v-html="g.name"></span>
							</label>
						</div>
					</div>
				</div>
				<div v-if="statuses && statuses.length" class="bookingfilters__block">
					<span class="bookingfilters__block__title" v-html="$t('bookings.filters.status')"></span>
					<span class="bookingfilters__block__content">
						<label v-for="st in statuses" :key="st.trim().toLowerCase()" :for="st.trim().toLowerCase()" class="form__label">
							<input type="checkbox" :value="st.trim().toLowerCase()" :name="st.trim().toLowerCase()" :id="st.trim().toLowerCase()" v-model="filters.status" />
							<span class="form__checkbox" :class="{ 'checked': filters.status }">
								<svg viewBox="0 0 50 50">
									<use xlink:href="#icon--check" width="100%" height="100%"></use>
								</svg>
							</span>
							<span class="form__label__text" v-html="$t(`bookings.status.${st.trim().toLowerCase()}`)"></span>
						</label>
					</span>
				</div>
			</div>
			<div class="bookingfilters__box__actions">
				<span class="bookingfilters__box__btn btn filled" @click="filterResults">
					{{ $t('bookings.btns.confirmfilters') }}
				</span>
				<span class="bookingfilters__box__btn btn reset" @click="clearFilters">
					{{ $t('bookings.btns.removefilters') }}
				</span>
			</div>
		</div>
	</div>
</template>
<script>
import { EventBus } from '@/events_bus.js';
import { gsap } from 'gsap';
import { CustomEase } from 'gsap/all';
gsap.registerPlugin(CustomEase);

import DatePicker from 'vue2-datepicker';

export default {
	name: 'Filters',
	props: {
		mode: {
			type: String,
			required: true,
			default: 'mobile'
		},
		garages: {
			type: Array,
			required: true,
		}
	},
	components: {
		DatePicker
	},
	data() {
		return {
			booking: null,
			filters: {
				from_date: '',
				to_date: '',
				garages: [],
				status: [],
			},
			statuses: null,
		}
	},
	methods: {
		closeWindow() {
			this.filters = {
				from_date: '',
				to_dat: '',
				garages: []
			}
			if(this.mode == 'mobile') {
				this.closeDrawer()
			} else {
				this.closeModal();
			}
		},
		clearFilters() {
			this.filters = {
				from_date: '',
				to_date: '',
				garages: [],
				status: []
			};
			this.filterResults();
		},
		filterResults() {
			EventBus.$emit('filter-results', this.filters);
			if(this.mode == 'mobile') {
				this.closeDrawer()
			} else {
				this.closeModal();
			}
		},
		openModal() {
			let ww = window.innerWidth;
			let filters = document.querySelector('.bookingfilters');
			let box = document.querySelector('.bookings__box__list');
			let boxPos = box.getBoundingClientRect();

			filters.style = `width: ${boxPos.width}px;`;

			gsap.timeline({
				onComplete: () => {
					gsap.set('.bookingfilters', {
						pointerEvents: 'all'
					})
				}
			})
				.to('.bookingfilters', {
					duration: 0.5,
					height: 'auto',
					maxHeight: '100000px',
				})
		},
		closeModal() {
			gsap.timeline({
				onComplete: () => {
					gsap.set('.bookingfilters', {
						pointerEvents: 'none'
					})
				}
			})
				.to('.bookingfilters', {
					duration: 0.3,
					height: 0,
					maxHeight: 0,
				})
		},
		openDrawer() {
			gsap.to('.bookingfilters', {
				duration: 0.9,
				x: '0%',
				ease: CustomEase.create("custom", "M0,0 C0.726,0 0,1 1,1 "),
				onStart: () => {
					document.body.style = 'overflow: hidden';
				},
				onComplete: () => {
					gsap.set('.bookingfilters', {
						pointerEvents: 'all'
					})
				}
			})
		},
		closeDrawer() {
			gsap.to('.bookingfilters', {
				duration: 0.9,
				x: '100%',
				ease: CustomEase.create("custom", "M0,0 C0.726,0 0,1 1,1 "),
				onComplete: () => {
					gsap.set('body', { clearProps: 'all' })
				}
			})
		},
		init() {
			this.$service.bookings.statuses().then(res => {
				console.log('statuses', res);
				if(res && res.data) {
					this.statuses = res.data;
				}
			})
		},
	},
	mounted() {
		EventBus.$on('open-filters', () => {
			this.init();
			if(this.mode == 'mobile') {
				this.openDrawer()
			} else {
				this.openModal();
			}
		});

		this.init();
	}
}
</script>
<style lang="scss">
@import '~vue2-datepicker/scss/index.scss';
.bookingfilters {
	@extend %flexCenterCenter;
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	background: $color-seasalt;
	display: block;
	overflow: auto;
	transform: translateX(100%);
	pointer-events: none;
	z-index: 10000;
	&__head {
		@extend %flexCenterBetween;
		width: 100%;
		padding: var(--gutter);
		&__title {
			@extend %text-25;
			display: block;
			width: 100%;
			font-family: $font-manrope;
			font-variation-settings: 'wght' 700;
		}
		&__close {
			@extend %icon;
			svg {
				@include size(70%,70%);
				fill: $color-black;
			}
		}
	}
	&__box {
		@extend  %panel;
		background: transparent;
		height: auto;
		overflow: auto;
		border-radius: 0;
		padding: var(--gutter);
		&__head {
			@extend %flexCenterBetween;
			width: 100%;
			display: none;
			margin-bottom: var(--gutter);
			&__title {
				@extend %text-33;
				display: block;
				width: 100%;
				font-family: $font-manrope;
				font-variation-settings: 'wght' 700;
			}
			&__close {
				@extend %icon;
				svg {
					@include size(70%,70%);
					fill: $color-black;
				}
			}
		}
		&__content {
			display: block;
			width: 100%;
		}
		&__actions {
			@extend %flexCenterBetween;
			flex-wrap: wrap;
			width: 100%;
		}
		&__btn {
			width: 100%;
			&.filled {
				background: $color-blue;
				color: $color-white;
				margin-bottom: var(--thirdgutter);
			}
			&.reset {
				color: $color-black;
			}
		}
		@media (max-width: $to-large) {
			display: flex;
			box-shadow: none;
			flex-direction: column;
			justify-content: space-between;
			height: calc(100svh - (var(--supergutter) * 2));
			padding-bottom: var(--gutter);
			&__btn {
				&.outline {
					display: none;
				}
			}
		}
	}
	&__multi {
		display: block;
		width: 100%;
		max-height: 200px;
		overflow: auto;
		overflow-x: hidden;
		.form__label {
			width: 100%;
		}
	}
	&__block {
		display: block;
		width: 100%;
		margin-bottom: var(--gutter);
		font-family: $font-manrope;
		&__title {
			@extend %text-20;
			display: block;
			width: 100%;
			margin-bottom: var(--minigutter);
			font-variation-settings: 'wght' 700;
		}
		&__subtitle {
			@extend %text-15;
			display: block;
			font-variation-settings: 'wght' 600;
			margin-bottom: var(--thirdgutter);
		}
		&__subtitle {
			&:last-of-type {
				margin-top: var(--minigutter);
			}
		}
		&__content {
			@extend %flexStartStart;
			flex-wrap: wrap;
			width: 100%;
			.form__label {
				margin-right: var(--minigutter);
			}
		}
	}
	&__text {
		display: block;
		width: 100%;
		@extend %text-18;
	}
	&__form {
		display: block;
		width: 100%;
		margin: var(--gutter) 0;
	}
	@media (min-width: $from-large) {
		position: absolute;
		bottom: unset;
		left: unset;
		background: $color-white;
		height: 0;
		max-height: 0;
		overflow: hidden;
		z-index: 1000;
		transform: translateX(0);
		border-radius: var(--radius);
		box-shadow: 0 0 20px rgba($color-silver, 0.4);
		&__box {
			padding-top: 0;
			&__content {
				display: flex;
				justify-content: space-between;
				flex-wrap: wrap;
			}
			&__actions {
				justify-content: flex-start;
				flex-direction: row-reverse;
			}
			&__btn {
				width: max-content;
				min-width: 150px;
				&.filled {
					margin-bottom: 0;
				}
			}
		}
		&__block {
			width: 45%;
		}
	}
	.mx-datepicker {
		width: 100%;
		.mx-input {
			background: $color-white;
			height: var(--input);
			border: 1px solid $color-white;
			border-radius: var(--inputradius);
			padding: 0 var(--minigutter);
			border: 1px solid $color-grey;
		}
		.mx-icon-calendar {
			svg {
				fill: $color-black;
			}
		}
	}
}
</style>