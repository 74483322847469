<template>
	<div class="loadingmodal">
		<div class="loadingmodal__box">
			<div v-if="isLoading" class="loadingmodal__loading">
				<template v-if="!editSaved">
					<span class="loadingmodal__loading__spinner">
						<span class="loadingmodal__loading__spinner__box">
							<Spinner />
						</span>
						<span class="loadingmodal__loading__spinner__label">
							{{ $t('common.is_saving') }}
						</span>
					</span>
				</template>
				<template v-else>
					<span class="loadingmodal__loading__end" :class="saveType.res">
						<span class="loadingmodal__loading__end__icon">
							<svg viewBox="0 0 50 50">
								<use :xlink:href="`#icon--${saveType.res}`" width="100%" height="100%"></use>
							</svg>
						</span>
						<span class="loadingmodal__loading__end__label" v-html="message"></span>
					</span>
				</template>
			</div>
		</div>
	</div>
</template>
<script>
import { EventBus } from '@/events_bus.js';
import { gsap } from 'gsap';
import { CustomEase } from 'gsap/all';
gsap.registerPlugin(CustomEase);

import Spinner from '@/views/components/common/Spinner';

export default {
	name: 'LoadingModal',
	components: {
		Spinner,
	},
	data() {
		return {
			isLoading: false,
			editSaved: false,
			saveType: null,
			message: null
		}
	},
	methods: {
		openModal() {
			gsap.timeline({
				onComplete: () => {
					gsap.set('.loadingmodal', {
						pointerEvents: 'all'
					})
				}
			})
				.to('.loadingmodal', {
					duration: 0.3,
					opacity: 1
				})
				.to('.loadingmodal__box', {
					duration: 0.3,
					y: '0%',
					opacity: 1
				})
		},
		closeModal() {
			EventBus.$emit('loadingmodal-modal');
			gsap.timeline({
				onComplete: () => {
					gsap.set('.loadingmodal', {
						pointerEvents: 'none'
					})
				}
			})
			.to('.loadingmodal__box', {
				duration: 0.3,
				y: 150,
				opacity: 0
			})
			.to('.loadingmodal', {
				duration: 0.3,
				opacity: 0
			})
		},
		init() {

		}
	},
	mounted() {
		EventBus.$on('startsave', obj => {
			this.isLoading = true;
			this.openModal();
		});
		EventBus.$on('endsave', obj => {
			this.saveType = obj;
			this.editSaved = true;

			if(obj.res == 'check') {
				this.message = this.$t(`common.messages.${obj.res}`)
			} else {
				this.message = this.$t(`common.messages.error`);
			}
			
			setTimeout(() => {
				this.isLoading = false;
				if(obj && obj.type) {
					switch(obj.type) {
						case 'garagecreate':
							this.$router.push({ name: 'garages.page' });
							this.closeModal();
							// this.$router.push({ name: 'garagedetail.page', params: { id: obj.data.id }});
							break;
						case 'garageedit':
							this.closeModal();
							break;
						case 'garageediterror':
							break;

					}
				}
			}, 1500)
		});
		EventBus.$on('cancel-save', () => {
			this.editSaved = false;
			this.isLoading = false;
			this.closeModal();
		})
	}
}
</script>
<style lang="scss">
.loadingmodal {
	@extend %flexCenterCenter;
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	background: rgba($color-black, 0.8);
	z-index: 10000;
	overflow: hidden;
	opacity: 0;
	pointer-events: none;
	&__box {
		@extend  %panel;
		@extend %flexCenterCenter;
		background: $color-seasalt;
		width: max-content;
		max-width: 90vw;
		max-height: 90vh;
		min-width: 300px;
		min-height: 300px;
		padding: var(--gutter);
		overflow: hidden;
		&__head {
			@extend %flexCenterBetween;
			width: 100%;
			display: none;
			margin-bottom: var(--gutter);
			&__title {
				@extend %text-33;
				display: block;
				width: 100%;
				font-family: $font-manrope;
				font-variation-settings: 'wght' 700;
			}
			&__back {
				@extend %flexCenterStart;
				width: calc(100% - var(--btn));
				cursor: pointer;
				&__icon {
					@extend %flexCenterCenter;
					@include size(var(--smallicon),var(--smallicon));
					svg {
						display: block;
						@include size(75%,75%);
						fill: $color-black;
					}
				}
				&__label {
					@extend %text-15;
					display: block;
					width: max-content;
					font-variation-settings: 'wght' 600;
					padding-left: var(--minigutter);
				}
			}
		}
		&__content {
			display: block;
			width: 100%;
			overflow: auto;
			overflow-x: hidden;
		}
	}
	&__label {
		display: block;
		width: 100%;
		&__text {
			display: block;
			width: 100%;
		}
	}
	&__loading {
		@extend %flexCenterCenter;
		background: rgba($color-white, 0.86);
		@include size(100%,100%);
		pointer-events: all;
		z-index: 100;
		&__spinner {
			display: block;
			margin-bottom: var(--gutter);
			&__box {
				display: block;
				@include size(65px,65px);
				margin: 0 auto var(--gutter);
			}
			&__label {
				@extend %text-15;
				display: block;
				width: 100%;
				font-family: $font-manrope;
				font-variation-settings: 'wght' 600;
				text-align: center;
			}
		}
		&__end {
			display: block;
			text-align: center;
			&__icon {
				@extend %flexCenterCenter;
				@include size(var(--supergutter),var(--supergutter));
				border-radius: 100%;
				margin: 0 auto;
				svg {
					display: block;
					@include size(var(--smallicon), var(--smallicon));
					fill: $color-white;
				}
			}
			&__label {
				@extend %text-25;
				display: block;
				font-variation-settings: 'wght' 700;
				text-align: center;
			}
			&.check {
				.loadingmodal__loading__end {
					&__icon, &__label {
						margin: 0 auto;
						text-align: center;
					}
					&__icon {
						background: $color-green;
					}
					&__label {
						color: $color-green;
						margin-top: var(--supergutter);
					}
				}
			}
			&.close {
				.loadingmodal__loading__end {
					&__icon {
						background: $color-red;
					}
					&__label {
						color: $color-red;
					}
				}
			}
			@media (min-width: $from-medium) {
				&__label {
					max-width: 350px;
					margin-top: var(--gutter);
				}
			}
		}
	}
}
</style>
