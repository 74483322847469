<template>
	<main id="verifyemail" class="verifyemail page">
		<div class="content">
			<span class="verifyemail__logo">
				<svg viewBox="0 0 189.3 62">
					<use xlink:href="#logo--main" width="100%" height="100%"></use>
				</svg>
			</span>
			<div class="verifyemail__content">
				<template v-if="isLoading">
					<span class="verifyemail__spinner">
						<Spinner />
					</span>
					<span class="verifyemail__text" v-html="$t('verify.email.text')"></span>
				</template>
				<template v-else>
					<span class="verifyemail__confirm">
						<template v-if="!errors">
							<span class="verifyemail__confirm__title" v-html="$t('verify.email.confirm.title')"></span>
							<span class="verifyemail__confirm__text" v-html="$t('verify.email.confirm.text')"></span>
						</template>
						<template v-else>
							<span class="verifyemail__confirm__title" v-html="$t('verify.email.error.title')"></span>
							<span class="verifyemail__confirm__text" v-html="$t('verify.email.error.text')"></span>
						</template>
					</span>
				</template>
			</div>
		</div>
	</main>
</template>
<script>
import { EventBus } from '@/events_bus.js';

import Spinner from '@/views/components/common/Spinner';

export default {
	name: 'VerifyEmail',
	components: {
		Spinner
	},
	data() {
		return {
			isLoading: true,
			errors: false,
		}
	},
	methods: {
		onResize() {
			this.wWidth = window.innerWidth;
			if(this.wWidth < 1200) {
				this.editMode = 'mobile'
			} else {
				this.editMode = 'desktop'
			}
		},
		init() {
			let params = this.$route.params
			let query = this.$route.query;
			let qstring = `?expires=${query.expires}&signature=${query.signature}`;
			this.$service.user.email.verify(params.id, params.hash, qstring).then(res => {
				this.errors = null;
				this.isLoading = false;
			}).catch(err => {
				console.error('ERR', err);
				this.isLoading = false;
				this.errors = true;
			})
		}
	},
	mounted() {
		this.onResize();
		this.$nextTick(() => {
			window.addEventListener('resize', () => {
				this.onResize();
			});
		});
		this.init();
	}
}
</script>
<style lang="scss">
.verifyemail {
	background: $color-seasalt;
	height: 100svh;
	position: relative;
	&:before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		display: block;
		background: url('~@/assets/images/texture-green.png') repeat-x;
		@include size(100%,100%);
		z-index: 1;
		pointer-events: none;
	}
	.content {
		@extend %flexCenterCenter;
		flex-direction: column;
		height: 100%;
	}
	&__logo {
		display: block;
		@include size(190px,62px);
		svg {
			display: block;
			@include size(100%,100%);
			fill: $color-green;
		}
	}
	&__content {
		display: block;
		width: 100%;
		margin: var(--supergutter) 0;
	}
	&__spinner {
		display: block;
		@include size(65px,65px);
		position: relative;
		margin: var(--gutter) auto;
	}
	&__text {
		display: block;
		width: 100%;
		text-align: center;
	}
	&__confirm {
		display: block;
		width: 100%;
		text-align: center;
		&__title,
		&__text {
			display: block;
			width: 100%;
			text-align: center;
		}
		&__title {
			@extend %text-33;
		}
		&__text {
			@extend %text-20;
		}
	}
}
</style>