<template>
	<div v-if="notice" :class="`notipreview ${notice.type}`">
		<div class="notipreview__head">
			<span class="notipreview__head__icon">
				<svg viewBox="0 0 50 50">
					<use :xlink:href="`#icon--${notice.type}`" width="100%" height="100%"></use>
				</svg>
			</span>
			<small class="notipreview__head__title" v-html="notice.title"></small>
			<span class="notipreview__head__check">
				<input type="checkbox" :name="notice.id" @change="readNotice" />
				<span class="notipreview__head__check__circle"></span>
			</span>
		</div>
		<span class="notipreview__content">
			<small class="notipreview__text" v-html="notice.body"></small>
			<span v-if="notice.destination" class="notipreview__linkbox">
				<span class="notipreview__btn" @click="handleBtn">
					<small v-html="notice.action"></small>
					<svg viewBox="0 0 50 50">
						<use xlink:href="#icon--back" width="100%" height="100%"></use>
					</svg>
				</span>
			</span>
		</span>
	</div>
</template>
<script>
import { EventBus } from '@/events_bus.js';

export default {
	name: 'Notice',
	props: {
		notice: {
			type: Object,
			required: true,
			default: {}
		}
	},
	data() {
		return {
			showContent: false,
			read: false,
		}
	},
	methods: {
		handleBtn() {
			if(this.notice && this.notice.destination) {
				let where = this.notice.destination.replace('garaging://','');
				where = where.split('/');

				switch(where[0]) {
					case 'profile':
						this.$router.push({ name: 'account.page' });
						break;
					case 'bookings':
						if(where[1]) {
							this.$router.push({ name: 'bookingdetail.page', params: { id: where[1] } })
						} else {
							this.$router.push({ name: 'bookings.page' })
						}
						break;
					case 'reviews':
						if(where[1]) {
							this.$router.push({ name: 'reviews.page', hash: `#${where[1]}` });
						}
						break;
				}

				EventBus.$emit('close-notilist');

				this.readNotice();
			}

		},
		readNotice() {
			if(!this.notice.read_at) {
				this.$nots.isRead(this.notice.id).then(res => {
					this.$el.classList.remove('new');
				});
			}
		}
	},
	mounted() {
		if(!this.notice.read_at) {
			this.$el.classList.add('new');
		} else {
			this.$el.classList.remove('new');
		}
	}
}
</script>
<style lang="scss">
.notipreview {
	display: block;
	width: 100%;
	padding: var(--minigutter);
	margin-bottom: 4px;
	border-radius: var(--radius);
	position: relative;
	&:after {
		content: '';
		position: absolute;
		top: 0;
		left: var(--radius);
		display: block;
		background: rgba($color-davy, 0.2);
		@include size(calc(100% - (var(--radius) * 2)), 1px);
	}
	&:first-of-type {
		&:after {
			display: none;
		}
	}
	&__head {
		@extend %flexCenterStart;
		width: 100%;
		position: relative;
		&__icon {
			display: block;
			@include size(20px,20px);
			svg {
				display: block;
				@include size(100%,100%);
				fill: rgba($color-green, 0.5);
			}
		}
		&__title {
			display: block;
			width: calc(100% - 20px);
			@extend %text-15;
			color: $color-davy;
			font-variation-settings: 'wght' 500;
			padding: 0 var(--gutter) 0 var(--minigutter);
		}
		&__check {
			position: absolute;
			top: 0;
			right: 0;
			display: none;
			@include size(20px,20px);
			cursor: pointer;
			input {
				position: absolute;
				top: 0;
				left: 0;
				display: block;
				@include size(100%,100%);
				opacity: 0;
				cursor: pointer;
			}
			svg, &__circle {
				pointer-events: none;
			}
			svg {
				display: block;
				@include size(80%,80%);
				fill: $color-green;
			}
			&__circle {
				display: block;
				@include size(100%,100%);
				border: 1px solid $color-davy;
				border-radius: 100%;
			}
		}
	}
	&__content {
		display: block;
		width: 100%;
		padding-left: calc(var(--minigutter) + 20px);
	}
	&__linkbox {
		@extend %flexCenterEnd;
		width: 100%;
		margin-top: var(--thirdgutter);
	}
	&__btn {
		@extend %flexCenterEnd;
		width: max-content;
		text-align: right;
		cursor: pointer;
		svg {
			display: block;
			@include size(var(--thirdgutter),var(--thirdgutter));
			fill: $color-black;
			transform: scaleX(-1);
		}
	}
	&.new {
		background: rgba($color-blue, 0.2);
		&:after {
			display: none;
		}
		.notipreview {
			&__head {
				&__icon {
					svg {
						fill: $color-caribbean;
					}
				}
				&__check {
					display: block;
				}
				&__title {
					color: $color-caribbean;
					font-variation-settings: 'wght' 800;
				}
			}
			&__text {
				color: $color-davy;
				font-variation-settings: 'wght' 500;
			}
		}
	}
}
</style>