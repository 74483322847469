<template>
	<div class="publicmodal">
		<div class="publicmodal__box">
			<span class="publicmodal__box__close" @click="closeModal">
				<svg viewBox="0 0 50 50">
					<use xlink:href="#icon--close" width="100%" height="100%"></use>
				</svg>
			</span>
			<div class="publicmodal__box__head">
				<div class="publicmodal__box__head__title" v-html="$t('garages.public.title')"></div>
				<div class="publicmodal__box__head__text" v-html="$t('garages.public.text')"></div>
			</div>
			<div v-if="me" class="publicmodal__box__content">
<!--				<span class="publicmodal__row">-->
<!--					<span class="publicmodal__icon">-->
<!--						<template v-if="me.stripe_verified_at">-->
<!--							<svg viewBox="0 0 50 50">-->
<!--								<use xlink:href="#icon&#45;&#45;check" fill="#71b800" width="100%" height="100%"></use>-->
<!--							</svg>-->
<!--						</template>-->
<!--						<template v-else>-->
<!--							<svg viewBox="0 0 50 50">-->
<!--								<use xlink:href="#icon&#45;&#45;close" fill="#da291c" width="100%" height="100%"></use>-->
<!--							</svg>-->
<!--						</template>-->
<!--					</span>-->
<!--					<span class="publicmodal__label" v-html="$t('garages.public.stripe')"></span>-->
<!--				</span>-->
				<span class="publicmodal__row">
					<span class="publicmodal__icon">
						<template v-if="me.document_verified_at">
							<svg viewBox="0 0 50 50">
								<use xlink:href="#icon--check" fill="#71b800" width="100%" height="100%"></use>
							</svg>
						</template>
						<template v-else>
							<svg viewBox="0 0 50 50">
								<use xlink:href="#icon--close" fill="#da291c" width="100%" height="100%"></use>
							</svg>
						</template>
					</span>
					<span class="publicmodal__label" v-html="$t('garages.public.docs')"></span>
				</span>
				<span class="publicmodal__row">
					<span class="publicmodal__icon">
						<template v-if="me.email_verified_at">
							<svg viewBox="0 0 50 50">
								<use xlink:href="#icon--check" fill="#71b800" width="100%" height="100%"></use>
							</svg>
						</template>
						<template v-else>
							<svg viewBox="0 0 50 50">
								<use xlink:href="#icon--close" fill="#da291c" width="100%" height="100%"></use>
							</svg>
						</template>
					</span>
					<span class="publicmodal__label" v-html="$t('garages.public.email')"></span>
				</span>
				<span class="publicmodal__row">
					<span class="publicmodal__icon">
						<template v-if="me.phone_verified_at">
							<svg viewBox="0 0 50 50">
								<use xlink:href="#icon--check" fill="#71b800" width="100%" height="100%"></use>
							</svg>
						</template>
						<template v-else>
							<svg viewBox="0 0 50 50">
								<use xlink:href="#icon--close" fill="#da291c" width="100%" height="100%"></use>
							</svg>
						</template>
					</span>
					<span class="publicmodal__label" v-html="$t('garages.public.phone')"></span>
				</span>
			</div>
		</div>
	</div>
</template>
<script>
import { EventBus } from "@/events_bus.js";
import { gsap } from "gsap";
import { CustomEase } from "gsap/all";
gsap.registerPlugin(CustomEase);


export default {
	name: "PublicModal",
	data() {
		return {
			wWidth: 0,
			isLoading: false,
			isDeleted: false,
			gid: null,
			me: null,
		};
	},
	methods: {
		onResize() {
			this.wWidth = window.innerWidth;
			if(this.wWidth < 1200) {
				this.editMode = 'mobile'
			} else {
				this.editMode = 'desktop'
			}
		},
		openModal() {
			gsap.timeline({
				onComplete: () => {
					gsap.set(".publicmodal", {
						pointerEvents: "all",
					});
				},
			})
				.to(".publicmodal", {
					duration: 0.3,
					opacity: 1,
				})
				.to(".publicmodal__box", {
					duration: 0.3,
					y: "0%",
					opacity: 1,
				});
		},
		closeModal() {
			gsap.timeline({
				onComplete: () => {
					gsap.set(".publicmodal", {
						pointerEvents: "none",
					});
					EventBus.$emit('close-public-modal');
				},
			})
				.to(".publicmodal__box", {
					duration: 0.3,
					y: 150,
					opacity: 0,
				})
				.to(".publicmodal", {
					duration: 0.3,
					opacity: 0,
				});
		},
		init() {
			this.openModal();
			this.$service.user.me.get().then(res => {
				this.me = res;
			})
		}
	},
	mounted() {
		this.onResize();
		this.$nextTick(() => {
			window.addEventListener("resize", () => {
				this.onResize();
			});
		});

		this.init();
	},
};
</script>
<style lang="scss">
@import "~vue2-datepicker/scss/index.scss";

.publicmodal {
	--select: 100px;
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	display: block;
	background: $color-seasalt;
	padding: var(--gutter) 0;
	display: block;
	overflow: auto;
	z-index: 10000;
	transform: translateX(100%);
	pointer-events: none;
	overflow-x: hidden;
	overflow-y: auto;
	&__box {
		@extend %panel;
		background: transparent;
		padding: 0 var(--supergutter);
		max-width: 300px;
		height: auto;
		overflow: auto;
		padding-bottom: var(--supergutter);
		position: relative;
		&__close {
			position: absolute;
			top: var(--gutter);
			right: var(--gutter);
			@extend %flexCenterCenter;
			@include size(var(--minigutter),var(--minigutter));
			cursor: pointer;
			svg {
				display: block;
				@include size(100%,100%);
				fill: $color-davy;
				pointer-events: none;
			}
		}
		&__head {
			display: block;
			width: 100%;
			margin-bottom: var(--gutter);
			position: relative;
			&__title,
			&__text {
				display: block;
				width: 100%;
			}
			&__title {
				@extend %text-33;
				font-family: $font-manrope;
				font-variation-settings: "wght" 700;
				margin-bottom: var(--minigutter)
			}
		}
		&__content {
			@extend %text-19;
			display: block;
			width: 100%;
			margin-top: var(--gutter);
			text-align: center;
		}
	}
	&__row {
		@extend %flexCenterStart;
		width: 100%;
		padding: 0 var(--minigutter);
		margin: 0 0 var(--minigutter);
	}
	&__icon {
		@extend %flexCenterCenter;
		@include size(var(--smallicon),var(--smallicon));
		svg {
			display: block;
			@include size(50%,50%);
		}
	}
	&__label {
		display: block;
		width: calc(100% - var(--smallicon));
		padding-left: var(--thirdgutter);
		font-variation-settings: 'wght' 700;
		text-align: left;
	}
	@media (min-width: $from-medium) {
		display: flex;
		align-items: center;
		justify-content: center;
		background: rgba($color-black, 0.1);
		transform: translateX(0%);
		opacity: 0;
		&__box {
			background: $color-white;
			max-height: 90vh;
			min-width: 600px;
			height: auto;
			padding: var(--gutter) var(--supergutter);
			transform: translateY(150px);
			opacity: 0;
			position: relative;
			&__head {
				margin-bottom: var(--thirdgutter);
			}
			&__text {
				font-size: 0.938rem;
			}
		}
	}
}
</style>
