<template>
	<router-link v-if="garage" :to="{ name: 'garagedetail.page', params: { id: garage.id }}" class="garagepreview" :class="type">
		<figure class="garagepreview__media">
			<template v-if="pic">
				<img class="garagepreview__media__image" :src="pic" alt="" />
			</template>
			<template v-else>
				<img class="garagepreview__media__image" src="@/assets/images/placeholder.png" alt="" />
			</template>
		</figure>
		<span class="garagepreview__data">
			<span v-if="garage.name" class="garagepreview__data__datetime" v-html="garage.name"></span>
			<small v-if="garage.address" class="garagepreview__data__name" v-html="garage.address"></small>
		</span>
		<span class="garagepreview__info">
			<span class="garagestatus" :class="{ 'public': garage.published }">
				<template v-if="garage.published">
					{{ $t('garages.published') }}
				</template>
				<template v-else>
					{{ $t('garages.unpublished') }}
				</template>
			</span>
			<!-- <span class="bookingpreview__info__icon">
				<span class="emptygarage">
					<svg viewBox="0 0 50 50">
						<use xlink:href="#icon--garage-empty" width="100%" height="100%"></use>
					</svg>
				</span>
				<span class="fullgarage">
					<svg viewBox="0 0 50 50">
						<use xlink:href="#icon--garage-full" width="100%" height="100%"></use>
					</svg>
				</span>
			</span> -->
		</span>
	</router-link>
</template>
<script>
import dayjs from 'dayjs';

export default {
	name: 'GaragePreview',
	props: {
		garage: {
			type: Object,
			required: true,
			default: {}
		},
		type: {
			type: String,
			required: true,
			default: 'list'
		}
	},
	data() {
		return {
			pic: null,
		}
	},
	mounted() {
		if(this.garage && this.garage.gallery && this.garage.gallery[0] && this.garage.gallery[0].filename != '') {
			this.pic = this.garage.gallery[0].filename;
		}
	}
}
</script>
<style lang="scss">
.garagepreview {
	@extend %flexCenterBetween;
	width: 100%;
	font-family: $font-manrope;
	padding: var(--thirdgutter);
	transition: 250ms linear;
	&:hover {
		background: $color-seasalt;
	}
	&__symbol {
		@extend %icon;
		svg {
			fill: $color-blue;
		}
	}
	&__data {
		display: block;
		&__datetime,
		&__name {
			display: block;
			width: 100%;
		}
		&__datetime {

		}
	}
	&.list {
		.garagepreview {
			&__media {
				display: none;
			}
			&__symbol {
				display: none;
			}
			&__data {
				width: 60%;
				&__datetime {
					@extend %text-15;
					font-variation-settings: 'wght' 600;
					margin-bottom: 3px;
					@media (max-width: $to-large) {
						font-size: 1rem;
					}
				}
				&__name {
					@extend %text-10;
					color: rgba($color-davy, 0.66);
					font-variation-settings: 'wght' 700;
					text-transform: uppercase;
				}
			}
			&__info {
				@extend %flexCenterEnd;
				width: 40%;
				.garagestatus {
					display: block;
					width: max-content;
					text-align: right;
					@extend %text-12;
					text-transform: uppercase;
					font-variation-settings: 'wght' 600;
					&.public {
						color: $color-success;
					}
				}
			}
		}
	}
	&.preview {
		background: $color-white;
		border-radius: var(--radius);
		box-shadow: $shadow;
		margin-bottom: calc(var(--thirdgutter) * 2);
		padding: calc(var(--thirdgutter) * 2);
		.garagepreview {
			&__media {
				display: block;
				@include size(var(--supergutter), var(--supergutter));
				border-radius: var(--radius);
				overflow: hidden;
				&__image {
					display: block;
					@include size(100%,100%);
					object-fit: cover;
				}
			}
			&__symbol {
				display: none;
			}
			&__data {
				width: calc(100% - var(--supergutter));
				padding-left: var(--minigutter);
				&__datetime {
					@extend %text-20;
					font-variation-settings: 'wght' 600;
					margin-bottom: 3px;
					@media (max-width: $to-large) {
						font-size: 1rem;
					}
				}
				&__name {
					@extend %text-14;
					color: rgba($color-davy, 0.66);
					font-variation-settings: 'wght' 700;
					text-transform: uppercase;
				}
			}
			&__info {
				@extend %flexCenterEnd;
				width: calc(var(--supergutter) * 2);
				display: none;
				.garagestatus {
					display: block;
					width: max-content;
					text-align: right;
					@extend %text-12;
					text-transform: uppercase;
					font-variation-settings: 'wght' 600;
					&.public {
						color: $color-success;
					}
				}
			}
		}
	}
	@media (min-width: $from-large) {
		&.list {
			.garagepreview {
				&__data {
					width: 75%;
				}
				&__info {
					width: 25%;
				}
			}
		}
		&.preview {
			box-shadow: none;
			.garagepreview {
				&__data {
					width: calc(100% - (var(--supergutter) * 3));
				}
				&__info {
					display: flex;
				}
			}
		}
	}
}
</style>