<template>
	<main id="page" class="dashboard" ref="page">
		<div class="dashboard__head">
			<div class="content">
				<div class="dashboard__head__title">
					Dashboard
				</div>
			</div>
		</div>
		<div class="dashboard__content">
			<div class="content">
				<section v-if="notices && notices.length" class="section notices">
					<small class="section__title">
						{{ $t('common.titles.notices') }} <span class="section__title__number">({{ howmany }})</span>
					</small>
					<div class="notices__content">
						<Flicking :options="flickopts" :hideBeforeInit="true">
							<NotificationPreview v-for="(n, nI) in notices" :key="n.id" :notice="n" />
						</Flicking>
					</div>
				</section>
				<section v-if="totalBookings || totalEarnings" class="section gateinfo">
					<small class="section__title">
						{{ $t('common.titles.gateinfo') }}
					</small>
					<div class="gateinfo__content">
						<Totals :tot="totalBookings" :isLoading="totalsLoading" class="small-panel" />
						<Totals :tot="totalEarnings" :isLoading="totalsLoading" class="small-panel" />
						<router-link :to="{ name: 'howtoopengates.page'}" class="infogates">
							<span class="infogates__label" v-html="$t('dashboard.infogates')"></span>
							<span class="infogates__icon">
								<svg viewBox="0 0 50 50">
									<use xlink:href="#icon--back" width="100%" height="100%"></use>
								</svg>
							</span>
						</router-link>
					</div>
				</section>
				<section v-if="wWidth > 1200" class="section charts">
					<div class="charts__content">
						<Stats v-if="garagesList" statType="earning" :garages="garagesList" />
						<Stats v-if="garagesList" statType="bookings" :garages="garagesList" />
					</div>
				</section>
				<section class="section lists">
					<div class="lists__content">
						<Booking :list="bookings" :isLoading="bookingsLoading" class="dashboard__panel" />
						<Garages :list="garages" :isLoading="garagesLoading" class="dashboard__panel" />
					</div>
				</section>
			</div>
		</div>
	</main>
</template>
<script>
import dayjs from "dayjs";
import { Flicking } from "@egjs/vue-flicking";
import "@egjs/vue-flicking/dist/flicking.css";

import NotificationPreview from '@/views/components/notifications/NotificationPreview';
import Totals from '@/views/components/panels/Totals';
import Booking from '@/views/components/panels/Booking';
import Garages from '@/views/components/panels/Garages';

import Stats from '@/views/components/stats/Stats';

export default {
	name: 'Dashboard',
	components: {
		Flicking,
		NotificationPreview,
		Totals,
		Booking,
		Garages,
		Stats,
	},
	data() {
		return {
			wWidth: 0,
			bookingsLoading: true,
			garagesLoading: true,
			totalsLoading: true,
			flickopts: {
				moveYype: 'freeScroll',
				bound: true
			},
			notices: null,
			totals: null,
			bookings: null,
			garages: null,
			garagesList: null,
			booksList: null,
			fake: null,
			stats: {
				earnings: null,
				bookings: null
			},
			totalEarnings: {
				label: this.$t('dashboard.totals.earnings'),
				value: null
			},
			totalBookings: {
				label: this.$t('dashboard.totals.bookings'),
				value: null
			},
			notices: null,
			notice: null,
			notifiche: null,
			howmany: 0,
		}
	},
	methods: {
		onResize() {
			this.wWidth = window.innerWidth;
			if(this.wWidth < 1200) {
				this.editMode = 'mobile'
			} else {
				this.editMode = 'desktop'
			}
		},
		init() {
			this.totals = {};
			this.$service.stats.totals().then(res => {
				this.totalBookings.value = res.totalBookings;
				this.totalEarnings.value = `&euro; ${res.totalEarnings}`;
				this.totalsLoading = false;
			})

			let now = new Date();

			this.bookings = [];
			this.$service.bookings.get().then(res => {
				this.bookingsLoading = false;
				if(res && res.data) {
					if(res.data.length) {
						res.data.forEach((r, i) => {
							if(new Date(r.start_at).getTime() >= now.getTime() && (this.bookings.length < 5)) {
								this.bookings.push(r);
							}
						})
					} else {
						this.bookings = [];
					}
				}
			})
			
			this.garages = [];
			this.$service.garages.main.get().then(res => {
				if(res && res.data){
					this.garagesLoading = false;
					if(res.data.length) {
						this.garagesList = [...res.data];
						res.data.forEach((r, i) => {
							if(i < 5) {
								this.garages.push(r)
							}
						})
					} else {
						this.garagesList = [];
					}
				}
			})
		}
	},
	mounted() {
		this.onResize();
		this.$nextTick(() => {
			window.addEventListener('resize', () => {
				this.onResize();
			});
		});

		let token = localStorage.getItem('token');

		let timer = setInterval(() => {
			token = localStorage.getItem('token');
			if(token) {
				clearInterval(timer);
				this.init();
			}
		}, 100)

		
	}
}
</script>
<style lang="scss">
.dashboard {
	padding-top: var(--head);
	overflow: auto;
	&__head {
		display: block;
		width: 100%;
		margin: var(--gutter) 0;
		&__title {
			display: block;
			@extend %text-33;
			font-family: $font-manrope;
			font-variation-settings: 'wght' 700;
			margin-bottom: var(--thirdgutter);
		}
		@media (min-width: $from-large) {
			display: none;
		}
	}
	&__content {
		display: block;
		width: 100%;
		margin: var(--supergutter) 0;
		@media (min-width: $from-large) {
			.small-panel {
				height: var(--smallpanelh);
			}
		}
		.notices {
			margin-top: 0;
			.section__title {
				&__number {
					display: block;
					margin-left: 3px;
				}
			}
			@media (min-width: $from-large) {
				display: none;
			}
		}
		.gateinfo {
			&__content {
				@extend %flexStartBetween;
				flex-wrap: wrap;
				width: 100%;
				.infogates {
					@extend %panel;
					@extend %flexCenterBetween;
					@include size(100%,var(--icon));
					font-family: $font-manrope;
					padding: var(--minigutter);
					&__label {
						@extend %text-15;
					}
					&__icon {
						@extend %icon;
						svg {
							display: block;
							@include size(50%,50%);
							transform: scaleX(-1);
						}
					}
					@media (min-width: $from-large) {
						width: 32.5%;
						height: var(--smallpanelh);
						border-radius: var(--bigradius);
					}
				}
			}
		}
		.charts {
			display: block;
			width: 100%;
			margin-bottom: var(--gutter);
			&__content {
				display: flex;
				justify-content: space-between;
				flex-wrap: wrap;
				width: 100%;
			}
		}
		.lists {
			display: block;
			width: 100%;
			margin-bottom: var(--gutter);
			&__content {
				display: flex;
				flex-wrap: wrap;
				justify-content: space-between;
				width: 100%;
			}
		}
	}
	@media (max-width: $to-large) {
		.dashboard__panel {
			margin-top: var(--gutter);
			&:first-child {
				margin-top: 0;
			}
		}
		.section {
			margin-bottom: var(--gutter);
		}
	}
}
</style>