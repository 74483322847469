<template>
	<div v-if="review" class="reviewpreview" :data-id="review.id" :data-date="review.booked_at" @click="reviewModal">
		<div class="reviewpreview__box">
			<span class="reviewpreview__stars">
				<svg v-for="index in 5" :key="`${review.id}_${index}`" class="star" viewBox="0 0 50 50">
					<use xlink:href="#icon--star" width="100%" height="100%"></use>
				</svg>
			</span>
			<span v-if="review.user && review.user.name && review.user.surname" class="reviewpreview__name">
				{{ review.user.name }} {{ review.user.surname }}
			</span>
			<small class="reviewpreview__date">
				{{ reviewdate }}
			</small>
			<template v-if="review.description.length > 150">
				<span class="reviewpreview__text" v-html="handleText()"></span>
				<small class="reviewpreview__readmore simple-btn">
					{{ $t('common.readmore') }}
				</small>
			</template>
			<template v-else>
				<span class="reviewpreview__text" v-html="review.description"></span>
			</template>
		</div>
	</div>
</template>
<script>
import { EventBus } from '@/events_bus.js';
import dayjs from 'dayjs';

export default {
	name: 'ReviewPreview',
	props: {
		review: {
			type: Object,
			required: true
		}
	},
	data() {
		return {
			reviewdate: null,
		}
	},
	methods: {
		reviewModal() {
			EventBus.$emit('open-review', this.review);
		},
		handleRating() {
			let stars = this.$el.querySelectorAll('.star');

			stars.forEach((s, i) => {
				if(i < this.review.rating) {
					s.classList.add('active');
				}
			})
		},
		handleText() {
			let txtLength = this.review.description.length;
			if(txtLength > 150) {
				return `${this.review.description.substring(0, 150)}...`;
			} else {
				return this.review.description
			}
		},
		handleDate() {
			this.reviewdate = dayjs(this.review.booked_at).format('DD MMM');
		}
	},
	mounted() {
		if(this.review) {
			if(this.review.rating) {
				this.handleRating();
			}
			if(this.review.booked_at) {
				this.handleDate();
			}
		}
	}
}
</script>
<style lang="scss">
.reviewpreview {
	@extend %panel;
	display: block;
	width: 100%;
	background: $color-white;
	padding: var(--gutter);
	margin-bottom: var(--gutter);
	cursor: pointer;
	@media (max-width: $to-large) {
		flex: 1 0 auto;
	}
	&__box {
		display: flex;
		flex-wrap: wrap;
		flex-direction: column;
		width: 100%;
		height: 100%;
	}
	&__stars {
		@extend %flexCenterCenter;
		width: 100%;
		text-align: center;
		margin-bottom: var(--minigutter);
		.star {
			@extend %icon;
			margin: 0 var(--thirdgutter);
			fill: rgba($color-silver, 0.5);
			&.active {
				fill: $color-blue;
			}
		}
	}
	&__name, &__date,
	&__text {
		display: block;
		width: 100%;
		text-align: center;
	}
	&__readmore {
		display: block;
		width: max-content;
		color: $color-blue;
		margin: var(--thirdgutter) auto;
		cursor: pointer;
	}
	&__name, &__date {
		margin-bottom: var(--minigutter);
	}
	&__name {
		@extend %text-20;
		font-family: $font-manrope;
		font-variation-settings: 'wght' 700;
		color: $color-black;
	}
	&__date {
		@extend %text-13;
		color: $color-silver;
	}
	&__text {
		@extend %text-13;
	}
	@media (min-width: $from-medium) {
		width: 48%;
		margin: 0 1% var(--minigutter)
	}
	@media (min-width: $from-large) {
		width: 31%;
	}
}
</style>