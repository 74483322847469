<template>
	<main id="page" class="account">
		<div class="mobile__head">
			<span class="mobile__head__back" @click="closeDrawer">
				<span class="mobile__head__back__icon">
					<svg viewBox="0 0 50 50">
						<use xlink:href="#icon--back" width="100%" height="100%"></use>
					</svg>
				</span>
				<span class="mobile__head__back__label">
					{{ $t('common.back') }}
				</span>
			</span>
			<span class="mobile__head__edit" @click="openEdit">
				<svg viewBox="0 0 50 50">
					<use xlink:href="#icon--edit" width="100%" height="100%"></use>
				</svg>
			</span>
		</div>
		<template v-if="pageLoading">
			<Loading :isLoading="pageLoading" />
		</template>
		<template v-else>
			<template v-if="me">
				<div class="account__head">
					<div class="content">
						<div class="account__head__title">
							Account
						</div>
					</div>
				</div>
				<div class="account__content">
					<div class="smallcontent">
						<div class="account__box">
							<div class="account__info">
								<div class="account__info__media">
									<figure class="account__info__pic">
										<span v-if="avatarLoading" class="account__info__pic__loading">
											<Spinner />
										</span>
										<template v-if="me.avatar">
											<img class="account__info__pic__image" :src="me.avatar" alt="" />
										</template>
										<template v-else>
											<svg viewBox="0 0 50 50">
												<use xlink:href="#icon--account" width="100%" height="100%"></use>
											</svg>
										</template>
									</figure>
									<span class="account__info__btn">
										<input type="file" class="account__info__upload" @change="uploadAvatar" />
										<span class="account__info__fakebtn">
											{{ $t('account.btns.upload') }}
										</span>
									</span>
								</div>
								<div class="account__info__data">
									<strong>
										{{ me.name }} {{ me.surname }}
									</strong>
									{{ me.email }}<br />
									{{ me.address }}<br />
									Tel {{ me.phone }}
								</div>
							</div>
							<div class="account__actions">
								<span class="account__btn profile" @click="openEdit">
									<span class="account__btn__label">
										{{ $t('account.btns.profile') }}
									</span>
									<span class="account__btn__icons">
										<template v-if="!me.phone">
											<span class="account__btn__icons__alert">
												<svg viewBox="0 0 50 50">
													<use xlink:href="#icon--alert" width="100%" height="100%"></use>
												</svg>
											</span>
										</template>
										<span class="account__btn__icons__arrow">
											<svg viewBox="0 0 50 50">
												<use xlink:href="#icon--back" width="100%" height="100%"></use>
											</svg>
										</span>
									</span>
								</span>
								<template v-if="me.phone">
									<template v-if="!me.phone_verified_at">
										<span class="account__btn tel" @click="openPhone">
											<span class="account__btn__label">
												{{ $t('account.btns.number') }}
											</span>
											<span class="account__btn__icons">
												<span class="account__btn__icons__alert">
													<svg viewBox="0 0 50 50">
														<use xlink:href="#icon--alert" width="100%" height="100%"></use>
													</svg>
												</span>
												<span class="account__btn__icons__arrow">
													<svg viewBox="0 0 50 50">
														<use xlink:href="#icon--back" width="100%" height="100%"></use>
													</svg>
												</span>
											</span>
										</span>
									</template>
									<template v-else>
										<span class="account__verified">
											<span class="account__verified__icon">
												<svg viewBox="0 0 50 50">
													<use xlink:href="#icon--check" width="100%" height="100%"></use>
												</svg>
											</span>
											<span class="account__verified__label">
												{{ $t('account.verified.number') }}
											</span>
										</span>
									</template>
								</template>
								<template v-if="!me.email_verified_at">
									<span class="account__btn email" @click="openEmail">
										<span class="account__btn__label">
											{{ $t('account.btns.email') }}
										</span>
										<span class="account__btn__icons">
											<span class="account__btn__icons__alert">
												<svg viewBox="0 0 50 50">
													<use xlink:href="#icon--alert" width="100%" height="100%"></use>
												</svg>
											</span>
											<span class="account__btn__icons__arrow">
												<svg viewBox="0 0 50 50">
													<use xlink:href="#icon--back" width="100%" height="100%"></use>
												</svg>
											</span>
										</span>
									</span>
								</template>
								<template v-else>
									<span class="account__verified">
										<span class="account__verified__icon">
											<svg viewBox="0 0 50 50">
												<use xlink:href="#icon--check" width="100%" height="100%"></use>
											</svg>
										</span>
										<span class="account__verified__label">
											{{ $t('account.verified.email') }}
										</span>
									</span>
								</template>
								<span class="account__btn identita" @click="openIdentity">
									<span class="account__btn__label">
										{{ $t('account.btns.identita') }}
									</span>
									<span class="account__btn__icons">
										<template v-if="!hasDocs">
											<span class="account__btn__icons__alert">
												<svg viewBox="0 0 50 50">
													<use xlink:href="#icon--alert" width="100%" height="100%"></use>
												</svg>
											</span>
										</template>
										<span class="account__btn__icons__arrow">
											<svg viewBox="0 0 50 50">
												<use xlink:href="#icon--back" width="100%" height="100%"></use>
											</svg>
										</span>
									</span>
								</span>
								<label for="showdisability" class="truefalse__toggle showdisability">
									<span class="form__truefalse__title" v-html="$t('account.disability.text')"></span>
									<input type="checkbox" name="showdisability" id="showdisability" class="truefalse__toggle__input" v-model="showDisability" />
									<span class="truefalse__toggle__btn" :class="{ 'on': showDisability }"></span>
								</label>
								<span v-if="showDisability" class="account__btn identita" @click="openDisability">
									<span class="account__btn__label">
										{{ $t('account.btns.disability') }}
									</span>
									<span class="account__btn__icons">
										<template v-if="!hasDocs">
											<span class="account__btn__icons__alert">
												<svg viewBox="0 0 50 50">
													<use xlink:href="#icon--alert" width="100%" height="100%"></use>
												</svg>
											</span>
										</template>
										<span class="account__btn__icons__arrow">
											<svg viewBox="0 0 50 50">
												<use xlink:href="#icon--back" width="100%" height="100%"></use>
											</svg>
										</span>
									</span>
								</span>
							</div>
							<div class="account__settings">
								<span class="account__settings__title" v-html="$t('account.settings.title')"></span>
								<div class="account__settings__content">
									<label for="email_notification" class="truefalse__toggle">
										<span class="form__settings__truefalse__title" v-html="$t('account.settings.email')"></span>
										<input type="checkbox" name="email_notification" id="email_notification" class="truefalse__toggle__input" :value="me.email_notification" v-model="me.email_notification" @change="onToggleSettings($event)" />
										<span class="truefalse__toggle__btn" :class="{ 'on': me.email_notification }"></span>
									</label>
									<label for="push_notification" class="truefalse__toggle">
										<span class="form__settings__truefalse__title" v-html="$t('account.settings.push')"></span>
										<input type="checkbox" name="push_notification" id="push_notification" class="truefalse__toggle__input" :value="me.push_notification" v-model="me.push_notification" @change="onToggleSettings($event)" />
										<span class="truefalse__toggle__btn" :class="{ 'on': me.push_notification }"></span>
									</label>
								</div>
							</div>
							<div class="account__ctas">
								<span class="account__ctas__btn btn change" @click="changePassword">
									{{ $t('account.btns.changepsw') }}
								</span>
								<span class="account__ctas__btn btn delete" @click="deleteAccount">
									{{ $t('account.btns.delete') }}
								</span>
							</div>
						</div>
					</div>
				</div>
				<Edit :me="me" :mode="editMode" />
				<Email :me="me" :mode="editMode" />
				<Identity :mode="editMode" />
				<Disability :mode="editMode" />
				<Phone :mode="editMode" />
				<Delete :mode="editMode" />
				<ChangePassword :mode="editMode" />
			</template>
		</template>
	</main>
</template>
<script>
import { EventBus } from '@/events_bus.js';

import Loading from '@/views/components/common/Loading';
import Spinner from '@/views/components/common/Spinner';

import Edit from '@/views/components/account/Edit';
import Email from '@/views/components/account/Email';
import Identity from '@/views/components/account/Identity';
import Disability from '@/views/components/account/Disability';
import Phone from '@/views/components/account/Phone';
import Delete from '@/views/components/account/Delete';
import ChangePassword from '@/views/components/account/ChangePassword';

export default {
	name: 'Account',
	components: {
		Loading,
		Edit,
		Email,
		Identity,
		Disability,
		Phone,
		Delete,
		Spinner,
		ChangePassword
	},
	data() {
		return {
			pageLoading: false,
			me: null,
			avatar: null,
			editMode: 'mobile',
			isLoading: false,
			hasDocs: false,
			form: {
				settings: {
					email: false,
					push: false,
				}
			},
			showDisability: false,
			disabilityExpired: false,
			avatarLoading: false,
		}
	},
	methods: {
		changePassword() {
			EventBus.$emit('change-password');
		},
		deleteAccount() {
			EventBus.$emit('delete-account');
		},
		closeDrawer() {
			this.$router.go(-1);
		},
		openEdit() {
			EventBus.$emit('edit-account');
		},
		openIdentity() {
			EventBus.$emit('upload-identity');
		},
		openDisability() {
			EventBus.$emit('upload-disability');
		},
		openEmail() {
			EventBus.$emit('verify-email');
		},
		openPhone() {
			EventBus.$emit('verify-phone');
		},
		onToggleSettings(e) {
			console.log('TTT', e.target)
			if(this.me.push_notification) {
				EventBus.$emit('ask-notification');
			} else {
				EventBus.$emit('revoke-notification');
			}
			if(e && e.target) {
				e.target.parentNode.classList.add('disabled');
			}
			if(this.me) {
				this.$service.user.me.update(this.me).then(res => {
					e.target.parentNode.classList.remove('disabled');
				}).catch(err => {
					console.error('ERR', err);
				})
			}
		},
		uploadAvatar(e) {
			this.avatarLoading = true;
			const formdata = new FormData();
			formdata.append('file', e.target.files[0]);

			this.$service.user.avatar(formdata, { 'content-type': 'multipart/form-data' }).then(res => {
				this.avatar = res;
				this.init();
			}).catch(err => {
				console.error('ERR', err);
			})
		},
		onResize() {
			this.wWidth = window.innerWidth;
			if(this.wWidth < 1200) {
				this.editMode = 'mobile'
			} else {
				this.editMode = 'desktop'
			}
		},
		init() {
			this.pageLoading = true;
			this.$service.user.me.get().then(res => {
				if(res) {
					this.me = res;
					this.pageLoading = false;
					
					this.avatarLoading = false;

					this.$service.user.documents().then(res => {
						if(res && res.data && res.data.length) {
							this.hasDocs = true;
						}
					})
				}
			})
		}
	},
	mounted() {
		this.onResize();
		this.$nextTick(() => {
			window.addEventListener('resize', () => {
				this.onResize();
			});
		});

		this.init();

		EventBus.$on('edit-saved', () => {
			this.init();
		})

		EventBus.$on('phone-verification-successful', () => {
			this.init();
		});
		
	}
}
</script>
<style lang="scss">
.account {
	padding-top: var(--head);
	overflow: auto;
	.mobile__head {
		@extend %flexCenterBetween;
		@include size(100%, var(--head));
		padding: 0 var(--gutter);
		position: relative;
		&__back {
			@extend %flexCenterStart;
			width: calc(100% - var(--btn));
			cursor: pointer;
			&__icon {
				@extend %flexCenterCenter;
				@include size(var(--smallicon),var(--smallicon));
				svg {
					display: block;
					@include size(75%,75%);
					fill: $color-black;
				}
			}
			&__label {
				@extend %text-15;
				display: block;
				width: max-content;
				font-variation-settings: 'wght' 600;
				padding-left: var(--minigutter);
			}
		}
		&__edit {
			@extend %btn;
			@extend %panel;
			width: var(--btn);
			border-radius: calc(var(--bigradius) / 2);
			border: 1px solid rgba($color-black, 0.15);
			svg {
				display: block;
				@include size(40%,40%);
				fill: $color-black;
				pointer-events: none;
			}
		}
	}
	&__box {
		@extend %panel;
		@extend %flexStartBetween;
		flex-wrap: wrap;
		width: 100%;
		padding: var(--gutter);
	}
	&__head {
		display: block;
		width: 100%;
		margin: var(--gutter) 0;
		&__title {
			display: block;
			@extend %text-33;
			font-family: $font-manrope;
			font-variation-settings: 'wght' 700;
			margin-bottom: var(--thirdgutter);
		}
		@media (min-width: $from-large) {
			display: none;
		}
	}
	&__content {
		display: block;
		width: 100%;
		margin: var(--supergutter) 0;
		@media (max-width: $to-large) {
			background: $color-white;
		}
		@media (min-width: $from-large) {
			.small-panel {
				height: var(--smallpanelh);
			}
		}
	}
	&__info,
	&__actions {
		display: block;
		width: 100%;
	}
	&__info {
		margin-bottom: var(--gutter);
		&__data {
			@extend %text-15;
			display: block;
			width: 100%;
			strong {
				display: block;
				width: 100%;
				@extend %text-18;
				font-variation-settings: 'wght' 600;
				margin-bottom: var(--thirdgutter);
			}
		}
		&__media {
			@extend %flexCenterBetween;
			width: 100%;
			margin-bottom: var(--gutter);
		}
		&__pic {
			@extend %flexCenterCenter;
			background: rgba($color-silver,0.3);
			@include size(var(--thumb),var(--thumb));
			border-radius: calc(var(--bigradius) * 2);
			overflow: hidden;
			position: relative;
			svg {
				display: block;
				@include size(25%,25%);
				fill: rgba($color-grey,0.3);
			}
			&__image {
				display: block;
				@include size(100%,100%);
				object-fit: cover;
			}
			&__loading {
				position: absolute;
				top: 0;
				left: 0;
				@extend %flexCenterCenter;
				background: rgba($color-black, 0.6);
				@include size(100%,100%);
				.spinner {
					display: block;
					@include size(var(--supergutter),var(--supergutter));
				}
			}
		}
		&__btn {
			display: block;
			width: calc(100% - var(--thumb) - var(--gutter));
			position: relative;
			margin-left: var(--gutter);
			cursor: pointer !important;
			& * {
				cursor: pointer;
			}
		}
		&__upload {
			display: block;
			@include size(136px, 47px);
			overflow: hidden;
			opacity: 0;
			cursor: pointer;
		}
		&__fakebtn {
			position: absolute;
			top: 0;
			left: 0;
			background: $color-white;
			@extend %flexCenterCenter;
			@include size(136px, 47px);
			font-variation-settings: 'wght' 700;
			color: $color-blue;
			border: 1px solid $color-blue;
			border-radius: var(--radius);
			pointer-events: none;
		}
	}
	&__verified {
		@extend %flexCenterStart;
		@include size(100%,var(--gutter));
		padding: 0 var(--minigutter);
		margin-bottom: calc(var(--thirdgutter) * 2);
		&__icon {
			@extend %icon;
			svg {
				display: block;
				@include size(70%,70%);
				fill: $color-green;
			}
		}
		&__label {
			@extend %text-15;
			display: block;
			width: calc(100% - var(--smallicon));
			font-variation-settings: 'wght' 600;
			padding-left: var(--minigutter);
		}
	}
	&__btn {
		@extend %panel;
		@extend %flexCenterBetween;
		@include size(100%,var(--accountbtn));
		padding: 0 var(--minigutter);
		box-shadow: -3px 5px 0 rgba($color-black, 0.08);
		border: 1px solid rgba($color-black, 0.15);
		margin-bottom: calc(var(--thirdgutter) * 2);
		cursor: pointer;
		&__label {
			display: block;
			width: 75%;
		}
		&__icons {
			@extend %flexCenterEnd;
			width: 25%;
			&__alert {
				@extend %icon;
				svg {
					@include size(80%,80%);
					fill: $color-red;
				}
			}
			&__arrow {
				@extend %icon;
				svg {
					@include size(70%,70%);
					fill: $color-black;
					transform: scaleX(-1);
				}
			}
		}
		@media (max-width: $to-large) {
			&.profile {
				display: none;
			}
		}
	}
	&__ctas {
		@extend %flexCenterBetween;
		flex-wrap: wrap;
		width: 100%;
		margin-top: var(--supergutter);
		padding-top: var(--gutter);
		border-top: 1px solid $color-silver;
		&__btn {
			display: block;
			width: 100%;
			padding: 0 var(--gutter);
			&.delete {
				background: $color-red;
				color: $color-white;
			}
			&.change {
				background: $color-white;
				border: 2px solid $color-blue;
				color: $color-blue;
				margin-bottom: var(--thirdgutter);
			}
		}
	}
	&__settings {
		display: block;
		width: 100%;
		margin: var(--gutter) 0;
		&__title {
			@extend %text-20;
			display: block;
			width: 100%;
			font-family: $font-manrope;
			font-variation-settings: 'wght' 600;
			padding-bottom: var(--thirdgutter);
			border-bottom: 1px solid rgba($color-silver, 0.5);
		}
		&__content {
			display:  block;
			width: 100%;
			padding-top: var(--minigutter);
		}
	}
	.truefalse {
		&__toggle {
			@extend %flexCenterBetween;
			width: 100%;
			margin-bottom: var(--minigutter);
			cursor: pointer;
			transition: 180ms linear;
			&.disabled {
				opacity: 0.5;
				pointer-events: none;
			}
			&__input {
				display: none;
			}
			&__label {
				@extend %text-15;
				display: block;
				font-family: $font-manrope;
				padding-right: var(--thirdgutter);
			}
			&__btn {
				display: block;
				@include size(48px,22px);
				border-radius: var(--minigutter);
				border: 1px solid $color-davy;
				position: relative;
				&:before {
					content: '';
					position: absolute;
					top: 50%;
					left: 2px;
					display: block;
					background: $color-blue;
					@include size(16px,16px);
					border-radius: 100%;
					transform: translateY(-50%);
					transition: 180ms linear;
					filter: grayscale(1);
				}
				&.on {
					&:before {
						left: calc(100% - 18px);
						filter: grayscale(0);
					}
				}
			}
		}
	}
	.showdisability {
		margin-top: var(--supergutter);
		.form__truefalse {
			&__title {
				width: 70%;
			}
		}
	}
	@media (min-width: $from-large) {
		.mobile__head {
			display: none;
		}
		&__box {
			padding: var(--supergutter) var(--supergutter) var(--gutter);
		}
		&__info,
		&__actions {
			width: 49%;
		}
		&__delete {
			border-top: 1px solid $color-silver;
			&__btn {
				width: max-content;
			}
		}
		&__ctas {
			&__btn {
				width: max-content;
			}
		}
	}
	@media (max-width: $to-large) {
		position: fixed;
		top: 0;
		left: 0;
		display: block;
		background: $color-white !important;
		padding-top: 0;
		z-index: 100000;
		&__head {
			display: none;
		}
		&__box {
			border-radius: 0;
			box-shadow: none;
			padding: 0;
		}
		&__content {
			margin-top: 0;
		}
	}
}
</style>