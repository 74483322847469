<template>
	<main id="page" class="bookings" ref="page">
		<div class="mobile__head">
			<span class="mobile__head__back" @click="closeDrawer">
				<span class="mobile__head__back__icon">
					<svg viewBox="0 0 50 50">
						<use xlink:href="#icon--back" width="100%" height="100%"></use>
					</svg>
				</span>
				<span class="mobile__head__back__label">
					{{ $t('common.back') }}
				</span>
			</span>
		</div>
		<template v-if="pageLoading">
			<Loading :isLoading="pageLoading" />
		</template>
		<template v-else>
			<template v-if="nobookings">
				<div class="bookings__empty">
					<figure class="bookings__empty__media">
						<img src="@/assets/images/boy.png" alt="" />
					</figure>
					<span class="bookings__empty__title" v-html="$t('bookings.empty.title')"></span>
					<span class="bookings__empty__text" v-html="$t('bookings.empty.text')"></span>
				</div>
			</template>
			<template v-else>
				<div class="bookings__head">
					<div class="content">
						<div class="bookings__head__title">
							bookings
						</div>
					</div>
				</div>
				<div class="bookings__content">
					<div class="smallcontent">
						<div class="bookings__box">
							<div class="bookings__box__head">
								<span class="bookings__box__head__label">
									<router-link :to="{ name: 'bookings.page' }" class="backtolist">
										<svg viewBox="0 0 50 50">
											<use xlink:href="#icon--back" width="100%" height="100%"></use>
										</svg>
										{{ $t('bookings.labels') }}
									</router-link>
								</span>
								<span class="bookings__box__actions">
									<Filters :mode="editMode" :garages="garages" />
									<span class="bookings__box__filter btn filled" @click="openFilters">
										<span class="bookings__box__filter__label">
											{{ $t('bookings.btns.filter') }}
										</span>
									</span>
								</span>
							</div>
							<div class="bookings__box__list">
								<template v-if="bArchive && bArchive.length">
									<BookingPreview v-for="b in bArchive" :key="b.id" :booking="b" type="preview" />
								</template>
								<template v-else>
									<small class="bookings__box__list__noresults" v-html="$t('bookings.filters.noresults')"></small>
								</template>
							</div>
						</div>
					</div>
				</div>
			</template>
		</template>
	</main>
</template>
<script>
import { EventBus } from '@/events_bus.js';
import { gsap } from 'gsap';
import { CustomEase } from 'gsap/all';
gsap.registerPlugin(CustomEase);

import dayjs from 'dayjs';

import Loading from '@/views/components/common/Loading';
import BookingPreview from '@/views/components/booking/BookingPreview';
import Filters from '@/views/components/booking/Filters';

export default {
	name: 'BookingsArchive',
	components: {
		Loading,
		BookingPreview,
		Filters
	},
	data() {
		return {
			pageLoading: false,
			nobookings: true,
			bArchive: null,
			editMode: 'mobile',
			garages: null,
			howmany: null
		}
	},
	methods: {
		closeDrawer() {
			this.$router.go(-1);
		},
		addGarage() {

		},
		openFilters() {
			EventBus.$emit('open-filters');
		},
		onResize() {
			this.wWidth = window.innerWidth;
			if(this.wWidth < 1200) {
				this.editMode = 'mobile'
			} else {
				this.editMode = 'desktop'
			}
		},
		init() {
			this.pageLoading = true;
			this.bArchive = [];

			let now = new Date().getTime();
			this.$service.bookings.get().then(res => {
				this.pageLoading = false;
				if(res && res.data && res.data.length) {
					// this.nobookings = false;
					res.data.forEach(b => {
						if(dayjs(b.end_at).valueOf() < now) {
							this.bArchive.push(b);
						}
					})
					if(this.bArchive.length) {
						this.nobookings = false;
					}
				}
			})

			this.garages = [];
			this.$service.garages.main.get().then(res => {
				if(res && res.data && res.data.length) {
					this.garages = [...res.data];
				}
			})
		}
	},
	mounted() {
		this.onResize();
		this.$nextTick(() => {
			window.addEventListener('resize', () => {
				this.onResize();
			});
		});

		this.init();

		EventBus.$on('filter-results', (filters) => {

			let filtri = [];
			if(filters.from_date != '') {
				filtri.push(`filters[start_at]=${dayjs(filters.from_date).format('YYYY-MM-DDTHH:mm:ss')}`)
			}
			if(filters.to_date != '') {
				filtri.push(`filters[end_at]=${dayjs(filters.to_date).format('YYYY-MM-DDTHH:mm:ss')}`)
			}
			if(filters.garages.length) {
				filters.garages.forEach(g => {
					filtri.push(`filters[garage_ids][]=${+g}`)
				})
			}
			if(filters.status.length) {
				filters.status.forEach(s => {
					filtri.push(`filters[status]=${s.toUpperCase()}`);
				})
			}


			this.bArchive = [];
			let now = new Date().getTime();
			this.$service.bookings.get(filtri.join('&')).then(res => {
				// this.nobookings = false;
				res.data.forEach(b => {
					if(dayjs(b.end_at).valueOf() < now) {
						this.bArchive.push(b);
					}
				})
				if(this.bArchive.length) {
					this.nobookings = false;
				}
			})
		})
		
	}
}
</script>
<style lang="scss">
.bookings {
	padding-top: var(--head);
	overflow: auto;
	.mobile__head {
		@extend %flexCenterBetween;
		@include size(100%, var(--head));
		padding: 0 var(--gutter);
		position: relative;
		&__back {
			@extend %flexCenterStart;
			width: calc(100% - var(--btn));
			cursor: pointer;
			&__icon {
				@extend %flexCenterCenter;
				@include size(var(--smallicon),var(--smallicon));
				svg {
					display: block;
					@include size(75%,75%);
					fill: $color-black;
				}
			}
			&__label {
				@extend %text-15;
				display: block;
				width: max-content;
				font-variation-settings: 'wght' 600;
				padding-left: var(--minigutter);
			}
		}
	}
	&__empty {
		@extend %flexCenterCenter;
		flex-direction: column;
		@include size(330px,100%);
		margin: 0 auto;
		&__media {
			display: block;
			@include size(200px,220px);
			margin-bottom: var(--supergutter);
			&__image {
				display: block;
				@include size(100%,100%);
				object-fit: contain;
			}
		}
		&__title {
			@extend %text-25;
			font-family: $font-manrope;
			font-variation-settings: 'wght' 700;
			margin-bottom: var(--minigutter);
			text-align: center;
		}
		&__text {
			@extend %text-15;
			margin-bottom: var(--gutter);
			text-align: center;
		}
		&__add {
			@extend %btn;
			display: block;
			@include size(var(--addbtn),var(--addbtn));
			background: $color-white;
			border: 1px solid $color-blue;
			position: relative;
			padding: 0;
			box-shadow: $shadow;
			@extend %arrowClose;
			&:before,
			&:after {
				background: $color-blue;
				@include size(40%,3px);
			}
			&:before {
				transform: translate(-50%,-50%);
			}
			&:after {
				transform: translate(-50%,-50%) rotate(90deg);
			}
		}
	}
	&__content {
		display: block;
		width: 100%;
		margin: var(--supergutter) 0;
		@media (min-width: $from-large) {
			.small-panel {
				height: var(--smallpanelh);
			}
		}
	}
	&__box {
		@extend %panel;
		@extend %flexCenterBetween;
		flex-wrap: wrap;
		width: 100%;
		padding: var(--gutter);
		background: transparent;
		box-shadow: none;
		&__head {
			@extend %flexCenterBetween;
			width: 100%;
			margin-bottom: var(--gutter);
			&__label, &__actions {
				width: 50%;
			}
			&__label {
				@extend %text-13;
				@extend %flexBaselineStart;
				width: 50%;
				.backtolist {
					@extend %flexCenterCenter;
					@include size(max-content,var(--smallicon));
					text-transform: uppercase;
					svg {
						display: block;
						@include size(60%,60%);
						fill: $color-black;
						margin-right: var(--minigutter);
					}
				}
			}
		}
		&__actions {
			@extend %flexCenterEnd;
			position: relative;
		}
		&__filter {
			@extend %flexCenterEnd;
			background: $color-blue;
			width: 140px;
			color: $color-white;
			padding: 0 var(--gutter);
			box-shadow: none !important;
			&__label {
				display: block;
				margin: 0 auto;
			}
		}
		&__list {
			display: block;
			width: 100%;
			&__noresults {
				@extend %flexCenterCenter;
				width: 100%;
				padding: var(--supergutter);
				text-align: center;
				text-transform: uppercase;
				opacity: 0.4;
			}
		}
	}
	@media (min-width: $from-large) {
		.mobile__head {
			display: none;
		}
		&__head {
			display: none;
		}
		&__box {
			background: $color-white;
			box-shadow: $shadow;
		}
	}
	@media (max-width: $to-large) {
		position: fixed;
		top: 0;
		left: 0;
		display: block;
		background: $color-antiflash !important;
		padding-top: 0;
		z-index: 100000;
		&__head {
			display: none;
		}
		&__box {
			border-radius: 0;
			box-shadow: none;
			padding: 0;
			&__head {
				justify-content: flex-end;
				&__label {
					// display: none;
				}
			}
		}
		&__content {
			margin-top: 0;
		}
	}
}
</style>