<template>
	<div v-if="me" class="accountemail">
		<div class="accountemail__head">
			<span class="accountemail__head__back" @click="closeDrawer">
				<span class="accountemail__head__back__icon">
					<svg viewBox="0 0 50 50">
						<use xlink:href="#icon--back" width="100%" height="100%"></use>
					</svg>
				</span>
				<span class="accountemail__head__back__label">
					{{ $t('common.back') }}
				</span>
			</span>
		</div>
		<div class="accountemail__box">
			<div class="accountemail__box__head">
				<div class="accountemail__box__head__title">
					{{ $t('account.email.title') }}
				</div>
				<span class="accountemail__box__head__close" @click="closeModal">
					<svg viewBox="0 0 50 50">
						<use xlink:href="#icon--close" width="100%" height="100%"></use>
					</svg>
				</span>
			</div>
<!--			<div class="accountemail__box__text" v-html="$t('account.email.text')"></div>-->
			<div class="accountemail__box__content">
				<template v-if="emailSent">
					{{ $t('account.email.sent') }}
				</template>
				<template v-else>
					<div class="accountemail__box__instruction">
						{{ $t('account.email.instruction') }} {{ me.email }}
					</div>
					<span class="accountemail__box__btn btn filled" @click="sendEmail">
						{{ $t('account.email.send') }}
					</span>
				</template>

			</div>
		</div>
	</div>
</template>
<script>
import { EventBus } from '@/events_bus.js';
import { gsap } from 'gsap';
import { CustomEase } from 'gsap/all';
gsap.registerPlugin(CustomEase);

export default {
	name: 'Email',
	props: {
		me: {
			type: Object,
			required: true,
			default: {}
		},
		mode: {
			type: String,
			required: true,
			default: 'mobile'
		}
	},
	data() {
		return {
			emailSent: false,
		}
	},
	methods: {
		sendEmail() {
			this.$service.user.email.verification().then(res => {
				this.emailSent = true;
			})
		},
		openModal() {
			gsap.timeline({
				onComplete: () => {
					gsap.set('.accountemail', {
						pointerEvents: 'all'
					})
				}
			})
				.to('.accountemail', {
					duration: 0.3,
					opacity: 1
				})
				.to('.accountemail__box', {
					duration: 0.3,
					y: '0%',
					opacity: 1
				})
		},
		closeModal() {
			gsap.timeline({
				onComplete: () => {
					gsap.set('.accountemail', {
						pointerEvents: 'none'
					})
				}
			})
			.to('.accountemail__box', {
				duration: 0.3,
				y: 150,
				opacity: 0
			})
			.to('.accountemail', {
				duration: 0.3,
				opacity: 0
			})
		},
		openDrawer() {
			gsap.to('.accountemail', {
				duration: 0.9,
				x: '0%',
				ease: CustomEase.create("custom", "M0,0 C0.726,0 0,1 1,1 "),
				onComplete: () => {
					gsap.set('.accountemail', {
						pointerEvents: 'all'
					})
				}
			})
		},
		closeDrawer() {
			gsap.to('.accountemail', {
				duration: 0.9,
				x: '100%',
				ease: CustomEase.create("custom", "M0,0 C0.726,0 0,1 1,1 ")
			})
		},
		init() {
		},
	},
	mounted() {
		EventBus.$on('verify-email', () => {
			this.init();
			if(this.mode == 'mobile') {
				this.openDrawer()
			} else {
				this.openModal();
			}
		});

		this.init();
	}
}
</script>
<style lang="scss">
.accountemail {
	@extend %flexCenterCenter;
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	background: $color-seasalt;
	z-index: 10000;
	display: block;
	overflow: auto;
	transform: translateX(100%);
	pointer-events: none;
	&__head {
		@extend %flexCenterStart;
		@include size(100%, var(--headDrawer));
		padding: 0 var(--gutter);
		&__back {
			@extend %flexCenterStart;
			width: calc(100% - var(--btn));
			cursor: pointer;
			&__icon {
				@extend %flexCenterCenter;
				@include size(var(--smallicon),var(--smallicon));
				svg {
					display: block;
					@include size(75%,75%);
					fill: $color-black;
				}
			}
			&__label {
				@extend %text-15;
				display: block;
				width: max-content;
				font-variation-settings: 'wght' 600;
				padding-left: var(--minigutter);
			}
		}
	}
	&__box {
		@extend  %panel;
		background: transparent;
		padding: 0 var(--gutter);
		height: auto;
		overflow: auto;
		&__head {
			@extend %flexCenterBetween;
			width: 100%;
			display: none;
			margin-bottom: var(--gutter);
			&__title {
				@extend %text-33;
				display: block;
				width: 100%;
				font-family: $font-manrope;
				font-variation-settings: 'wght' 700;
			}
			&__close {
				@extend %icon;
				svg {
					@include size(70%,70%);
					fill: $color-black;
				}
			}
		}
		&__text {
			@extend %text-19;
			display: block;
			width: 100%;
		}
		&__instruction {
			@extend %text-15;
			display: block;
			width: 100%;
			text-align: center;
			margin: var(--gutter) 0;
		}
		&__content {
			position: absolute;
			top: 50%;
			left: 0;
			display: block;
			width: 100%;
			transform: translateY(-50%);
			padding: 0 var(--gutter);
		}
		&__btn {
			background: $color-blue;
			color: $color-white;
		}
		@media (max-width: $to-large) {
			box-shadow: none;
		}
	}
	@media (min-width: $from-large) {
		display: flex;
		background: rgba($color-black, 0.1);
		transform: translateX(0%);
		opacity: 0;
		&__head {
			display: none;
		}
		&__box {
			background: $color-white;
			max-height: 90vh;
			width: 780px;
			height: 75vh;
			padding: var(--gutter);
			transform: translateY(150px);
			opacity: 0;
			position: relative;
			&__head {
				display: flex;
				margin-bottom: var(--thirdgutter);
			}
			&__text {
				font-size: 0.938rem;
			}
			&__content {
				position: absolute;
				top: 50%;
				left: 0;
				display: block;
				width: 100%;
				text-align: center;
				transform: translateY(-50%);
			}
		}
	}
}
</style>
