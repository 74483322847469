<template>
	<div class="menu">
		<div class="menu__head">
			<div class="menu__mobile">
				<div class="menu__mobile__box">
					<span class="menu__mobile__label">
						{{ $t('menu.label') }}
					</span>
					<span class="menu__mobile__close" @click="closeMenu">
						<svg viewBox="0 0 50 50">
							<use xlink:href="#icon--close" width="100%" height="100%"></use>
						</svg>
					</span>
				</div>
			</div>
			<div class="menu__desktop">
				<router-link :to="{ name: 'dashboard.page' }" class="menu__desktop__logo">
					<svg viewBox="0 0 189.3 62">
						<use xlink:href="#logo--main" width="100%" height="100%"></use>
					</svg>
				</router-link>
			</div>
		</div>
		<div class="menu__list">
			<router-link :to="{ name: 'dashboard.page' }" class="menu__item btn filled">
				<span class="menu__item__label">
					{{ $t('menu.nav.dashboard') }}
				</span>
				<span class="menu__item__icons">
					<span class="menu__item__icons__arrow menu__item__icon">
						<svg viewBox="0 0 50 50">
							<use xlink:href="#icon--back"></use>
						</svg>
					</span>
				</span>
			</router-link>
			<router-link :to="{ name: 'account.page' }" class="menu__item btn filled">
				<template v-if="me">
					<span v-if="!me.phone || !me.birthday || !me.fiscal_code" class="menu__item__alert">
						<svg viewBox="0 0 50 50">
							<use xlink:href="#icon--alert" width="100%" height="100%"></use>
						</svg>
					</span>
				</template>
				<span class="menu__item__label">
					{{ $t('menu.nav.account') }}
				</span>
				<span class="menu__item__icons">
					<span class="menu__item__icons__arrow menu__item__icon">
						<svg viewBox="0 0 50 50">
							<use xlink:href="#icon--back"></use>
						</svg>
					</span>
				</span>
			</router-link>
			<router-link :to="{ name: 'garages.page' }" class="menu__item btn filled">
				<span class="menu__item__label">
					{{ $t('menu.nav.garage') }}
				</span>
				<span class="menu__item__icons">
					<span class="menu__item__icons__arrow menu__item__icon">
						<svg viewBox="0 0 50 50">
							<use xlink:href="#icon--back"></use>
						</svg>
					</span>
				</span>
			</router-link>
			<router-link :to="{ name: 'bookings.page' }" class="menu__item btn filled">
				<span class="menu__item__label">
					{{ $t('menu.nav.reservations') }}
				</span>
				<span class="menu__item__icons">
					<span class="menu__item__icons__arrow menu__item__icon">
						<svg viewBox="0 0 50 50">
							<use xlink:href="#icon--back"></use>
						</svg>
					</span>
				</span>
			</router-link>
			<router-link :to="{ name: 'reviews.page' }" class="menu__item btn filled">
				<span class="menu__item__label">
					{{ $t('menu.nav.reviews') }}
				</span>
				<span class="menu__item__icons">
					<span class="menu__item__icons__arrow menu__item__icon">
						<svg viewBox="0 0 50 50">
							<use xlink:href="#icon--back"></use>
						</svg>
					</span>
				</span>
			</router-link>
			<router-link :to="{ name: 'howtoopengates.page'}" class="menu__item btn filled">
				<span class="menu__item__label">
					{{ $t('menu.nav.infogates') }}
				</span>
				<span class="menu__item__icons">
					<span class="menu__item__icons__arrow menu__item__icon">
						<svg viewBox="0 0 50 50">
							<use xlink:href="#icon--back"></use>
						</svg>
					</span>
				</span>
			</router-link>
			<router-link :to="{ name: 'getpaid.page' }" class="menu__item btn filled">
				<span class="menu__item__label">
					{{ $t('menu.nav.payments') }}
				</span>
				<span class="menu__item__icons">
					<span class="menu__item__icons__arrow menu__item__icon">
						<svg viewBox="0 0 50 50">
							<use xlink:href="#icon--back"></use>
						</svg>
					</span>
				</span>
			</router-link>
			<router-link :to="{ name: 'becomeguest.page' }" class="menu__item btn filled">
				<span class="menu__item__label">
					{{ $t('menu.nav.guest') }}
				</span>
				<span class="menu__item__icons">
					<span class="menu__item__icons__arrow menu__item__icon">
						<svg viewBox="0 0 50 50">
							<use xlink:href="#icon--back"></use>
						</svg>
					</span>
				</span>
			</router-link>
			<span class="menu__foot__item btn outline" @click="logout">
				{{ $t('menu.nav.logout') }}
			</span>
		</div>
		<div class="menu__foot">
		</div>
	</div>
</template>
<script>
import { EventBus } from '@/events_bus.js';
import { gsap } from 'gsap';
import { CustomEase } from 'gsap/all';

gsap.registerPlugin(CustomEase);

export default {
	name: 'Menu',
	data() {
		return {
			ww: 0,
			me: null,
		}
	},
	watch: {
		me: {
			handler(newval, oldval) {
				return newval;
			}
		}
	},
	methods: {
		onResize() {
			this.ww = window.innerWidth;
		},
		openMenu() {
			this.$el.classList.add('openmenu');
			gsap.to('.menu', {
				duration: 1.2,
				x: '0%',
				ease: CustomEase.create("custom", "M0,0 C0.726,0 0,1 1,1 "),
				onStart: () => {
					gsap.set('.menu', { pointerEvents: 'none' })
				},
				onComplete: () => {
					gsap.set('.menu', { pointerEvents: 'all' })
				}
			})
		},
		closeMenu() {
			this.$el.classList.remove('openmenu');
			gsap.to('.menu', {
				duration: 1.2,
				x: '100%',
				ease: CustomEase.create("custom", "M0,0 C0.726,0 0,1 1,1 "),
				onComplete: () => {
					gsap.set(['.menu__head', '.menu__item', '.menu__foot'], {
						clearProps: 'all'
					})
					setTimeout(() => {
						gsap.set('.menu', {
							clearProps: 'all'
						});
					}, 100)
				}
			})
		},
		logout() {
			this.$service.login.logout();
			this.$api.clearToken();
			this.$router.push({ name: 'login.page' })
		},
		checkRequired() {
			this.$service.user.me.get().then(res => {
				this.me = res;
				this.$forceUpdate();
			})
		}
	},
	mounted() {
		this.$nextTick(() => {
			window.addEventListener('resize', () => {
				this.onResize();
			});
		});

		EventBus.$on('open-menu', this.openMenu);

		let items = gsap.utils.toArray('.menu__item');

		items.forEach(item => {
			item.addEventListener('click', () => {
				if(this.$el.classList.contains('openmenu')) {
					this.closeMenu();
				}
			})
		})
		setTimeout(() => {
			this.checkRequired()
		}, 1500)
		
	}
}
</script>
<style lang="scss">
.menu {
	position: fixed;
	top: 0;
	left: 0;
	display: block;
	background: $color-blue;
	@include size(100%, 100svh);
	pointer-events: all;
	&:before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		display: block;
		background: url('~@/assets/images/texture-sfumata-dark.png') repeat-x;
		@include size(100%,100%);
		z-index: 1;
		pointer-events: none;
	}
	&__head, &__list, &__foot {
		position: relative;
		z-index: 2;
	}
	&__head {
		display: block;
		width: 100%;
		padding: 0 var(--gutter);
	}
	&__list {
		display: block;
		width: 100%;
		padding: var(--gutter);
	}
	&__item {
		background: $color-white;
		@extend %flexCenterBetween;
		margin-bottom: var(--minigutter);
		padding: 0 var(--minigutter);
		position: relative;
		&__alert {
			position: absolute;
			top: 50%;
			right: var(--minigutter);
			@extend %flexCenterCenter;
			@include size(var(--smallicon),var(--smallicon));
			transform: translateY(-50%);
			svg {
				display: block;
				@include size(100%,100%);
				fill: $color-red;
			}
			@media (max-width: $to-medium) {
				right: calc(var(--smallicon) * 2);
			}
		}
		&__label {
			display: block;
			width: 70%;
		}
		&__icons {
			@extend %flexCenterEnd;
			width: 30%;
			&__arrow {
				svg {
					fill: $color-black;
					transform: scaleX(-1);
				}
			}
		}
		&__icon {
			@extend %icon;
			@include size(var(--smallicon),var(--smallicon));
			svg {
				display: block;
				@include size(50%,50%);
			}
		}
	}
	&__foot {
		position: absolute;
		bottom: 0;
		left: 0;
		display: block;
		width: 100%;
		padding: var(--gutter);
		&__item {
			border-color: $color-white !important;
			color: $color-white;
		}
	}
	&__mobile {
		@extend %flexEndEnd;
		height: var(--head);
		position: relative;
		z-index: 2;
		&__box {
			@extend %flexCenterBetween;
			width: 100%;
		}
		&__label {
			@extend %text-33;
			color: $color-white;
			font-variation-settings: 'wght' 700;
		}
		&__close {
			@extend %icon;
			@include size(var(--minigutter), var(--minigutter));
			svg {
				fill: $color-white;
			}
		}
	}
	&__desktop {
		@extend %flexEndStart;
		display: none;
		height: var(--head);
		&__logo {
			display: block;
			@include size(125px, 41px);
			svg {
				display: block;
				@include size(100%,100%);
				fill: $color-white;
			}
		}
	}
	@media (max-width: $to-large) {
		transform: translateX(100%);
	}
	@media (min-width: $from-large) {
		width: var(--menu);
		&__mobile {
			display: none;
		}
		&__desktop {
			display: flex;
		}
		.menu__item {
			justify-content: flex-start;
			&__icons {
				display: none;
			}
		}
	}
}
</style>