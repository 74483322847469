<template>
	<div class="availability">
		<div class="availability__head">
			<span class="availability__head__back" @click="closeDrawer">
				<span class="availability__head__back__icon">
					<svg viewBox="0 0 50 50">
						<use xlink:href="#icon--back" width="100%" height="100%"></use>
					</svg>
				</span>
				<span class="availability__head__back__label">
					{{ $t('common.back') }}
				</span>
			</span>
		</div>
		<div class="availability__box">
			<div class="availability__box__head">
				<span class="availability__box__head__back" @click="closeModal">
					<span class="availability__head__back__icon">
						<svg viewBox="0 0 50 50">
							<use xlink:href="#icon--back" width="100%" height="100%"></use>
						</svg>
					</span>
					<span class="availability__head__back__label">
						{{ $t('common.back') }}
					</span>
				</span>
			</div>
			<div class="availability__box__content avapre">
				<span class="avapre__title">
					Crea una nuova disponbilità
				</span>
				<span class="avapre__choice" data-type="single" @click="switchMode($event)">
					<span class="avapre__box">
						<span class="avapre__head">
							<span class="avapre__head__symbol">
								<small class="avapre__head__symbol__circle"></small>
								<small class="avapre__head__symbol__circle"></small>
								<small class="avapre__head__symbol__circle"></small>
							</span>
						</span>
						<span class="avapre__content">
							<span class="avapre__content__title">
								{{ $t('availabilities.box.single.title') }}
							</span>
							<span class="avapre__content__subtitle">
								{{ $t('availabilities.box.single.subtitle') }}
							</span>
							<span class="avapre__content__text">
								{{ $t('availabilities.box.single.text') }}
							</span>
						</span>
					</span>
				</span>
				<span class="avapre__choice" data-type="unique" @click="switchMode($event)">
					<span class="avapre__box">
						<span class="avapre__head">
							<span class="avapre__head__symbol">
								<small class="avapre__head__symbol__circle"></small>
								<small class="avapre__head__symbol__line"></small>
								<small class="avapre__head__symbol__circle"></small>
							</span>
						</span>
						<span class="avapre__content">
							<span class="avapre__content__title">
								{{ $t('availabilities.box.unique.title') }}
							</span>
							<span class="avapre__content__subtitle">
								{{ $t('availabilities.box.unique.subtitle') }}
							</span>
							<span class="avapre__content__text">
								{{ $t('availabilities.box.unique.text') }}
							</span>
						</span>
					</span>
				</span>
			</div>
		</div>
	</div>
</template>
<script>
import { EventBus } from '@/events_bus.js';
import dayjs from 'dayjs';
import { gsap } from 'gsap';
import { CustomEase } from 'gsap/all';
gsap.registerPlugin(CustomEase);

import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";

const { CalendarPanel } = DatePicker;


import Single from '@/views/components/garages/availabilities/Single';
import Unique from '@/views/components/garages/availabilities/Unique';

import Spinner from '@/views/components/common/Spinner';

export default {
	name: 'Availability',
	props: {
		mode: {
			type: String,
			required: true,
			default: 'mobile',
		},
		gid: {
			required: false,
		}
	},
	components: {
		DatePicker,
		CalendarPanel,
		Spinner,
		Single,
		Unique
	},
	data() {
		return {
			info: null,
			isEdit: false,
		}
	},
	methods: {
		openModal() {
			gsap.timeline({
				onComplete: () => {
					gsap.set('.availability', {
						pointerEvents: 'all'
					})
				}
			})
				.to('.availability', {
					duration: 0.3,
					opacity: 1
				})
				.to('.availability__box', {
					duration: 0.3,
					y: '0%',
					opacity: 1
				})
		},
		closeModal() {
			EventBus.$emit('availability-modal');
			gsap.timeline({
				onComplete: () => {
					gsap.set('.availability', {
						pointerEvents: 'none'
					})
				}
			})
			.to('.availability__box', {
				duration: 0.3,
				y: 150,
				opacity: 0
			})
			.to('.availability', {
				duration: 0.3,
				opacity: 0
			})
		},
		openDrawer() {
			gsap.to('.availability', {
				duration: 0.9,
				x: '0%',
				ease: CustomEase.create("custom", "M0,0 C0.726,0 0,1 1,1 "),
				onComplete: () => {
					gsap.set('.availability', {
						pointerEvents: 'all'
					})
				}
			})
		},
		closeDrawer() {
			EventBus.$emit('availability-modal');
			gsap.to('.availability', {
				duration: 0.9,
				x: '100%',
				ease: CustomEase.create("custom", "M0,0 C0.726,0 0,1 1,1 ")
			})
		},
		closeAvaListModal() {
			gsap.timeline({
				onComplete: () => {
					gsap.set('.availability', {
						pointerEvents: 'none'
					})
				}
			})
			.to('.availability__box', {
				duration: 0.3,
				y: 150,
				opacity: 0
			})
			.to('.availability', {
				duration: 0.3,
				opacity: 0
			})
		},
		closeAvaListDrawer() {
			gsap.to('.availability', {
				duration: 0.9,
				x: '100%',
				ease: CustomEase.create("custom", "M0,0 C0.726,0 0,1 1,1 ")
			})
		},
		switchMode(e) {
			let mode = e.target.getAttribute('data-type');
			if(this.mode == 'mobile') {
				this.closeAvaListDrawer()
			} else {
				this.closeAvaListModal();
			}
			if(mode == 'single') {
				EventBus.$emit('open-single', this.info);
			}
			if(mode == 'unique') {
				EventBus.$emit('open-unique', this.info);
			}
		}
	},
	mounted() {
		EventBus.$on('availability-form', (info) => {
			this.info = info;
			if(info && info.isedit != undefined) {
				this.isEdit = info.isedit;
			}
			if(this.mode == 'mobile') {
				this.openDrawer()
			} else {
				this.openModal();
			}
		});
	}
}
</script>
<style lang="scss">
.availability {
	--select: 100px;
	@extend %flexCenterCenter;
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	background: $color-seasalt;
	z-index: 10000;
	display: block;
	overflow: hidden;
	transform: translateX(100%);
	pointer-events: none;
	&__head {
		@extend %flexCenterStart;
		@include size(100%, var(--headDrawer));
		padding: 0 var(--gutter);
		position: relative;
		z-index: 100;
		&__back {
			@extend %flexCenterStart;
			width: calc(100% - var(--btn));
			cursor: pointer;
			&__icon {
				@extend %flexCenterCenter;
				@include size(var(--smallicon),var(--smallicon));
				svg {
					display: block;
					@include size(75%,75%);
					fill: $color-black;
				}
			}
			&__label {
				@extend %text-15;
				display: block;
				width: max-content;
				font-variation-settings: 'wght' 600;
				padding-left: var(--minigutter);
			}
		}
	}
	&__box {
		@extend %panel;
		background: transparent;
		padding: 0;
		height: calc(100svh - var(--headDrawer));
		overflow: hidden;
		position: relative;
		z-index: 100;
		&__head {
			@extend %flexCenterBetween;
			width: 100%;
			display: none;
			margin-bottom: var(--gutter);
			position: relative;
			z-index: 100;
			&__title {
				@extend %text-33;
				display: block;
				width: 100%;
				font-family: $font-manrope;
				font-variation-settings: 'wght' 700;
			}
			&__back {
				@extend %flexCenterStart;
				width: calc(100% - var(--btn));
				cursor: pointer;
				&__icon {
					@extend %flexCenterCenter;
					@include size(var(--smallicon),var(--smallicon));
					svg {
						display: block;
						@include size(75%,75%);
						fill: $color-black;
					}
				}
				&__label {
					@extend %text-15;
					display: block;
					width: max-content;
					font-variation-settings: 'wght' 600;
					padding-left: var(--minigutter);
				}
			}
		}
		&__content {
			display: block;
			@include size(100%,calc(100% - var(--headDrawer) + var(--gutter)));
			overflow: auto;
			overflow-x: hidden;
		}
	}
	&__label {
		display: block;
		width: 100%;
		&__text {
			display: block;
			width: 100%;
		}
	}
	&__button {
		width: 100%;
		text-align: center;
		margin-bottom: var(--gutter);
		cursor: pointer;
		&.filled {
			background: $color-blue;
			color: $color-white;
		}
		&.outline {
			border-color: $color-blue;
			color: $color-blue
		}
	}
	&__loading {
		position: absolute;
		top: 0;
		left: 0;
		@extend %flexCenterCenter;
		background: rgba($color-white, 0.86);
		@include size(100%,100%);
		pointer-events: all;
		z-index: 100;
		&__spinner {
			display: block;
			margin-bottom: var(--gutter);
			&__box {
				display: block;
				@include size(65px,65px);
				margin: 0 auto var(--gutter);
			}
			&__label {
				@extend %text-15;
				display: block;
				width: 100%;
				font-family: $font-manrope;
				font-variation-settings: 'wght' 600;
				text-align: center;
			}
		}
	}
	&__success {
		@extend %flexCenterCenter;
		flex-direction: column;
		text-align: center;
		&__icon {
			@extend %flexCenterCenter;
			background: $color-error;
			@include size(var(--supergutter),var(--supergutter));
			border-radius: 100%;
			margin-bottom: var(--gutter);
			overflow: hidden;
			svg {
				display: block;
				@include size(var(--smallicon),var(--smallicon));
				fill: $color-white;
			}
		}
		&__label {
			display: block;
			padding: var(--gutter) var(--supergutter);
			&__txt {
				display: block;
				width: 100%;
				@extend %text-33;
				font-variation-settings: 'wght' 700;
				color: $color-error;
				margin-bottom: var(--gutter);
			}
			&__btn {
				width: max-content;
				padding: 0 var(--supergutter);
				margin: 0 auto;
				background: $color-blue;
				color: $color-white;
			}
		}
		&.success {
			.availability__success {
				&__icon {
					background: $color-success;
				}
				&__label {
					&__txt {
						color: $color-success;
					}
				}
			}
		}
	}
	&__actions {
		@extend %flexCenterEnd;
		background: $color-white;
		width: 60%;
		&__btn {
			@extend %flexCenterCenter;
			border-radius: calc(var(--bigradius) / 2);
			border: 1px solid $color-silver;
			box-shadow: $shadow;
			padding: var(--thirdgutter) var(--minigutter);
			margin-left: var(--minigutter);
			&__icon {
				@extend %flexCenterCenter;
				@include size(var(--minigutter),var(--minigutter));
				svg {
					display: block;
					@include size(100%,100%);
					fill: $color-black;
				}
				&.close svg {
					transform: rotate(45deg);
				}
			}
			&__label {
				margin-left: var(--thirdgutter);
				display: none;
			}
		}
	}
	&__save {
		display: block;
		width: 100%;
		padding: var(--gutter) 0;
		&__btn {
			display: block;
			background: $color-blue;
			color: $color-white;
			font-variation-settings: 'wght' 700;
			margin: 0 auto;
		}
	}
	@media (min-width: $from-large) {
		display: flex;
		background: rgba($color-black, 0.1);
		transform: translateX(0%);
		opacity: 0;
		&__head {
			display: none;
		}
		&__box {
			background: $color-white;
			// height: 75vh;
			height: auto;
			max-width: 780px;
			width: 100%;
			// height: calc(100% - var(--headDrawer));
			padding: var(--gutter);
			transform: translateY(150px);
			opacity: 0;
			&__head {
				display: flex;
				margin-bottom: var(--thirdgutter);
			}
			&__text {
				font-size: 0.938rem;
			}
		}
		&__button {
			margin-bottom: 0;
		}
		&__actions {
			&__btn {
				&__label {
					display: block;
				}
			}
		}
		&__save {
			display: none;
		}
		&__success {
			&__label {
				margin: 0 auto;
				max-width: 75%;
			}
		}
	}
}

.avapre {
	display: flex;
	flex-direction: column;
	@include size(100%,100%);
	padding: var(--gutter);
	&__choice {
		@extend %panel;
		background: $color-white;
		width: 100%;
		padding: var(--minigutter);
		position: relative;
		cursor: pointer;
		&:last-child {
			margin-top: var(--gutter);
		}
		&__input {
			display: none;
		}
	}
	&__box {
		display: block;
		width: 100%;
		pointer-events: none
	}
	&__title {
		display: block;
		width: 100%;
		@extend %text-25;
		font-family: $font-manrope;
		font-variation-settings: 'wght' 700;
		margin-bottom: var(--gutter);
	}
	&__head {
		@extend %flexCenterStart;
		width: 100%;
		margin-bottom: var(--gutter);
		&__symbol {
			@extend %flexCenterStart;
			width: 100%;
			&__circle {
				display: block;
				@include size(12px, 12px);
				border-radius: 100%;
				border: 3px solid $color-blue;
			}
			&__line {
				display: block;
				@include size(12px,3px);
				background: $color-blue;
			}
			small {
				&:nth-child(2) {
					margin: 0 2px;
				}
			}
		}
	}
	&__content {
		display: block;
		width: 100%;
		&__title, &__subtitle,
		&__text {
			display: block;
			width: 100%;
		}
		&__title {
			@extend %text-20;
			font-family: $font-manrope;
			font-variation-settings: 'wght' 700;
			color: $color-blue;
			margin-bottom: var(--thirdgutter);
		}
		&__subtitle {
			@extend %text-15;
			font-family: $font-manrope;
			font-variation-settings: 'wght' 700;
			margin-bottom: var(--thirdgutter);
		}
		&__text {
			@extend %text-14;
			color: $color-silver;
		}
	}
	@media (min-width: $from-medium) {
		flex-direction: row;
		flex-wrap: wrap;
		&__title {
			margin-bottom: var(--supergutter);
		}
		&__choice {
			float: left;
			background: $color-seasalt;
			width: 46%;
			margin: 0 2% 0 0;
			&:last-child {
				float: right;
				margin-top: 0;
				margin-left: 2%;
			}
		}
	}
}
.avabox {
	position: absolute;
	top: 0;
	left: 0;
	display: block;
	@include size(100%,100%);
	padding: var(--headDrawer) 0 var(--gutter);
	transform: translateX(100%);
	overflow: hidden;
	z-index: 0;
	&__block {
		display: block;
		width: 96vw;
		height: var(--input);
		margin: 0 2vw;
		overflow: hidden;
		transition: 450ms linear;
		border-radius: var(--radius);
		&.current {
			padding-top: var(--minigutter);
			height: calc(100% - var(--input));
			box-shadow: 0 0 20px rgba($color-silver, 0.5);
		}
	}
	&__trigger {
		@extend %flexCenterStart;
		@include size(100%, var(--input));
		padding: 0 var(--gutter);
		cursor: pointer;
		& * {
			pointer-events: none;
		}
		&__symbol {
			@extend %flexCenterStart;
			width: max-content;
			&__circle {
				display: block;
				@include size(12px, 12px);
				border-radius: 100%;
				border: 3px solid $color-blue;
			}
			&__line {
				display: block;
				@include size(12px,3px);
				background: $color-blue;
			}
			small {
				&:nth-child(2) {
					margin: 0 2px;
				}
			}
		}
		&__label {
			display: block;
			width: max-content;
			@extend %text-15;
			font-family: $font-manrope;
			font-variation-settings: 'wght' 700;
			color: $color-blue;
			padding-left: var(--thirdgutter);
		}
	}
	&__content {
		display: block;
		width: 100%;
		height: calc(100% - var(--input));
		padding: 0 var(--gutter);
		overflow: auto;
		transition: 450ms linear;
		&__box {
			display: block;
			width: 100%;
		}
		&.open {
			overflow: auto;
			padding: var(--gutter) calc(var(--gutter) * 1.2);
			position: relative;
		}
	}
	@media (min-width: $from-medium) {
		height: calc(100% - var(--gutter));
		overflow: auto;
		&__block {
			width: 56%;
			margin: 0 2%;
		}
		&__content {
			height: 100%;
			&__box {
				padding-bottom: var(--gutter);
			}
		}
	}
}
</style>