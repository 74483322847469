<template>
	<div v-if="tot" class="totpanel">
		<span class="totpanel__label" v-html="tot.label"></span>
		<span class="totpanel__value">
			<template v-if="isLoading">
				<span class="totpanel__value__loading">
					<Spinner />
				</span>
			</template>
			<template v-else>
				<span v-html="tot.value"></span>
			</template>
		</span>
	</div>
</template>
<script>
import Spinner from '@/views/components/common/Spinner';

export default {
	name: 'Totals',
	props: {
		tot: {
			type: Object,
			required: true,
			default: null
		},
		isLoading: {
			type: Boolean,
			required: false,
			default: false,
		}
	},
	components: {
		Spinner
	}
}
</script>
<style lang="scss">
.totpanel {
	@extend %panel;
	@extend .bigradius;
	@extend %flexBetweenStart;
	flex-direction: column;
	@include size(48%,190px);
	font-family: $font-manrope;
	padding: var(--minigutter);
	margin-bottom: var(--gutter);
	&__label {
		@extend %text-20;
		font-variation-settings: 'wght' 700;
	}
	&__value {
		@extend %text-25;
		width: 100%;
		font-variation-settings: 'wght' 700;
		color: $color-blue;
		text-align: right;
		&__loading {
			float: right;
			display: block;
			@include size(30px,30px);
			.spinner {
				@include size(30px,30px);
			}
		}
	}

	@media (min-width: $from-large) {
		flex-direction: row;
		align-items: center;
		width: 32.5%;
		height: auto;
		padding: calc(var(--thirdgutter) * 2);
		&__label {
			font-size: 0.938rem;
			font-variation-settings: 'wght' 400;
		}
	}
}
</style>