<template>
	<div id="app">
		<Icons />
		<router-view/>
		<div v-if="notslist && notslist.length" class="notice__snackbars">
			<NoticeBar v-for="not in notslist" :key="not.id" :notice="not" />
		</div>
		<NoNotificationModal />
	</div>
</template>
<script>
import { EventBus } from '@/events_bus.js';
import { onMessage } from "firebase/messaging";

import Icons from '@/views/layouts/utils/Icons';
import NoticeBar from '@/views/components/notifications/NoticeBar';
import NoNotificationModal from '@/views/components/notifications/NoNotificationModal';

export default {
	name: 'App',
	components: {
		Icons,
		NoticeBar,
		NoNotificationModal,
	},
	data() {
		return {
			windowWidth: 0,
			not: null,
			notslist: [],
			me: null,
			cf: false,
			phone: false,
			dob: false
		}
	},
	methods: {
		checkParams() {
			let params = window.location.search;
			let value = new URLSearchParams(params);
			if(value.get('access_token')) {

				this.$service.login.googleLogin(value.get('access_token')).then(res => {
					console.log('Login effettuato.');
				}).catch(err => {
					console.error('ERR', err);
				})
			}
		},
		onResize() {
			let mode = localStorage.getItem('mode');
			this.windowWidth = window.innerWidth;
			if(this.windowWidth < 1200) {
				localStorage.setItem('mode', 'mobile');
				if(mode != 'mobile') {
					window.location.reload();
				}
			} else {
				localStorage.setItem('mode', 'desktop');
				if(mode != 'desktop') {
					window.location.reload();
				}
			}
		},
		init() {
			this.$api.refreshToken().then(res => {
				console.log('Refresh Token');
			}).catch(err => {
				console.error('ERR', err);
				if(localStorage.getItem('tokentimer')) {
					this.$router.push({ name: 'login.page' })
				}
			})
		},
		initFirebase() {
			console.log('INIT FIREBASE')
			this.notifiche = [];
			let timer = setInterval(() => {
				let fbtoken = localStorage.getItem('firebase_token');
				if(fbtoken && !this.firebase) {
					this.firebase = true;
					clearInterval(timer);
					this.$service.notifications.fcm(fbtoken).then(res => {
						onMessage(this.$messaging, (payload) => {
							if(payload && payload.notification) {
								this.notslist.push(payload.notification);
							}
							EventBus.$emit('new-notification');
						});
					})
				}
			}, 10)
		},
		removeFromSnacks(arr, id) {
			const objWithIdIndex = arr.findIndex((obj) => obj.id === id);
			if(objWithIdIndex > -1) {
				arr.splice(objWithIdIndex, 1);
			}
			return arr;
		}
	},
	mounted() {
		this.init();
		this.onResize();
		this.$nextTick(() => {
			window.addEventListener('resize', () => {
				this.onResize();
			});
		});


		this.checkParams();
		if(localStorage.getItem('token')) {
			EventBus.$on('ask-notification', () => {
				this.initFirebase();
			})
		}

		EventBus.$on('close-snack', (id) => {
			this.removeFromSnacks(this.notslist, id);
		})

	}
}
</script>
<style lang="scss">
#app {
	display: block;
	background: $color-antiflash;
	width: 100%;
	min-height: 100svh;
	.notice__snackbars {
		position: fixed;
		top: calc(var(--supergutter) * 1.4);
		right: var(--minigutter);
		display: block;
		width: 90vw;
		max-width: 400px;
		z-index: 1000000;
		padding: 0 var(--minigutter);
		pointer-events: none;
	}
}
</style>
