<template>
	<div class="facebook" @click="logInWithFacebook">
		Accedi con Facebook
	</div>
</template>
<script>
export default {
	name: "FacebookLogin",
	methods: {
		logInWithFacebook() {
			let _self = this;
			this.loadFacebookSDK(document, "script", "facebook-jssdk");
			this.initFacebook().then(() => {
				window.FB.login(function (response) {
					if (response.authResponse && response.authResponse.accessToken) {
						_self.$service.login.facebook(response.authResponse.accessToken).then(res => {
							_self.$api.setToken(res.access_token, res.expires_in);
							window.location.href = '/#/app/';
						})
					} else {
						alert("User cancelled login or did not fully authorize.");
					}
				}, {scope: 'email'});
			});
		},
		initFacebook() {
			return new Promise(resolve => {
				window.fbAsyncInit = function () {
					window.FB.init({
						appId: "166893699695308", //You will need to change this
						cookie: true, // This is important, it's not enabled by default
						version: "v13.0",
					});
					setTimeout(() => {
						resolve();
					}, 15);
				};
			})
		},
		loadFacebookSDK(d, s, id) {
			var js,
				fjs = d.getElementsByTagName(s)[0];
			if (d.getElementById(id)) {
				return;
			}
			js = d.createElement(s);
			js.id = id;
			js.src = "https://connect.facebook.net/en_US/sdk.js";
			fjs.parentNode.insertBefore(js, fjs);
		},
	},
};
</script>
<style lang="scss">
.facebook {
	@extend %btn;
	width: 100%;
	color: $color-white;
	margin-bottom: calc(var(--gutter) * 0.66);
	background: $color-fb;
}
</style>
