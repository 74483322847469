<template>
	<div v-if="notice" class="noticebar">
		<span class="noticebar__content">
			<span class="noticebar__title" v-html="notice.title"></span>
			<span class="noticebar__text" v-html="notice.text"></span>
		</span>
		<span class="noticebar__close" @click="closeNotification">
			<svg viewBox="0 0 50 50">
				<use xlink:href="#icon--close" width="100%" height="100%"></use>
			</svg>
		</span>
	</div>
</template>
<script>
import { EventBus } from '@/events_bus.js';
import { gsap } from 'gsap';

export default {
	name: 'NoticeBar',
	props: {
		notice: {
			type: Object,
			required: true,
		}
	},
	data() {
		return {

		}
	},
	methods: {
		init() {
			gsap.timeline()
				.from(this.$el, {
					duration: 0.4,
					opacity: 0,
					y: '-50px'
				})
				.to(this.$el, {
					duration: 0.3,
					opacity: 0,
					y: '-50px',
					onStart: () => {
						EventBus.$emit('close-snack', this.notice.id);
					}
				}, '+=5')
		},
		closeNotification() {
			gsap.to(this.$el, {
				duration: 0.3,
				opacity: 0,
				y: '50px',
				onComplete: () => {
					EventBus.$emit('close-snack', this.notice.id);
				}
			})
		}
	},
	mounted() {
		this.init();
	}
}
</script>
<style lang="scss">
.noticebar {
	@extend %flexCenterBetween;
	background: $color-white;
	width: 100%;
	padding: var(--thirdgutter);
	margin-bottom: 5px;
	pointer-events: all;
	position: relative;
	box-shadow: 0 0 10px rgba($color-davy, 0.2);
	&__close {
		@extend %flexCenterCenter;
		@include size(calc(var(--input) - var(--minigutter)), var(--input));
		cursor: pointer;
		svg {
			display: block;
			@include size(var(--thirdgutter),var(--thirdgutter));
			fill: $color-davy;
		}
	}
	&__content {
		display: block;
		width: calc(100% - (var(--input) - var(--minigutter)));
		padding: var(--thirdgutter) 0;
	}
	&__title,
	&__text {
		display: block;
		width: 100%;
	}
	&__title {
		@extend %text-15;
		font-variation-settings: 'wght' 700;
	}
	&__text {
		@extend %text-13;
	}
}
</style>