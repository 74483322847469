<template>
	<main id="page" class="getpaid" ref="page">
		<div class="mobile__head">
			<span class="mobile__head__back" @click="closeDrawer">
				<span class="mobile__head__back__icon">
					<svg viewBox="0 0 50 50">
						<use xlink:href="#icon--back" width="100%" height="100%"></use>
					</svg>
				</span>
				<span class="mobile__head__back__label">
					{{ $t('common.back') }}
				</span>
			</span>
		</div>
		<div class="getpaid__head">
			<div class="content">
				<div class="getpaid__head__title">
					{{ $t('common.titles.getpaid') }}
				</div>
			</div>
		</div>
		<template v-if="pageLoading">
			<Loading :isLoading="pageLoading" />
		</template>
		<template v-else>
			<div class="getpaid__content">
				<div class="smallcontent">
					<div class="getpaid__box">
						<template v-if="stripeConnected">
							<span class="getpaid__box__text" v-html="$t('getpaid.connected')"></span>
							<span class="getpaid__box__icon">
								<svg viewBox="0 0 50 50">
									<use xlink:href="#icon--check" width="100%" height="100%"></use>
								</svg>
							</span>
							<a href="https://connect.stripe.com/login" target="_blank" class="getpaid__box__btn btn filled">
								{{ $t('getpaid.btns.connected') }}
							</a>
						</template>
						<template v-else>
							<span class="getpaid__box__text" v-html="$t('getpaid.text')"></span>
							<template v-if="!showbtn">
								<span class="getpaid__box__loading">
									<Spinner />
								</span>
							</template>
							<template v-else>
								<a :href="url" target="_blank" class="getpaid__box__btn btn filled">
									{{ $t('getpaid.btns.not_connected') }}
								</a>
							</template>
						</template>
					</div>
				</div>
			</div>
		</template>
	</main>
</template>
<script>
import { EventBus } from '@/events_bus.js';
import { gsap } from 'gsap';
import { CustomEase } from 'gsap/all';
gsap.registerPlugin(CustomEase);

import Loading from '@/views/components/common/Loading';
import Spinner from '@/views/components/common/Spinner';

export default {
	name: 'GetPaid',
	data() {
		return {
			nogetpaid: true,
			getpaid: null,
			showbtn: false,
			stripeConnected: false,
			pageLoading: true,
		}
	},
	components: {
		Loading,
		Spinner
	},
	methods: {
		closeDrawer() {
			this.$router.go(-1);
		},
		connectStripe() {
		},
		init() {
			this.$service.user.me.get().then(res => {
				this.pageLoading = false;
				if(res.stripe_verified_at) {
					this.stripeConnected = true;
				} else {
					this.$service.stripe.connect().then(res => {
						this.showbtn = true;
						this.url = res.url;
					})
				}
			})
		}
	},
	mounted() {
		this.loading = true;
		this.init();
	}
}
</script>
<style lang="scss">
.getpaid {
	padding-top: var(--head);
	.mobile__head {
		@extend %flexCenterBetween;
		@include size(100%, var(--head));
		padding: 0 var(--gutter);
		position: relative;
		&__back {
			@extend %flexCenterStart;
			width: calc(100% - var(--btn));
			cursor: pointer;
			&__icon {
				@extend %flexCenterCenter;
				@include size(var(--smallicon),var(--smallicon));
				svg {
					display: block;
					@include size(75%,75%);
					fill: $color-black;
				}
			}
			&__label {
				@extend %text-15;
				display: block;
				width: max-content;
				font-variation-settings: 'wght' 600;
				padding-left: var(--minigutter);
			}
		}
	}
	&__box {
		@extend %panel;
		display: block;
		width: 100%;
		padding: var(--gutter);
		&__title,
		&__text {
			display: block;
			width: 100%;
			margin-bottom: var(--gutter);
			text-align: center;
		}
		&__icon {
			@extend %flexCenterCenter;
			@include size(var(--icon),var(--icon));
			border: 3px solid $color-success;
			border-radius: 100%;
			margin: 0 auto;
			svg {
				display: block;
				@include size(60%,60%);
				fill: $color-success;
			}
		}
		&__btn {
			padding: 0 var(--gutter);
			background: $color-blue;
			width: max-content;
			color: $color-white;
			margin: var(--gutter) auto;
		}
		&__loading {
			@extend %flexCenterCenter;
			@include size(var(--supergutter),var(--supergutter));
			margin: 0 auto;
			.spinner {
				@include size(80%,80%);
			}
		}
	}
	&__empty {
		@extend %flexCenterCenter;
		flex-direction: column;
		@include size(330px,100%);
		margin: 0 auto;
		margin-top: var(--supergutter);
	}
	&__content {
		display: block;
		width: 100%;
		margin: var(--supergutter) 0;
		@media (min-width: $from-large) {
			.small-panel {
				height: var(--smallpanelh);
			}
		}
	}
	@media (min-width: $from-large) {
		.mobile__head {
			display: none;
		}
		&__box {
			padding: var(--supergutter) var(--supergutter) var(--gutter);
		}
	}
	@media (max-width: $to-large) {
		position: fixed;
		top: 0;
		left: 0;
		display: block;
		background: $color-white !important;
		padding-top: 0;
		z-index: 100000;
		&__head {
			display: none;
		}
		&__box {
			border-radius: 0;
			box-shadow: none;
			padding: 0;
		}
		&__content {
			margin-top: 0;
		}
	}
}
</style>