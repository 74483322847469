<template>
	<div class="forgotpassword">
		<div class="forgotpassword__head">
			<span class="forgotpassword__head__back" @click="closeDrawer">
				<span class="forgotpassword__head__back__icon">
					<svg viewBox="0 0 50 50">
						<use xlink:href="#icon--back" width="100%" height="100%"></use>
					</svg>
				</span>
				<span class="forgotpassword__head__back__label">
					{{ $t('common.back') }}
				</span>
			</span>
		</div>
		<div class="forgotpassword__box">
			<div class="forgotpassword__box__head">
				<div class="forgotpassword__box__head__title">
					{{ $t('login.forgot.title') }}
				</div>
				<span class="forgotpassword__box__head__close" @click="closeModal">
					<svg viewBox="0 0 50 50">
						<use xlink:href="#icon--close" width="100%" height="100%"></use>
					</svg>
				</span>
			</div>
			<div v-if="isLoading" class="forgotpassword__loading">
				<template v-if="!editSaved">
					<span class="forgotpassword__loading__spinner">
						<span class="forgotpassword__loading__spinner__box">
							<Spinner />
						</span>
						<span class="forgotpassword__loading__spinner__label">
							{{ $t('common.is_saving') }}
						</span>
					</span>
				</template>
				<template v-else>
					<span class="forgotpassword__loading__success">
						{{ $t('common.edit_saved') }}
					</span>
				</template>
			</div>
			<div class="forgotpassword__content">
				<div class="forgot__text" v-html="$t('login.forgot.text')"></div>
				<form id="forgot" class="form forgotform">
					<div class="form__field">
						<label for="forgotmail" class="form__label forgotform__label">
							{{ $t('login.forgot.email.label') }}
							<input type="email" name="forgotmail" id="forgotmail" class="form__input forgotform__email" :placeholder="$t('login.forgot.email.placeholder')" v-model="form.mail" />
						</label>
						<small class="forgotform__error"></small>
					</div>
					<div class="form__field">
						<button id="sendmail" class="forgotform__button btn filled" type="button" @click="checkMail">
							{{ $t('login.forgot.email.btn') }}
						</button>
						<small class="sendresult" :class="status" v-html="message"></small>
					</div>
				</form>
			</div>
		</div>
	</div>
</template>
<script>
import { EventBus } from '@/events_bus.js';
import { gsap } from 'gsap';
import { CustomEase } from 'gsap/all';
gsap.registerPlugin(CustomEase);

import Spinner from '@/views/components/common/Spinner';

export default {
	name: 'ForgotPassword',
	props: {
		mode: {
			type: String,
			required: true,
			default: 'mobile'
		}
	},
	components: {
		Spinner
	},
	data() {
		return {
			form: {
				email: '',
			},
			isLoading: false,
			editSaved: false,
			error: null,
			message: null,
			status: null,
		}
	},
	methods: {
		openModal() {
			gsap.timeline({
				onComplete: () => {
					gsap.set('.forgotpassword', {
						pointerEvents: 'all'
					})
				}
			})
				.to('.forgotpassword', {
					duration: 0.3,
					opacity: 1
				})
				.to('.forgotpassword__box', {
					duration: 0.3,
					y: '0%',
					opacity: 1
				})
		},
		closeModal() {
			gsap.timeline({
				onComplete: () => {
					gsap.set('.forgotpassword', {
						pointerEvents: 'none'
					})
				}
			})
			.to('.forgotpassword__box', {
				duration: 0.3,
				y: 150,
				opacity: 0
			})
			.to('.forgotpassword', {
				duration: 0.3,
				opacity: 0
			})
		},
		openDrawer() {
			gsap.to('.forgotpassword', {
				duration: 0.9,
				x: '0%',
				ease: CustomEase.create("custom", "M0,0 C0.726,0 0,1 1,1 "),
				onComplete: () => {
					gsap.set('.forgotpassword', {
						pointerEvents: 'all'
					})
				}
			})
		},
		closeDrawer() {
			gsap.to('.forgotpassword', {
				duration: 0.9,
				x: '100%',
				ease: CustomEase.create("custom", "M0,0 C0.726,0 0,1 1,1 ")
			})
		},
		checkMail() {
			this.$service.login.checkMail(this.form.mail).then(res => {
				if(res && res.status) {
					this.status = res.status;
					if(this.status == 'ok') {
						this.$service.login.forgotPassword(this.form.mail).then(data => {
							this.message = this.$t(`login.forgot.response.${res.status}`);
						}).catch(err => {
							console.log('E', err)
						})
					}
				}
			}).catch(err => {
				console.log('ERR', err)
				if(err && err.status) {
					this.status = err.status;
					console.log('STATUS', err.status)
					this.message = this.$t(`login.forgot.response.${err.status}`);
				}
			})
		},
		saveEdit() {
			this.isLoading = true;
		},
		init() {
			this.form.email = '';
			this.error = null;
			this.message = null;
			this.status = null;
		}
	},
	mounted() {
		EventBus.$on('show-forgot', () => {
			this.init();
			if(this.mode == 'mobile') {
				this.openDrawer()
			} else {
				this.openModal();
			}
		});
	}
}
</script>
<style lang="scss">
.forgotpassword {
	--select: 100px;
	@extend %flexCenterCenter;
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	background: $color-seasalt;
	z-index: 10000;
	display: block;
	overflow: auto;
	transform: translateX(100%);
	pointer-events: none;
	&__head {
		@extend %flexCenterStart;
		@include size(100%, var(--headDrawer));
		padding: 0 var(--gutter);
		&__back {
			@extend %flexCenterStart;
			width: calc(100% - var(--btn));
			cursor: pointer;
			&__icon {
				@extend %flexCenterCenter;
				@include size(var(--smallicon),var(--smallicon));
				svg {
					display: block;
					@include size(75%,75%);
					fill: $color-black;
				}
			}
			&__label {
				@extend %text-15;
				display: block;
				width: max-content;
				font-variation-settings: 'wght' 600;
				padding-left: var(--minigutter);
			}
		}
	}
	&__box {
		@extend  %panel;
		background: transparent;
		padding: 0 var(--gutter);
		height: auto;
		overflow: auto;
		&__head {
			@extend %flexCenterBetween;
			width: 100%;
			display: none;
			margin-bottom: var(--gutter);
			&__title {
				@extend %text-33;
				display: block;
				width: 100%;
				font-family: $font-manrope;
				font-variation-settings: 'wght' 700;
			}
			&__close {
				@extend %icon;
				cursor: pointer;
				svg {
					@include size(70%,70%);
					fill: $color-black;
				}
			}
		}
		&__text {
			@extend %text-19;
			display: block;
			width: 100%;
		}
	}
	&__label {
		display: block;
		width: 100%;
		&__text {
			display: block;
			width: 100%;
		}
	}
	&__button {
		width: 100%;
		text-align: center;
		margin-bottom: var(--gutter);
		&.filled {
			background: $color-blue;
			color: $color-white;
		}
		&.outline {
			border-color: $color-blue;
			color: $color-blue
		}
	}
	&__loading {
		position: absolute;
		top: 0;
		left: 0;
		@extend %flexCenterCenter;
		background: rgba($color-white, 0.86);
		@include size(100%,100%);
		pointer-events: all;
		z-index: 100;
		&__spinner {
			display: block;
			margin-bottom: var(--gutter);
			&__box {
				display: block;
				@include size(65px,65px);
				margin: 0 auto var(--gutter);
			}
			&__label {
				@extend %text-15;
				display: block;
				width: 100%;
				font-family: $font-manrope;
				font-variation-settings: 'wght' 600;
				text-align: center;
			}
		}
		&__success {
			display: block;
			@extend %text-33;
			color: $color-green;
			font-variation-settings: 'wght' 700;
			text-align: center;
		}
	}
	&__content {
		display: block;
		width: 100%;
		padding: var(--gutter) 0 calc(var(--gutter) * 3);
	}
	.forgotform {
		display: block;
		width: 100%;
		padding: 0 var(--minigutter);
		.form__field {
			height: auto !important;
			.sendresult {
				display: block;
				width: 100%;
				font-variation-settings: 'wght' 700;
				margin-top: var(--thirdgutter);
				@extend %text-15;
				&.ok {
					color: $color-success;

				}
			}
		}
		&__label {
			display: flex;
			flex-direction: column;
			width: 100%;
			line-height: 1.5;
			font-variation-settings: 'wght' 600;
			margin: var(--supergutter) 0 0;
		}
		&__button {
			width: 100%;
			text-align: center;
			&.filled {
				background: $color-blue;
				color: $color-white;
			}
			&.outline {
				border-color: $color-blue;
				color: $color-blue
			}
		}
	}
	@media (min-width: $from-large) {
		display: flex;
		background: rgba($color-black, 0.1);
		transform: translateX(0%);
		opacity: 0;
		&__head {
			display: none;
		}
		&__box {
			background: $color-white;
			max-height: 90vh;
			max-width: 780px;
			padding: var(--gutter);
			transform: translateY(150px);
			opacity: 0;
			&__head {
				display: flex;
				margin-bottom: var(--thirdgutter);
			}
			&__text {
				font-size: 0.938rem;
			}
		}
		&__button {
			margin-bottom: 0;
		}
	}
}
</style>
