<template>
	<div class="accountedit">
		<div class="accountedit__head">
			<span class="accountedit__head__back" @click="closeDrawer">
				<span class="accountedit__head__back__icon">
					<svg viewBox="0 0 50 50">
						<use xlink:href="#icon--back" width="100%" height="100%"></use>
					</svg>
				</span>
				<span class="accountedit__head__back__label">
					{{ $t('common.back') }}
				</span>
			</span>
		</div>
		<div class="accountedit__box">
			<div class="accountedit__box__head">
				<div class="accountedit__box__head__title">
					{{ $t('account.edit.title') }}
				</div>
				<span class="accountedit__box__head__close" @click="closeModal">
					<svg viewBox="0 0 50 50">
						<use xlink:href="#icon--close" width="100%" height="100%"></use>
					</svg>
				</span>
			</div>
			<div v-if="isLoading" class="accountedit__loading">
				<template v-if="!editSaved">
					<span class="accountedit__loading__spinner">
						<span class="accountedit__loading__spinner__box">
							<Spinner />
						</span>
						<span class="accountedit__loading__spinner__label">
							{{ $t('common.is_saving') }}
						</span>
					</span>
				</template>
				<template v-else>
					<span class="accountedit__loading__success">
						{{ $t('common.edit_saved') }}
					</span>
				</template>
			</div>
			<form id="accountedit" class="accountform">
				<div class="form__field">
					<label for="firstname" class="accountedit__label">
						{{ $t('account.edit.firstname.label') }}
					</label>
					<input type="firstname" name="firstname" id="firstname" class="form__input" :placeholder="$t('account.edit.firstname.placeholder')" v-model="form.name" />
					<small v-if="errors && errors.name" class="form__error" v-html="errors.name"></small>
				</div>
				<div class="form__field">
					<label for="lastname" class="accountedit__label">
						{{ $t('account.edit.lastname.label') }}
					</label>
					<input type="lastname" name="lastname" id="lastname" class="form__input" :placeholder="$t('account.edit.lastname.placeholder')" v-model="form.surname" />
					<small v-if="errors && errors.surname" class="form__error" v-html="errors.surname"></small>
				</div>
				<div class="form__field email">
					<label for="email" class="accountedit__label">
						{{ $t('account.edit.email.label') }}
					</label>
					<input type="email" name="email" id="email" class="form__input" :value="form.email" readonly />
					<small v-if="errors && errors.email" class="form__error" v-html="errors.email"></small>
				</div>
				<div class="form__field phone">
					<label for="phone" class="accountedit__label">
						{{ $t('account.edit.phone.label') }}
					</label>
					<input type="phone" name="phone" id="phone" class="form__input" :placeholder="$t('account.edit.phone.placeholder')" v-model="form.phone" />
					<small v-if="errors && errors.phone" class="form__error" v-html="errors.phone"></small>
				</div>
				<div class="form__field dob">
					<label for="dob" class="accountedit__label">
						{{ $t('account.edit.dob.label') }}
					</label>
					<date-picker v-model="form.birthday" valueType="timestamp" format="DD/MM/YYYY" :disabled-date="(date) => date > new Date()">
						<template v-slot:icon-calendar>
							<svg viewBox="0 0 50 50" width="300" height="300">
								<use xlink:href="#icon--form-calendar" width="100%" height="100%"></use>
							</svg>
						</template>
					</date-picker>
					<small v-if="errors && errors.birthday" class="form__error" v-html="errors.birthday"></small>
				</div>
				<div class="form__field">
					<label for="fiscal_code" class="accountedit__label">
						{{ $t('account.edit.fiscal_code.label') }}
					</label>
					<input type="fiscal_code" name="fiscal_code" id="fiscal_code" class="form__input" :placeholder="$t('account.edit.fiscal_code.placeholder')" v-model="form.fiscal_code" maxlength="16" />
					<small v-if="errors && errors.fiscal_code" class="form__error" v-html="errors.fiscal_code"></small>
				</div>
				<div class="form__field">
					<label for="address" class="accountedit__label">
						{{ $t('account.edit.address.label') }}
					</label>
					<input type="address" name="address" id="address" class="form__input" :placeholder="$t('account.edit.address.placeholder')" v-model="form.address" />
					<small class="form__error"></small>
				</div>
				<div class="form__field half">
					<label for="city" class="accountedit__label">
						{{ $t('account.edit.city.label') }}
					</label>
					<input type="city" name="city" id="city" class="form__input" :placeholder="$t('account.edit.city.placeholder')" v-model="form.city" />
					<small class="form__error"></small>
				</div>
				<div class="form__field half">
					<label for="zip" class="accountedit__label">
						{{ $t('account.edit.zip.label') }}
					</label>
					<input type="zip" name="zip" id="zip" class="form__input" :placeholder="$t('account.edit.zip.placeholder')" v-model="form.zip" />
					<small class="form__error"></small>
				</div>
				<div class="form__field province">
					<label for="province" class="accountedit__label">
						{{ $t('account.edit.province.label') }}
					</label>
					<input type="province" name="province" id="province" class="form__input" :placeholder="$t('account.edit.province.placeholder')" v-model="form.province" />
					<small class="form__error"></small>
				</div>
				<div class="form__field actions">
					<span id="cancel" class="accountedit__button btn" @click="closeModal">
						{{ $t('common.btns.cancel') }}
					</span>
					<span id="save" class="accountedit__button btn filled" @click="saveEdit">
						{{ $t('common.btns.save') }}
					</span>
				</div>
			</form>
		</div>
	</div>
</template>
<script>
import { EventBus } from '@/events_bus.js';
import dayjs from 'dayjs';
import { gsap } from 'gsap';
import { CustomEase } from 'gsap/all';
gsap.registerPlugin(CustomEase);

import DatePicker from 'vue2-datepicker';

import Spinner from '@/views/components/common/Spinner';

export default {
	name: 'Edit',
	props: {
		me: {
			type: Object,
			required: true,
			default: {}
		},
		mode: {
			type: String,
			required: true,
			default: 'mobile'
		}
	},
	components: {
		DatePicker,
		Spinner
	},
	data() {
		return {
			form: {
				address: null,
				avatar: null,
				birthday: null,
				description: null,
				email: null,
				name: null,
				newsletter: null,
				phone: null,
				surname: null,
				fiscal_code: null,
				city: null,
				zip: null,
				province: null
			},
			isLoading: false,
			editSaved: false,
			errors: {
				birthday: null,
				email: null,
				name: null,
				phone: null,
				surname: null
			}
		}
	},
	methods: {
		openModal() {
			gsap.timeline({
				onComplete: () => {
					gsap.set('.accountedit', {
						pointerEvents: 'all'
					})
				}
			})
				.to('.accountedit', {
					duration: 0.3,
					opacity: 1
				})
				.to('.accountedit__box', {
					duration: 0.3,
					y: '0%',
					opacity: 1
				})
		},
		closeModal() {
			gsap.timeline({
				onComplete: () => {
					gsap.set('.accountedit', {
						pointerEvents: 'none'
					})
				}
			})
			.to('.accountedit__box', {
				duration: 0.3,
				y: 150,
				opacity: 0
			})
			.to('.accountedit', {
				duration: 0.3,
				opacity: 0
			})
		},
		openDrawer() {
			gsap.to('.accountedit', {
				duration: 0.9,
				x: '0%',
				ease: CustomEase.create("custom", "M0,0 C0.726,0 0,1 1,1 "),
				onComplete: () => {
					gsap.set('.accountedit', {
						pointerEvents: 'all'
					})
				}
			})
		},
		closeDrawer() {
			gsap.to('.accountedit', {
				duration: 0.9,
				x: '100%',
				ease: CustomEase.create("custom", "M0,0 C0.726,0 0,1 1,1 ")
			})
		},
		saveEdit() {
			this.isLoading = true;

			this.errors = null;
			this.msg = '';
			this.formResult = '';

			// check
			["name", "surname", "email", "fiscal_code", "phone", "birthday"].map(checkField => {
				if (this.form && !(this.form[checkField] && this.form[checkField].toString().trim().length)) {
					this.errors = (this.errors == null) ? {} : this.errors;
					// this.errors[checkField] = true;
					if(!this.errors[checkField] || this.errors[checkField] == '') {
						this.errors[checkField] = this.$t(`account.edit.errors.${checkField}`);
					}
				}
			})
			
			
			
			if (this.errors === null) {
				this.form.birthday = dayjs(this.form.birthday).format('YYYY-MM-DDTHH:mm:ss');
				
				if(!this.form['phone'].includes('+39')) {
					this.form['phone'] = '+39' + this.form['phone'];
				}
				this.$service.user.me.update(this.form).then(res => {
					this.editSaved = true;
					setTimeout(() => {
						this.isLoading = false;
						this.editSaved = false;
						if(this.mode == 'mobile') {
							this.closeDrawer()
						} else {
							this.closeModal();
						}
						EventBus.$emit('edit-saved');
					}, 3000);
				}).catch(err => {
					console.error('ERRs', err);
				})
			} else {
				// TODO: deve tornare su all'errore
				console.log('E', this.errors);
				this.formResult = 'error';
				this.msg = this.$t('form.formerror')
				this.isLoading = false;
				this.editSaved = false;
			}
		},
		init() {
			let me = this.me;
			if(me) {
				this.form = {
					address: me.address ? me.address : null,
					avatar: me.avatar ? me.avatar : null,
					birthday: me.birthday ? dayjs(me.birthday).valueOf() : null,
					fiscal_code: me.fiscal_code ? me.fiscal_code.toUpperCase() : null,
					description: me.description ? me.description : null,
					email: me.email ? me.email : null,
					name: me.name ? me.name : null,
					newsletter: me.newsletter ? me.newsletter : null,
					phone: me.phone ? me.phone : null,
					surname: me.surname ? me.surname : null
				}
			}
		}
	},
	mounted() {
		EventBus.$on('edit-account', () => {
			this.init();
			if(this.mode == 'mobile') {
				this.openDrawer()
			} else {
				this.openModal();
			}
		});
	}
}
</script>
<style lang="scss">
#fiscal_code {
	text-transform: uppercase;
}
.accountedit {
	--select: 100px;
	@extend %flexCenterCenter;
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	background: $color-seasalt;
	z-index: 10000;
	display: block;
	overflow: auto;
	transform: translateX(100%);
	pointer-events: none;
	&__head {
		@extend %flexCenterStart;
		@include size(100%, var(--headDrawer));
		padding: 0 var(--gutter);
		&__back {
			@extend %flexCenterStart;
			width: calc(100% - var(--btn));
			cursor: pointer;
			&__icon {
				@extend %flexCenterCenter;
				@include size(var(--smallicon),var(--smallicon));
				svg {
					display: block;
					@include size(75%,75%);
					fill: $color-black;
				}
			}
			&__label {
				@extend %text-15;
				display: block;
				width: max-content;
				font-variation-settings: 'wght' 600;
				padding-left: var(--minigutter);
			}
		}
	}
	&__box {
		@extend  %panel;
		background: transparent;
		padding: 0 var(--gutter);
		height: auto;
		overflow: auto;
		&__head {
			@extend %flexCenterBetween;
			width: 100%;
			display: none;
			margin-bottom: var(--gutter);
			&__title {
				@extend %text-33;
				display: block;
				width: 100%;
				font-family: $font-manrope;
				font-variation-settings: 'wght' 700;
			}
			&__close {
				@extend %icon;
				svg {
					@include size(70%,70%);
					fill: $color-black;
				}
			}
		}
		// &__title {
		// 	@extend %text-33;
		// 	display: block;
		// 	width: 100%;
		// 	font-family: $font-manrope;
		// 	font-variation-settings: 'wght' 700;
		// }
		&__text {
			@extend %text-19;
			display: block;
			width: 100%;
		}
		.accountform {
			@extend %flexStartBetween;
			flex-wrap: wrap;
			margin-top: var(--supergutter);
			display: block;
			.form__field {
				&.actions {
					@extend %flexCenterBetween;
					.accountedit__button {
						width: 49%;
					}
				}
				&.province {
					.form__select {
						width: 100%;
					}
				}
			}
			.form__error {
				@extend %text-10;
				display: block;
				width: 100%;
				text-transform: uppercase;
				color: $color-error;
				padding: 5px;
			}
		}
	}
	&__label {
		display: block;
		width: 100%;
		&__text {
			display: block;
			width: 100%;
		}
	}
	&__button {
		width: 100%;
		text-align: center;
		margin-bottom: var(--gutter);
		&.filled {
			background: $color-blue;
			color: $color-white;
		}
		&.outline {
			border-color: $color-blue;
			color: $color-blue
		}
	}
	&__loading {
		position: absolute;
		top: 0;
		left: 0;
		@extend %flexCenterCenter;
		background: rgba($color-white, 0.86);
		@include size(100%,100%);
		pointer-events: all;
		z-index: 100;
		&__spinner {
			display: block;
			margin-bottom: var(--gutter);
			&__box {
				display: block;
				@include size(65px,65px);
				margin: 0 auto var(--gutter);
			}
			&__label {
				@extend %text-15;
				display: block;
				width: 100%;
				font-family: $font-manrope;
				font-variation-settings: 'wght' 600;
				text-align: center;
			}
		}
		&__success {
			display: block;
			@extend %text-33;
			color: $color-green;
			font-variation-settings: 'wght' 700;
			text-align: center;
		}
	}
	.email {
		pointer-events: none;
		input {
			opacity: 0.41;
		}
	}
	.mx-datepicker {
		width: 100%;
		.mx-input {
			background: $color-white;
			height: var(--input);
			border: 1px solid $color-white;
			border-radius: var(--inputradius);
			padding: 0 var(--minigutter);
			border: 1px solid $color-grey;
		}
		.mx-icon-calendar {
			svg {
				fill: $color-black;
			}
		}
	}
	.phone {
		@extend %flexCenterBetween;
		flex-wrap: wrap;
		width: 100%;
		.form {
			&__label {
				justify-content: space-between !important;
			}
			&__input {
				width: 100%;
			}
		}
	}
	@media (min-width: $from-large) {
		display: flex;
		background: rgba($color-black, 0.1);
		transform: translateX(0%);
		opacity: 0;
		&__head {
			display: none;
		}
		&__box {
			background: $color-white;
			max-height: 90vh;
			max-width: 780px;
			padding: var(--gutter);
			transform: translateY(150px);
			opacity: 0;
			&__head {
				display: flex;
				margin-bottom: var(--thirdgutter);
			}
			&__text {
				font-size: 0.938rem;
			}
			.accountform {
				display: flex;
			}
			.form__field {
				width: 48%;
				&.actions {
					margin-left: 52%;
					
				}
			}
		}
		&__button {
			margin-bottom: 0;
		}
	}
}
</style>
