<template>
	<main id="page" class="bookingdetail">
		<div class="mobile__head">
			<span class="mobile__head__back" @click="closeDrawer">
				<span class="mobile__head__back__icon">
					<svg viewBox="0 0 50 50">
						<use xlink:href="#icon--back" width="100%" height="100%"></use>
					</svg>
				</span>
				<span class="mobile__head__back__label">
					{{ $t('common.back') }}
				</span>
			</span>
		</div>
		<template v-if="pageLoading">
			<Loading :isLoading="pageLoading" />
		</template>
		<template v-else>
			<template v-if="booking">
				<div class="bookingdetail__head">
					<div class="content">
						<div class="bookingdetail__head__title">
							Booking
						</div>
					</div>
				</div>
				<div class="bookingdetail__content">
					<div class="smallcontent">
						<div class="bookingdetail__box">
							<div class="bookingdetail__box__head">
								<span class="bookingdetail__box__head__back" @click="closeDrawer">
									<svg viewBox="0 0 50 50">
										<use xlink:href="#icon--back" width="100%" height="100%"></use>
									</svg>
								</span>
								<span class="bookingdetail__box__head__box">
									<span v-if="booking.garage && booking.garage.name" class="bookingdetail__box__head__title" v-html="booking.garage.name"></span>
									<small v-if="booking.garage && booking.garage.address" class="bookingdetail__box__head__address" v-html="booking.garage.address"></small>
								</span>
							</div>
							<div class="bookingdetail__box__list">
								<div class="bookingdetail__item">
									<span class="bookingdetail__item__title" v-html="$t('bookings.detail.title')"></span>
									<BookingPreview :booking="booking" type="preview" />
								</div>
								<div class="bookingdetail__item">
									<span class="bookingdetail__item__title" v-html="$t('bookings.detail.status')"></span>
									<span class="bookingdetail__item__status">
										<small v-html="$t('bookings.detail.occupied')"></small>
										{{ booking.guest_name }} {{ booking.guest_surname }}<br />
										<a v-if="booking.guest_email" :href="`mailto:${booking.guest_email}`" class="bookingdetail__item__link" target="_blank" v-html="booking.guest_email"></a><br />
										<a v-if="booking.guest_phone" :href="`tel:${booking.guest_phone}`" class="bookingdetail__item__link" target="_blank" v-html="booking.guest_phone"></a>
										<span class="bookingdetail__item__info">
											{{ $t('bookings.detail.plate') }}: <strong>{{ booking.vehicle_plate }}</strong>
										</span>
									</span>
								</div>
							</div>
							<div v-if="booking.status != 'CANCELED' && !expired" class="bookingdetail__box__actions">
								<span class="bookingdetail__box__cancel btn filled" @click="deleteBooking">
									{{ $t('bookings.btns.delete') }}
								</span>
							</div>
						</div>
					</div>
				</div>
				<Delete :mode="editMode" :bid="bid" :reasons="reasons" />
			</template>
		</template>
	</main>
</template>
<script>
import { EventBus } from '@/events_bus.js';
import { gsap } from 'gsap';
import { CustomEase } from 'gsap/all';
gsap.registerPlugin(CustomEase);

import dayjs from 'dayjs';

import BookingPreview from '@/views/components/booking/BookingPreview';
import Delete from '@/views/components/booking/Delete';

import Loading from '@/views/components/common/Loading';

export default {
	name: 'BookingDetail',
	components: {
		Loading,
		BookingPreview,
		Delete
	},
	data() {
		return {
			pageLoading: false,
			bid: null,
			booking: null,
			all: null,
			editMode: 'mobile',
			reasons: null,
			expired: false,
		}
	},
	methods: {
		closeDrawer() {
			this.$router.go(-1);
		},
		getBooking(g) {
			this.booking = g;
		},
		deleteBooking() {
			EventBus.$emit('delete-booking', this.booking);
		},
		onResize() {
			this.wWidth = window.innerWidth;
			if(this.wWidth < 1200) {
				this.editMode = 'mobile'
			} else {
				this.editMode = 'desktop'
			}
		},
		init() {
			let now = dayjs().valueOf();

			this.pageLoading = true;
			this.bid = this.$route.params.id;
			this.reasons = [];
			if(this.bid) {
				this.$service.bookings.detail.get(this.bid).then(res => {
					this.booking = res;
					if(dayjs(this.booking.end_at).valueOf() < now) {
						this.expired = true;
					}
					this.pageLoading = false;
				}).then(() => {
					this.$service.bookings.reasons().then(res => {
						if(res.data && res.data.length) {
							this.reasons = res.data;
						}
					})
				})
			}
		}
	},
	mounted() {
		this.onResize();
		this.$nextTick(() => {
			window.addEventListener('resize', () => {
				this.onResize();
			});
		});
		this.init();
	}
}
</script>
<style lang="scss">
.bookingdetail {
	padding-top: var(--head);
	.mobile__head {
		@extend %flexCenterBetween;
		@include size(100%, var(--head));
		padding: 0 var(--gutter);
		position: relative;
		&__back {
			@extend %flexCenterStart;
			width: calc(100% - var(--btn));
			cursor: pointer;
			&__icon {
				@extend %flexCenterCenter;
				@include size(var(--smallicon),var(--smallicon));
				svg {
					display: block;
					@include size(75%,75%);
					fill: $color-black;
				}
			}
			&__label {
				@extend %text-15;
				display: block;
				width: max-content;
				font-variation-settings: 'wght' 600;
				padding-left: var(--minigutter);
			}
		}
	}
	&__content {
		display: block;
		width: 100%;
		margin: var(--supergutter) 0;
		@media (min-width: $from-large) {
			.small-panel {
				height: var(--smallpanelh);
			}
		}
	}
	&__box {
		@extend %panel;
		@extend %flexCenterBetween;
		flex-wrap: wrap;
		width: 100%;
		padding: var(--gutter);
		background: transparent;
		box-shadow: none;
		&__head {
			@extend %flexStartStart;
			width: 100%;
			margin-bottom: var(--gutter);
			&__back {
				display: block;
				width: var(--gutter);
				padding-top: var(--thirdgutter);
				svg {
					display: block;
					@include size(var(--minigutter), var(--minigutter));
					fill: $color-black;
					cursor: pointer;
				}
			}
			&__box {
				display: block;
				width: calc(100% - var(--gutter));
			}
			&__title, &__address {
				display: block;
				width: 100%;
			}
			&__title {
				@extend %text-25;
				font-family: $font-manrope;
				font-variation-settings: 'wght' 700;
			}
			&__address {
				@extend %text-15;
				font-family: $font-manrope;
				margin-top: var(--thirdgutter);
			}
		}
		&__list {
			display: block;
			width: 100%;
		}
		&__actions {
			position: fixed;
			bottom: 0;
			left: 0;
			@extend %flexCenterEnd;
			width: 100%;
			padding: var(--gutter);
		}
		&__cancel {
			background: $color-red;
			color: $color-white;
			box-shadow: none;
			padding: 0 var(--gutter);
		}
	}
	&__item {
		display: block;
		width: 100%;
		margin-bottom: var(--gutter);
		&__title {
			@extend %text-20;
			display: block;
			width: 100%;
			font-variation-settings: 'wght' 700;
			margin-bottom: var(--minigutter);
		}
		&__status {
			@extend %panel;
			@extend %text-20;
			display: block;
			width: 100%;
			padding: calc(var(--thirdgutter) * 2);
			font-variation-settings: 'wght' 700;
			small {
				@extend %text-13;
				display: block;
				width: 100%;
				font-variation-settings: 'wght' 400;
			}
		}
		&__link {
			@extend %text-18;
			font-variation-settings: 'wght' 500;
			text-decoration: underline;
		}
		&__info {
			display: block;
			width: 100%;
			padding-top: var(--thirdgutter);
			@extend %text-15;
			font-variation-settings: 'wght' 500;
			strong {
				font-variation-settings: 'wght' 700;
			}
		}
	}
	@media (min-width: $from-large) {
		.mobile__head {
			display: none;
		}
		&__head {
			display: none;
		}
		&__box {
			background: $color-white;
			box-shadow: $shadow;
			&__actions {
				position: relative;
				border-top: 1px solid $color-silver;
				padding: var(--gutter) 0 0;
			}
			&__cancel {
				width: max-content;
			}
		}
		&__item {
			&__status {
				box-shadow: none;
			}
		}
		// &__box {
		// 	padding: var(--supergutter) var(--supergutter) var(--gutter);
		// }
	}
	@media (max-width: $to-large) {
		position: fixed;
		top: 0;
		left: 0;
		display: block;
		background: $color-antiflash !important;
		padding-top: 0;
		z-index: 100000;
		&__head {
			display: none;
		}
		&__box {
			border-radius: 0;
			box-shadow: none;
			padding: 0;
		}
		&__content {
			margin-top: 0;
		}
	}
}
</style>