<template>
	<main id="login" class="login page">
		<div class="content">
			<div class="login__box">
				<router-link to="/" class="login__logo">
					<svg viewBox="0 0 189.3 62">
						<use xlink:href="#logo--main" width="100%" height="100%"></use>
					</svg>
				</router-link>
				<div class="login__social">
					<div class="login__social__list">
						<AppleLogin />
						<GoogleLogin />
						<FacebookLogin />
					</div>
				</div>
				<div class="login__signup">
					<span class="login__signup__title">
						<span>
							{{ $t('login.signup.title') }}
						</span>
					</span>
					<form id="signup" class="form signup" @submit.prevent="onSubmit">
						<div class="form__field">
							<label for="email" class="form__label signup__label">
								{{ $t('login.signup.email.label') }}
							</label>
							<input type="email" name="email" class="form__input signup__input" :placeholder="$t('login.signup.email.placeholder')" v-model="form.email" />
							<small v-if="errors && errors.email" class="form__error" v-html="errors.email"></small>
							<small v-if="validMailError" class="form__error">{{ $t('create.errors.emailNotValid') }}</small>
						</div>
						<div class="actions">
							<div class="form__field">
								<label for="password" class="form__label signup__label">
									{{ $t('login.signup.password.label') }}
								</label>
								<span class="input-box">
									<input type="password" name="password" class="form__input signup__input" :placeholder="$t('login.signup.password.placeholder')" v-model="form.password" />
									<span class="show-psw" @click="togglePswVisibility">
										<span class="show-psw__show">
											<svg viewBox="0 0 50 50">
												<use xlink:href="#icon--hidepsw" width="100%" height="100%"></use>
											</svg>
										</span>
										<span class="show-psw__hide">
											<svg viewBox="0 0 50 50">
												<use xlink:href="#icon--showpsw" width="100%" height="100%"></use>
											</svg>
										</span>
									</span>
								</span>
								<span class="form__field__utils">
									<small v-if="errors && errors.password" class="form__error" v-html="errors.password"></small>
									<small v-if="errors && errors.length">
										<span v-for="(e, eI) in errors" :key="eI" v-html="$t(`login.errors.${e.toLowerCase()}`)" class="signup__error"></span>
									</small>
									<small class="forgotpsw" @click="forgotPassword">
										{{ $t('login.signup.password.forgot') }}
									</small>
								</span>
							</div>
							<button id="continue" class="signup__button btn filled" type="submit">
								{{ $t('login.signup.continue') }}
							</button>
							<span class="signup__text" v-html="$t('login.signup.text')">
							</span>
							<button id="createuserform" class="signup__button btn outline" type="button" @click="showCreate">
								{{ $t('login.signup.create') }}
							</button>
						</div>
					</form>
				</div>
			</div>
		</div>
		<CreateAccount :mode="editMode" />
		<ForgotPassword :mode="editMode" />
	</main>
</template>
<script>
import { EventBus } from '@/events_bus.js';
import { gsap } from 'gsap';
import { CustomEase } from 'gsap/all';

import FacebookLogin from '@/views/components/login/FacebookLogin';
import GoogleLogin from '@/views/components/login/GoogleLogin';
import AppleLogin from '@/views/components/login/AppleLogin';

import CreateAccount from '@/views/components/login/CreateAccount';
import ForgotPassword from '@/views/components/login/ForgotPassword';

gsap.registerPlugin(CustomEase);

export default {
	name: 'Login',
	components: {
		FacebookLogin,
		GoogleLogin,
		AppleLogin,
		CreateAccount,
		ForgotPassword,
	},
	data() {
		return {
			form: {
				email: '',
				password: '',
			},
			mail: '',
			insertPassword: false,
			pswforgot: false,
			create: false,
			editMode: 'mobile',
			psw: null,
			errors: [],
			emailNotValid: false,
			isLoading: false,
			msg: null,
			validMailError: false,
		}
	},
	watch: {
		'$route': {
			immediate: true,
			deep: true,
			handler(newval, oldval) {
				this.checkRoute();
				return newval
			}
		}
	},
	methods: {
		forgotPassword() {
			EventBus.$emit('show-forgot');
		},
		onResize() {
			this.wWidth = window.innerWidth;
			if(this.wWidth < 1200) {
				this.editMode = 'mobile'
			} else {
				this.editMode = 'desktop'
			}
		},
		showCreate() {
			this.create = true;
			this.$router.push({ name: 'createaccount.page' })
		},
		checkRoute() {
			let page = this.$route.name.split('.')[0];
			switch(page) {
				case 'maillogin':
					setTimeout(() => {
						this.$el.querySelector('.login__social').classList.add('hide');
						this.$el.querySelector('.actions').classList.add('show');

						gsap.to('.login__social', {
							duration: 1.2,
							height: 0,
							padding: 0,
							ease: CustomEase.create("custom", "M0,0 C0.726,0 0,1 1,1 ")
						})
						gsap.to('.login .actions', {
							duration: 1.2,
							height: 270,
							maxHeight: 10000,
							ease: CustomEase.create("custom", "M0,0 C0.726,0 0,1 1,1 ")
						})
					}, 300)
					break;
				case 'createaccount':
					setTimeout(() => {
						EventBus.$emit('show-create');
					}, 100)
					break;
				default:
					gsap.to('.login__social', {
						duration: 1.2,
						height: 'auto',
						maxHeight: '10000px',
						padding: '60px 0',
						ease: CustomEase.create("custom", "M0,0 C0.726,0 0,1 1,1 ")
					})
					gsap.to('.login .actions', {
						duration: 1.2,
						height: 0,
						maxHeight: 0,
						ease: CustomEase.create("custom", "M0,0 C0.726,0 0,1 1,1 ")
					})
					break;
			}
		},
		checkMail() {
			this.$service.login.checkMail(this.mail).then(res => {
				console.log('Login...');
			})
		},
		validEmail (email) {
			var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
			return re.test(email);
		},
		togglePswVisibility(item) {
			let input = item.target.parentNode.querySelector('input');

			item.target.classList.contains('show') ? item.target.classList.remove('show') : item.target.classList.add('show')

			if(input.type == 'password') {
				input.type = 'text';
			} else {
				input.type = 'password';
			}
		},
		inputFocus() {
			if(this.$route.name.split('.')[0] != 'maillogin') {
				this.$router.push({ name: 'maillogin.page' })
			}

			if(!this.$el.querySelector('.login__social').classList.contains('hide')) {
				this.$el.querySelector('.login__social').classList.add('hide');
				this.$el.querySelector('.actions').classList.add('show');
				gsap.to('.login__social', {
					duration: 1.2,
					height: 0,
					padding: 0,
					ease: CustomEase.create("custom", "M0,0 C0.726,0 0,1 1,1 ")
				})
				gsap.to('.login .actions', {
					duration: 1.2,
					height: 'auto',
					maxHeight: '1000px',
					ease: CustomEase.create("custom", "M0,0 C0.726,0 0,1 1,1 ")
				})
			}

			
		},
		async onSubmit() {
			this.submit();
		},
		submit() {
			this.errors = null;
			this.msg = '';
			
			["email", "password"].map(checkField => {
				if (this.form && !(this.form[checkField] && this.form[checkField].toString().trim().length)) {
					this.errors = (this.errors == null) ? {} : this.errors;
					// this.errors[checkField] = true;
					if(!this.errors[checkField] || this.errors[checkField] == '') {
						this.errors[checkField] = this.$t(`account.edit.errors.${checkField}`);
					}
				}
			})
			
			if(this.errors === null) {
				if(this.validEmail(this.form.email)) {
					console.log('valid email')
					this.validMailError = false;
					this.$service.login.login(this.form.email, this.form.password).then(res => {
						if(res && res.access_token) {
							this.errors = null;
							setTimeout(() => {
								this.isLoading = false;
								this.$api.setToken(res.access_token, res.expires_in);
								console.log('YOU ARE LOGGED')
								window.location.href = '/#/app/'
							}, 1500);
						}
					}).catch(err => {
						console.error('ERROR', err);
						this.isLoading = false;
						this.errors = [];
						let temp = err.messages.error;
						console.log('TEMP', temp)
						this.errors.push(temp);
						console.log('--->>>', this.errors)
						/*for(const key in temp) {
							this.errors.push(this.$t(`login.errors.${temp[key][0].toLowerCase().split(' ').join('_').replace(/\./g,'')}`))
						}*/
						// this.msg = this.$t('form.senderror');
					})
				} else {
					this.validMailError = true;
				}
			}
			
		}
	},
	mounted() {

		this.$api.clearToken();

		this.onResize();
		this.$nextTick(() => {
			window.addEventListener('resize', () => {
				this.onResize();
			});
		});

		this.checkRoute();

		let mail = this.$el.querySelector('.signup__input');
		if(mail) {
			mail.addEventListener('focus', () => {
				this.inputFocus();

			})
		}
	}
}
</script>
<style lang="scss">
.login {
	background: $color-seasalt;
	height: 100svh;
	position: relative;
	&__box {
		display: block;
		width: 100%;
		max-width: 340px;
		margin: 0 auto;
	}
	&:before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		display: block;
		background: url('~@/assets/images/street-texture.png') no-repeat 50% 0;
		@include size(100%,100%);
		z-index: 1;
		pointer-events: none;
	}
	& > .content {
		@extend %flexCenterCenter;
		flex-direction: column;
		height: 100%;
		position: relative;
		z-index: 4;
	}
	&__logo {
		display: block;
		@include size(186px,60px);
		margin: 0 auto var(--supergutter);
		svg {
			display: block;
			@include size(100%,100%);
			fill: $color-green;
		}
	}
	&__social {
		display: block;
		width: 100%;
		height: auto;
		padding-bottom: var(--supergutter);
		overflow: hidden;
		&__list {
			display: block;
			width: 100%;
		}
		&__item {
			@extend %btn;
			width: 100%;
			color: $color-white;
			margin-bottom: calc(var(--gutter) * 0.66);
			&.apple {
				background: $color-black;
			}
			&.google {
				background: $color-red;
			}
			&.facebook {
				background: $color-fb;
			}
		}
		&.closed {
			.login__social {
				height: 0;
				max-height: 0;
				&__list {
					transform: translateY(-100%);
				}
			}
		}
	}
	&__signup {
		display: block;
		width: 100%;
		&__title {
			@extend %flexCenterCenter;
			width: 100%;
			position: relative;
			@extend %text-15;
			margin-bottom: var(--gutter);
			&:before {
				content: '';
				position: absolute;
				top: 50%;
				left: 0;
				display: block;
				background: $color-silver;
				@include size(100%,1px);
				transform: translateY(-50%);
				z-index: 1;
			}
			span {
				display: block;
				background: $color-seasalt;
				padding: 0 calc(var(--gutter) * 0.75);
				position: relative;
				z-index: 2;
			}
		}
		.signup {
			display: block;
			width: 100%;
			&__error {
				position: absolute;
				top: 100%;
				left: 0;
				display: block;
				width: 100%;
				margin-top: var(--thirdgutter);
				color: $color-error;
			}
			&__label {
				margin-bottom: 0;
			}
			&__text {
				@extend %text-15;
				display: block;
				width: 100%;
				text-align: center;
				margin: var(--minigutter) 0;
			}
			&__button {
				width: 100%;
				text-align: center;
				&.filled {
					background: $color-blue;
					color: $color-white;
				}
				&.outline {
					border-color: $color-blue;
					color: $color-blue
				}
			}
			&__password {
				&__lost {
					float: right;
					display: block;
					width: max-content;
					margin: 6px 0;
				}
			}
			&__text, &__button {
				// opacity: 0;
				// display: none;
				// transition: 250ms linear;
			}
		}
	}
	.input-box {
		display: block;
		width: 100%;
		position: relative;
		.show-psw {
			position: absolute;
			top: 50%;
			right: var(--thirdgutter);
			display: block;
			@include size(var(--smallicon),var(--smallicon));
			transform: translateY(-50%);
			cursor: pointer;
			&__show,
			&__hide {
				position: absolute;
				top: 0;
				left: 0;
				display: flex;
				@extend %flexCenterCenter;
				@include size(100%,100%);
				pointer-events: none;
				svg {
					display: block;
					@include size(100%,100%);
					fill: $color-black;
				}
			}
			&__hide {
				display: none;
			}
			&.show {
				.show-psw__show {
					display: none;
				}
				.show-psw__hide {
					display: block;
				}
			}
		}
	}
	.form__field {
		&__utils {
			@extend %flexCenterBetween;
			flex-wrap: wrap;
			width: 100%;
			position: relative;
			padding-top: calc(var(--thirdgutter) / 2);
			small {
				width: max-content;
				max-width: 50%;
			}
			.forgotpsw {
				cursor: pointer;
			}
		}
	}
	& .actions {
		height: 0;
		overflow: hidden;
	}
	.signup__error {
		position: absolute;
		top: 0;
		left: 0;
		display: block;
		width: 100%;
		margin-top: var(--thirdgutter);
		color: $color-error !important;
	}
	.form__error {
		@extend %text-10;
		display: block;
		width: 100%;
		text-transform: uppercase;
		color: $color-error;
		padding: 5px;
		text-align: left;
	}
}
</style>
