<template>
	<main id="page" class="becomeguest" ref="page">
		<div class="mobile__head">
			<span class="mobile__head__back" @click="closeDrawer">
				<span class="mobile__head__back__icon">
					<svg viewBox="0 0 50 50">
						<use xlink:href="#icon--back" width="100%" height="100%"></use>
					</svg>
				</span>
				<span class="mobile__head__back__label">
					{{ $t('common.back') }}
				</span>
			</span>
		</div>
		<template v-if="pageLoading">
			<Loading :isLoading="pageLoading" />
		</template>
		<template v-else>
			<div class="becomeguest__head">
				<div class="content">
					<div class="becomeguest__head__title">
						{{ $t('becomeguest.title') }}
					</div>
				</div>
			</div>
			<div class="becomeguest__content">
				<div class="smallcontent">
					<div class="becomeguest__box">
						<span class="becomeguest__box__text" v-html="$t('becomeguest.text')"></span>
						<span class="becomeguest__box__links">
							<a href="itms-apps://itunes.apple.com/app/id6449772532" target="_blank" class="becomeguest__link">
								<img src="@/assets/images/appstore.png" alt="" />
							</a>
							<a href="https://play.google.com/store/apps/details?id=digital.mwd.app.garaging" target="_blank" class="becomeguest__link">
								<img src="@/assets/images/playstore.png" alt="" />
							</a>
						</span>
					</div>
				</div>
			</div>
		</template>
	</main>
</template>
<script>
import { EventBus } from '@/events_bus.js';

import Loading from '@/views/components/common/Loading';


export default {
	name: 'BecomeGuest',
	components: {
		Loading,
	},
	data() {
		return {
			pageLoading: false,
			editMode: 'mobile',
			isLoading: false,
		}
	},
	methods: {
		closeDrawer() {
			this.$router.go(-1);
		},
		onResize() {
			this.wWidth = window.innerWidth;
			if(this.wWidth < 1200) {
				this.editMode = 'mobile'
			} else {
				this.editMode = 'desktop'
			}
		},
		init() {
			this.pageLoading = true;
			setTimeout(() => {
				this.pageLoading = false;
			}, 500)
		}
	},
	mounted() {
		this.onResize();
		this.$nextTick(() => {
			window.addEventListener('resize', () => {
				this.onResize();
			});
		});

		this.init();
		
	}
}
</script>
<style lang="scss">
.becomeguest {
	padding-top: var(--head);
	.mobile__head {
		@extend %flexCenterBetween;
		@include size(100%, var(--head));
		padding: 0 var(--gutter);
		position: relative;
		&__back {
			@extend %flexCenterStart;
			width: calc(100% - var(--btn));
			cursor: pointer;
			&__icon {
				@extend %flexCenterCenter;
				@include size(var(--smallicon),var(--smallicon));
				svg {
					display: block;
					@include size(75%,75%);
					fill: $color-black;
				}
			}
			&__label {
				@extend %text-15;
				display: block;
				width: max-content;
				font-variation-settings: 'wght' 600;
				padding-left: var(--minigutter);
			}
		}
	}
	&__box {
		@extend %panel;
		@extend %flexCenterBetween;
		flex-wrap: wrap;
		width: 100%;
		padding: var(--gutter);
		&__text {
			display: block;
			width: 100%;
			@extend %text-25;
			font-variation-settings: 'wght' 300;
			text-align: center;
			margin-bottom: var(--supergutter);
			strong {
				@extend %text-33;
				display: block;
				font-family: $font-manrope;
				color: $color-blue;
				font-variation-settings: 'wght' 700;
				margin-bottom: var(--minigutter);
			}
		}
		&__links {
			@extend %flexCenterCenter;
			flex-wrap: wrap;
			width: 100%;
			margin-bottom: var(--supergutter);
		}
	}
	&__link {
		display: block;
		@include size(auto,70px);
		margin: var(--thirdgutter);
	}
	&__head {
		display: block;
		width: 100%;
		margin: var(--gutter) 0;
		&__title {
			display: block;
			@extend %text-33;
			font-family: $font-manrope;
			font-variation-settings: 'wght' 700;
			margin-bottom: var(--thirdgutter);
		}
		@media (min-width: $from-large) {
			display: none;
		}
	}
	&__content {
		display: block;
		width: 100%;
		margin: var(--supergutter) 0;
		@media (min-width: $from-large) {
			.small-panel {
				height: var(--smallpanelh);
			}
		}
	}
	@media (min-width: $from-large) {
		.mobile__head {
			display: none;
		}
		&__box {
			padding: var(--supergutter) var(--supergutter) var(--gutter);
		}
	}
	@media (max-width: $to-large) {
		position: fixed;
		top: 0;
		left: 0;
		display: block;
		background: $color-white !important;
		padding-top: 0;
		z-index: 100000;
		&__head {
			display: none;
		}
		&__box {
			border-radius: 0;
			box-shadow: none;
			padding: 0;
		}
		&__content {
			margin-top: 0;
		}
	}
}
</style>