<template>
	<div class="garage__form">
		<div class="garagedetail__box__head">
			<template v-if="wWidth > 1200">
				<label v-if="!isCreate" for="public" class="garagedetail__toggle">
					<input type="checkbox" name="public" id="public" class="garagedetail__toggle__input" @change="getPublic($event)" />
					<span class="garagedetail__toggle__label">{{ $t('garages.detail.toggle') }}</span>
					<span class="garagedetail__toggle__btn" :class="{ 'on': isPublic }"></span>
					<small class="garagedetail__noava" v-if="noAvailabilities">
						Non puoi pubblicare senza aggiungere disponibilità
					</small>
				</label>
			</template>
			<div v-if="!isCreate" class="garagedetail__headactions">
				<template v-if="wWidth > 1200">
					<span class="garagedetail__availability btn" @click="availabilityList">
						<span class="garagedetail__availability__icon">
							<svg viewBox="0 0 50 50">
								<use xlink:href="#icon--availability" width="100%" height="100%"></use>
							</svg>
						</span>
						<span class="garagedetail__availability__label" v-html="$t('garages.detail.availability')"></span>
					</span>
				</template>
				<span class="garagedetail__share btn" @click="qrcode">
					<svg viewBox="0 0 50 50">
						<use xlink:href="#icon--share" width="100%" height="100%"></use>
					</svg>
				</span>
			</div>
		</div>
		<div class="garagedetail__box__content">
			<form id="garageform" name="garageform" class="form">
				<div class="form__field form__name">
					<input type="text" class="form__input" name="name" v-model="form.name" :placeholder="$t('garages.form.placeholders.name')" />
					<small v-if="errors && errors.length" class="form__error" v-html="getError('name')"></small>
				</div>
				<div class="form__field form__photos">
					<small class="form__field__title" v-html="$t('garages.form.photos')"></small>
					<div class="form__field__content">
						<span v-if="!galleryLoaded" class="galleryloading">
							<Spinner />
						</span>
						<Flicking :options="photosOpts" :hideBeforeInit="true" ref="photoslider">
							<template v-if="form.photos.length">
								<figure v-for="(p, pI) in form.photos" :key="pI" class="form__photos__item" :data-index="pI" :data-id="p.id">
									<span class="form__photos__item__delete btn" @click="deletePhoto($event)">
										<svg viewBox="0 0 50 50">
											<use xlink:href="#icon--trash" width="100%" height="100%"></use>
										</svg>
									</span>
									<img class="form__photos__item__image" :src="p.filename" alt="" />
								</figure>
							</template>
							<label class="form__photos__add" :class="{ 'full': (form.photos.length < 1)}">
								<input type="file" id="uploadphoto" class="form__photos__add__input" multiple="true" @change="addPhoto($event)" />
								<span class="form__photos__add__label" v-html="$t('garages.btns.addphoto')"></span>
							</label>
						</Flicking>
						<small class="form__photos__count">
							{{ form.photos.length }} {{ $t('garages.form.photoscount') }}
						</small>
					</div>
				</div>
				<div class="form__field form__maps">
					<small class="form__field__title" v-html="$t('garages.form.position')"></small>
					<div class="form__map form__panel" :class="{'disabled': !isCreate }">
						<label for="address" class="form__label">
							{{ $t('garages.form.address') }}
						</label>
						<input type="text" name="address" id="address" class="form__input autocomplete" :placeholder="$t('garages.form.addressplaceholder')" v-model="form.address" />
						<small v-if="errors && errors.length" class="form__error" v-html="getError('address')"></small>
						<div id="map" class="form__map__map">
						</div>
						<span class="form__map__instructions">
							<span class="form__map__instructions__icon">
								<svg viewBox="0 0 50 50">
									<use xlink:href="#icon--mapmarker" width="100%" height="100%"></use>
								</svg>
							</span>
							<span class="form__map__instructions__label" v-html="$t('garages.form.maplabel')"></span>
						</span>
					</div>
				</div>
				<div class="form__field form__vehicles">
					<small class="form__field__title" v-html="$t('garages.form.vehicles')"></small>
					<div class="form__vehicles__list form__panel" :class="{'disabled': !isCreate }">
						<label v-for="v in vehiclesType" :key="v.id" :for="v.id" class="vehicle form__label">
							<input type="checkbox" :name="v.id" :id="v.id" :value="v.id" v-model="form.vehicle_types" />
							<span class="form__checkbox" :class="{ 'checked': form.vehicle_types }">
								<svg viewBox="0 0 50 50">
									<use xlink:href="#icon--check" width="100%" height="100%"></use>
								</svg>
							</span>
							<span class="vehicle__text">
								<span class="vehicle__icon">
									<svg viewBox="0 0 50 50">
										<use :xlink:href="`#vehicles--${v.category.toLowerCase()}`" width="100%" height="100%"></use>
									</svg>
								</span>
								<span class="vehicle__label" v-html="v.name"></span>
							</span>
						</label>
					</div>
				</div>
				<div class="form__field form__characts">
					<small class="form__field__title" v-html="$t('garages.form.characteristics')"></small>
					<div class="form__characts__content form__panel">
						<div class="form__characts__col">
							<div class="characts__block">
								<small class="characts__block__label" v-html="$t('garages.form.floor')"></small>
								<div class="characts__floor" :class="{'disabled': !isCreate }">
									<label v-for="f in floors" :key="f.id" :for="f.id" class="characts__floor__item form__label">
										<input type="radio" class="form__input" name="floor" :id="f.id" :value="+f.value" v-model="form.floor" />
										<span class="characts__floor__item__icon">
											<svg viewBox="0 0 62.6 50">
												<use :xlink:href="`#number--${f.icon}`" width="100%" height="100%"></use>
											</svg>
										</span>
									</label>
									<small v-if="errors && errors.length" class="form__error" v-html="getError('floor')"></small>
								</div>
							</div>
							<div class="characts__block">
								<div class="characts__misc">
									<label v-for="(value, key, index) in form.charateristics" :key="key + index" :for="key" class="characts__item form__label">
										<input type="checkbox" :name="key" :id="key" :value="value" v-model="form.charateristics[key]" />
										<span class="form__checkbox" :class="{ 'checked': form.charateristics[value] }">
											<svg viewBox="0 0 50 50">
												<use xlink:href="#icon--check" width="100%" height="100%"></use>
											</svg>
										</span>
										<span class="characts__item__text">
											<span class="characts__item__icon">
												<svg viewBox="0 0 50 50">
													<use :xlink:href="`#icon--${key}`" width="100%" height="100%"></use>
												</svg>
											</span>
											<span class="characts__item__label" v-html="$t(`garages.form.misc.${key}`)"></span>
										</span>
									</label>
									<div v-if="form.charateristics.gpl && (form.floor == -2 || form.floor == -1)" class="characts__misc__text red">
										<small class="characts__block__label" v-html="$t('garages.form.gplselected.title')"></small>
										<span class="characts__block__txt" v-html="$t('garages.form.gplselected.text')"></span>
									</div>
									<div v-if="form.charateristics.barriers" class="characts__misc__text">
										<small class="characts__block__label" v-html="$t('garages.form.barriers.title')"></small>
										<span class="characts__block__txt" v-html="$t('garages.form.barriers.text')"></span>
									</div>
								</div>
							</div>
						</div>
						<div class="form__characts__col">
							<div class="form__characts__select" :class="{'disabled': !isCreate }">
								<span class="form__characts__select__title" v-html="$t('garages.form.parktype')"></span>
								<template v-if="g">
									<CustomSelect v-if="parkType && parkType.length" :list="parkType" :selected="g.type" />
								</template>
								<template v-else>
									<CustomSelect v-if="parkType && parkType.length" :list="parkType" />
								</template>
								<small v-if="errors && errors.length" class="form__error" v-html="getError('gate')"></small>
							</div>
							<div class="form__characts__select">
								<span class="form__characts__select__title" v-html="$t('garages.form.gatetype')"></span>
								<template v-if="g">
									<CustomSelect v-if="gateTypes && gateTypes.length" :list="gateTypes" :selected="g.gate" />
								</template>
								<template v-else>
									<CustomSelect v-if="gateTypes && gateTypes.length" :list="gateTypes" />
								</template>
								<small v-if="errors && errors.length" class="form__error" v-html="getError('gate')"></small>
							</div>
							<div v-if="form.gate" class="form__characts__truefalse">
								<label for="smartopen" class="truefalse__toggle">
									<span class="form__characts__truefalse__title" v-html="$t('garages.form.issmart')"></span>
									<input type="checkbox" name="smartopen" id="smartopen" class="truefalse__toggle__input" @change="smartOpen($event)" />
									<span class="truefalse__toggle__btn" :class="{ 'on': form.smart }"></span>
								</label>
								<div v-if="form.smart" class="smartbox">
									<div v-if="devices && devices.length" class="devices">
										<div v-for="(d, dI) in devices" :key="dI" class="devices__item">
											<span class="devices__item__name" v-html="d.name"></span>
											<small class="devices__item__type" v-html="d.type"></small>
											<span v-if="d.configuration.mac_address || d.configuration.password" class="devices__item__config dconfig">
												<span class="dconfig__row">
													<small class="dconfig__label" v-html="$t('garages.form.adddevice.recap.label')"></small>
													<span v-if="d.configuration.mac_address" class="dconfig__item">
														<span class="dconfig__item__label" v-html="$t('garages.form.adddevice.recap.macaddress')"></span>
														<span class="dconfig__item__value" v-html="d.configuration.mac_address"></span>
													</span>
													<span v-if="d.configuration.password" class="dconfig__item">
														<span class="dconfig__item__label" v-html="$t('garages.form.adddevice.recap.password')"></span>
														<span class="dconfig__item__value" v-html="d.configuration.password"></span>
													</span>
												</span>
											</span>
										</div>
									</div>
									<span class="smartbox__head">
										<!-- <small class="smartbox__head__label">
											{{ $t('garages.form.adddevice.text') }}
										</small>
										<span class="smartbox__head__btn" @click="handleDeviceModal">
											<svg viewBox="0 0 50 50">
												<use xlink:href="#icon--settings" width="100%" height="100%"></use>
											</svg>
										</span> -->
										<span class="smartbox__head__btn" @click="handleDeviceModal">
											<small class="smartbox__head__btn__label">
												{{ $t('garages.form.adddevice.text') }}
											</small>
											<svg viewBox="0 0 50 50">
												<use xlink:href="#icon--settings" width="100%" height="100%"></use>
											</svg>
										</span>
									</span>
								</div>
							</div>
							<div class="form__characts__numbers">
								<span class="form__characts__select__title" v-html="$t('garages.form.spots')"></span>
								<template v-if="g">
									<QuantitySelector :min="1" :max="1000" :editable="quantityEditable" :savedValue="g.vehicle_quantity" @updatequantity="vehiclesQuantity" />
								</template>
								<template v-else>
									<QuantitySelector :min="1" :max="1000" @updatequantity="vehiclesQuantity" />
								</template>
								<span class="form__characts__numbers__info">
									<span class="form__characts__numbers__info__icon">
										<svg viewBox="0 0 50 50">
											<use xlink:href="#icon--info" width="100%" height="100%"></use>
										</svg>
									</span>
									<span class="form__characts__numbers__info__text" v-html="$t('garages.form.numbertext')"></span>
								</span>
							</div>
						</div>
					</div>
				</div>
				<div class="form__field form__description">
					<small class="form__field__title" v-html="$t('garages.form.descriptions.title')"></small>
					<div class="form__description__content form__panel">
						<div class="form__description__col">
							<small class="form__description__label" v-html="$t('garages.form.descriptions.public')"></small>
							<small class="form__description__info" v-html="$t('garages.form.descriptions.public_text')"></small>
							<textarea v-model="form.descriptions.public" class="form__textarea" :placeholder="$t('garages.form.descriptions.publicpholder')"></textarea>
							<small v-if="errors && errors.length" class="form__error" v-html="getError('description')"></small>
						</div>
						<div class="form__description__col">
							<small class="form__description__label" v-html="$t('garages.form.descriptions.private')"></small>
							<small class="form__description__info" v-html="$t('garages.form.descriptions.private_text')"></small>
							<textarea v-model="form.descriptions.private" class="form__textarea" :placeholder="$t('garages.form.descriptions.privatepholder')"></textarea>
						</div>
					</div>
				</div>
				<template v-if="wWidth <= 1200">
					<div class="form__field form__utils">
						<span v-if="!isCreate" class="garagedetail__availability btn" @click="availabilityList">
							<span class="garagedetail__availability__label" v-html="$t('garages.detail.availability')"></span>
							<span class="garagedetail__availability__icon">
								<svg viewBox="0 0 50 50">
									<use xlink:href="#icon--back" width="100%" height="100%"></use>
								</svg>
							</span>
						</span>
						<label for="public" class="garagedetail__toggle">
							<input type="checkbox" name="public" id="public" class="garagedetail__toggle__input" @change="getPublic($event)" />
							<span class="garagedetail__toggle__label">{{ $t('garages.detail.toggle') }}</span>
							<span class="garagedetail__toggle__btn" :class="{ 'on': isPublic }"></span>
						</label>
					</div>
				</template>
				<div class="form__field__terms">
					<label for="terms" class="terms__item form__label">
						<input type="checkbox" name="terms" id="terms" v-model="terms" />
						<span class="form__checkbox" :class="{ 'checked': terms }">
							<svg viewBox="0 0 50 50">
								<use xlink:href="#icon--check" width="100%" height="100%"></use>
							</svg>
						</span>
						<span class="terms__item__text">
							<span class="terms__item__label" v-html="$t('garages.form.terms')"></span>
						</span>
					</label>
				</div>
				<div class="form__field form__actions">
					<span class="form__actions__btn btn filled cancel" @click="deleteGarage" :class="{ 'disabled': isCreate }">
						{{ $t('garages.btns.delete') }}
					</span>
					<span class="form__actions__btn btn filled save" @click="saveGarage" :class="{ 'disabled': !terms }">
						{{ $t('garages.btns.save') }}
					</span>
				</div>
			</form>
			<div class="legenda" v-html="$t('garages.legenda')">
			</div>
		</div>
		<PublicModal v-if="showpublicmodal" />
		<DeviceModal :gates="devices" />
		<QRModal />
	</div>
</template>
<script>
import dayjs from 'dayjs';
import { EventBus } from '@/events_bus.js';
import { Flicking } from "@egjs/vue-flicking";
import "@egjs/vue-flicking/dist/flicking.css";

import CustomSelect from '@/views/components/common/CustomSelect';
import QuantitySelector from '@/views/components/common/QuantitySelector';

import Spinner from '@/views/components/common/Spinner';

import PublicModal from '@/views/components/garages/PublicModal';
import QRModal from '@/views/components/garages/QRModal';
import DeviceModal from '@/views/components/garages/DeviceModal';

import NewDevice from '@/views/components/garages/NewDevice';

export default {
	name: 'GarageForm',
	props: {
		isCreate: {
			type: Boolean,
			required: true,
		},
		g: {
			type: Object,
			required: false,
		}
	},
	components: {
		Flicking,
		CustomSelect,
		QuantitySelector,
		Spinner,
		PublicModal,
		QRModal,
		NewDevice,
		DeviceModal
	},
	data() {
		return {
			noAvailabilities: false,
			devices: [],
			terms: false,
			og: null,
			wWidth: 0,
			garage: null,
			enablePublication: false,
			isPublic: false,
			quantityEditable: true,
			galleryLoaded: false,
			showpublicmodal: false,
			photosOpts: {
				moveType: ["freeScroll", { stopAtEdge: true }],
				bound: true,
				align: 'prev',
				autoInit: false,
			},
			floors: [
				{
					id: 0,
					value: -2,
					icon: 'minus-two'
				},
				{
					id: 1,
					value: -1,
					icon: 'minus-one'
				},
				{
					id: 3,
					value: 0,
					icon: 'zero'
				},
				{
					id: 4,
					value: 1,
					icon: 'one'
				},
				{
					id: 5,
					value: 2,
					icon: 'two'
				},
			],
			misc: [
				'stairs',
				'elevator',
				'charge_station',
				'gpl',
				'indoor',
				'barriers'
			],
			parkType: [],
			gateTypes: [],
			vehiclesType: null,
			form: {
				name: null,
				photos: [],
				parks: [],
				floor: null,
				published: false,
				charateristics: {
					stairs: false,
					elevator: false,
					charge_station: false,
					gpl: false,
					indoor: false,
					barriers: false,
				},
				vehicle_quantity: 1,
				gate: null,
				address: null,
				type: null,
				smart: false,
				descriptions: {
					public: null,
					private: null
				},
				vehicle_types: [],
				macaddress: null,
				password: null,
				devices: []
			},
			errors: null,
			msg: '',
			me: null,
			vehiclePromise: null,
			gatePromise: null,
			garagePromise: null,
			showDeviceModal: false
		}
	},
	methods: {
		handleDeviceModal() {
			setTimeout(() => {
				if(this.devices && this.devices.length) {
					EventBus.$emit('open-device-modal-edit', {gate: this.form.gate, devs: this.devices});
				} else {
					EventBus.$emit('open-device-modal', this.form.gate);
				}
			}, 100)
		},
		qrcode() {
			EventBus.$emit('qr-modal', this.g.id);
		},
		getError(name) {
			let input = document.querySelector(`[name="${name}"]`);
			if(input) {
				input.parentNode.classList.add('field-error');
			}

			let err = this.errors.filter(er => {
				return er.key == name;
			})
			if(err && err.length) {
				return err[0].message
			}
		},
		onResize() {
			this.wWidth = window.innerWidth;
		},
		availabilityList() {
			EventBus.$emit('availability-modal');
		},
		vehiclesQuantity(value) {
			this.form.vehicle_quantity = value;
		},
		changeGate(value) {
			this.form.gatetype = value.type.toUpperCase();
		},
		smartOpen() {
			this.form.smart = !this.form.smart;
			if(this.form.smart) {
				this.handleDeviceModal();
			}
		},
		getPublic(e) {
			console.log('GET PUBLIC');
			this.isPublic = !this.isPublic;
			this.form.published = this.isPublic;
			
			if(!this.enablePublication) {
				this.showpublicmodal = true;
			}
			if(this.isPublic) {
				this.$service.availability.get(this.g.id, dayjs().toISOString()).then(res => {
					console.log(this.noAvailabilities);
					if(res && res.data && res.data.length) {
						this.noAvailabilities = false;
					} else {
						this.noAvailabilities = true;
					}
				}).catch(err => {
					console.log('ERR', err);
				})
			} else {
				this.noAvailabilities = false;
			}
			
		},
		initVehicles() {
			// this.vehiclesType = [];
			// this.vehiclePromise = new Promise(resolve => {
			// 	console.log('VEHICLE PROMISE');
			// 	resolve('VEICOLI');
			// 	// this.$service.vehicles.get().then(res => {
			// 	// 	if(res && res.data && res.data.length) {
			// 	// 		this.vehiclesType = [...res.data];
			// 	// 	}
			// 	// 	resolve();
			// 	// })
			// })
			// this.gatePromise = new Promise(resolve => {
			// 	console.log('GATE PROMISE');
			// 	resolve('GATES')
			// 	// this.$service.garages.enum.gates().then(res => {
			// 	// 	if(res && res.data && res.data.length) {
			// 	// 		console.log('GATES', res);
			// 	// 	}
			// 	// 	resolve();
			// 	// })
			// })
			// return new Promise(resolve => {
			// 	this.$service.vehicles.get().then(res => {
			// 		if(res && res.data && res.data.length) {
			// 			this.vehiclesType = [...res.data];
			// 		}
			// 		resolve();
			// 	})
			// })
		},
		initGarage() {
			this.loadGallery = true;
			this.quantityEditable = false;
			
			this.form.name = this.g.name;
			this.form.photos = [...this.g.gallery];
			this.form.vehicle_types = [];
			this.form.floor = this.g.floor;
			this.form.charateristics = {
				stairs: this.g.stairs,
				elevator: this.g.elevator,
				charge_station: this.g.charge_station,
				gpl: this.g.gpl,
				indoor: this.g.indoor,
				barriers: this.g.barriers,
			};
			this.form.vehicle_quantity = this.g.vehicle_quantity;
			this.form.gate = this.g.gate;
			this.form.type = this.g.type;
			this.form.address = this.g.address;
			this.form.lat = this.g.lat;
			this.form.lng = this.g.lng;
			this.form.smart = this.g.smart;
			this.form.published = this.g.published;
			this.isPublic = this.form.published;
			this.form.descriptions = {
				public: this.g.description,
				private: this.g.private_description
			};

			if(this.g.devices && this.g.devices.length) {
				this.devices = [...this.g.devices];
				// if(this.g.devices[0].configuration && this.g.devices[0].configuration.password) {
				// 	this.form.devices[0].configuration.password = this.g.devices[0].configuration.password
				// }
				// if(this.g.devices[0].configuration && this.g.devices[0].configuration.mac_address) {
				// 	this.form.devices[0].configuration.mac_address = this.g.devices[0].configuration.mac_address
				// }
			}

			this.g.vehicle_types.forEach(vt => {
				this.form.vehicle_types.push(vt.id);
			})

			if(this.g.lat && this.g.lng) {
				setTimeout(() => {
					this.$maps.setupMarker(this.g.lat, this.g.lng);
				}, 500);
			}
			setTimeout(() => {
				this.$refs.photoslider.init();
				if(this.$refs.photoslider) {
					this.galleryLoaded = true;
				}
			}, 150)

		},
		newGarage() {
			setTimeout(() => {
				this.$maps.setupMarker();
				this.$refs.photoslider.init();
				this.galleryLoaded = true;
			}, 500);
		},
		handleUploadPhoto(item) {
			return new Promise((resolve, reject) => {
				const formdata = new FormData();
				formdata.append('file', item);
				if(this.g && this.g.id) {
					formdata.append('garage_id', this.g.id);
				}
				this.$service.garages.media.main.post(formdata, { 'content-type': 'multipart/form-data' }).then(res => {
					if(res && res.data) {
						this.form.photos.unshift(res.data);
						resolve();
					}
				}).catch(err => {
					console.error('ERR', err);
					reject(err);
				})
			})
		},
		addPhoto(e) {
			let promises = [];
			Array.from(e.target.files).forEach(f => {
				promises.push(this.handleUploadPhoto(f));
			})

			Promise.all(promises).then(res => {
				console.log('Promises fulfilled');
			})
		},
		deletePhoto(e) {
			let arrid = e.target.parentNode.getAttribute('data-index');
			let photoid = e.target.parentNode.getAttribute('data-id');
			if(arrid && arrid.length) {
				this.form.photos.splice(+arrid, 1);
				this.$service.garages.media.detail.delete(photoid).then(res => {
				})
			}
		},
		saveGarage() {
			if(this.form) {
				this.garage = {
					name: this.form.name ? this.form.name : '',
					address: this.form.address ? this.form.address : '',
					description: this.form.descriptions.public ? this.form.descriptions.public : '',
					private_description: this.form.descriptions.private ? this.form.descriptions.private : '',
					floor: this.form.floor,
					indoor: this.form.charateristics.indoor,
					gate: this.form.gate ? this.form.gate : '',
					elevator: this.form.charateristics.elevator,
					stairs: this.form.charateristics.stairs,
					barriers: this.form.charateristics.barriers,
					charge_station: this.form.charateristics.charge_station,
					gpl: this.form.charateristics.gpl,
					lat: this.form.lat ? this.form.lat : '',
					lng: this.form.lng ? this.form.lng : '',
					zip: this.form.zip,
					city: this.form.city,
					vehicle_quantity: this.form.vehicle_quantity,
					vehicle_types: this.form.vehicle_types,
					gallery: [],
					smart: this.form.smart,
					published: this.form.published,
					type: this.form.type ? this.form.type : '',
				}

				if(this.garage.smart) {
					this.garage.devices = [...this.devices]
					// this.garage.devices = [{
					// 	configuration: {
					// 		password: this.form.devices[0].configuration.password ? this.form.devices[0].configuration.password : null,
					// 		mac_address: this.form.devices[0].configuration.mac_address ? this.form.devices[0].configuration.mac_address : null
					// 	}
					// }]
				}
				this.form.photos.forEach(p => {
					this.garage.gallery.push(p.id);
				})

				setTimeout(() => {
					// empty errors
					this.errors = [];
					this.msg = '';
					this.formResult = '';

					// check
					if(this.garage.smart) {
						["name", "description", "address", "type", "gate", "mac_address", "password", "floor", "lat", "lng"].map(checkField => {
							// if(checkField == 'mac_address' || checkField == 'password') {
							// 	if (!this.garage.devices[0].configuration[checkField]) {
							// 		this.errors.push({ key: checkField, message: this.$t(`garages.errors.${checkField}`) });
							// 	}
							// } else {
							// 	if (!this.garage[checkField].toString().trim().length) {
							// 		this.errors.push({ key: checkField, message: this.$t(`garages.errors.${checkField}`) });
							// 	}
							// }
						})
					} else {
						["name", "description", "address", "type", "gate", "floor", "lat", "lng"].map(checkField => {
							if (!this.garage[checkField].toString().trim().length) {
								this.errors.push({ key: checkField, message: this.$t(`garages.errors.${checkField}`) });
							}
						})
					}
					
					if (!this.errors.length) {
						if(!this.g) {
							this.og = this.garage;
							EventBus.$emit('confirm-park', this.garage);
						} else {
							if(this.noAvailabilities) {
								document.querySelector('.garagedetail__toggle').scrollIntoView({
									behavior: 'smooth',
									block: 'center'
								})
								setTimeout(() => {
									document.querySelector('.garagedetail__noava').classList.add('highlight');
								}, 800)
								setTimeout(() => {
									document.querySelector('.garagedetail__noava').classList.remove('highlight');
								}, 2500)
							} else {
								EventBus.$emit('startsave', { type: 'startsave' });
								console.log('DA SALVARE', this.garage);
								this.$service.garages.detail.put(this.g.id, this.garage).then(res => {
									console.log('SAVE GARAGE', res);
									EventBus.$emit('endsave', { type: 'garageedit', data: res, res: 'check' });
								}).catch(err => {
									EventBus.$emit('endsave', { type: 'error', data: err, res: 'close' });
									console.error('ERRore', err);
								})
							}
		
						}
					} else {
						console.log('--->>> ERROR');
						this.formResult = 'error';
						this.msg = this.$t('form.formerror')
					}
				}, 100)
			}
		},
		deleteGarage() {
			EventBus.$emit('delete-garage', this.g);
		}
	},
	mounted() {
		this.onResize();
		this.$nextTick(() => {
			window.addEventListener('resize', () => {
				this.onResize();
			});
		});


		this.$service.user.me.get().then(res => {
			if(res) {
				// if(res.document_verified_at && res.email_verified_at && res.phone_verified_at) {
				// 	this.enablePublication = true;
				// }
				
				if(res.document_verified_at && res.email_verified_at && res.phone_verified_at && res.stripe_verified_at) {
					this.enablePublication = true;
				}
			}
		})


		EventBus.$on('change-place', place => {
			for(const component of place.address_components) {
				const componentType = component.types[0];

				switch(componentType) {
					case 'postal_code':
						this.form.zip = component.long_name;
						break;
					case 'locality':
						this.form.city = component.long_name;
						break;
				}
			}
			this.form.address = place.name;
			this.form.lat = place.geometry.location.lat()
			this.form.lng = place.geometry.location.lng()
		})

		EventBus.$on('markerchange', latlng => {
			this.form.lat = latlng.lat();
			this.form.lng = latlng.lng();
		})
		setTimeout(() => {
			this.$maps.init();

			this.$service.vehicles.get().then(vres => {
				if(vres && vres.data && vres.data.length) {
					this.vehiclesType = [...vres.data];
				}
				this.$service.garages.enum.gates().then(gres => {
					if(gres && gres.data && gres.data.length) {
						gres.data.forEach((g, gi) => {
							this.gateTypes.push({ id: `gate_${gi}`, type: g, value: this.$t(`garages.form.gatetypes.${g.toLowerCase()}`) });
						})

						this.$service.garages.enum.garages().then(pres => {
							if(pres && pres.data && pres.data.length) {
								pres.data.forEach((p, pi) => {
									this.parkType.push({ id: `park_${pi}`, type: p, value: this.$t(`garages.form.parktypes.${p.toLowerCase()}`) });
								})

								if(this.g) {
									this.initGarage();
								} else {
									this.newGarage();
								}
							}
						})
					}
				})
			})

		}, 100)

		EventBus.$on('update-select', value => {
			console.log('VALUE --->>>', value);
			if(value && value.type && value.type != '') {
				if(value.id.includes('park')) {
					this.form.type = value.type;
				}
				if(value.id.includes('gate')) {
					this.form.gate = value.type;
				}
			}
		})

		EventBus.$on('save-for-real', () => {
			EventBus.$emit('startsave', { type: 'startsave' });
			console.log('THE REAL OG --->>>', this.og);
			setTimeout(() => {
				if(this.og) {
					this.$service.garages.main.post(this.og).then(res => {
						EventBus.$emit('endsave', { type: 'garagecreate', data: res, res: 'check' });
					}).catch(err => {
						EventBus.$emit('endsave', { type: 'error', data: err, res: 'close' });
						console.error('ERRore', err);
						this.errors = [];
						this.errors.push(err.messages.message)
						this.msg = this.$t('form.senderror');
					})
				}
			}, 500)
		})

		EventBus.$on('close-public-modal', () => {
			this.showpublicmodal = false;
			this.isPublic = !this.isPublic;
			this.form.published = this.isPublic;
		})

		EventBus.$on('close-device-modal', () => {
			this.showDeviceModal = false;
		});

		EventBus.$on('save-devices', items => {
			this.devices = items;

			if(this.g) {
				this.g.devices = [...this.devices];
			} else {
				this.form.devices = [...this.devices];
			}
		})

	}
}
</script>
<style lang="scss">
.terms__item {
	cursor: pointer;
	&__text {
		display: block;
		width: calc(100% - 30px);
		margin-left: 10px;
	}
	&__label {
		font-variation-settings: 'wght' 400;
		a {
			color: $color-blue;
			font-variation-settings: 'wght' 600;
		}
	}
}
.garage__form {
	display: block;
	width: 100%;
	.garagedetail {
		&__noava {
			position: absolute;
			top: 100%;
			left: 0;
			display: block;
			width: 100%;
			font-size: 11px;
			color: $color-error;
			padding: 3px;
			&.highlight {
				background: rgba($color-error, 0.2);
			}
		}
		&__toggle {
			@extend %flexCenterBetween;
			width: 300px;
			position: relative;
			&__input {
				display: none;
			}
			&__label {
				@extend %text-15;
				display: block;
				font-family: $font-manrope;
				padding-right: var(--thirdgutter);
			}
			&__btn {
				display: block;
				@include size(48px,22px);
				border-radius: var(--minigutter);
				border: 1px solid $color-davy;
				position: relative;
				&:before {
					content: '';
					position: absolute;
					top: 50%;
					left: 2px;
					display: block;
					background: $color-blue;
					@include size(16px,16px);
					border-radius: 100%;
					transform: translateY(-50%);
					transition: 180ms linear;
					filter: grayscale(1);
				}
				&.on {
					&:before {
						left: calc(100% - 18px);
						filter: grayscale(0);
					}
				}
			}
		}
		&__availability {
			@extend %flexCenterCenter;
			background: $color-white;
			border: 1px solid $color-blue;
			border-radius: var(--minigutter);
			padding: 0 var(--thirdgutter);
			margin-right: var(--minigutter);
			&__icon, &__label {
				pointer-events: none;
			}
			&__icon {
				@extend %icon;
				svg {
					display: block;
					@include size(80%,80%);
					fill: $color-blue;
				}
			}
			&__label {
				display: block;
				font-variation-settings: 'wght' 700;
				color: $color-blue;
				padding-left: var(--thirdgutter);
			}
		}
	}
	.form {
		display: block;
		width: 100%;
		&__error {
			@extend %text-10;
			display: block;
			width: 100%;
			text-transform: uppercase;
			color: $color-error;
			padding: 5px;
		}
		&__field {
			width: 100%;
			
			&__title {
				@extend %text-20;
				width: 100%;
				font-family: $font-manrope;
				font-variation-settings: 'wght' 700;
				color: $color-blue;
				margin-bottom: var(--thirdgutter);
			}
			&__content {
				width: 100%;
			}
			.galleryloading {
				position: absolute;
				top: 0;
				left: 0;
				background: $color-white;
				@extend %flexCenterCenter;
				@include size(100%,var(--size));
				.spinner {
					@include size(30px,30px);
				}
			}
		}
		&__label {
			@extend %text-15;
			font-family: $font-manrope;
			font-variation-settings: 'wght' 600;
			margin-bottom: 0;
		}
		&__name {
			.form__input {
				@extend %text-25;
				font-family: $font-manrope;
				font-variation-settings: 'wght' 700;
				@media (max-width: $to-large) {
					font-size: 1.2rem;
				}
			}
		}
		&__photos {
			--size: 176px;
			padding-bottom: var(--supergutter);
			margin-bottom: var(--gutter);
			border-bottom: 1px solid $color-silver;
			position: relative;
			&__count {
				@extend %text-14;
				position: absolute;
				bottom: var(--gutter);
				right: 0;
				border-radius: var(--bigradius);
				border: 1px solid rgba($color-black, 0.15);
				padding: 5px var(--thirdgutter) 3px;
				transform: translateY(50%)
			}
			.flicking-viewport {
				height: var(--size);
			}
			&__add {
				@extend %flexCenterCenter;
				@include size(var(--size),var(--size));
				border-radius: var(--bigradius);
				border: 1px solid $color-silver;
				position: relative;
				cursor: pointer;
				&__input {
					position: absolute;
					top: 0;
					left: 0;
					bottom: 0;
					right: 0;
					opacity: 0;
					cursor: pointer;
				}
				&__label {
					@extend %flexCenterCenter;
					background: $color-blue;
					@include size(max-content, var(--gutter));
					color: $color-white;
					font-variation-settings: 'wght' 700;
					padding: 0 var(--minigutter);
					border-radius: var(--radius);
				}
				&.full {
					width: 100%;
				}
			}
			&__item {
				display: block;
				height: var(--size);
				position: relative;
				border-radius: var(--radius);
				overflow: hidden;
				margin-right: var(--thirdgutter);
				&__delete {
					@extend %flexCenterCenter;
					position: absolute;
					top: var(--minigutter);
					right: var(--minigutter);
					@include size(var(--input),var(--input));
					background: $color-white;
					border-radius: 100%;
					svg {
						display: block;
						@include size(50%,50%);
						fill: $color-black;
						pointer-events: none;
					}
				}
				&__image {
					display: block;
					@include size(100%,100%);
					object-fit: contain;
				}
			}
		}
		&__map {
			width: 100%;
			&__map {
				display: block;
				width: 100%;
				background: $color-seasalt;
				border-radius: var(--radius);
				aspect-ratio: 16 / 7;
				margin-top: var(--minigutter);
			}
			&__instructions {
				@extend %flexCenterStart;
				width: 100%;
				margin: var(--thirdgutter) 0 var(--gutter);
				padding-left: var(--radius);
				&__icon {
					@extend %icon;
				}
				&__label {
					@extend %text-13;
					display: block;
					width: calc(100% - var(--smallicon));
					font-variation-settings: 'wght' 600;
					padding-left: var(--minigutter)
				}
			}
		}
		&__vehicles {
			&__list {
				@extend %flexCenterStart;
				flex-wrap: wrap;
				width: max-content;
				margin: var(--gutter) 0;
				.vehicle {
					@extend %flexCenterStart;
					width: 100%;
					margin-bottom: var(--thirdgutter);
					.form__checkbox {
						border: 2px solid $color-blue !important;
						border-radius: calc(var(--radius) / 3);
					}
					input[type="checkbox"]:not(checked) ~ .form__checkbox {
						border: 2px solid $color-blue !important;
					}
					&__text {
						@extend %flexCenterStart;
						width: max-content;
						padding-left: var(--minigutter)
					}
					&__icon {
						@extend %icon;
						@include size(var(--icon),var(--icon));
						svg {
							display: block;
							@include size(100%,100%);
						}
					}
					&__label {
						display: block;
						width: calc(100% - var(--icon));
						padding-left: var(--thirdgutter);
					}
				}
			}
		}
		&__characts {
			&__content {
				@extend %flexStartBetween;
				flex-wrap: wrap;
				width: 100%;
			}
			&__col {
				width: 100%;
			}
			.characts {
				&__block {
					display: block;
					width: 100%;
					margin-bottom: var(--supergutter);
					&__label {
						@extend %text-12;
						display: block;
						width: 100%;
						font-family: $font-manrope;
						font-variation-settings: 'wght' 700;
						color: $color-davy;
						text-transform: uppercase;
						margin-bottom: var(--thirdgutter);
					}
				}
				&__floor {
					@extend %flexCenterStart;
					flex-wrap: wrap;
					width: 100%;
					&__item {
						@extend %flexCenterCenter;
						@include size(var(--icon),var(--icon));
						position: relative;
						overflow: hidden;
						padding-bottom: 0;
						margin-right: var(--thirdgutter);
						cursor: pointer;
						.form__input {
							position: absolute;
							top: 0;
							left: 0;
							opacity: 0;
							cursor: pointer;
						}
						&__icon {
							@extend %flexCenterCenter;
							@include size(100%,100%);
							border-radius: var(--radius);
							border: 1px solid $color-blue;
							pointer-events: none;
							svg {
								display: block;
								@include size(60%,60%);
								fill: $color-black;
							}
						}
						.form__input:checked ~ .characts__floor__item__icon {
							background: $color-blue;
							svg {
								fill: $color-white;
							}
						}
						.form__input:not(:checked) ~ .characts__floor__item__icon {
							background: $color-white;
							svg {
								fill: $color-black;
							}
						}
					}
				}
				&__misc {
					display: block;
					width: 100%;
					margin-top: var(--gutter);
					&__text {
						display: block;
						width: 100%;
						margin-top: var(--gutter);
						&.red {
							color: $color-red !important;
						}
					}
					&__txt {
						display: block;
						@include size(80%, calc(var(--input) * 2));
						border-radius: var(--radius);
						border: 1px solid rgba($color-black, 0.15);
						padding: var(--thirdgutter);
						resize: none;
					}
				}
				&__item {
					@extend %flexCenterStart;
					width: max-content;
					margin-bottom: var(--minigutter);
					cursor: pointer;
					.form__checkbox {
						border: 2px solid $color-blue;
						border-radius: calc(var(--radius) / 3);
					}
					input[type="checkbox"]:not(checked) ~ .form__checkbox {
						border: 2px solid $color-blue !important;
					}
					&__text {
						@extend %flexCenterStart;
						width: 100%;
						padding-left: var(--minigutter)
					}
					&__icon {
						@extend %icon;
						@include size(var(--smallicon),var(--smallicon));
						svg {
							display: block;
							@include size(100%,100%);
						}
					}
					&__label {
						display: block;
						width: max-content;
						// width: calc(100% - var(--icon));
						padding-left: var(--thirdgutter);
					}
				}
			}
			&__select {
				display: block;
				width: 100%;
				margin-bottom: var(--gutter);
				&__title {
					@extend %text-15;
					display: block;
					width: 100%;
					font-family: $font-manrope;
					font-variation-settings: 'wght' 600;
					margin-bottom: var(--thirdgutter);
				}
				.form__select {
					width: 100%;
					max-width: 300px;
				}
			}
			&__truefalse {
				display: block;
				width: 100%;
				margin-bottom: var(--gutter);
				&__title {
					@extend %text-15;
					display: block;
					width: 100%;
					font-family: $font-manrope;
					font-variation-settings: 'wght' 600;
				}
				.truefalse {
					&__toggle {
						@extend %flexCenterBetween;
						width: 100%;
						max-width: 300px;
						&__input {
							display: none;
						}
						&__label {
							@extend %text-15;
							display: block;
							font-family: $font-manrope;
							padding-right: var(--thirdgutter);
						}
						&__btn {
							display: block;
							@include size(48px,22px);
							border-radius: var(--minigutter);
							border: 1px solid $color-davy;
							position: relative;
							&:before {
								content: '';
								position: absolute;
								top: 50%;
								left: 2px;
								display: block;
								background: $color-blue;
								@include size(16px,16px);
								border-radius: 100%;
								transform: translateY(-50%);
								transition: 180ms linear;
								filter: grayscale(1);
							}
							&.on {
								&:before {
									left: calc(100% - 18px);
									filter: grayscale(0);
								}
							}
						}
					}
				}
			}
			&__numbers {
				display: block;
				width: 100%;
				&__info {
					@extend %flexStartStart;
					width: 100%;
					margin-top: var(--gutter);
					&__icon {
						@extend %flexCenterCenter;
						@include size(var(--smallicon),var(--smallicon));
						svg {
							display: block;
							@include size(100%,100%);
							fill: $color-error;
						}
					}
					&__text {
						display: block;
						width: 100%;
						@extend %text-13;
						padding-left: var(--thirdgutter);
						padding-bottom: var(--gutter);
					}
				}
			}
		}
		&__description {
			&__content {
				@extend %flexStartBetween;
				flex-wrap: wrap;
				width: 100%;
			}
			&__col {
				display: block;
				width: 100%;
				padding-bottom: var(--gutter);
				&:last-of-type {
					padding-bottom: var(--minigutter);
				}
			}
			&__label {
				@extend %text-15;
				display: block;
				width: 100%;
				font-family: $font-manrope;
				font-variation-settings: 'wght' 600;
				margin-bottom: var(--thirdgutter);
			}
			&__info {
				@extend %text-13;
				display: block;
				width: 100%;
				font-variation-settings: 'wght' 500;
				color: $color-davy;
				min-height: 40px;
				margin-bottom: var(--thirdgutter);
			}
		}
		&__actions {
			@extend %flexCenterBetween;
			flex-wrap: wrap;
			width: 100%;
			margin-top: 0;
			padding-top: var(--gutter);
			&__btn {
				width: 100%;
				min-width: 196px;
				color: $color-white;
				padding: 0 var(--gutter);
				&.cancel {
					background: $color-red;
					order: 2;
					&.disabled {
						opacity: 0;
						pointer-events: none;
					}
				}
				&.save {
					background: $color-blue;
					order: 1;
					margin-bottom: var(--minigutter);
				}
			}
		}
		&__panel {
			@extend %panel;
			margin: var(--thirdgutter) 0 var(--gutter);
			padding: calc(var(--thirdgutter) * 2);
			border-bottom: 0;
		}
		.disabled {
			opacity: 0.4 !important;
			cursor: not-allowed;
			& * {
				pointer-events: none !important;
			}
		}
		@media (min-width: $from-large) {
			&__vehicles {
				&__list {
					.vehicle {
						width: 50%;
					}
				}
			}
			&__panel {
				box-shadow: none;
				border-radius: 0;
				background: transparent;
				padding: 0;
				border-bottom: 1px solid $color-silver;
			}
			&__field:last-of-type {
				.form__panel {
					border-bottom: 0;
				}
			}
			&__characts,
			&__description {
				&__col {
					width: 48%;
				}
			}
			&__description {
				&__col {
					padding-bottom: var(--supergutter);
				}
			}
			&__actions {
				margin-top: var(--supergutter);
				&__btn {
					width: max-content;
					&.cancel {
						order: 1;
					}
					&.save {
						order: 2;
						margin-bottom: 0;
					}
				}
			}
		}
	}
	.legenda {
		display: block;
		width: 100%;
		margin-top: var(--gutter);
		@extend %text-12;
		opacity: 0.7;
	}
	@media (max-width: $to-large) {
		.form__description {
			margin-bottom: 0;
		}
		.garagedetail {
			&__toggle {
				width: 100%;
				margin-top: var(--minigutter);
			}
			&__availability {
				justify-content: space-between;
				width: 100%;
				padding: var(--gutter);
				border: 0;
				background: $color-white;
				box-shadow: $shadow;
				border-radius: var(--radius);
				margin-right: 0;
				margin-bottom: var(--gutter);
				&__label {
					padding-left: 0;
					padding-right: var(--thirdgutter);
				}
				&__icon {
					svg {
						@include size(60%,60%);
						transform: scaleX(-1);
					}
				}
			}
		}
	}

	.macaddress {
		display: block;
		width: 100%;
		max-width: 300px;
		margin: var(--thirdgutter) 0;
		input {
			display: block;
			width: 100%;
			margin-bottom: var(--thirdgutter);
		}
	}

	.smartbox {
		display: block;
		width: 100%;
		max-width: 300px;
		padding: var(--gutter) 0;
		&__head {
			@extend %flexCenterBetween;
			width: 100%;
			margin-bottom: var(--minigutter);
			&__label {
				@extend %text-15;
				display: block;
				width: 60%;
			}
			&__btn {
				@extend %flexCenterCenter;
				background: $color-blue;
				// @include size(45px,var(--gutter));
				width: 100%;
				border-radius: var(--radius);
				position: relative;
				padding: var(--thirdgutter);
				color: $color-white;
				font-variation-settings: 'wght' 600;
				cursor: pointer;
				svg {
					display: block;
					@include size(25px,25px);
					fill: $color-white;
					margin-left: var(--minigutter);
				}
			}
		}
	}

	.devices {
		display: block;
		width: 100%;
		margin: 0;
		padding-bottom: var(--minigutter);
		&__item {
			@extend %flexBaselineBetween;
			flex-wrap: wrap;
			width: 100%;
			padding-bottom: var(--thirdgutter);
			padding-top: var(--minigutter);
			border-bottom: 1px solid rgba($color-black, 0.1);
			&:last-of-type {
				border-bottom: 0;
			}
			&__name, &__type, &__config {
				display: block;
			}
			&__name, &__type {
				width: max-content;
			}
			&__name {
				@extend %text-20;
				font-variation-settings: 'wght' 600;
			}
			&__config {
				width: 100%;
				padding: 10px 0;
				.dconfig {
					&__row {
						display: block;
						width: 100%;
						margin-bottom: var(--minigutter);
					}
					&__label {
						display: block;
						width: 100%;
					}
					&__item {
						@extend %flexBaselineBetween;
						width: 100%;
						&__label {
							@extend %text-12;
							display: block;
							width: max-content;
							padding-right: var(--thirdgutter);
							text-transform: uppercase;
						}
					}
				}
			}
		}
	}
	
}
</style>
