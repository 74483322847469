import { EventBus } from '@/events_bus.js';

const PLACES =
	process.env.VUE_APP_PLACES !== undefined &&
	process.env.VUE_APP_PLACES !== ""
		? process.env.VUE_APP_PLACES
		: "{{ VUE_APP_PLACES }}";

export default {
	install: function (Vue) {
		((g) => {
			var h,
				a,
				k,
				p = "The Google Maps JavaScript API",
				c = "google",
				l = "importLibrary",
				q = "__ib__",
				m = document,
				b = window;
			b = b[c] || (b[c] = {});
			var d = b.maps || (b.maps = {}),
				r = new Set(),
				e = new URLSearchParams(),
				u = () =>
					h ||
					(h = new Promise(async (f, n) => {
						await (a = m.createElement("script"));
						e.set("libraries", [...r] + "");
						for (k in g)
							e.set(
								k.replace(
									/[A-Z]/g,
									(t) => "_" + t[0].toLowerCase()
								),
								g[k]
							);
						e.set("callback", c + ".maps." + q);
						a.src = `https://maps.${c}apis.com/maps/api/js?` + e;
						d[q] = f;
						a.onerror = () =>
							(h = n(Error(p + " could not load.")));
						a.nonce = m.querySelector("script[nonce]")?.nonce || "";
						m.head.append(a);
					}));
			d[l]
				? console.warn(p + " only loads once. Ignoring:", g)
				: (d[l] = (f, ...n) =>
						r.add(f) && u().then(() => d[l](f, ...n)));
		})({
			key: PLACES,
			v: "weekly",
			libraries: 'places'
		});

		let marker = null;
		let autocomplete = null;
		let map = null;
		let image = null;

		const maps = {
			setupMarker: (lat, lng) => {
				if(lat && lng) {
					marker.setVisible(false);
					const pos = new google.maps.LatLng(lat, lng);
					marker.setPosition(pos);
					map.setCenter(pos);
					map.setZoom(15);
					marker.setVisible(true);
				} else {
					let newlat = 45.4386918;
					let newlng = 10.9933224;
					setTimeout(() => {
						const pos = new google.maps.LatLng(newlat, newlng);
						map.setCenter(pos);
						map.setZoom(15);
					}, 100)
				}
			},
			init: () => {
				async function initMap(lat, lng) {
					let latnew = lat || 45.4386918;
					let lngnew = lng || 10.9933224;

					const { Map } = await google.maps.importLibrary("maps");
					// add current location
					map = new Map(document.getElementById("map"), {
						center: { lat: latnew, lng: lngnew },
						zoom: 14,
						scrollwheel: false
					});

					autocomplete = new google.maps.places.Autocomplete(
						document.querySelector("#address.autocomplete"),
						{
							fields: [
								"address_components",
								"geometry",
								"icon",
								"name"
							],
							strictBounds: false,
							types: ["address"]
						}
					);

					autocomplete.bindTo("bounds", map);

					image = './images/mapmarker.png';

					marker = new google.maps.Marker({
						map,
						anchorPoint: new google.maps.Point(0, -29),
						icon: image,
						draggable: true
					});
					// const marker2 = new google.maps.Marker({
					// 	map,
					// 	anchorPoint: new google.maps.Point(45.402644950740765, 10.968606862112107),
					// });

					autocomplete.addListener("place_changed", () => {
						marker.setVisible(false);
						const place = autocomplete.getPlace();

						if (!place.geometry || !place.geometry.location) {
							// User entered the name of a Place that was not suggested and
							// pressed the Enter key, or the Place Details request failed.
							window.alert("No details available for input: '" + place.name + "'");
							return;
						}

						// If the place has a geometry, then present it on a map.
						if (place.geometry.viewport) {
							map.fitBounds(place.geometry.viewport);
						} else {
							map.setCenter(place.geometry.location);
							map.setZoom(15);
						}
						marker.setPosition(place.geometry.location);
						marker.setVisible(true);

						EventBus.$emit('change-place', place);
					});

					google.maps.event.addListener(marker, 'dragend', marker => {
						let latLng = marker.latLng;
						EventBus.$emit('markerchange', latLng);
					})

				}

				initMap();
			}
		};

		Vue.prototype.$maps = maps;
	},
};
