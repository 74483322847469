<template>
	<div class="accountidentity">
		<div class="accountidentity__head">
			<span class="accountidentity__head__back" @click="closeDrawer">
				<span class="accountidentity__head__back__icon">
					<svg viewBox="0 0 50 50">
						<use xlink:href="#icon--back" width="100%" height="100%"></use>
					</svg>
				</span>
				<span class="accountidentity__head__back__label">
					{{ $t('common.back') }}
				</span>
			</span>
		</div>
		<div class="accountidentity__box">
			<div v-if="isLoading" class="accountedit__loading blocked">
				<template v-if="!editSaved">
					<span class="accountedit__loading__spinner">
						<span class="accountedit__loading__spinner__box">
							<Spinner />
						</span>
						<span class="accountedit__loading__spinner__label">
							{{ $t('common.is_saving') }}
						</span>
					</span>
				</template>
				<template v-else>
					<span class="accountedit__loading__success">
						<template v-if="idcard">
							{{ $t('common.edit_saved') }}
						</template>
						<template v-else>
							{{ $t('common.doc_saved') }}
						</template>
					</span>
				</template>
			</div>
			<div class="accountidentity__box__head">
				<div class="accountidentity__box__head__title">
					{{ $t('account.identity.title') }}
				</div>
				<span class="accountidentity__box__head__close" @click="closeModal">
					<svg viewBox="0 0 50 50">
						<use xlink:href="#icon--close" width="100%" height="100%"></use>
					</svg>
				</span>
			</div>
			<div class="accountidentity__box__text" v-html="$t('account.identity.text')"></div>
			<div class="accountidentity__doc">
				<span class="accountidentity__doc__label">
					{{ $t('account.identity.front') }}
				</span>
				<figure v-if="frontImage && frontImage.filename && frontImage.filename != ''" class="accountidentity__doc__media">
					<img class="accountidentity__doc__media__image" :src="frontImage.filename" alt="" />
				</figure>
				<span class="accountidentity__doc__btn">
					<input type="file" :id="$t('account.identity.front')" class="accountidentity__doc__btn__upload" accept="image/png, image/gif, image/jpeg" @change="uploadFront" />
					<span class="accountidentity__doc__btn__fakebtn btn filled">
						<template v-if="!frontImage">
							{{ $t('account.identity.upload') }}
						</template>
						<template v-else>
							{{ $t('account.identity.substitute') }}
						</template>
					</span>
				</span>
			</div>
			<div class="accountidentity__doc">
				<span class="accountidentity__doc__label">
					{{ $t('account.identity.back') }}
				</span>
				<figure v-if="backImage && backImage.filename && backImage.filename != ''" class="accountidentity__doc__media">
					<img class="accountidentity__doc__media__image" :src="backImage.filename" alt="" />
				</figure>
				<span class="accountidentity__doc__btn">
					<input type="file" :id="$t('account.identity.back')" class="accountidentity__doc__btn__upload" accept="image/png, image/gif, image/jpeg" @change="uploadBack" />
					<span class="accountidentity__doc__btn__fakebtn btn filled">
						<template v-if="!backImage">
							{{ $t('account.identity.upload') }}
						</template>
						<template v-else>
							{{ $t('account.identity.substitute') }}
						</template>
					</span>
				</span>
			</div>
			<div class="accountidentity__date">
				<span class="accountidentity__date__label">
					{{ $t('account.identity.date') }}
				</span>
				<date-picker v-model="expirationDate" valueType="timestamp" format="DD/MM/YYYY" :disabled-date="(date) => date < new Date()">
					<template v-slot:icon-calendar>
						<svg viewBox="0 0 50 50" width="300" height="300">
							<use xlink:href="#icon--form-calendar" width="100%" height="100%"></use>
						</svg>
					</template>
				</date-picker>
			</div>
			<div class="accountidentity__actions">
				<span id="cancel" class="accountidentity__button btn" @click="closeModal">
					{{ $t('common.btns.cancel') }}
				</span>
				<span id="save" class="accountidentity__button btn filled" @click="saveEdit">
					{{ $t('common.btns.save') }}
				</span>
			</div>
		</div>
	</div>
</template>
<script>
import { EventBus } from '@/events_bus.js';
import dayjs from 'dayjs';
import { gsap } from 'gsap';
import { CustomEase } from 'gsap/all';
gsap.registerPlugin(CustomEase);

import DatePicker from 'vue2-datepicker';

import Spinner from '@/views/components/common/Spinner';

export default {
	name: 'Identity',
	props: {
		mode: {
			type: String,
			required: true,
			default: 'mobile'
		}
	},
	components: {
		DatePicker,
		Spinner
	},
	data() {
		return {
			idcard: null,
			isLoading: false,
			editSaved: false,
			originalDocs: null,
			expirationDate: null,
			frontImage: null,
			backImage: null
		}
	},
	methods: {
		openModal() {
			gsap.timeline({
				onComplete: () => {
					gsap.set('.accountidentity', {
						pointerEvents: 'all'
					})
				}
			})
				.to('.accountidentity', {
					duration: 0.3,
					opacity: 1
				})
				.to('.accountidentity__box', {
					duration: 0.3,
					y: '0%',
					opacity: 1
				})
		},
		closeModal() {
			gsap.timeline({
				onComplete: () => {
					gsap.set('.accountidentity', {
						pointerEvents: 'none'
					})
				}
			})
			.to('.accountidentity__box', {
				duration: 0.3,
				y: 150,
				opacity: 0
			})
			.to('.accountidentity', {
				duration: 0.3,
				opacity: 0
			})
		},
		openDrawer() {
			gsap.to('.accountidentity', {
				duration: 0.9,
				x: '0%',
				ease: CustomEase.create("custom", "M0,0 C0.726,0 0,1 1,1 "),
				onComplete: () => {
					gsap.set('.accountidentity', {
						pointerEvents: 'all'
					})
				}
			})
		},
		closeDrawer() {
			gsap.to('.accountidentity', {
				duration: 0.9,
				x: '100%',
				ease: CustomEase.create("custom", "M0,0 C0.726,0 0,1 1,1 ")
			})
		},
		uploadFront(e) {
			const formdata = new FormData();
			formdata.append('file', e.target.files[0]);
			this.$service.media.main.post(formdata, { 'content-type': 'multipart/form-data' }).then(res => {
				this.frontImage = res;
			}).catch(err => {
				console.error('ERR', err);
			})
		},
		uploadBack(e) {
			const formdata = new FormData();
			formdata.append('file', e.target.files[0]);

			this.$service.media.main.post(formdata, { 'content-type': 'multipart/form-data' }).then(res => {
				this.backImage = res;
			}).catch(err => {
				console.error('ERR', err);
			})
		},
		saveEdit() {
			this.isLoading = true;
			document.querySelector('.accountidentity__box').scrollTo(0, 0)
			let documents = {
				type: 'IDENTITY_CARD',
				expires_at: null,
				front: null,
				back: null
			}
			if(this.expirationDate) {
				documents.expires_at = dayjs(this.expirationDate).format('YYYY-MM-DDTHH:mm:ss');
				if(this.frontImage) {
					documents.front = this.frontImage.id;
				}
				if(this.backImage) {
					documents.back = this.backImage.id;
				}
				if(documents.expires_at && documents.front && documents.back) {
					if(!this.idcard) {
						this.$service.docs.main.post(documents).then(res => {
							this.editSaved = true;
							setTimeout(() => {
								this.isLoading = false;
								this.editSaved = false;
								if(this.mode == 'mobile') {
									this.closeDrawer()
								} else {
									this.closeModal();
								}
								EventBus.$emit('edit-saved');
							}, 3000);
						})
					} else {
						this.$service.docs.detail.put(this.idcard.id, documents).then(res => {
							this.editSaved = true;
							setTimeout(() => {
								this.isLoading = false;
								this.editSaved = false;
								if(this.mode == 'mobile') {
									this.closeDrawer()
								} else {
									this.closeModal();
								}
								EventBus.$emit('edit-saved');
							}, 3000);
						})
					}
				}
			}
		},
		closeEdit() {
			if(this.mode == 'mobile') {
				this.closeDrawer()
			} else {
				this.closeModal();
			}
		},
		init() {
		},
	},
	mounted() {
		this.$service.docs.main.get().then(res => {
			if(res && res.data && res.data.length) {
				let t = res.data.filter(d => {
					return d.type == 'IDENTITY_CARD';
				})
				if(t && t.length) {
					this.idcard = t[0];
					if(this.idcard.front && this.idcard.front.filename) {
						this.frontImage = this.idcard.front;
					}
					if(this.idcard.back && this.idcard.back.filename) {
						this.backImage = this.idcard.back;
					}
					if(this.idcard.expires_at && this.idcard.expires_at != '') {
						this.expirationDate = dayjs(this.idcard.expires_at).valueOf();
					}
				}
			}
		})
		EventBus.$on('upload-identity', () => {
			this.init();
			if(this.mode == 'mobile') {
				this.openDrawer()
			} else {
				this.openModal();
			}
		});

		this.init();
	}
}
</script>
<style lang="scss">
@import '~vue2-datepicker/scss/index.scss';

.accountidentity {
	@extend %flexCenterCenter;
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	background: $color-seasalt;
	z-index: 10000;
	display: block;
	overflow: auto;
	transform: translateX(100%);
	pointer-events: none;
	&__head {
		@extend %flexCenterStart;
		@include size(100%, var(--headDrawer));
		padding: 0 var(--gutter);
		&__back {
			@extend %flexCenterStart;
			width: calc(100% - var(--btn));
			cursor: pointer;
			&__icon {
				@extend %flexCenterCenter;
				@include size(var(--smallicon),var(--smallicon));
				svg {
					display: block;
					@include size(75%,75%);
					fill: $color-black;
				}
			}
			&__label {
				@extend %text-15;
				display: block;
				width: max-content;
				font-variation-settings: 'wght' 600;
				padding-left: var(--minigutter);
			}
		}
	}
	&__box {
		@extend  %panel;
		background: transparent;
		padding: 0 var(--gutter);
		height: auto;
		overflow: auto;
		padding-bottom: var(--supergutter);
		position: relative;
		&__head {
			@extend %flexCenterBetween;
			width: 100%;
			display: none;
			margin-bottom: var(--gutter);
			&__title {
				@extend %text-33;
				display: block;
				width: 100%;
				font-family: $font-manrope;
				font-variation-settings: 'wght' 700;
			}
			&__close {
				@extend %icon;
				cursor: pointer;
				svg {
					@include size(70%,70%);
					fill: $color-black;
				}
			}
		}
		&__text {
			@extend %text-19;
			display: block;
			width: 100%;
			margin-bottom: var(--supergutter);
		}
		@media (max-width: $to-large) {
			box-shadow: none;
		}
		&.blocked {
			overflow: hidden;
		}
	}
	&__doc {
		display: block;
		width: 100%;
		margin: var(--minigutter) 0 calc(var(--gutter) * 1.5);
		&__label {
			@extend %text-15;
			display: block;
			width: 100%;
			font-family: $font-manrope;
			font-variation-settings: 'wght' 600;
			margin-bottom: var(--thirdgutter);
		}
		&__media {
			display: block;
			width: 100%;
			max-width: 340px;
			border: 1px solid $color-davy;
			border-radius: var(--radius);
			overflow: hidden;
			margin-bottom: var(--minigutter);
			&__image {
				display: block;
				@include size(100%,100%);
				object-fit: cover;
			}
		}
		&__btn {
			display: block;
			max-width: 340px;
			position: relative;
			&__upload {
				display: block;
				@include size(100%, 47px);
				overflow: hidden;
				opacity: 0;
				cursor: pointer;
			}
			&__fakebtn {
				position: absolute;
				top: 0;
				left: 0;
				background: $color-blue;
				@extend %flexCenterCenter;
				@include size(100%, var(--btn));
				font-variation-settings: 'wght' 700;
				color: $color-white;
				border-radius: var(--radius);
				pointer-events: none;
				cursor: pointer;
			}
		}
	}
	&__button {
		width: 100%;
		text-align: center;
		margin-bottom: var(--gutter);
		&.filled {
			background: $color-blue;
			color: $color-white;
		}
		&.outline {
			border-color: $color-blue;
			color: $color-blue
		}
	}
	&__date {
		display: block;
		width: 100%;
		max-width: 340px;
		&__label {
			@extend %text-15;
			display: block;
			width: 100%;
			font-family: $font-manrope;
			font-variation-settings: 'wght' 600;
			margin-bottom: var(--thirdgutter);
		}
		.mx-datepicker {
			width: 100%;
			.mx-input {
				background: $color-white;
				height: var(--input);
				border: 1px solid $color-white;
				border-radius: var(--inputradius);
				padding: 0 var(--minigutter);
				border: 1px solid $color-grey;
			}
			.mx-icon-calendar {
				svg {
					fill: $color-black;
				}
			}
		}
	}
	&__actions {
		@extend %flexCenterBetween;
		margin-top: var(--supergutter);
		.accountidentity__button {
			width: 49%;
		}
	}
	&__loading {
		position: absolute;
		bottom: 0;
		left: 0;
		@extend %flexCenterCenter;
		background: rgba($color-white, 0.86);
		@include size(100%,100%);
		pointer-events: all;
		z-index: 100;
		&__spinner {
			display: block;
			margin-bottom: var(--gutter);
			&__box {
				display: block;
				@include size(65px,65px);
				margin: 0 auto var(--gutter);
			}
			&__label {
				@extend %text-15;
				display: block;
				width: 100%;
				font-family: $font-manrope;
				font-variation-settings: 'wght' 600;
				text-align: center;
			}
		}
		&__success {
			display: block;
			@extend %text-33;
			color: $color-green;
			font-variation-settings: 'wght' 700;
			text-align: center;
		}
	}
	@media (min-width: $from-large) {
		display: flex;
		background: rgba($color-black, 0.1);
		transform: translateX(0%);
		opacity: 0;
		&__head {
			display: none;
		}
		&__landing {
			height: 75vh;
		}
		&__box {
			background: $color-white;
			max-height: 90vh;
			width: 780px;
			height: 75vh;
			padding: var(--gutter);
			transform: translateY(150px);
			opacity: 0;
			&__head {
				display: flex;
				margin-bottom: var(--thirdgutter);
			}
			&__text {
				font-size: 0.938rem;
			}
			&__content {
				position: absolute;
				top: 50%;
				left: 0;
				display: block;
				width: 100%;
				text-align: center;
				transform: translateY(-50%);
			}
		}
		&__actions {
			margin-left: 52%;
		}
	}
}
.mx-datepicker-main  {
	z-index: 100000 !important;
}
</style>