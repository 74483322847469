<template>
	<div class="qrmodal">
		<div class="qrmodal__box">
			<span class="qrmodal__box__close" @click="closeModal">
				<svg viewBox="0 0 50 50">
					<use xlink:href="#icon--close" width="100%" height="100%"></use>
				</svg>
			</span>
			<div class="qrmodal__box__content">
				<template v-if="downloaded">
					<span class="downloaded">
						<strong>
							{{ $t('garages.qr.downloaded.title') }}
						</strong>
						<span>
							{{ $t('garages.qr.downloaded.text') }}
						</span>
						<span class="btn outline" @click="closeModal">
							{{ $t('common.btns.close') }}
						</span>
					</span>
				</template>
				<template v-else>
					<span v-if="pdfloading" class="loading-canvas">
						<Spinner />
					</span>
					<div class="canvas">
						<span class="canvas__bg"></span>
						<span class="canvas__bg__bottom"></span>
						<div class="canvas__head">
							<span class="canvas__logo">
								<img src="@/assets/images/logo-garaging.png" alt="" />
							</span>
						</div>
						<div class="canvas__body">
							<div class="canvas__body__qr">
								<span v-if="loading" class="loading-qr">
									<Spinner />
								</span>
							</div>
							<div class="canvas__body__text">
								Scansiona il <strong>QR code</strong>,<br />scopri i dettagli del garage e prenotalo!<br />Tutto direttamente dall'app <strong>Garaging</strong>.
							</div>
						</div>
						<div class="canvas__foot">
							<div class="canvas__foot__info">
								www.garaging.it<br />
								info@garaging.it
							</div>
							<div class="canvas__foot__stores">
								<span class="stores__icon">
									<img src="@/assets/images/appstore.png" alt="" />
								</span>
								<span class="stores__icon">
									<img src="@/assets/images/playstore.png" alt="" />
								</span>
							</div>
						</div>
					</div>
					<div class="qrmodal__box__ctas">
						<span v-if="!loading" class="qrmodal__box__btn btn filled" @click="generatePDF" :class="{ 'generating': pdfloading }">
							<template v-if="!pdfloading">
								{{ $t('garages.qr.download') }}
							</template>
							<template v-else>
								{{ $t('garages.qr.generating') }}
							</template>
						</span>
					</div>
				</template>

			</div>
		</div>
	</div>
</template>
<script>
import { EventBus } from "@/events_bus.js";
import { gsap } from "gsap";
import { CustomEase } from "gsap/all";
gsap.registerPlugin(CustomEase);

import { jsPDF } from "jspdf";
import html2canvas from 'html2canvas';

import Spinner from '@/views/components/common/Spinner';


export default {
	name: "QRModal",
	components: {
		Spinner,
	},
	data() {
		return {
			wWidth: 0,
			gid: null,
			loading: false,
			pdfloading: false,
			output: null,
			downloaded: false,
		};
	},
	methods: {
		onResize() {
			this.wWidth = window.innerWidth;
			if(this.wWidth < 1200) {
				this.editMode = 'mobile'
			} else {
				this.editMode = 'desktop'
			}
		},
		openModal() {
			gsap.timeline({
				onComplete: () => {
					gsap.set(".qrmodal", {
						pointerEvents: "all",
					});
				},
			})
				.to(".qrmodal", {
					duration: 0.3,
					opacity: 1,
				})
				.to(".qrmodal__box", {
					duration: 0.3,
					y: "0%",
					opacity: 1,
				});
		},
		closeModal() {
			gsap.timeline({
				onComplete: () => {
					gsap.set(".qrmodal", {
						pointerEvents: "none",
					});
					this.loading = false;
					this.pdfloading = false;
					this.downloaded = false;
				},
			})
				.to(".qrmodal__box", {
					duration: 0.3,
					y: 150,
					opacity: 0,
				})
				.to(".qrmodal", {
					duration: 0.3,
					opacity: 0,
				});
		},
		init() {
			this.openModal();

			this.loading = true;
			
			this.$service.qr.get(this.gid).then(res => {
				this.loading = false;
				let qrbox = document.querySelector('.canvas__body__qr');
				qrbox.innerHTML = res;
			})
		},
		generatePDF() {
			if(this.pdfloading) return;

			this.pdfloading = true;
			const c = document.querySelector('.canvas');
			const clone = c.cloneNode(true);
			clone.classList.add('cloned');
			clone.style = "width: 1920px";
			document.body.appendChild(clone);
			let cloned = document.querySelector('.cloned')

			setTimeout(() => {
				html2canvas(cloned).then(canvas => {
					this.pdfloading = false;
					this.downloaded = true;
					const doc = new jsPDF({
						orientation: 'portrait',
						unit: 'mm',
						format: [210, 297],
						autoPaging: true
					})
	
					
					doc.addImage(canvas, 'JPEG', 0, 0, 210, 297, '', 'MEDIUM', 0)
					doc.save(`garaging-garage_${this.gid}.pdf`);

					cloned.parentNode.removeChild(cloned);
				})
			}, 150)

		}
	},
	mounted() {
		this.onResize();
		this.$nextTick(() => {
			window.addEventListener("resize", () => {
				this.onResize();
			});
		});

		EventBus.$on('qr-modal', gid => {
			this.gid = gid;
			this.init();
		})
	},
};
</script>
<style lang="scss">
@import "~vue2-datepicker/scss/index.scss";

.qrmodal {
	--select: 100px;
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	display: block;
	background: $color-seasalt;
	padding: var(--gutter) 0;
	display: block;
	overflow: auto;
	z-index: 10000;
	transform: translateX(100%);
	pointer-events: none;
	overflow-x: hidden;
	overflow-y: auto;
	&__box {
		@extend %panel;
		background: transparent;
		padding: 0 var(--supergutter);
		max-width: 300px;
		height: auto;
		overflow: auto;
		padding-bottom: var(--supergutter);
		position: relative;
		&__close {
			position: absolute;
			top: var(--gutter);
			right: var(--gutter);
			@extend %flexCenterCenter;
			@include size(var(--minigutter),var(--minigutter));
			cursor: pointer;
			svg {
				display: block;
				@include size(100%,100%);
				fill: $color-davy;
				pointer-events: none;
			}
		}
		&__head {
			display: block;
			width: 100%;
			margin-bottom: var(--gutter);
			position: relative;
			&__title,
			&__text {
				display: block;
				width: 100%;
			}
			&__title {
				@extend %text-33;
				font-family: $font-manrope;
				font-variation-settings: "wght" 700;
				margin-bottom: var(--minigutter)
			}
		}
		&__content {
			@extend %text-19;
			display: block;
			width: 100%;
			margin-top: var(--gutter);
			text-align: center;
			position: relative;
			.loading-canvas {
				position: absolute;
				top: 0;
				left: 0;
				@extend %flexCenterCenter;
				background: rgba($color-antiflash, 0.9);
				width: 100%;
				aspect-ratio: 1 / 1.41;
				z-index: 100;
				.spinner {
					@include size(var(--supergutter), var(--supergutter));
				}
			}
			.downloaded {
				@extend %flexCenterCenter;
				flex-direction: column;
				width: 100%;
				padding: calc(var(--supergutter) * 2) 0;
				strong {
					display: block;
					width: 100%;
					margin-bottom: var(--gutter);
					@extend %text-25;
					color: $color-green;
					font-family: $font-manrope;
					font-variation-settings: 'wght' 700;
				}
				.btn {
					margin-top: var(--supergutter);
					padding: 0 var(--gutter);
					text-transform: uppercase;
					color: $color-blue;
				}
			}
		}
		&__ctas {
			@extend %flexCenterCenter;
			width: 100%;
			margin-top: var(--minigutter);
			.btn {
				background: $color-blue;
				padding: 0 var(--gutter);
				color: $color-white;
				text-transform: uppercase;
				@extend %text-15;
				&.generating {
					cursor: wait;
				}
			}
		}
	}
	&__row {
		@extend %flexCenterStart;
		width: 100%;
		padding: 0 var(--minigutter);
		margin: 0 0 var(--minigutter);
	}
	&__icon {
		@extend %flexCenterCenter;
		@include size(var(--smallicon),var(--smallicon));
		svg {
			display: block;
			@include size(50%,50%);
		}
	}
	&__label {
		display: block;
		width: calc(100% - var(--smallicon));
		padding-left: var(--thirdgutter);
		font-variation-settings: 'wght' 700;
		text-align: left;
	}
	@media (min-width: $from-medium) {
		display: flex;
		align-items: center;
		justify-content: center;
		background: rgba($color-black, 0.1);
		transform: translateX(0%);
		opacity: 0;
		&__box {
			background: $color-white;
			max-height: 90vh;
			min-width: 600px;
			height: auto;
			padding: var(--gutter) var(--supergutter);
			transform: translateY(150px);
			opacity: 0;
			position: relative;
			&__head {
				margin-bottom: var(--thirdgutter);
			}
			&__text {
				font-size: 0.938rem;
			}
		}
	}

	.canvas {
		display: block;
		width: 100%;
		aspect-ratio: 1 / 1.41;
		position: relative;
		&:before {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			display: block;
			@include size(100%,100%);
			border: 1px solid rgba($color-black, 0.1);
		}
		&__bg,
		&__bg__bottom {
			position: absolute;
			left: 0;
			display: block;
			@include size(100%,100%);
			z-index: 0;
		}
		&__bg {
			top: 0;
			background: url('~@/assets/images/texture-green.png') repeat-x;
		}
		&__bg__bottom {
			bottom: 0;
			background: url('~@/assets/images/texture-sfumata-dark.png') repeat-x;
			transform: rotate(180deg);
		}
		&__head, &__body, &__foot {
			z-index: 3;
		}
		&__head {
			position: absolute;
			top: 0;
			left: 0;
			@extend %flexCenterCenter;
			width: 100%;
			margin-bottom: var(--gutter);
			padding: 8% 10% 5%;
		}
		&__logo {
			display: block;
			width: 50%;
			img {
				display: block;
				@include size(100%,100%);
				object-fit: contain;
			}
		}
		&__foot {
			position: absolute;
			bottom: 0;
			left: 0;
			@extend %flexCenterBetween;
			width: 100%;
			padding: 1.5%;
			&__info {
				display: block;
				width: 50%;
				font-size: 75%;
				text-align: left;
			}
			&__stores {
				@extend %flexCenterEnd;
				width: 50%;
				.stores__icon {
					display: block;
					height: 1%;
					img {
						display: block;
						@include size(100%,100%);
						object-fit: contain;
					}
				}
			}
		}
		&__body {
			position: absolute;
			top: 50%;
			left: 0;
			@extend %flexCenterCenter;
			flex-direction: column;
			width: 100%;
			transform: translateY(-50%);
			padding: 1.5%;
			&__qr {
				display: block;
				width: 50%;
				aspect-ratio: 1 / 1;
				background: $color-antiflash;
				position: relative;
				svg {
					display: block;
					@include size(100%,100%);
					fill: $color-davy;
				}
				.loading-qr {
					position: absolute;
					top: 50%;
					left: 50%;
					display: block;
					@include size(var(--supergutter),var(--supergutter));
					transform: translate(-50%,-50%);
					.spinner {
						@include size(100%,100%);
					}
				}
			}
			&__text {
				display: block;
				width: 100%;
				font-family: $font-manrope;
				color: $color-blue;
				font-size: 1.4rem;
				margin-top: var(--gutter);
				font-variation-settings: 'wght' 700;
				strong {
					color: $color-green;
				}
			}
		}
	}
}


.cloned {
	background: $color-white;
	display: block;
	width: 100%;
	aspect-ratio: 1 / 1.41;
	position: relative;
	z-index: -100;
	.canvas {
		&:before {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			display: block;
			@include size(100%,100%);
			border: 1px solid rgba($color-black, 0.1);
		}
		&__bg,
		&__bg__bottom {
			position: absolute;
			left: 0;
			display: block;
			@include size(100%,100%);
			z-index: 0;
		}
		&__bg {
			top: 0;
			background: url('~@/assets/images/texture-green.png') repeat-x;
		}
		&__bg__bottom {
			bottom: 0;
			background: url('~@/assets/images/texture-sfumata-dark.png') repeat-x;
			transform: rotate(180deg);
		}
		&__head, &__body, &__foot {
			z-index: 3;
		}
		&__head {
			position: absolute;
			top: 0;
			left: 0;
			@extend %flexCenterCenter;
			width: 100%;
			margin-bottom: var(--gutter);
			padding: 8% 10% 5%;
		}
		&__logo {
			display: block;
			width: 56%;
			img {
				display: block;
				@include size(100%,100%);
				object-fit: contain;
			}		
		}
		&__foot {
			position: absolute;
			bottom: 0;
			left: 0;
			@extend %flexCenterBetween;
			width: 100%;
			padding: 1.5%;
			&__info {
				display: block;
				width: 50%;
				font-size: 300%;
				text-align: left;
			}
			&__stores {
				@extend %flexCenterEnd;
				width: 50%;
				.stores__icon {
					display: block;
					height: 10vh;
					margin-left: 30px;
					img {
						display: block;
						@include size(100%,100%);
						object-fit: contain;
					}
				}
			}
		}
		&__body {
			position: absolute;
			top: 50%;
			left: 0;
			@extend %flexCenterCenter;
			flex-direction: column;
			width: 100%;
			transform: translateY(-50%);
			padding: 1.5%;
			&__qr {
				display: block;
				width: 50%;
				aspect-ratio: 1 / 1;
				svg {
					display: block;
					@include size(100%,100%);
					fill: $color-davy;
				}
			}
			&__text {
				display: block;
				width: 100%;
				font-family: $font-manrope;
				color: $color-blue;
				font-size: 3.8vw;
				margin-top: var(--gutter);
				font-variation-settings: 'wght' 700;
				text-align: center;
				margin-top: 120px;
				strong {
					color: $color-green;
				}
			}
		}
	}
}
</style>
