<template>
	<main id="page" class="opengates" ref="page">
		<div class="mobile__head">
			<span class="mobile__head__back" @click="closeDrawer">
				<span class="mobile__head__back__icon">
					<svg viewBox="0 0 50 50">
						<use xlink:href="#icon--back" width="100%" height="100%"></use>
					</svg>
				</span>
				<span class="mobile__head__back__label">
					{{ $t('common.back') }}
				</span>
			</span>
		</div>
		<template v-if="pageLoading">
			<Loading :isLoading="pageLoading" />
		</template>
		<template v-else>
			<div class="opengates__head">
				<div class="content">
					<div class="opengates__head__title">
						{{ $t('infogates.title') }}
					</div>
				</div>
			</div>
			<div class="opengates__content">
				<div class="smallcontent">
					<div class="opengates__box">
						<span class="opengates__box__title" v-html="$t('infogates.title')"></span>
						<span class="opengates__box__text" v-html="$t('infogates.text')"></span>
						<span class="opengates__box__links">
							<span class="opengates__link btn filled" @click="switchContent('manual')">
								{{ $t('infogates.btns.manual') }}
							</span>
							<span class="opengates__link btn filled" @click="switchContent('motorized')">
								{{ $t('infogates.btns.motorized') }}
							</span>
						</span>
						<div v-if="showContent" class="opengates__block">
							<template v-if="showContent == 'manual'">
								<div class="manual">
									<span class="opengates__block__title" v-html="$t('infogates.btns.manual')"></span>
									<span class="opengates__block__text" v-html="$t('infogates.manual.text')"></span>
									<a href="https://www.amazon.it/Faneam-Cassaforte-Combinazione-Impermeabile-Portachiavi/dp/B097GHM25G/ref=asc_df_B097GHM25G/?tag=googshopit-21&amp;linkCode=df0&amp;hvadid=499157517097&amp;hvpos=&amp;hvnetw=g&amp;hvrand=3250085364875938063&amp;hvpone=&amp;hvptwo=&amp;hvqmt=&amp;hvdev=c&amp;hvdvcmdl=&amp;hvlocint=&amp;hvlocphy=1009008&amp;hvtargid=pla-1396316242238&amp;mcid=b590d0fef05634ebabaaa33ffdc49fdb&amp;th=1&_encoding=UTF8&tag=garaging-21&linkCode=ur2&linkId=dc3ebbe23d3efe5466a32b6b151d3798&camp=3414&creative=21718" target="_blank" class="opengates__block__link">
										<img class="opengates__block__link__media" src="@/assets/images/faneam-grande.png" alt="" />
										<span class="opengates__block__link__label" v-html="`Faneam Grande`"></span>
									</a>
									<a href="https://www.amazon.it/Sicurezza-Diyife-Combinazione-Impermeabile-Archiviazione/dp/B09CV2BXG7/ref=asc_df_B09CV2BXG7/?tag=googshopit-21&amp;linkCode=df0&amp;hvadid=498989102335&amp;hvpos=&amp;hvnetw=g&amp;hvrand=6822697548052676904&amp;hvpone=&amp;hvptwo=&amp;hvqmt=&amp;hvdev=c&amp;hvdvcmdl=&amp;hvlocint=&amp;hvlocphy=1009008&amp;hvtargid=pla-1437032321504&amp;mcid=3775e51f2885390fb32772605ffba83a&amp;th=1&_encoding=UTF8&tag=garaging-21&linkCode=ur2&linkId=1c431874d5f9b767fb29faa91a4c6763&camp=3414&creative=21718" target="_blank" class="opengates__block__link">
										<img class="opengates__block__link__media" src="@/assets/images/cassetta-di-sicurezza-per-chiavi.png" alt="" />
										<span class="opengates__block__link__label" v-html="`Nestling`"></span>
									</a>
									<a href="https://www.amazon.it/Sicurezza-Cassaforte-Combinazione-Lucchetto-Aggiornata/dp/B07F82K92R/ref=asc_df_B07F82K92R/?tag=googshopit-21&amp;linkCode=df0&amp;hvadid=279840685357&amp;hvpos=&amp;hvnetw=g&amp;hvrand=1856001570627298668&amp;hvpone=&amp;hvptwo=&amp;hvqmt=&amp;hvdev=c&amp;hvdvcmdl=&amp;hvlocint=&amp;hvlocphy=1009008&amp;hvtargid=pla-486756876147&amp;psc=1&amp;mcid=d4be46fb8c903dd49733f424b9eaf082&_encoding=UTF8&tag=garaging-21&linkCode=ur2&linkId=63ac29b8457d6f16ae8975a15654db2e&camp=3414&creative=21718" target="_blank" class="opengates__block__link">
										<img class="opengates__block__link__media" src="@/assets/images/cassetta-sicurezza-chiavi-grande-capacita.png" alt="" />
										<span class="opengates__block__link__label" v-html="`Cassetta Sicurezza Chiavi Grande Capacità`"></span>
									</a>
									<a href="https://www.amazon.it/GRIFEMA-Cassetta-Sicurezza-Cassaforte-Portatile/dp/B08L7N2S6Z/ref=asc_df_B08L7N2S6Z/?tag=googshopit-21&amp;linkCode=df0&amp;hvadid=498989102335&amp;hvpos=&amp;hvnetw=g&amp;hvrand=3154199571732737128&amp;hvpone=&amp;hvptwo=&amp;hvqmt=&amp;hvdev=c&amp;hvdvcmdl=&amp;hvlocint=&amp;hvlocphy=1009008&amp;hvtargid=pla-1259139723330&amp;mcid=c9b30c3d312b33f68d19d8cfa2c9a177&amp;th=1&_encoding=UTF8&tag=garaging-21&linkCode=ur2&linkId=a1907a64f012d20942ac11da87423b38&camp=3414&creative=21718" target="_blank" class="opengates__block__link">
										<img class="opengates__block__link__media" src="@/assets/images/grifema-cassett-sdi-sicurezza-per-chiavi.png" alt="" />
										<span class="opengates__block__link__label" v-html="`GRIFEMA Cassetta di Sicurezza per Chiavi`"></span>
									</a>
								</div>
							</template>
							<template v-if="showContent == 'motorized'">
								<div class="motorized">
									<span class="opengates__block__title" v-html="$t('infogates.btns.motorized')"></span>
									<span class="opengates__block__text" v-html="$t('infogates.motorized.text')"></span>
									<a href="https://www.amazon.it/dp/B07B7NXV4R?_encoding=UTF8&amp;psc=1&amp;ref_=cm_sw_r_cp_ud_dp_A8EBQ7P87M6G1TZMXFM3&_encoding=UTF8&tag=garaging-21&linkCode=ur2&linkId=17c4072101cdb08367bac48f153c8a90&camp=3414&creative=21718" target="_blank" class="opengates__block__link">
										<img class="opengates__block__link__media" src="@/assets/images/switchbot.png" alt="" />
										<span class="opengates__block__link__info">
											<span class="opengates__block__link__label" v-html="$t('infogates.motorized.switchbot.title')"></span>
											<span class="opengates__block__link__text" v-html="$t('infogates.motorized.switchbot.text')"></span>
											<span class="opengates__block__link__btn btn outline" v-html="$t('infogates.motorized.switchbot.btn')"></span>
										</span>
									</a>
									<a href="https://www.1control.eu/ecommerce/it/garaging.php" target="_blank" class="opengates__block__link">
										<img class="opengates__block__link__media" src="@/assets/images/solo.png" alt="" />
										<span class="opengates__block__link__info">
											<span class="opengates__block__link__label" v-html="$t('infogates.motorized.solo.title')"></span>
											<span class="opengates__block__link__text" v-html="$t('infogates.motorized.solo.text')"></span>
											<span class="opengates__block__link__btn btn outline" v-html="$t('infogates.motorized.solo.btn')"></span>
										</span>
									</a>
								</div>
							</template>
						</div>
					</div>
				</div>
			</div>
		</template>
	</main>
</template>
<script>
import { EventBus } from '@/events_bus.js';
import Loading from '@/views/components/common/Loading';

export default {
	name: 'HowToOpenGates',
	components: {
		Loading,
	},
	data() {
		return {
			pageLoading: false,
			editMode: 'mobile',
			isLoading: false,
			showContent: null,
		}
	},
	methods: {
		closeDrawer() {
			this.$router.go(-1);
		},
		onResize() {
			this.wWidth = window.innerWidth;
			if(this.wWidth < 1200) {
				this.editMode = 'mobile'
			} else {
				this.editMode = 'desktop'
			}
		},
		switchContent(val) {
			this.showContent = val;
			setTimeout(() => {
				document.querySelector('.opengates__block').scrollIntoView({
					block: 'start',
					behavior: 'smooth'
				})
			}, 150)
		},
		init() {
			this.pageLoading = true;
			setTimeout(() => {
				this.pageLoading = false;
			}, 500)
		}
	},
	mounted() {
		this.onResize();
		this.$nextTick(() => {
			window.addEventListener('resize', () => {
				this.onResize();
			});
		});

		this.init();
		
	}
}
</script>
<style lang="scss">
.opengates {
	padding-top: var(--head);
	.mobile__head {
		@extend %flexCenterBetween;
		@include size(100%, var(--head));
		padding: 0 var(--gutter);
		position: relative;
		&__back {
			@extend %flexCenterStart;
			width: calc(100% - var(--btn));
			cursor: pointer;
			&__icon {
				@extend %flexCenterCenter;
				@include size(var(--smallicon),var(--smallicon));
				svg {
					display: block;
					@include size(75%,75%);
					fill: $color-black;
				}
			}
			&__label {
				@extend %text-15;
				display: block;
				width: max-content;
				font-variation-settings: 'wght' 600;
				padding-left: var(--minigutter);
			}
		}
	}
	&__box {
		@extend %panel;
		@extend %flexCenterBetween;
		flex-wrap: wrap;
		width: 100%;
		padding: var(--gutter);
		&__title,
		&__text {
			display: block;
			width: 100%;
			text-align: left;
			margin-bottom: var(--supergutter);
		}
		&__title {
			@extend %text-25;
			font-family: $font-manrope;
			color: $color-blue;
			font-variation-settings: 'wght' 700;
		}
		&__links {
			@extend %flexCenterCenter;
			flex-wrap: wrap;
			width: 100%;
			margin-bottom: var(--supergutter);
		}
	}
	&__link {
		background: $color-blue;
		padding: 0 var(--gutter);
		margin: 0 var(--thirdgutter) var(--minigutter);
		color: $color-white;
	}
	&__head {
		display: block;
		width: 100%;
		margin: var(--gutter) 0;
		&__title {
			display: block;
			@extend %text-33;
			font-family: $font-manrope;
			font-variation-settings: 'wght' 700;
			margin-bottom: var(--thirdgutter);
		}
		@media (min-width: $from-large) {
			display: none;
		}
	}
	&__content {
		display: block;
		width: 100%;
		margin: var(--supergutter) 0;
		@media (min-width: $from-large) {
			.small-panel {
				height: var(--smallpanelh);
			}
		}
	}
	&__block {
		display: block;
		width: 100%;
		&__title,
		&__text {
			display: block;
			width: 100%;
			margin-bottom: var(--gutter);
		}
		&__title {
			@extend %text-25;
			font-family: $font-nunito;
			font-variation-settings: 'wght' 700;
			text-align: center;
			color: $color-blue;
		}
		.manual,
		.motorized {
			@extend %flexStartCenter;
			flex-wrap: wrap;
			width: 100%;
		}
		&__link {
			display: block;
			width: 290px;
			margin: 0 var(--thirdgutter) var(--minigutter);
			padding: var(--minigutter) var(--minigutter);
			border: 1px solid rgba($color-black, 0.3);
			transition: 180ms linear;
			border-radius: var(--radius);
			&__media, &__label,
			&__info, &__text {
				display: block;
				width: 100%;
				pointer-events: none;
			}
			&__label {
				min-height: 63px;
			}
			&__label {
				margin-top: var(--minigutter);
				text-align: center;
				font-variation-settings: 'wght' 700;
			}
			&__btn {
				display: block;
				margin-top: var(--gutter);
			}
			&:hover {
				background: rgba($color-black, 0.05);
			}
		}
	}
	@media (min-width: $from-large) {
		.mobile__head {
			display: none;
		}
		&__box {
			padding: var(--supergutter) var(--supergutter) var(--gutter);
		}
		&__block {
			&__link {
				width: calc(100% / 4 - 20px);
			}
			.motorized {
				.opengates__block__link {
					display: flex;
					align-items: center;
					width: 100%;
					cursor: pointer;
					&__media {
						width: 290px;
					}
					&__info {
						width: calc(100% - 290px);
					}
					&__label, &__text {
						text-align: left;
						min-height: 0;
						margin-bottom: var(--minigutter);
					}
					&__btn {
						width: max-content;
						padding: 0 var(--gutter);
						margin: var(--gutter) 0 0;
						transition: 180ms linear;
					}
					&:hover {
						.opengates__block__link__btn {
							background: $color-blue;
							color: $color-white;
						}
					}
				}
			}
		}
	}
	@media (max-width: $to-large) {
		position: fixed;
		top: 0;
		left: 0;
		display: block;
		background: $color-white !important;
		padding-top: 0;
		z-index: 100000;
		&__head {
			display: none;
		}
		&__box {
			border-radius: 0;
			box-shadow: none;
			padding: 0;
		}
		&__content {
			background: $color-white;
			margin-top: 0;
		}
	}
}
</style>
