import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

const store = new Vuex.Store({
	state: {
		notices: [
			{
				"id": 0,
				"title": "Verifica Documenti",
				"body": "Documento d'identità verificato con successo",
				"action": "Visualizza",
				"destination": "garaging://profile",
				"type": "info",
				"read_at": null
			},
			{
				"id": 1,
				"title": "Scadenza Documenti",
				"body": "Il tuo documento d'identità è in scadenza",
				"action": "Visualizza",
				"destination": "garaging://profile",
				"type": "alert",
				"read_at": null
			},
			{
				"id": 2,
				"title": "Nuova Prenotazione",
				"body": "Hai ricevuto una nuova prenotazione",
				"action": "Visualizza",
				"destination": "garaging://bookings/193",
				"type": "info",
				"read_at": null
			},
			{
				"id": 3,
				"title": "Prenotazione Annullata",
				"body": "Una tua prenotazione è stata annullata",
				"action": "Visualizza",
				"destination": "garaging://bookings/191",
				"type": "info",
				"read_at": null
			},
			{
				"id": 4,
				"title": "Nuova Recensione",
				"body": "Hai ricevuto una nuova recensione",
				"action": "Visualizza",
				"destination": "garaging://recensioni",
				"type": "info",
				"read_at": null
			},
		],
		vehicles: {
			data: [
				{
					id: 708,
					name: "Bicicletta",
					category: "BIKE",
					plate_required: false,
					created_at: "2023-05-31T13:58:01.000000Z",
					updated_at: "2023-05-31T13:58:01.000000Z",
				},
				{
					id: 7081,
					name: "Moto",
					category: "MOTORCYCLE",
					plate_required: false,
					created_at: "2023-05-31T13:58:01.000000Z",
					updated_at: "2023-05-31T13:58:01.000000Z",
				},
				{
					id: 7082,
					name: "Auto",
					category: "CAR",
					plate_required: false,
					created_at: "2023-05-31T13:58:01.000000Z",
					updated_at: "2023-05-31T13:58:01.000000Z",
				},
				{
					id: 7083,
					name: "Suv / furgoncino",
					category: "SUV",
					plate_required: false,
					created_at: "2023-05-31T13:58:01.000000Z",
					updated_at: "2023-05-31T13:58:01.000000Z",
				},
				{
					id: 7084,
					name: "Camper fino a x m",
					category: "CAMPER",
					plate_required: false,
					created_at: "2023-05-31T13:58:01.000000Z",
					updated_at: "2023-05-31T13:58:01.000000Z",
				},
				{
					id: 7085,
					name: "Camper fino a y m",
					category: "CARAVAN",
					plate_required: false,
					created_at: "2023-05-31T13:58:01.000000Z",
					updated_at: "2023-05-31T13:58:01.000000Z",
				},
			],
		},
		garages: [],
		notifications: {
			granted: false,
		}
	},
	mutations: {},
	actions: {},
	modules: {},
});

export default store;
