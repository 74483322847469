<template>
	<div class="deletegarage">
		<div class="deletegarage__head">
			<span class="deletegarage__head__back" @click="closeDrawer">
				<span class="deletegarage__head__back__icon">
					<svg viewBox="0 0 50 50">
						<use xlink:href="#icon--back" width="100%" height="100%"></use>
					</svg>
				</span>
				<span class="deletegarage__head__back__label">
					{{ $t("common.back") }}
				</span>
			</span>
		</div>
		<div class="deletegarage__box">
			<div class="deletegarage__box__head">
				<div class="deletegarage__box__head__title">
					{{ $t("garages.delete.title") }}
				</div>
			</div>
			<div class="deletegarage__box__content">
				<template v-if="isLoading">
					<span class="deletegarage__spinner">
						<Spinner />
					</span>
				</template>
				<template v-else>
					<template v-if="booked">
						<span class="deletegarage__text" v-html="$t('garages.delete.nodelete')"></span>
						<div class="deletegarage__ctas">
							<span class="deletegarage__button btn outline" @click="cancelDelete" v-html="$t('common.btns.cancel')"></span>
						</div>
					</template>
					<span class="deletegarage__text" v-html="$t('garages.delete.text')"></span>
					<div class="deletegarage__ctas">
						<span class="deletegarage__button btn outline" @click="cancelDelete" v-html="$t('common.btns.cancel')"></span>
						<span class="deletegarage__button btn filled" @click="confirmDelete" v-html="$t('common.btns.delete')"></span>
					</div>
				</template>
			</div>
		</div>
	</div>
</template>
<script>
import { EventBus } from "@/events_bus.js";
import { gsap } from "gsap";
import { CustomEase } from "gsap/all";
gsap.registerPlugin(CustomEase);

import Spinner from '@/views/components/common/Spinner';

export default {
	name: "DeleteGarage",
	props: {
		mode: {
			type: String,
			required: true,
			default: "mobile",
		},
	},
	components: {
		Spinner
	},
	data() {
		return {
			wWidth: 0,
			isLoading: false,
			isDeleted: false,
			gid: null,
			garage: null,
			booked: false,
		};
	},
	methods: {
		onResize() {
			this.wWidth = window.innerWidth;
			if(this.wWidth < 1200) {
				this.editMode = 'mobile'
			} else {
				this.editMode = 'desktop'
			}
		},
		openModal() {
			gsap.timeline({
				onComplete: () => {
					gsap.set(".deletegarage", {
						pointerEvents: "all",
					});
				},
			})
				.to(".deletegarage", {
					duration: 0.3,
					opacity: 1,
				})
				.to(".deletegarage__box", {
					duration: 0.3,
					y: "0%",
					opacity: 1,
				});
		},
		closeModal() {
			gsap.timeline({
				onComplete: () => {
					gsap.set(".deletegarage", {
						pointerEvents: "none",
					});
				},
			})
				.to(".deletegarage__box", {
					duration: 0.3,
					y: 150,
					opacity: 0,
				})
				.to(".deletegarage", {
					duration: 0.3,
					opacity: 0,
				});
		},
		openDrawer() {
			gsap.to(".deletegarage", {
				duration: 0.9,
				x: "0%",
				ease: CustomEase.create("custom", "M0,0 C0.726,0 0,1 1,1 "),
				onComplete: () => {
					gsap.set(".deletegarage", {
						pointerEvents: "all",
					});
				},
			});
		},
		closeDrawer() {
			gsap.to(".deletegarage", {
				duration: 0.9,
				x: "100%",
				ease: CustomEase.create("custom", "M0,0 C0.726,0 0,1 1,1 "),
			});
		},
		cancelDelete() {
			if (this.mode == "mobile") {
				this.closeDrawer();
			} else {
				this.closeModal();
			}
		},
		confirmDelete() {
			this.isLoading = true;
			this.$service.garages.detail.delete(this.gid).then(res => {
				this.isDeleted = true;
				setTimeout(() => {
					this.cancelDelete();
					this.isLoading = false;
					this.$router.push({ name: 'garages.page' });
				}, 1500)
			})
		},
	},
	mounted() {
		this.onResize();
		this.$nextTick(() => {
			window.addEventListener("resize", () => {
				this.onResize();
			});
		});

		EventBus.$on('delete-garage', (g) => {
			this.gid = g.id;
			this.garage = g;
			
			console.log('G', g)
			
			if(g && g.availabilities && g.availabilities.length) {
				console.log('AVA', true)
				g.availabilities.forEach(a => {
					console.log('BOOKED? ', a.booked);
					if(a.booked) {
						this.booked = true;
						return;
					}
				})
				
				console.log('BOOKED', this.booked)
			}
			if (this.mode == "mobile") {
				this.openDrawer();
			} else {
				this.openModal();
			}
		});
	},
};
</script>
<style lang="scss">
@import "~vue2-datepicker/scss/index.scss";

.deletegarage {
	--select: 100px;
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	display: block;
	background: $color-seasalt;
	padding: var(--gutter) 0;
	display: block;
	overflow: auto;
	z-index: 10000;
	transform: translateX(100%);
	pointer-events: none;
	overflow-x: hidden;
	overflow-y: auto;
	&__head {
		@extend %flexCenterStart;
		@include size(100%, var(--headDrawer));
		padding: 0 var(--gutter);
		&__back {
			@extend %flexCenterStart;
			width: calc(100% - var(--btn));
			cursor: pointer;
			&__icon {
				@extend %flexCenterCenter;
				@include size(var(--smallicon), var(--smallicon));
				svg {
					display: block;
					@include size(75%, 75%);
					fill: $color-black;
				}
			}
			&__label {
				@extend %text-15;
				display: block;
				width: max-content;
				font-variation-settings: "wght" 600;
				padding-left: var(--minigutter);
			}
		}
	}
	&__box {
		@extend %panel;
		background: transparent;
		padding: 0 var(--supergutter);
		height: auto;
		overflow: auto;
		padding-bottom: var(--supergutter);
		&__head {
			@extend %flexCenterBetween;
			width: 100%;
			margin-bottom: var(--gutter);
			&__title {
				@extend %text-33;
				display: block;
				width: 100%;
				font-family: $font-manrope;
				font-variation-settings: "wght" 700;
				text-align: center;
			}
		}
		&__content {
			@extend %text-19;
			display: block;
			width: 100%;
			margin-top: var(--supergutter);
			text-align: center;
		}
		@media (max-width: $to-large) {
			box-shadow: none;
		}
	}
	&__spinner {
		@extend %flexCenterCenter;
		@include size(100%,100%);
		position: relative;
		margin-bottom: var(--supergutter);
		.spinner {
			@include size(var(--supergutter),var(--supergutter));
		}
	}
	&__text {
		@extend %text-15;
		display: block;
		width: 100%;
		margin-bottom: var(--gutter);
	}
	&__button {
		width: 100%;
		text-align: center;
		margin-bottom: var(--thirdgutter);
		&.filled {
			background: $color-red;
			color: $color-white;
		}
		&.outline {
			border-color: $color-blue;
			color: $color-blue;
		}
		&.disabled {
			opacity: 0.6;
			pointer-events: none;
		}
	}
	@media (min-width: $from-medium) {
		display: flex;
		align-items: center;
		justify-content: center;
		background: rgba($color-black, 0.1);
		transform: translateX(0%);
		opacity: 0;
		&__head {
			display: none;
		}
		&__box {
			background: $color-white;
			max-height: 90vh;
			min-width: 400px;
			height: auto;
			padding: var(--gutter) var(--supergutter);
			transform: translateY(150px);
			opacity: 0;
			position: relative;
			&__head {
				display: flex;
				margin-bottom: var(--thirdgutter);
				&__close {
					display: block;
				}
			}
			&__text {
				font-size: 0.938rem;
			}
		}
	}
}
</style>
