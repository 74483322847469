<template>
	<div class="devicemodal">
		<div class="devicemodal__box">
			<span class="devicemodal__box__close" @click="closeModal">
				<svg viewBox="0 0 50 50">
					<use xlink:href="#icon--close" width="100%" height="100%"></use>
				</svg>
			</span>
			<div class="devicemodal__box__head">
				<div class="devicemodal__box__head__title" v-html="$t('garages.devices.title')"></div>
				<div class="devicemodal__box__head__text" v-html="$t('garages.devices.text')"></div>
			</div>
			<div v-if="gate" class="devicemodal__box__content">
				<span v-if="devices.length < deviceLimit" class="devicebox__head" :class="{ 'hide': multisolo }">
					<small class="devicebox__head__label">
						Aggiungi dispositivi
					</small>
					<span class="devicebox__head__btn" @click="addDevice">
					</span>
				</span>

				<template v-if="devices && devices.length">
					<template v-if="devices[0].type == 'SOLO'">
						<NewDevice :index="0" :device="devices[0]" :limit="deviceLimit" />
					</template>
					<template v-else>
						<NewDevice v-for="(d, dI) in devices" :key="dI" :index="dI" :device="d" :limit="deviceLimit" />
					</template>
				</template>
			</div>
			<div class="devicemodal__box__ctas">
				<span class="devicemodal__button btn outline" @click="closeModal" v-html="$t('common.btns.cancel')"></span>
				<span class="devicemodal__button btn filled" @click="saveDevices" v-html="$t('common.btns.save')"></span>
			</div>
		</div>
	</div>
</template>
<script>
import { EventBus } from "@/events_bus.js";
import { gsap } from "gsap";
import { CustomEase } from "gsap/all";
gsap.registerPlugin(CustomEase);

import NewDevice from '@/views/components/garages/NewDevice';

export default {
	name: "devicemodal",
	props: {
		gates: {
			type: Array,
			required: true,
		}
	},
	components: {
		NewDevice
	},
	data() {
		return {
			wWidth: 0,
			gate: null,
			showGate: false,
			showBarrier: false,
			showGarage: false,
			deviceLimit: 0,
			devices: null,
			multisolo: false,
		}
	},
	methods: {
		addDevice() {
			if(this.devices.length < this.deviceLimit) {
				this.devices.push({ name: '', type: '', configuration: { mac_address: '', password: '' } });
			}
		},
		onResize() {
			this.wWidth = window.innerWidth;
			if(this.wWidth < 1200) {
				this.editMode = 'mobile'
			} else {
				this.editMode = 'desktop'
			}
		},
		openModal() {
			gsap.timeline({
				onComplete: () => {
					gsap.set(".devicemodal", {
						pointerEvents: "all",
					});
				},
			})
				.to(".devicemodal", {
					duration: 0.3,
					opacity: 1,
					pointerEvents: 'all',
				})
				.to(".devicemodal__box", {
					duration: 0.3,
					y: "0%",
					opacity: 1,
				});
		},
		closeModal() {
			gsap.timeline({
				onComplete: () => {
					gsap.set(".devicemodal", {
						pointerEvents: "none",
					});
					EventBus.$emit('close-device-modal');
				},
			})
				.to(".devicemodal__box", {
					duration: 0.3,
					y: 150,
					opacity: 0,
				})
				.to(".devicemodal", {
					duration: 0.3,
					opacity: 0,
					pointerEvents: 'none',
				}, '-=0.3');
		},
		init() {
			this.devices = [];
			this.openModal();
		},
		saveDevices() {
			if(this.multisolo) {
				this.devices.push({
					configuration: {
						mac_address: `${this.devices[0].configuration.mac_address}-1`,
						password: ''
					},
					name: this.devices[0].tmpname,
					type: 'SOLO'
				})
				this.devices[0].configuration.mac_address = `${this.devices[0].configuration.mac_address}-0`;
			}
			console.log('--->>>', this.devices)
			EventBus.$emit('save-devices', this.devices);
			this.closeModal();
		}
	},
	mounted() {
		this.onResize();
		this.$nextTick(() => {
			window.addEventListener("resize", () => {
				this.onResize();
			});
		});

		EventBus.$on('open-device-modal', (gates) => {
			this.init();
			console.log('PROPS?', gates);
			this.gate = gates;

			switch(this.gate) {
				case 'GARAGE_ONLY':
				case 'GATE_ONLY':
				case 'BARRIER':
					this.deviceLimit = 1;
					break;
				case 'GATE_GARAGE':
				case 'BARRIER_GARAGE':
					this.deviceLimit = 2;
			}
		})

		EventBus.$on('open-device-modal-edit', obj => {
			this.init();
			this.gate = obj.gate;
			switch(this.gate) {
				case 'GARAGE_ONLY':
				case 'GATE_ONLY':
				case 'BARRIER':
					this.deviceLimit = 1;
					break;
				case 'GATE_GARAGE':
				case 'BARRIER_GARAGE':
					this.deviceLimit = 2;
			}

			this.devices = [...obj.devs];
			if(this.devices && this.devices.length && this.devices[0].type == 'SOLO') {
				if(this.devices.length > 1) {
					let dev1 = this.devices[0].configuration.mac_address.replace('-0','');
					let dev2 = this.devices[1].configuration.mac_address.replace('-1','');
					if(dev1 == dev2) {
						this.devices[0].tmpname = this.devices[1].name;
						this.devices[0].configuration.mac_address = dev1;
						this.devices[1].configuration.mac_address = dev2;
						
						setTimeout(() => {
							EventBus.$emit('is-multisolo');
						}, 80)
					}
				}
			}
		})

		EventBus.$on('remove-device', (index) => {
			this.devices.splice(+index, 1);
		})
		EventBus.$on('multisolo', () => {
			this.multisolo = true;
		})
		EventBus.$on('nomultisolo', () => {
			this.multisolo = false;
		})
	},
};
</script>
<style lang="scss">
.devicemodal {
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	display: block;
	background: $color-seasalt;
	padding: var(--gutter) 0;
	display: block;
	overflow: auto;
	z-index: 10000;
	pointer-events: none;
	opacity: 0;
	overflow-x: hidden;
	overflow-y: auto;
	&__box {
		@extend %panel;
		background: transparent;
		height: auto;
		overflow: auto;
		transform: translateY(150px);
		padding: 0 var(--minigutter) var(--supergutter);
		position: relative;
		&__close {
			position: absolute;
			top: var(--gutter);
			right: var(--gutter);
			@extend %flexCenterCenter;
			@include size(var(--minigutter),var(--minigutter));
			cursor: pointer;
			svg {
				display: block;
				@include size(100%,100%);
				fill: $color-davy;
				pointer-events: none;
			}
		}
		&__head {
			display: block;
			width: 100%;
			margin-bottom: var(--gutter);
			position: relative;
			margin-top: var(--supergutter);
			&__title,
			&__text {
				display: block;
				width: 100%;
			}
			&__title {
				@extend %text-33;
				font-family: $font-manrope;
				font-variation-settings: "wght" 700;
				margin-bottom: var(--minigutter)
			}
		}
		&__content {
			@extend %text-19;
			display: block;
			width: 100%;
			margin-top: var(--gutter);
			text-align: center;
			.devicebox {
				display: block;
				width: 100%;
				max-width: 300px;
				padding: var(--gutter) 0;
				&__head {
					@extend %flexCenterBetween;
					width: 100%;
					margin-bottom: var(--minigutter);
					&__label {
						@extend %text-15;
						display: block;
						width: 60%;
						text-align: left;
					}
					&__btn {
						@extend %flexCenterCenter;
						background: $color-blue;
						@include size(var(--gutter),var(--gutter));
						border-radius: var(--radius);
						position: relative;
						cursor: pointer;
						@extend %arrowClose;
						&:before, &:after {
							background: $color-white;
							@include size(40%, 2px);
						}
						&:before {
							transform: translate(-50%,-50%);
						}
						&:after {
							transform: translate(-50%,-50%) rotate(-90deg);
						}
					}
					&.hide {
						display: none;
					}
				}
			}
		}
		&__ctas {
			@extend %flexCenterBetween;
			flex-wrap: wrap;
			width: 100%;
			padding-top: var(--minigutter);
			.btn {
				&:first-of-type {
					margin-bottom: var(--minigutter);
				}
			}
		}
	}
	&__button {
		width: 100%;
		&.filled {
			background: $color-green;
			color: $color-white
		}
	}
	&__icon {
		@extend %flexCenterCenter;
		@include size(var(--smallicon),var(--smallicon));
		svg {
			display: block;
			@include size(50%,50%);
		}
	}
	&__label {
		display: block;
		width: calc(100% - var(--smallicon));
		padding-left: var(--thirdgutter);
		font-variation-settings: 'wght' 700;
		text-align: left;
	}
	&__row {
		@extend %flexCenterBetween;
		flex-wrap: wrap;
		width: 100%;
		margin-bottom: var(--minigutter);
		&__label {
			display: block;
			width: calc(100% - var(--gutter));
			@extend %text-15;
			font-variation-settings: 'wght' 700;
			text-align: left;
		}
		&__btn {
			display: block;
			@include size(var(--gutter),var(--gutter));
			background: $color-blue;
			@extend %arrowClose;
			border-radius: var(--radius);
			position: relative;
			&:before, &:after {
				@include size(40%, 2px);
				background: $color-white;
			}
			&:before {
				transform: translate(-50%,-50%);
			}
			&:after {
				transform: translate(-50%,-50%) rotate(90deg);
			}
		}
	}
	@media (min-width: $from-medium) {
		display: flex;
		align-items: center;
		justify-content: center;
		background: rgba($color-black, 0.1);
		opacity: 0;
		&__box {
			background: $color-white;
			max-height: 90vh;
			min-width: 600px;
			max-width: 300px;
			height: auto;
			padding: var(--gutter) var(--supergutter);
			transform: translateY(150px);
			opacity: 0;
			position: relative;
			&__head {
				margin-bottom: var(--thirdgutter);
			}
			&__text {
				font-size: 0.938rem;
			}
		}
		&__button {
			width: 45%;
		}
	}
}
</style>
