<template>
	<div class="confirmpark">
		<div class="confirmpark__head">
			<span class="confirmpark__head__back" @click="closeDrawer">
				<span class="confirmpark__head__back__icon">
					<svg viewBox="0 0 50 50">
						<use xlink:href="#icon--back" width="100%" height="100%"></use>
					</svg>
				</span>
				<span class="confirmpark__head__back__label">
					{{ $t('common.back') }}
				</span>
			</span>
		</div>
		<div class="confirmpark__box">
			<div class="confirmpark__title">
				{{ $t('garages.confirm.title') }}
			</div>
			<span class="confirmpark__text" v-html="$t('garages.confirm.text')"></span>
			<div v-if="recap" class="confirmpark__recap">
				<div class="confirmpark__recap__box">
					<small class="confirmpark__recap__subtitle">
						{{ $t('garages.confirm.subtitle.info') }}
					</small>
					<span class="confirmpark__recap__row">
						<strong class="confirmpark__recap__label" v-html="$t('garages.confirm.labels.name')"></strong>
						<span class="confirmpark__recap__value">
							{{ recap.name }}
						</span>
					</span>
					<span class="confirmpark__recap__row">
						<strong class="confirmpark__recap__label" v-html="$t('garages.confirm.labels.address')"></strong>
						<span class="confirmpark__recap__value">
							{{ recap.address }}
						</span>
					</span>
				</div>

				<div class="confirmpark__recap__box">
					<small class="confirmpark__recap__subtitle">
						{{ $t('garages.confirm.subtitle.vehicles') }}
					</small>
					<span v-for="v in vehiclesType" :key="v.id" class="confirmpark__recap__v v">
						<span class="v__icon">
							<svg viewBox="0 0 50 50">
								<use :xlink:href="`#vehicles--${v.category.toLowerCase()}`" width="100%" height="100%"></use>
							</svg>
						</span>
						<span class="v__label">{{ v.name }}</span>
					</span>
				</div>
				<div class="confirmpark__recap__box">
					<small class="confirmpark__recap__subtitle">
						{{ $t('garages.confirm.subtitle.chars') }}
					</small>
					<div class="confirmpark__recap__box__col">
						<span class="confirmpark__recap__row floor">
							<strong class="confirmpark__recap__label" v-html="$t('garages.confirm.labels.floor')"></strong>
							<span class="confirmpark__recap__value">
								{{ recap.floor == 0 ? 'T' : recap.floor }}
							</span>
						</span>
						<span v-if="recap.stairs" class="confirmpark__recap__c c">
							<span class="c__icon">
								<svg viewBox="0 0 50 50">
									<use xlink:href="#icon--stairs" width="100%" height="100%"></use>
								</svg>
							</span>
							<span class="c__label" v-html="$t('garages.form.misc.stairs')"></span>
						</span>
						<span v-if="recap.elevator" class="confirmpark__recap__c c">
							<span class="c__icon">
								<svg viewBox="0 0 50 50">
									<use xlink:href="#icon--elevator" width="100%" height="100%"></use>
								</svg>
							</span>
							<span class="c__label" v-html="$t('garages.form.misc.elevator')"></span>
						</span>
						<span v-if="recap.charge_station" class="confirmpark__recap__c c">
							<span class="c__icon">
								<svg viewBox="0 0 50 50">
									<use xlink:href="#icon--charge_station" width="100%" height="100%"></use>
								</svg>
							</span>
							<span class="c__label" v-html="$t('garages.form.misc.charge_station')"></span>
						</span>
						<span v-if="recap.gpl" class="confirmpark__recap__c c">
							<span class="c__icon">
								<svg viewBox="0 0 50 50">
									<use xlink:href="#icon--gpl" width="100%" height="100%"></use>
								</svg>
							</span>
							<span class="c__label" v-html="$t('garages.form.misc.gpl')"></span>
						</span>
						<span v-if="recap.indoor" class="confirmpark__recap__c c">
							<span class="c__icon">
								<svg viewBox="0 0 50 50">
									<use xlink:href="#icon--indoor" width="100%" height="100%"></use>
								</svg>
							</span>
							<span class="c__label" v-html="$t('garages.form.misc.indoor')"></span>
						</span>
						<span v-if="recap.barriers" class="confirmpark__recap__c c">
							<span class="c__icon">
								<svg viewBox="0 0 50 50">
									<use xlink:href="#icon--barriers" width="100%" height="100%"></use>
								</svg>
							</span>
							<span class="c__label" v-html="$t('garages.form.misc.barriers')"></span>
						</span>
					</div>
					<div class="confirmpark__recap__box__col">
						<span class="confirmpark__recap__row">
							<strong class="confirmpark__recap__label" v-html="$t('garages.confirm.labels.type')"></strong>
							<span class="confirmpark__recap__value">
								{{ type }}
							</span>
						</span>
						<span class="confirmpark__recap__row">
							<strong class="confirmpark__recap__label" v-html="$t('garages.confirm.labels.gate')"></strong>
							<span class="confirmpark__recap__value">
								{{ gate }}
							</span>
						</span>
						<span v-if="recap.devices" class="confirmpark__recap__row">
							<strong class="confirmpark__recap__label" v-html="$t('garages.confirm.labels.devices')"></strong>
							<span v-for="(dev, devI) in recap.devices" :key="devI" class="confirmpark__recap__value">
								<span v-html="dev.name"></span> - <small v-html="dev.type"></small>
							</span>
						</span>
						<span class="confirmpark__recap__row smart">
							<strong class="confirmpark__recap__label" v-html="$t('garages.confirm.labels.smart')"></strong>
							<span class="confirmpark__recap__value">
								<template v-if="recap.smart">
									<svg viewBox="0 0 50 50">
										<use xlink:href="#icon--check" fill="#71b800" width="100%" height="100%"></use>
									</svg>
								</template>
								<template v-else>
									<svg viewBox="0 0 50 50">
										<use xlink:href="#icon--close" fill="#da291c" width="100%" height="100%"></use>
									</svg>
								</template>
							</span>
						</span>
						<span class="confirmpark__recap__row floor">
							<strong class="confirmpark__recap__label" v-html="$t('garages.confirm.labels.vehicle_quantity')"></strong>
							<span class="confirmpark__recap__value">
								{{ recap.vehicle_quantity }}
							</span>
						</span>
					</div>
				</div>
				<div class="confirmpark__recap__box">
					<small class="confirmpark__recap__subtitle">
						{{ $t('garages.confirm.subtitle.descr') }}
					</small>
					<span class="confirmpark__recap__row">
						<strong class="confirmpark__recap__label" v-html="$t('garages.confirm.labels.description')"></strong>
						<span class="confirmpark__recap__value">
							{{ recap.description }}
						</span>
					</span>
					<span class="confirmpark__recap__row">
						<strong class="confirmpark__recap__label" v-html="$t('garages.confirm.labels.private_description')"></strong>
						<span class="confirmpark__recap__value">
							{{ recap.private_description }}
						</span>
					</span>
				</div>
			</div>
			<div class="confirmpark__ctas">
				<span class="confirmpark__ctas__btn btn filled cancel" @click="cancelSave">
					{{ $t('garages.btns.cancel') }}
				</span>
				<span class="confirmpark__ctas__btn btn filled save" @click="saveGarage">
					{{ $t('garages.btns.save') }}
				</span>
			</div>
		</div>
	</div>
</template>
<script>
import { EventBus } from '@/events_bus.js';
import dayjs from 'dayjs';
import { gsap } from 'gsap';
import { CustomEase } from 'gsap/all';
gsap.registerPlugin(CustomEase);

import Spinner from '@/views/components/common/Spinner';

export default {
	name: 'ConfirmModal',
	props: {
		mode: {
			type: String,
			required: true,
			default: 'mobile'
		},
		gid: {
			required: false
		}
	},
	components: {
		Spinner,
	},
	data() {
		return {
			recap: null,
			vehiclesType: null,
			type: null,
			gate: null,
		}
	},
	methods: {
		openModal() {
			gsap.timeline({
				onComplete: () => {
					gsap.set('.confirmpark', {
						pointerEvents: 'all'
					})
				}
			})
				.to('.confirmpark', {
					duration: 0.3,
					opacity: 1
				})
				.to('.confirmpark__box', {
					duration: 0.3,
					y: '0%',
					opacity: 1
				})
		},
		closeModal() {
			gsap.timeline({
				onComplete: () => {
					gsap.set('.confirmpark', {
						pointerEvents: 'none'
					})
				}
			})
			.to('.confirmpark__box', {
				duration: 0.3,
				y: 150,
				opacity: 0
			})
			.to('.confirmpark', {
				duration: 0.3,
				opacity: 0
			})
		},
		openDrawer() {
			gsap.to('.confirmpark', {
				duration: 0.9,
				x: '0%',
				ease: CustomEase.create("custom", "M0,0 C0.726,0 0,1 1,1 "),
				onComplete: () => {
					gsap.set('.confirmpark', {
						pointerEvents: 'all'
					})
				}
			})
		},
		closeDrawer() {
			gsap.to('.confirmpark', {
				duration: 0.9,
				x: '100%',
				ease: CustomEase.create("custom", "M0,0 C0.726,0 0,1 1,1 ")
			})
		},
		cancelSave() {
			EventBus.$emit('cancel-save');
			if(this.mode == 'mobile') {
				this.closeDrawer()
			} else {
				this.closeModal();
			}
		},
		saveGarage() {
			EventBus.$emit('save-for-real');
			this.cancelSave();
		},
		initMisc() {
			console.log('RECAP --->>>', this.recap);
			this.vehiclesType = [];
			this.$service.vehicles.get().then(res => {
				if(res && res.data && res.data.length) {
					this.recap.vehicle_types.forEach(vt => {
						let t = res.data.filter(d => {
							return d.id == vt;
						})
						if(t && t[0]) {
							this.vehiclesType.push(t[0]);
						}

					})
				}
			})
			this.$service.garages.enum.gates().then(gres => {
				if(gres && gres.data && gres.data.length) {
					let item = gres.data.filter(g => {
						return this.recap.gate == g;
					})
					if(item && item[0]) {
						this.type = this.$t(`garages.form.gatetypes.${item[0].toLowerCase()}`);
					}
				}
			})
			this.$service.garages.enum.garages().then(pres => {
				if(pres && pres.data && pres.data.length) {
					let item = pres.data.filter(p => {
						return this.recap.type == p;
					})
					if(item && item[0]) {
						this.gate = this.$t(`garages.form.parktypes.${item[0].toLowerCase()}`);
					}
				}
			})
		},
		// initMisc() {
		// 	this.parkType.forEach(pt => {
		// 		if(pt.type == this.recap.type) {
		// 			this.type = pt.value;
		// 		}
		// 	})

		// 	this.gateTypes.forEach(gt => {
		// 		if(gt.type == this.recap.gate) {
		// 			this.gate = gt.value;
		// 		}
		// 	})
		// },
		init(g) {
			if(g) {
				this.recap = g;
				this.initMisc();
				// this.initMisc();
			}
		}
	},
	mounted() {
		EventBus.$on('confirm-park', (g) => {

			this.init(g);
			if(this.mode == 'mobile') {
				this.openDrawer()
			} else {
				this.openModal();
			}
		});
	}
}
</script>
<style lang="scss">
.confirmpark {
	--select: 100px;
	@extend %flexCenterCenter;
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	background: $color-seasalt;
	z-index: 10000;
	display: block;
	overflow: hidden;
	transform: translateX(100%);
	pointer-events: none;
	&__head {
		@extend %flexCenterStart;
		@include size(100%, var(--headDrawer));
		padding: 0 var(--gutter);
		&__back {
			@extend %flexCenterStart;
			width: calc(100% - var(--btn));
			cursor: pointer;
			&__icon {
				@extend %flexCenterCenter;
				@include size(var(--smallicon),var(--smallicon));
				svg {
					display: block;
					@include size(75%,75%);
					fill: $color-black;
				}
			}
			&__label {
				@extend %text-15;
				display: block;
				width: max-content;
				font-variation-settings: 'wght' 600;
				padding-left: var(--minigutter);
			}
		}
	}
	&__spinner {
		@extend %flexCenterCenter;
		width: 100%;
		margin: var(--gutter) 0;
	}
	&__box {
		@extend  %panel;
		background: transparent;
		padding: 0;
		height: calc(100svh - var(--headDrawer));
		overflow-x: hidden;
		overflow-y: auto;
	}
	&__recap {
		@extend %flexStartStart;
		flex-wrap: wrap;
		width: 100%;
		&__subtitle {
			display: block;
			width: 100%;
			margin-bottom: var(--thirdgutter);
			@extend %text-18;
			color: $color-blue;
			font-variation-settings: 'wght' 600;
		}
		&__box {
			@extend %flexStartStart;
			flex-wrap: wrap;
			width: 100%;
			margin-bottom: var(--gutter);
			&__col {
				display: block;
				width: 100%;
				margin-bottom: var(--minigutter);
				.confirmpark__recap__row {
					margin-bottom: var(--minigutter);
				}
			}
		}
		&__row {
			display: block;
			width: 100%;
			&.floor {
				@extend %flexCenterStart;
				width: 100%;
				padding-right: var(--gutter);
				.confirmpark__recap {
					&__label {
						margin-bottom: 0;
					}
					&__value {
						@extend %text-25;
						font-variation-settings: 'wght' 700;
						color: $color-caribbean;
						padding-left: var(--thirdgutter);
					}
				}
			}
			&.smart {
				@extend %flexCenterStart;
				width: 100%;
				.confirmpark__recap {
					&__label {
						margin-bottom: 0;
					}
					&__value {
						padding-left: var(--thirdgutter);
						svg {
							display: block;
							@include size(var(--smallicon),var(--smallicon));
						}
					}
				}
			}
		}
		&__label {
			display: block;
			width: max-content;
			@extend %text-12;
			font-variation-settings: 'wght' 700;
			color: rgba($color-davy, 0.8);
			margin-bottom: calc(var(--thirdgutter) * 0.75);
			text-transform: uppercase;
		}
		&__value {
			@extend %flexBaselineStart;
			span, small {
				display: block;
				width: max-content;
			}
			span {
				padding-right: 5px;
			}
			small {
				font-style: italic;
				padding-left: 5px;
			}
			&.floor {
				@extend %text-25;
				font-variation-settings: 'wght' 700;
			}
		}
		&__v,
		&__c {
			@extend %flexCenterStart;
			width: max-content;
			margin-right: calc(var(--gutter) * 1.5);
			.v,
			.c {
				&__icon {
					@extend %flexCenterCenter;
					@include size(var(--icon),var(--icon));
					svg {
						display: block;
						@include size(100%,100%);
					}
				}
				&__label {
					@extend %text-15;
					font-variation-settings: 'wght' 700;
					padding-left: var(--thirdgutter);
				}
			}
			.c__icon {
				svg {
					@include size(50%,50%);
				}
			}
		}
	}
	&__title,
	&__text {
		display: block;
		width: 100%;
	}
	&__title {
		@extend %text-33;
		color: $color-blue;
		font-family: $font-manrope;
		font-variation-settings: 'wght' 700;
		margin-bottom: var(--minigutter);
	}
	&__text {
		margin-bottom: var(--gutter);
	}
	&__ctas {
		@extend %flexCenterBetween;
		flex-wrap: wrap;
		width: 100%;
		margin-top: 0;
		padding-top: var(--gutter);
		&__btn {
			width: 100%;
			min-width: 196px;
			color: $color-white;
			padding: 0 var(--gutter);
			&.cancel {
				background: $color-red;
				order: 2;
				&.disabled {
					opacity: 0;
					pointer-events: none;
				}
			}
			&.save {
				background: $color-blue;
				order: 1;
				margin-bottom: var(--minigutter);
			}
		}
	}
	@media (min-width: $from-large) {
		display: flex;
		background: rgba($color-black, 0.1);
		transform: translateX(0%);
		opacity: 0;
		&__head {
			display: none;
		}
		&__box {
			background: $color-white;
			max-height: 85vh;
			width: 780px;
			height: calc(100% - var(--headDrawer));
			padding: var(--gutter);
			transform: translateY(150px);
			opacity: 0;
			&__head {
				display: flex;
				margin-bottom: var(--thirdgutter);
			}
			&__text {
				font-size: 0.938rem;
			}
		}
		&__recap {
			&__box {
				&__col {
					width: 50%;
				}
			}
			&__row {
				width: 50%;
				&.full {
					width: 100%;
				}
			}
		}
		&__ctas {
			margin-top: var(--supergutter);
			&__btn {
				width: max-content;
				&.cancel {
					order: 1;
				}
				&.save {
					order: 2;
					margin-bottom: 0;
				}
			}
		}
	}
}
</style>