<template>
	<main id="page" class="solo" ref="page">
		<div class="mobile__head">
			<span class="mobile__head__back" @click="closeDrawer">
				<span class="mobile__head__back__icon">
					<svg viewBox="0 0 50 50">
						<use xlink:href="#icon--back" width="100%" height="100%"></use>
					</svg>
				</span>
				<span class="mobile__head__back__label">
					{{ $t('common.back') }}
				</span>
			</span>
		</div>
		<template v-if="pageLoading">
			<Loading :isLoading="pageLoading" />
		</template>
		<template v-else>
			<div class="solo__head">
				<div class="content">
					<div class="solo__head__title">
						{{ $t('infogates.title') }}
					</div>
				</div>
			</div>
			<div class="solo__content">
				<div class="smallcontent">
					<div class="solo__box">
						<span class="solo__box__title" v-html="$t('solo.title')"></span>
						<span class="swtichbot__box__text" v-html="$t('solo.text')"></span>
						<div class="solo__instruction inst">
							<span class="inst__step">
								<small class="inst__step__title">
									<span class="inst__step__title__num">01.</span> <span class="inst__step__title__label" v-html="$t('solo.step01')"></span>
								</small>
								<span class="inst__step__content">
									<a href="https://apps.apple.com/it/app/1control/id1469181112" target="_blank" class="inst__store">
										<img class="inst__store__image" src="@/assets/images/appstore.png" />
									</a>
									<a href="https://play.google.com/store/apps/details?id=it.onecontrol.app.and&pcampaignid=web_share" target="_blank" class="inst__store">
										<img class="inst__store__image" src="@/assets/images/playstore.png" />
									</a>
								</span>
							</span>
							<span class="inst__step">
								<small class="inst__step__title">
									<span class="inst__step__title__num">02.</span> <span class="inst__step__title__label" v-html="$t('solo.step02')"></span>
								</small>
							</span>
							<span class="inst__step">
								<small class="inst__step__title">
									<span class="inst__step__title__num">03.</span> <span class="inst__step__title__label" v-html="$t('solo.step03')"></span>
								</small>
							</span>
							<span class="inst__step">
								<small class="inst__step__title">
									<span class="inst__step__title__num">04.</span> <span class="inst__step__title__label" v-html="$t('solo.step04')"></span>
								</small>
							</span>
							<span class="inst__step">
								<small class="inst__step__title">
									<span class="inst__step__title__num">05.</span> <span class="inst__step__title__label" v-html="$t('solo.step05')"></span>
								</small>
							</span>
							<span class="inst__step">
								<small class="inst__step__title">
									<span class="inst__step__title__num">06.</span> <span class="inst__step__title__label" v-html="$t('solo.step06')"></span>
								</small>
								<span class="inst__step__content">
									<img class="inst__step__content__image" src="@/assets/images/seriale-solo.jpeg" alt="" />
								</span>
							</span>
						</div>
					</div>
				</div>
			</div>
		</template>
	</main>
</template>
<script>
import Loading from '@/views/components/common/Loading';

export default {
	name: 'Solo',
	components: {
		Loading,
	},
	data() {
		return {
			pageLoading: false,
			editMode: 'mobile',
			isLoading: false,
			showContent: null,
		}
	},
	methods: {
		closeDrawer() {
			this.$router.go(-1);
		},
		onResize() {
			this.wWidth = window.innerWidth;
			if(this.wWidth < 1200) {
				this.editMode = 'mobile'
			} else {
				this.editMode = 'desktop'
			}
		},
		init() {
			this.pageLoading = true;
			setTimeout(() => {
				this.pageLoading = false;
			}, 500)
		}
	},
	mounted() {
		this.onResize();
		this.$nextTick(() => {
			window.addEventListener('resize', () => {
				this.onResize();
			});
		});

		this.init();
		
	}
}
</script>
<style lang="scss">
.solo {
	padding-top: var(--head);
	.mobile__head {
		@extend %flexCenterBetween;
		@include size(100%, var(--head));
		padding: 0 var(--gutter);
		position: relative;
		&__back {
			@extend %flexCenterStart;
			width: calc(100% - var(--btn));
			cursor: pointer;
			&__icon {
				@extend %flexCenterCenter;
				@include size(var(--smallicon),var(--smallicon));
				svg {
					display: block;
					@include size(75%,75%);
					fill: $color-black;
				}
			}
			&__label {
				@extend %text-15;
				display: block;
				width: max-content;
				font-variation-settings: 'wght' 600;
				padding-left: var(--minigutter);
			}
		}
	}
	&__box {
		@extend %panel;
		@extend %flexCenterBetween;
		flex-wrap: wrap;
		width: 100%;
		padding: var(--gutter);
		&__title,
		&__text {
			display: block;
			width: 100%;
			text-align: left;
			margin-bottom: var(--supergutter);
		}
		&__title {
			@extend %text-25;
			font-family: $font-manrope;
			color: $color-blue;
			font-variation-settings: 'wght' 700;
		}
		&__links {
			@extend %flexCenterCenter;
			flex-wrap: wrap;
			width: 100%;
			margin-bottom: var(--supergutter);
		}
	}
	&__link {
		background: $color-blue;
		padding: 0 var(--gutter);
		margin: 0 var(--thirdgutter) var(--minigutter);
		color: $color-white;
	}
	&__head {
		display: block;
		width: 100%;
		margin: var(--gutter) 0;
		&__title {
			display: block;
			@extend %text-33;
			font-family: $font-manrope;
			font-variation-settings: 'wght' 700;
			margin-bottom: var(--thirdgutter);
		}
		@media (min-width: $from-large) {
			display: none;
		}
	}
	&__content {
		display: block;
		width: 100%;
		margin: var(--supergutter) 0;
		@media (min-width: $from-large) {
			.small-panel {
				height: var(--smallpanelh);
			}
		}
	}
	&__instruction {
		display: block;
		width: 100%;
		margin: var(--gutter) 0;
		.inst {
			&__step {
				@extend %flexCenterStart;
				flex-wrap: wrap;
				width: 100%;
				margin-bottom: var(--gutter);
				&__title {
					@extend %flexBaselineStart;
					@extend %text-20;
					width: 100%;
					font-variation-settings: 'wght' 700;
					color: $color-blue;
					margin-bottom: var(--minigutter);
					&__number{
						@extend %flexCenterCenter;
						@include size(var(--gutter),var(--gutter));

					}
					&__label {
						display: block;
						width: calc(100% - var(--gutter));
						padding-left: var(--minigutter);
					}
				}
				&__content {
					@extend %flexStartStart;
					flex-wrap: wrap;
					width: 100%;
					padding-left: calc(var(--gutter) * 1.5);
					&__text {
						display: block;
						width: 100%;
						margin-bottom: var(--minigutter);
					}
					&__image {
						display: block;
						width: 100%;
						max-width: 400px;
					}
				}
				&:last-of-type {
					.inst__step__content {
						width: 100%;
						padding-left: 0;
					}
				}
			}
		}
	}
	@media (min-width: $from-large) {
		.mobile__head {
			display: none;
		}
		&__box {
			padding: var(--supergutter) var(--supergutter) var(--gutter);
		}
		&__block {
			&__link {
				width: calc(100% / 4 - 20px);
			}
		}
	}
	@media (max-width: $to-large) {
		position: fixed;
		top: 0;
		left: 0;
		display: block;
		background: $color-white !important;
		padding-top: 0;
		z-index: 100000;
		&__head {
			display: none;
		}
		&__box {
			border-radius: 0;
			box-shadow: none;
			padding: 0;
		}
		&__content {
			background: $color-white;
			margin-top: 0;
		}
	}
}
</style>
