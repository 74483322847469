<template>
	<div class="booking">
		<div class="booking__head">
			<span class="booking__head__label">
				{{ $t('common.titles.bookings') }}
			</span>
			<template v-if="list && list.length">
				<span class="booking__head__actions">
					<router-link :to="{ name: 'bookings.page' }" class="booking__head__btn simple-btn">
						{{ $t('common.viewall') }}
					</router-link>
				</span>
			</template>
		</div>
		<div class="booking__content">
			<template v-if="isLoading">
				<span class="booking__loading">
					<Spinner />
				</span>
			</template>
			<template v-else>
				<template v-if="list && list.length">
					<BookingPreview v-for="(l, lI) in list" :key="lI" type="list" :booking="l" />
				</template>
				<template v-else>
					<small class="booking__content__nodata">
						<span class="booking__content__nodata__btn" v-html="$t('bookings.empty.title')"></span>
					</small>
				</template>
			</template>
		</div>
	</div>
</template>
<script>
import BookingPreview from '@/views/components/booking/BookingPreview';
import Spinner from '@/views/components/common/Spinner';

export default {
	name: 'Bookings',
	props: {
		list: {
			required: true,
			default: []
		},
		isLoading: {
			type: Boolean,
			required: true,
			defualt: false,
		}
	},
	components: {
		Spinner,
		BookingPreview
	},
}
</script>
<style lang="scss">
.booking {
	@extend %panel;
	@extend .bigradius;
	width: 100%;
	font-family: $font-manrope;
	padding: var(--minigutter);
	&__head {
		@extend %flexCenterBetween;
		width: 100%;
		margin-bottom: var(--minigutter);
		&__label {
			@extend %text-20;
			font-variation-settings: 'wght' 700;
		}
		&__actions {
			@extend %flexCenterEnd;
		}
		&__btn {
			@extend %flexCenterCenter;
			@extend %simplebtn;
		}
	}
	&__loading {
		@extend %flexCenterCenter;
		@include size(100%,100%);
		position: relative;
		.spinner {
			@include size(30px,30px);
		}
	}
	&__content {
		display: block;
		width: 100%;
		&__nodata {
			@extend %flexCenterCenter;
			width: 100%;
			padding: var(--supergutter);
			text-transform: uppercase;
			&__btn {
				padding: var(--thirdgutter) var(--gutter);
				text-align: center;
			}
		}
	}
	@media (min-width: $from-large) {
		width: 49%;
		padding: var(--gutter);
	}
}
</style>