<template>
	<div class="bookingdelete">
		<div class="bookingdelete__head">
			<span class="bookingdelete__head__back" @click="closeDrawer">
				<span class="bookingdelete__head__back__icon">
					<svg viewBox="0 0 50 50">
						<use xlink:href="#icon--back" width="100%" height="100%"></use>
					</svg>
				</span>
				<span class="bookingdelete__head__back__label">
					{{ $t('common.back') }}
				</span>
			</span>
		</div>
		<div class="bookingdelete__box">
			<div class="bookingdelete__box__head">
				<div class="bookingdelete__box__head__title">
					{{ $t('bookings.delete.reason') }}
				</div>
				<span class="bookingdelete__box__head__close" @click="closeModal">
					<svg viewBox="0 0 50 50">
						<use xlink:href="#icon--close" width="100%" height="100%"></use>
					</svg>
				</span>
			</div>
			<div class="bookingdelete__box__text">
				<span class="bookingdelte__text" v-html="$t('bookings.delete.text')"></span>
				<form id="delete-reason" name="delete-reason" class="bookingdelete__form">
					<div class="form__field">
						<label v-for="r in reasons" :key="r.id" :for="`reason_${r.id}`" class="form__label">
							<input type="checkbox" :name="`reason_${r.id}`" :id="`reason_${r.id}`" :value="r.id" v-model="motivo" />
							<span class="form__checkbox" :class="{ 'checked': motivo }">
								<svg viewBox="0 0 50 50">
									<use xlink:href="#icon--check" width="100%" height="100%"></use>
								</svg>
							</span>
							<span class="form__label__text" v-html="r.description"></span>
						</label>
						<textarea v-if="showtext" name="altro" id="altro-text" class="form__textarea" v-model="cancel_note"></textarea>
					</div>
				</form>
			</div>
			<div class="bookingdelete__box__actions">
				<span class="bookingdelete__box__btn btn outline" @click="closeModal">
					{{ $t('common.btns.cancel') }}
				</span>
				<span class="bookingdelete__box__btn btn filled" @click="deleteBooking">
					{{ $t('common.btns.delete') }}
				</span>
			</div>
		</div>
	</div>
</template>
<script>
import { EventBus } from '@/events_bus.js';
import { gsap } from 'gsap';
import { CustomEase } from 'gsap/all';
gsap.registerPlugin(CustomEase);

export default {
	name: 'Delete',
	props: {
		mode: {
			type: String,
			required: true,
			default: 'mobile'
		},
		bid: {
			required: true,
		},
		reasons: {
			type: Array,
			required: true,
			default: []
		}
	},
	data() {
		return {
			booking: null,
			motivo: [],
			showtext: false,
			cancel_note: null,
		}
	},
	watch: {
		motivo: {
			handler(newval, oldval) {
				if(newval.includes(7)) {
					this.showtext = true;
				} else {
					this.showtext = false;
				}
			}
		}
	},
	methods: {
		closeWindow() {
			if(this.mode == 'mobile') {
				this.closeDrawer()
			} else {
				this.closeModal();
			}
		},
		deleteBooking() {
			this.$service.bookings.detail.cancel(this.bid, this.reasons).then(res => {
				this.closeWindow();
				this.$router.push({ name: 'bookings.page' })
			})
		},
		openModal() {
			gsap.timeline({
				onComplete: () => {
					gsap.set('.bookingdelete', {
						pointerEvents: 'all'
					})
				}
			})
				.to('.bookingdelete', {
					duration: 0.3,
					opacity: 1
				})
				.to('.bookingdelete__box', {
					duration: 0.3,
					y: '0%',
					opacity: 1
				})
		},
		closeModal() {
			gsap.timeline({
				onComplete: () => {
					gsap.set('.bookingdelete', {
						pointerEvents: 'none'
					})
				}
			})
			.to('.bookingdelete__box', {
				duration: 0.3,
				y: 150,
				opacity: 0
			})
			.to('.bookingdelete', {
				duration: 0.3,
				opacity: 0
			})
		},
		openDrawer() {
			gsap.to('.bookingdelete', {
				duration: 0.9,
				x: '0%',
				ease: CustomEase.create("custom", "M0,0 C0.726,0 0,1 1,1 "),
				onComplete: () => {
					gsap.set('.bookingdelete', {
						pointerEvents: 'all'
					})
				}
			})
		},
		closeDrawer() {
			gsap.to('.bookingdelete', {
				duration: 0.9,
				x: '100%',
				ease: CustomEase.create("custom", "M0,0 C0.726,0 0,1 1,1 ")
			})
		}
	},
	mounted() {
		EventBus.$on('delete-booking', (booking) => {
			this.booking = booking;
			if(this.mode == 'mobile') {
				this.openDrawer()
			} else {
				this.openModal();
			}
		});
	}
}
</script>
<style lang="scss">
.bookingdelete {
	@extend %flexCenterCenter;
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	background: $color-seasalt;
	z-index: 10000;
	display: block;
	overflow: auto;
	transform: translateX(100%);
	pointer-events: none;
	&__head {
		@extend %flexCenterStart;
		@include size(100%, var(--headDrawer));
		padding: 0 var(--gutter);
		&__back {
			@extend %flexCenterStart;
			width: calc(100% - var(--btn));
			cursor: pointer;
			&__icon {
				@extend %flexCenterCenter;
				@include size(var(--smallicon),var(--smallicon));
				svg {
					display: block;
					@include size(75%,75%);
					fill: $color-black;
				}
			}
			&__label {
				@extend %text-15;
				display: block;
				width: max-content;
				font-variation-settings: 'wght' 600;
				padding-left: var(--minigutter);
			}
		}
	}
	&__box {
		@extend  %panel;
		background: transparent;
		padding: 0 var(--gutter);
		height: auto;
		overflow: auto;
		&__head {
			@extend %flexCenterBetween;
			width: 100%;
			display: none;
			margin-bottom: var(--gutter);
			&__title {
				@extend %text-33;
				display: block;
				width: 100%;
				font-family: $font-manrope;
				font-variation-settings: 'wght' 700;
			}
			&__close {
				@extend %icon;
				svg {
					@include size(70%,70%);
					fill: $color-black;
				}
			}
		}
		&__text {
			@extend %text-19;
			display: block;
			width: 100%;
			.form__label {
				width: 100%;
				cursor: pointer;
			}
			.form__textarea {
				display: block;
				width: calc(100% - var(--gutter));
				margin-left: var(--gutter);
			}
		}
		&__actions {
			@extend %flexCenterBetween;
			width: 100%;
		}
		&__btn {
			width: 100%;
			&.filled {
				background: $color-red;
				color: $color-white;
			}
		}
		@media (max-width: $to-large) {
			display: flex;
			box-shadow: none;
			flex-direction: column;
			justify-content: space-between;
			height: calc(100svh - var(--headDrawer));
			padding-bottom: var(--gutter);
			&__btn {
				&.outline {
					display: none;
				}
			}
		}
	}
	&__text {
		display: block;
		width: 100%;
		@extend %text-18;
	}
	&__form {
		display: block;
		width: 100%;
		max-height: 300px;
		overflow: auto;
		margin: var(--gutter) 0;
		.form__field {
			margin-bottom: 0;
		}
		.form__checkbox {
			border: 2px solid $color-blue;
			border-radius: calc(var(--radius) / 3);
		}
		input[type="checkbox"]:not(checked) ~ .form__checkbox {
			border: 2px solid $color-blue !important;
		}
		input[type="checkbox"]:checked ~ .form__checkbox {
			border: 2px solid $color-blue !important;
		}
	}
	@media (min-width: $from-large) {
		display: flex;
		background: rgba($color-black, 0.1);
		transform: translateX(0%);
		opacity: 0;
		&__head {
			display: none;
		}
		&__box {
			background: $color-white;
			max-height: 90vh;
			width: 780px;
			height: 75vh;
			padding: var(--gutter);
			transform: translateY(150px);
			opacity: 0;
			position: relative;
			&__head {
				display: flex;
				margin-bottom: var(--thirdgutter);
			}
			&__text {
				font-size: 0.938rem;
			}
			&__content {
				position: absolute;
				top: 50%;
				left: 0;
				display: block;
				width: 100%;
				text-align: center;
				transform: translateY(-50%);
			}
			&__actions {
				position: absolute;
				bottom: var(--gutter);
				left: 0;
				width: 100%;
				padding: 0 var(--gutter);
			}
			&__btn {
				width: 47%;
			}
		}
	}
}
</style>