<template>
	<div class="qtyselector" :class="{ 'disabled': !editable }">
		<button type="button" class="qtyselector__btn qtyselector__decrement" @click="decrementQuantity" v-bind:disabled="isMin">
        	&minus;
      	</button>
      	<div class="qtyselector__value" v-html="quantity"></div>
      	<input type="hidden" v-model="quantity" />
      	<button type="button" class="qtyselector__btn qtyselector__increment" @click="incrementQuantity" v-bind:disabled="isMax">
	        &plus;
     	</button>
	</div>
</template>
<script>
export default {
	name: 'QuantitySelector',
	props: {
		savedValue: {
			type: Number,
			required: false,
		},
		min: {
			type: Number,
			required: false,
			default: 0,
		},
		max: {
			type: Number,
			required: false,
			default: 10
		},
		editable: {
			type: Boolean,
			required: false,
			default: true,
		}
	},
	data() {
		return {
			quantity: null,
			isMin: true,
			isMax: false
		}
	},
	watch: {
		quantity: function() {
			this.isMin = this.quantity <= this.min;
			this.isMax = this.quantity >= (this.max + 1);
		}
	},
	methods: {
		decrementQuantity() {
			this.quantity -= 1;
			this.$emit('updatequantity', this.quantity);
		},
		incrementQuantity() {
			this.quantity += 1;
			this.$emit('updatequantity', this.quantity);
		}
	},
	mounted() {
		if(this.savedValue) {
			this.quantity = this.savedValue;
		} else {
			this.quantity = this.min;
		}
	}
}
</script>
<style lang="scss">
.qtyselector {
	@extend %flexCenterBetween;
	width: calc((var(--icon) * 3) + (var(--thirdgutter) * 2));
	// &.disabled {
	// 	& * {
	// 		pointer-events: none !important;
	// 		opacity: 0.6 !important;
	// 	}
	// }
	&__btn, &__value {
		@extend %flexCenterCenter;
		@include size(var(--icon),var(--icon));
		border-radius: var(--inputradius);
		font-variation-settings: 'wght' 700;
	}
	&__btn {
		@extend %text-33;
		@include size(var(--icon),var(--icon));
		border: 1px solid $color-blue;
		pointer-events: all;
		cursor: pointer;
		&:first-of-type {
			margin-right: var(--thirdgutter);
		}
		&:last-of-type {
			margin-left: var(--thirdgutter);
		}
	}
	&__value {
		@extend %text-20;
		color: $color-black;
		border: 1px solid $color-davy;
	}
}
</style>