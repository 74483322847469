import Vue from 'vue';

export default {
	install: function (Vue) {
		let appleCdn = document.createElement('script');
		appleCdn.setAttribute('src', 'https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js');
		appleCdn.type = 'text/javascript';
		appleCdn.async = true;
		document.head.appendChild(appleCdn);
	},
};
