<template>
	<main id="page" class="garagedetail" ref="page">
		<div class="mobile__head">
			<span class="mobile__head__back" @click="closeDrawer">
				<span class="mobile__head__back__icon">
					<svg viewBox="0 0 50 50">
						<use xlink:href="#icon--back" width="100%" height="100%"></use>
					</svg>
				</span>
				<span class="mobile__head__back__label">
					{{ $t('common.back') }}
				</span>
			</span>
		</div>
		<div class="garagedetail__head">
			<div class="content">
				<div class="garagedetail__head__title">
					Garage
				</div>
			</div>
		</div>
		<div class="garagedetail__content">
			<div class="smallcontent">
				<div class="garagedetail__box">
					<GarageForm :isCreate="true" />
				</div>
			</div>
		</div>
		<LoadingModal :mode="editMode" />
		<ConfirmModal :mode="editMode" />
	</main>
</template>
<script>
import { gsap } from 'gsap';
import { CustomEase } from 'gsap/all';
gsap.registerPlugin(CustomEase);

import GarageForm from '@/views/components/garages/GarageForm';
import LoadingModal from '@/views/components/common/LoadingModal';
import ConfirmModal from '@/views/components/garages/ConfirmModal';

export default {
	name: 'CreateGarage',
	components: {
		GarageForm,
		LoadingModal,
		ConfirmModal
	},
	data() {
		return {
			editMode: 'mobile',
			garage: null,
			all: null,
		}
	},
	methods: {
		onResize() {
			this.wWidth = window.innerWidth;
			if(this.wWidth < 1200) {
				this.editMode = 'mobile'
			} else {
				this.editMode = 'desktop'
			}
		},
		closeDrawer() {
			this.$router.go(-1);
		}
	},
	mounted() {
		this.onResize();
		this.$nextTick(() => {
			window.addEventListener('resize', () => {
				this.onResize();
			});
		});
	}
}
</script>
<style lang="scss">
.garagedetail {
	padding-top: var(--head);
	overflow: auto;
	.mobile__head {
		@extend %flexCenterBetween;
		@include size(100%, var(--head));
		padding: 0 var(--gutter);
		position: relative;
		&__back {
			@extend %flexCenterStart;
			width: calc(100% - var(--btn));
			cursor: pointer;
			&__icon {
				@extend %flexCenterCenter;
				@include size(var(--smallicon),var(--smallicon));
				svg {
					display: block;
					@include size(75%,75%);
					fill: $color-black;
				}
			}
			&__label {
				@extend %text-15;
				display: block;
				width: max-content;
				font-variation-settings: 'wght' 600;
				padding-left: var(--minigutter);
			}
		}
	}
	&__empty {
		@extend %flexCenterCenter;
		flex-direction: column;
		@include size(330px,100%);
		margin: 0 auto;
		margin-top: var(--supergutter);
		&__media {
			display: block;
			@include size(200px,220px);
			margin-bottom: var(--supergutter);
			&__image {
				display: block;
				@include size(100%,100%);
				object-fit: contain;
			}
		}
		&__title {
			@extend %text-25;
			font-family: $font-manrope;
			font-variation-settings: 'wght' 700;
			margin-bottom: var(--minigutter);
			text-align: center;
		}
		&__text {
			@extend %text-15;
			margin-bottom: var(--gutter);
			text-align: center;
		}
		&__add {
			@extend %btn;
			display: block;
			@include size(var(--addbtn),var(--addbtn));
			background: $color-white;
			border: 1px solid $color-blue;
			position: relative;
			padding: 0;
			box-shadow: $shadow;
			@extend %arrowClose;
			&:before,
			&:after {
				background: $color-blue;
				@include size(40%,3px);
			}
			&:before {
				transform: translate(-50%,-50%);
			}
			&:after {
				transform: translate(-50%,-50%) rotate(90deg);
			}
		}
	}
	&__content {
		display: block;
		width: 100%;
		margin: var(--supergutter) 0;
	}
	&__box {
		@extend %panel;
		@extend %flexCenterBetween;
		flex-wrap: wrap;
		width: 100%;
		padding: var(--gutter);
		background: transparent;
		box-shadow: none;
		&__head {
			@extend %flexCenterBetween;
			width: 100%;
			margin-bottom: var(--gutter);
			&__label, &__actions {
				width: 50%;
			}
			&__label {
				@extend %text-15;
				@extend %flexBaselineStart;
				width: 50%;
			}
		}
		&__content {
			width: 100%;
		}
	}
	&__headactions {
		@extend %flexCenterEnd;
		width: 100%;
	}
	&__share {
		@extend %icon;
		width: var(--btn);
		background: $color-white;
		box-shadow: $shadow;
		border: 1px solid $color-silver;
		svg {
			display: block;
			@include size(50%,50%);
			fill: $color-black;
		}
	}
	@media (min-width: $from-large) {
		.mobile__head {
			display: none;
		}
		&__head {
			display: none;
		}
		&__box {
			background: $color-white;
			box-shadow: $shadow;
		}
	}
	@media (max-width: $to-large) {
		position: fixed;
		top: 0;
		left: 0;
		display: block;
		background: $color-antiflash !important;
		padding-top: 0;
		z-index: 100000;
		&__head {
			display: none;
		}
		&__box {
			border-radius: 0;
			box-shadow: none;
			padding: 0;
		}
		&__content {
			margin-top: 0;
		}
	}
}
</style>