<template>
	<div class="changepassword">
		<div class="changepassword__box">
			<div class="changepassword__box__head">
				<div class="changepassword__box__head__title">
					{{ $t('account.password.title') }}
				</div>
				<span class="accountdelete__box__head__close" @click="closeModal">
					<svg viewBox="0 0 50 50">
						<use xlink:href="#icon--close" width="100%" height="100%"></use>
					</svg>
				</span>
			</div>
			<div v-if="isLoading" class="changepassword__loading">
				<template v-if="!editSaved">
					<span class="changepassword__loading__spinner">
						<span class="changepassword__loading__spinner__box">
							<Spinner />
						</span>
						<span class="changepassword__loading__spinner__label">
							{{ $t('common.is_saving') }}
						</span>
					</span>
				</template>
				<template v-else>
					<span class="changepassword__loading__success">
						{{ $t('common.edit_saved') }}
					</span>
				</template>
			</div>
			<form id="changepassword" class="changepswform">
				<div class="form__field">
					<label for="psw" class="cchangepswform__label">
						{{ $t('create.psw.label') }}
					</label>
					<span class="input-box">
						<input type="password" name="psw" id="psw" class="form__input" :placeholder="$t('create.psw.placeholder')" v-model="form.password" />
						<span class="show-psw" @click="togglePswVisibility">
							<span class="show-psw__show">
								<svg viewBox="0 0 50 50">
									<use xlink:href="#icon--hidepsw" width="100%" height="100%"></use>
								</svg>
							</span>
							<span class="show-psw__hide">
								<svg viewBox="0 0 50 50">
									<use xlink:href="#icon--showpsw" width="100%" height="100%"></use>
								</svg>
							</span>
						</span>
					</span>
					<small class="form__error"></small>
				</div>
				<div class="form__field">
					<label for="confirmpsw" class="cchangepswform__label">
						{{ $t('create.confirmpsw.label') }}
					</label>
					<span class="input-box">
						<input type="password" name="confirmpsw" id="confirmpsw" class="form__input" :placeholder="$t('create.confirmpsw.placeholder')" v-model="form.password_confirmation" />
						<span class="show-psw" @click="togglePswVisibility">
							<span class="show-psw__show">
								<svg viewBox="0 0 50 50">
									<use xlink:href="#icon--hidepsw" width="100%" height="100%"></use>
								</svg>
							</span>
							<span class="show-psw__hide">
								<svg viewBox="0 0 50 50">
									<use xlink:href="#icon--showpsw" width="100%" height="100%"></use>
								</svg>
							</span>
						</span>
					</span>
					<small class="form__error"></small>
				</div>
				<span v-if="message" class="form__field errors">
					<small v-html="message"></small>
				</span>
				<div class="form__field actions">
					<span id="cancel" class="changepassword__button btn" @click="closeModal">
						{{ $t('common.btns.cancel') }}
					</span>
					<span id="save" class="changepassword__button btn filled" @click="saveEdit">
						{{ $t('common.btns.save') }}
					</span>
				</div>
			</form>
		</div>
	</div>
</template>
<script>
import { EventBus } from '@/events_bus.js';
import dayjs from 'dayjs';
import { gsap } from 'gsap';
import { CustomEase } from 'gsap/all';
gsap.registerPlugin(CustomEase);

import Spinner from '@/views/components/common/Spinner';

export default {
	name: 'ChangePassword',
	components: {
		Spinner
	},
	data() {
		return {
			message: null,
			form: {
				password: null,
				password_confirmation: null
			},
			isLoading: false,
			editSaved: false,
			error: null,
			psw: null,
			pswVerify: null,
			nomatch: false,
		}
	},
	watch: {
		'form.password_confirm': {
			handler(newval, oldval) {
				if(this.form.password === this.form.password_confirmation) {
					this.message = null;
				}
			}
		}
	},
	methods: {
		closeWindow() {
			if(this.mode == 'mobile') {
				this.closeDrawer()
			} else {
				this.closeModal();
			}
		},
		openModal() {
			gsap.timeline({
				onComplete: () => {
					gsap.set('.changepassword', {
						pointerEvents: 'all'
					})
				}
			})
				.to('.changepassword', {
					duration: 0.3,
					opacity: 1
				})
				.to('.changepassword__box', {
					duration: 0.3,
					y: '0%',
					opacity: 1
				})
		},
		closeModal() {
			gsap.timeline({
				onComplete: () => {
					gsap.set('.changepassword', {
						pointerEvents: 'none'
					})
				}
			})
			.to('.changepassword__box', {
				duration: 0.3,
				y: 150,
				opacity: 0
			})
			.to('.changepassword', {
				duration: 0.3,
				opacity: 0
			})
		},
		openDrawer() {
			gsap.to('.changepassword', {
				duration: 0.9,
				x: '0%',
				ease: CustomEase.create("custom", "M0,0 C0.726,0 0,1 1,1 "),
				onComplete: () => {
					gsap.set('.changepassword', {
						pointerEvents: 'all'
					})
				}
			})
		},
		closeDrawer() {
			gsap.to('.changepassword', {
				duration: 0.9,
				x: '100%',
				ease: CustomEase.create("custom", "M0,0 C0.726,0 0,1 1,1 ")
			})
		},
		togglePswVisibility(item) {
			let input = item.target.parentNode.querySelector('input');

			item.target.classList.contains('show') ? item.target.classList.remove('show') : item.target.classList.add('show')

			if(input.type == 'password') {
				input.type = 'text';
			} else {
				input.type = 'password';
			}

		},
		saveEdit() {
			this.isLoading = true;
			if(this.form.password === this.form.password_confirmation) {
				this.message = null;
				let data = {
					password: this.form.password,
					password_confirmation: this.form.password_confirmation
				}
				this.$service.user.updatePassword(data).then(res => {
					this.editSaved = true;
					setTimeout(() => {
						this.isLoading = false;
						this.closeModal();
					}, 1500)
				})
			} else {
				this.isLoading = false;
				this.message = this.$t('account.password.errors.nomatch');
			}
		},
		init() {
			
		}
	},
	mounted() {
		EventBus.$on('change-password', () => {
			this.init();
			this.openModal();
		});
	}
}
</script>
<style lang="scss">
.changepassword {
	@extend %flexCenterCenter;
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	background: rgba($color-black, 0.8);
	z-index: 10000;
	overflow: hidden;
	opacity: 0;
	pointer-events: none;
	&__box {
		@extend  %panel;
		@extend %flexCenterCenter;
		flex-wrap: wrap;
		background: $color-seasalt;
		width: max-content;
		max-width: 90vw;
		max-height: 90vh;
		min-width: 300px;
		min-height: 300px;
		padding: var(--gutter);
		overflow: hidden;
		&__head {
			@extend %flexCenterBetween;
			width: 100%;
			display: none;
			margin-bottom: var(--gutter);
			&__title {
				@extend %text-33;
				display: block;
				width: 100%;
				font-family: $font-manrope;
				font-variation-settings: 'wght' 700;
			}
			&__close {
				@extend %icon;
				cursor: pointer;
				svg {
					@include size(70%,70%);
					fill: $color-black;
				}
			}
		}
		&__text {
			@extend %text-19;
			display: block;
			width: 100%;
		}
	}
	&__label {
		display: block;
		width: 100%;
		&__text {
			display: block;
			width: 100%;
		}
	}
	.errors {
		display: block;
		width: 100%;
		color: $color-error;
		text-transform: uppercase;
	}
	.actions {
		@extend %flexCenterBetween;
		flex-wrap: wrap;
	}
	&__button {
		width: 100%;
		text-align: center;
		margin-bottom: var(--gutter);
		&.filled {
			background: $color-blue;
			color: $color-white;
			order: 2;
		}
		&.outline {
			border-color: $color-blue;
			color: $color-blue;
			order: 1;
		}
	}
	&__loading {
		position: absolute;
		top: 0;
		left: 0;
		@extend %flexCenterCenter;
		background: rgba($color-white, 0.86);
		@include size(100%,100%);
		pointer-events: all;
		z-index: 100;
		&__spinner {
			display: block;
			margin-bottom: var(--gutter);
			&__box {
				display: block;
				@include size(65px,65px);
				margin: 0 auto var(--gutter);
			}
			&__label {
				@extend %text-15;
				display: block;
				width: 100%;
				font-family: $font-manrope;
				font-variation-settings: 'wght' 600;
				text-align: center;
			}
		}
		&__success {
			display: block;
			@extend %text-33;
			color: $color-green;
			font-variation-settings: 'wght' 700;
			text-align: center;
		}
	}
	.input-box {
		display: block;
		width: 100%;
		position: relative;
		.show-psw {
			position: absolute;
			top: 50%;
			right: var(--thirdgutter);
			display: block;
			@include size(var(--smallicon),var(--smallicon));
			transform: translateY(-50%);
			cursor: pointer;
			&__show,
			&__hide {
				position: absolute;
				top: 0;
				left: 0;
				display: flex;
				@extend %flexCenterCenter;
				@include size(100%,100%);
				pointer-events: none;
				svg {
					display: block;
					@include size(100%,100%);
					fill: $color-black;
				}
			}
			&__hide {
				display: none;
			}
			&.show {
				.show-psw__show {
					display: none;
				}
				.show-psw__hide {
					display: block;
				}
			}
		}
	}
	.changepswform {
		display: block;
		width: 100%;
		margin: var(--supergutter) 0;
	}
	@media (min-width: $from-large) {
		display: flex;
		background: rgba($color-black, 0.1);
		transform: translateX(0%);
		opacity: 0;
		&__head {
			display: none;
		}
		&__box {
			background: $color-white;
			max-height: 90vh;
			max-width: 780px;
			padding: var(--gutter);
			transform: translateY(150px);
			opacity: 0;
			&__head {
				display: flex;
				margin-bottom: var(--thirdgutter);
			}
			&__text {
				font-size: 0.938rem;
			}
		}
		&__button {
			width: max-content;
			margin-bottom: 0;
			padding: 0 var(--supergutter);
			&.filled {
				order: 2;
			}
			&.outline {
				order: 1;
			}
		}
		.changepswform {
			margin-bottom: 0;
			.form {
				&__field {
					&.actions {
						justify-content: space-between;
					}
				}
			}
		}
	}
}
</style>