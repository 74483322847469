<template>
	<div id="notfound" class="notfound">
		Not Found
	</div>
</template>
<script>
export default {
	name: 'NotFound'
}
</script>
<style lang="scss">
</style>