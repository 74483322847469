<template>
	<div class="accountphone">
		<div class="accountphone__head">
			<span class="accountphone__head__back" @click="closeDrawer">
				<span class="accountphone__head__back__icon">
					<svg viewBox="0 0 50 50">
						<use xlink:href="#icon--back" width="100%" height="100%"></use>
					</svg>
				</span>
				<span class="accountphone__head__back__label">
					{{ $t('common.back') }}
				</span>
			</span>
		</div>
		<div class="accountphone__box">
			<div class="accountphone__box__head">
				<div class="accountphone__box__head__title">
					{{ $t('account.phone.title') }}
				</div>
				<span class="accountphone__box__head__close" @click="closeModal">
					<svg viewBox="0 0 50 50">
						<use xlink:href="#icon--close" width="100%" height="100%"></use>
					</svg>
				</span>
			</div>
			<div class="accountphone__box__text" v-html="$t('account.phone.text')"></div>
			<div class="accountphone__box__content">
				<VerifyPhoneSystem />
			</div>
		</div>
	</div>
</template>
<script>
import { EventBus } from '@/events_bus.js';
import dayjs from 'dayjs';
import { gsap } from 'gsap';
import { CustomEase } from 'gsap/all';
import VerifyPhoneSystem from '@/views/components/common/VerifyPhoneSystem';
gsap.registerPlugin(CustomEase);

export default {
	name: 'Phone',
	components: {
		VerifyPhoneSystem
	},
	props: {
		mode: {
			type: String,
			required: true,
			default: 'mobile'
		}
	},
	data() {
		return {
			code: '',
			digitCount: 5,
			digits: null,
			phoneSent: false,
			tooshort: null,
		}
	},
	methods: {
		resend() {
			this.$service.user.phone.verification().then(res => {
				this.phoneSent = true;
			})
		},
		openModal() {
			gsap.timeline({
				onComplete: () => {
					gsap.set('.accountphone', {
						pointerEvents: 'all'
					})
				}
			})
				.to('.accountphone', {
					duration: 0.3,
					opacity: 1
				})
				.to('.accountphone__box', {
					duration: 0.3,
					y: '0%',
					opacity: 1
				})
		},
		closeModal() {
			gsap.timeline({
				onComplete: () => {
					gsap.set('.accountphone', {
						pointerEvents: 'none'
					})
				}
			})
			.to('.accountphone__box', {
				duration: 0.3,
				y: 150,
				opacity: 0
			})
			.to('.accountphone', {
				duration: 0.3,
				opacity: 0
			})
		},
		saveEdit() {

		},
		openDrawer() {
			gsap.to('.accountphone', {
				duration: 0.9,
				x: '0%',
				ease: CustomEase.create("custom", "M0,0 C0.726,0 0,1 1,1 "),
				onComplete: () => {
					gsap.set('.accountphone', {
						pointerEvents: 'all'
					})
				}
			})
		},
		closeDrawer() {
			gsap.to('.accountphone', {
				duration: 0.9,
				x: '100%',
				ease: CustomEase.create("custom", "M0,0 C0.726,0 0,1 1,1 ")
			})
		},
		init() {
			this.$service.user.phone.verification().then(res => {
				console.log('PHONE', res);
			})
		},
	},
	mounted() {
		this.digits = [];
		EventBus.$on('verify-phone', () => {
			this.init();
			if(this.mode == 'mobile') {
				this.openDrawer()
			} else {
				this.openModal();
			}
		});
	}
}
</script>
<style lang="scss">
.accountphone {
	--select: 100px;
	@extend %flexCenterCenter;
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	background: $color-seasalt;
	z-index: 10000;
	display: block;
	overflow: auto;
	transform: translateX(100%);
	pointer-events: none;
	&__head {
		@extend %flexCenterStart;
		@include size(100%, var(--headDrawer));
		padding: 0 var(--gutter);
		&__back {
			@extend %flexCenterStart;
			width: calc(100% - var(--btn));
			cursor: pointer;
			&__icon {
				@extend %flexCenterCenter;
				@include size(var(--smallicon),var(--smallicon));
				svg {
					display: block;
					@include size(75%,75%);
					fill: $color-black;
				}
			}
			&__label {
				@extend %text-15;
				display: block;
				width: max-content;
				font-variation-settings: 'wght' 600;
				padding-left: var(--minigutter);
			}
		}
	}
	&__box {
		@extend  %panel;
		background: transparent;
		padding: 0 var(--gutter);
		height: auto;
		overflow: auto;
		&__head {
			@extend %flexCenterBetween;
			width: 100%;
			display: none;
			margin-bottom: var(--gutter);
			&__title {
				@extend %text-33;
				display: block;
				width: 100%;
				font-family: $font-manrope;
				font-variation-settings: 'wght' 700;
			}
			&__close {
				@extend %icon;
				svg {
					@include size(70%,70%);
					fill: $color-black;
				}
			}
		}
		&__text {
			@extend %text-19;
			display: block;
			width: 100%;
		}
		&__code {
			@extend %flexCenterCenter;
			width: 100%;
			margin: calc(var(--supergutter) * 2) 0 0;
			input {
				@extend %text-40;
				@extend %flexCenterCenter;
				@include size(var(--supergutter),var(--smallpanelh));
				border-radius: calc(var(--bigradius) / 2);
				border: 1px solid $color-grey;
				color: $color-black;
				margin: 0 6px;
				text-align: center;
			}
		}
		&__instruction {
			@extend %text-15;
			display: block;
			width: 100%;
			text-align: center;
			margin: var(--gutter) 0;
		}
		&__resend {
			display: block;
			width: max-content;
			margin: var(--gutter) auto 0;
			font-variation-settings: 'wght' 700;
			cursor: pointer;
		}
		@media (max-width: $to-large) {
			box-shadow: none;
		}
	}
	@media (min-width: $from-large) {
		display: flex;
		background: rgba($color-black, 0.1);
		transform: translateX(0%);
		opacity: 0;
		&__head {
			display: none;
		}
		&__box {
			background: $color-white;
			max-height: 90vh;
			width: 780px;
			height: 75vh;
			padding: var(--gutter);
			transform: translateY(150px);
			opacity: 0;
			position: relative;
			&__head {
				display: flex;
				margin-bottom: var(--thirdgutter);
			}
			&__text {
				font-size: 0.938rem;
			}
			&__content {
				position: absolute;
				top: 50%;
				left: 0;
				display: block;
				width: 100%;
				text-align: center;
				transform: translateY(-50%);
			}
		}
	}
}
</style>